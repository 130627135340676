import styled from 'styled-components'

export const TimelineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 55px;
  height: 100%;
  width: 100%;
  padding: 0 64px;

  @media (max-width: 500px) {
    margin-top: 25px;
    padding: 0 32px;
  }
`

export const Year = styled.p`
  font-family: Halyard-Light;
  font-size: 60px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 35px;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 36px;
    margin-bottom: 12px;
  }
`

export const TimelineIntro = styled.h3`
  font-family: Halyard-Light;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  margin-bottom: 50px;
  text-align: center;
  max-width: 792px;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 4px;
    margin-bottom: 40px;
    max-width: 270px;
  }
`

export const SingleColumnImage = styled.div`
  width: 100%;
  max-width: 792px;
  margin-bottom: 75px;
  height: 100%;

  .gatsby-image-wrapper > div {
    padding-bottom: 66.16% !important;
  }
`

export const SingleColumnVideo = styled.div`
  width: 100%;
  max-width: 792px;
  margin-bottom: 75px;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    video {
      min-height: 250px;
    }
  }
`

export const ImageTextWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
  max-width: 1140px;
  height: 100%;

  .content-blok {
    height: 100%;
    width: 100%;

    p {
      font-size: 18px;
      line-height: 1.8;
    }

    b {
      font-family: Halyard-Medium;
      font-size: 24px;
      line-height: 32px;
      font-weight: normal;
    }
  }

  .outer-image-wrapper {
    width: 100%;
    min-height: 280px;
    min-width: 250px;

    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 835px) {
    display: none;
  }

  @media (min-width: 501px) and (max-width: 834px) {
    max-width: 600px;

    .content-blok {
      p {
        font-size: 24px;
        line-height: 32px;
      }

      b {
        font-family: Halyard-Medium;
        font-size: 35px;
        line-height: 43px;
        font-weight: normal;
      }
    }
  }
`

export const ImageTextWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  max-width: 1140px;
  height: 100%;

  .content-blok {
    height: 100%;
    width: 100%;

    p {
      font-size: 24px;
      line-height: 32px;
    }

    b {
      font-family: Halyard-Medium;
      font-size: 35px;
      line-height: 43px;
      font-weight: normal;
    }
  }

  .outer-image-wrapper {
    width: 100%;
    max-height: 583px;

    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 835px) {
    display: none;
  }
`

export const FirstAdditionalPoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -42px 0 0 0;
  padding: 0 64px;

  .container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  .first-div {
    margin: 0 auto;
    margin-top: -55px;
  }

  .second-div {
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    width: 35vw;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .svg-container {
    display: flex;
    flex-direction: column;
  }

  #line-svg {
    display: flex;
    align-items: center;
    height: ${({ singleColumn }) => (singleColumn ? '136px' : '800px')};

    .line {
      height: 100%;
    }
  }

  @media (max-width: 500px) {
    padding: 0 32px;

    .second-div {
      left: calc(60% + 10px);
      top: 25%;
      width: 40vw;
    }
  }
`

export const OddAdditionalPoints = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -49px 0 0 0;
  padding: 0 64px;

  .container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  .first-div {
    margin: 0 auto;
    margin-top: -42px !important;
  }

  .second-div {
    position: absolute;
    top: 0;
    right: calc(100% + 10px);
    width: 35vw;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .svg-container {
    display: flex;
    flex-direction: column;
  }

  #line-svg {
    display: flex;
    align-items: center;
    height: 150px;

    .line {
      height: 100%;
    }
  }

  @media (max-width: 500px) {
    padding: 0 32px;

    .second-div {
      right: calc(60% + 10px);
      width: 40vw;
    }
  }
`
export const EvenAdditionalPoints = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -49px 0 0 0;
  padding: 0 64px;

  .container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    margin-top: -42px;
  }

  .first-div {
    margin: 0 auto;
  }

  .second-div {
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    width: 35vw;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .svg-container {
    display: flex;
    flex-direction: column;
  }

  #line-svg {
    display: flex;
    align-items: center;
    height: 150px;

    .line {
      height: 100%;
    }
  }

  @media (max-width: 500px) {
    padding: 0 32px;

    .second-div {
      left: calc(60% + 10px);
      width: 40vw;
    }
  }
`

export const AdditionalPointText = styled.p`
  font-family: Halyard-Light;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 4px;

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const EndingLineWrapper = styled.div`
  margin: 68px 0 200px 0;
  height: 100%;
  width: 100%;

  hr {
    color: #000000;
    height: 1px;
    background-color: #000000;
    border: none;
  }

  @media (max-width: 500px) {
    margin: 57px 0 86px 0;
  }
`
export const LineCircleWrapper = styled.div`
  @media (max-width: 835px) {
    display: none;
  }
`

export const LineCircleMobileWrapper = styled.div`
  @media (min-width: 835px) {
    display: none;
  }
`
