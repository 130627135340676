import React, { useRef } from 'react'
import SbEditable from 'storyblok-react'
import styled, { css } from 'styled-components'
import { media } from 'utils'
import Components from 'components/components.js'
import { Wrapper, DotsDiv, ArrowLeft, ArrowRight } from './styles'
import arrowLeftIcon from 'static/img/arrow_left_light.svg'
import arrowRightIcon from 'static/img/arrow_right_light.svg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const LazySlider = React.lazy(() => import('react-slick'))

function SliderComponent({ blok = {}, ...props }) {
  const sliderRef = useRef()

  const SliderSettings = {
    dots: true,
    appendDots: dots => {
      return (
        <DotsDiv dots_position={blok.dots_position}>
          <ArrowLeft
            src={arrowLeftIcon}
            activeArrow={props.nextActiveArrow}
            deactivateArrow={props.nextDeactivateArrow}
            onClick={() => sliderRef.current.slickPrev()}
          />
          <ul>{dots}</ul>
          <ArrowRight
            src={arrowRightIcon}
            activeArrow={props.prevActiveArrow}
            deactivateArrow={props.prevDeactivateArrow}
            onClick={() => sliderRef.current.slickNext()}
          />
        </DotsDiv>
      )
    },
    infinite: false,
    autoplay: false,
    cssEase: 'ease',
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    swipe: true,
    draggable: false,
    fade: blok.transition === 'fade',
    ...props.setting,
  }

  function renderSlideComponent(slide) {
    return (
      Components[slide.component] &&
      React.createElement(Components[slide.component], {
        blok: slide,
        key: slide._uid,
      })
    )
  }

  function renderSlides() {
    return blok.slides && blok.slides.map(renderSlideComponent)
  }

  const isSSR = typeof window === 'undefined'

  return (
    <SbEditable content={blok}>
      <Wrapper
        equalItemsHeight={props.setting && props.setting.equalItemsHeight}
        style={{...props.style}}
      >
        {props.children || blok.slides ? (
          <>
            {!isSSR ? (
              <React.Suspense fallback={<div />}>
                <StyledSlider ref={sliderRef} {...SliderSettings} arrow_color={blok.arrow_color}>
                  {props.children || renderSlides()}
                </StyledSlider>
              </React.Suspense>
            ) : (
              // else render slides on SSR (eg. to fetch nested images)
              <div style={{ display: 'none' }}>{renderSlides()}</div>
            )}
          </>
        ) : null}
      </Wrapper>
    </SbEditable>
  )
}

const StyledSlider = styled(LazySlider)`
  .slick-prev,.slick-next{
    display: none;
  }

  .slick-prev:before,.slick-next:before{
    display: none;
  }

  ${media.greaterThan('sm')`
    .slick-prev,.slick-next{
      display: block;
      width:40px;
      height:40px;
      opacity:.6;
    }

    .slick-prev:before,.slick-next:before{
      display: block;
      color:${({ arrow_color }) => arrow_color || '#000'};
      font-size:40px;
    }
  `}

  ${media.greaterThan('md')`
      .slick-prev{
        transform: translate(-20px, -50%);
      }
      .slick-next{
        transform: translate(20px, -50%);
      }
  `}
`

export default SliderComponent
