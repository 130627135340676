import styled from 'styled-components'

export const WOAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0px 64px;

  @media (max-width: 834px) {
    padding: 0px 32px;
  }
`

export const WOAWrapper = styled.div`
  max-width: 1600px;
  width: 100%;

  .row {
    display: flex;
    column-gap: 30px;
    margin-bottom: 30px;
  }

  .column {
    flex: 1;
  }

  a {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 834px) {
    .column {
      margin-bottom: 36px;
    }

    .row {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
`
