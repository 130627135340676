import { useState } from 'react'

function useAccordion() {
  const [ref, setRef] = useState(null)
  const [height, setHeight] = useState(0)
  const [originalHeight, setOriginalHeight] = useState(0)

  const toggleAccordion = () => {
    if (height === 0) {
      const newHeight = getNewHeight()
      setHeight(newHeight)
    } else {
      setHeight(0)
    }
  }

  function getNewHeight() {
    const scrollHeight = ref.scrollHeight
    setOriginalHeight(scrollHeight)
    return scrollHeight
  }

  return [setRef, toggleAccordion, height, setHeight, originalHeight]
}

export default useAccordion
