import React, { useState, useEffect, useRef } from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'
import Player from '@vimeo/player'

import { PolyvVideo } from 'components/Common'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function Video({ blok, onVideoPlayerChange }) {
  const videoContainerRef = useRef(null)

  const [isVideoVisible, setVideo] = useState(false)
  const [videoPlayer, setVideoPlayer] = useState()
  const [hideUnMuteButton, setHideUnMuteButton] = useState(false)
  const [thumbnail] = useState(getThumbnail())
  const [playButtonSize, setPlayButtonSize] = useState(0)

  const videoSrc = !IsChinaDeploy
    ? blok.vimeo_link.vimeo_oembed?.response
    : blok.polyv_link

  useEffect(() => {
    if (isVideoVisible && !IsChinaDeploy) {
      const player = new Player(`vimeo-video-${blok.vimeo_link._uid}`, {
        id: blok.vimeo_link.vimeo_raw,
        autoplay: 1,
        muted: window.innerWidth < 1024 ? 1 : 0,
      })
      setVideoPlayer(player)
      onVideoPlayerChange && onVideoPlayerChange(player)
    }
  }, [isVideoVisible])

  useEffect(() => {
    function updatePlayButtonSize() {
      if (videoContainerRef.current) {
        const { offsetWidth, offsetHeight } = videoContainerRef.current
        const containerSize = Math.min(offsetWidth, offsetHeight)
        const buttonSize = containerSize / 6.5 // 1/15th of the container size
        setPlayButtonSize(buttonSize)
      }
    }

    updatePlayButtonSize()
    window.addEventListener('resize', updatePlayButtonSize)

    return () => {
      window.removeEventListener('resize', updatePlayButtonSize)
    }
  }, [])

  function playVideo() {
    /* fire GTM event */
    if (typeof window.__vimeoRefresh === 'function') {
      window.__vimeoRefresh()
    }

    setVideo(true)
  }

  function getThumbnail() {
    let src = ''
    if (IsChinaDeploy) {
      src = getFluidGatsbyImage(get(blok, 'thumbnail'), {
        maxWidth: 1200,
        downloadLocal: true,
      })
    } else {
      src =
        get(blok, 'thumbnail') ||
        get(blok, 'vimeo_link.vimeo_oembed.response.thumbnail_url', '').replace(
          /_\d*x\d*./g /* remove sizing from URL */,
          '.',
        )
    }
    return src
  }

  const handleUnMuteMobile = () =>
    videoPlayer.setMuted(0).then(() => {
      setHideUnMuteButton(true)
    })

  const playButtonSVG = (
    <svg
      width={playButtonSize}
      height={playButtonSize}
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="play-button"
    >
      <g id="Play Button Blue">
        <g id="Ellipse 22" filter="url(#filter0_d_1485_1126)">
          <circle cx="43" cy="39" r="39" fill="#3D68B2" />
        </g>
        <path
          id="Polygon 8"
          d="M57.7799 37.4968C58.4661 37.8777 58.4661 38.8645 57.7799 39.2454L36.3079 51.1654C35.6414 51.5354 34.8226 51.0535 34.8226 50.2911L34.8226 26.451C34.8226 25.6887 35.6414 25.2067 36.308 25.5767L57.7799 37.4968Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1485_1126"
          x="0"
          y="0"
          width="86"
          height="86"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1485_1126"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1485_1126"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )

  return (
    <SbEditable content={blok}>
      {!isVideoVisible && thumbnail ? (
        <VideoDiv onClick={playVideo} ref={videoContainerRef}>
          <div className="image-wrapper">
            {typeof thumbnail === 'string' ? (
              <img src={thumbnail} />
            ) : (
              <Img fluid={thumbnail} />
            )}
          </div>

          <button type="button" aria-label="play">
            {playButtonSVG}
          </button>
        </VideoDiv>
      ) : !IsChinaDeploy ? (
        <VideoDiv className="margin-standard">
          <div id={`vimeo-video-${blok.vimeo_link._uid}`} />
          {!hideUnMuteButton && (
            <button
              className="unMuteButton"
              aria-label="unmute"
              onClick={handleUnMuteMobile}
            >
              UNMUTE
            </button>
          )}
        </VideoDiv>
      ) : (
        <PolyvVideo className="margin-standard" videoId={videoSrc} />
      )}
    </SbEditable>
  )
}

export default Video

const VideoDiv = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  cursor: pointer;

  :hover .play-button {
    transform: scale(1.18);
  }

  & > div[id^='vimeo-video-'] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  iframe,
  img,
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img,
  .gatsby-image-wrapper {
    left: 50% !important;
    transform: translateX(-50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    display: block;
    width: 82px;
    height: 82px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -41px 0 0 -41px;
    cursor: pointer;
    background: transparent;
    border: none;
    z-index: 2;
  }

  .play-button {
    min-width: 50px;
    max-width: 80px;
    min-height: 50px;
    max-height: 80px;
    transition: transform 0.2s ease;
  }

  .unMuteButton {
    display: none;
  }
`
