import React, { useMemo } from 'react'
import { components } from 'react-select'
import { useIntl } from 'react-intl'
import { StyledLabel } from './styles'

export default function ValueContainer({
  customProps,
  baseProps,
}) {
  const intl = useIntl()

  const {
    noOptionsSelectedMessage,
    oneOptionsSelectedMessage,
    manyOptionsSelectedMessage,
  } = customProps

  const input = baseProps.children[1]
  const { id: inputId } = input.props

  const selectedOptionsCount = baseProps.getValue().length

  const filtersSelectedCountText = useMemo(() => {
    if (selectedOptionsCount === 0) {
      return noOptionsSelectedMessage || intl.formatMessage({ id: 'filtering-no-filters-applied' })
    } else if (selectedOptionsCount === 1) {
      return oneOptionsSelectedMessage || intl.formatMessage({ id: 'filtering-one-filter-applied' })
    }
    return manyOptionsSelectedMessage || intl.formatMessage({ id: 'filtering-many-filters-applied' }, { appliedFiltersCount: selectedOptionsCount })
  }, [selectedOptionsCount])

  return (
    <components.ValueContainer {...baseProps}>
      <StyledLabel htmlFor={inputId}>{filtersSelectedCountText}</StyledLabel>
      {baseProps.children}
    </components.ValueContainer>
  )
}
