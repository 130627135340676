import styled, { keyframes } from 'styled-components'

export const ANIMATION_TIME = 330
export const animationTimeMs = ANIMATION_TIME + 'ms'

export const fadeIn = keyframes`
  0% {
    transform: scale(0);
  }
  100%: {
    transform: scale(1);
  }
`

export const StyledModal = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 2s;
  backdrop-filter: blur(2px);
  min-height: 100vh;
  padding: 0;
`

export const CloseButton = styled.button`
  position: absolute;
  padding: 16px;
  top: 10px;
  right: 10px;
  z-index: 1;
  background: none;
  border: none;
  cursor: pointer;
`

export const StyledModalContent = styled.div`
  position: relative;
  background-color: #fff;
  width: 90%;
  max-width: 600px;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  overflow: auto;
  padding: 20px 0px 0;
  animation: ${fadeIn} ${animationTimeMs};
  transition: transform ${animationTimeMs}, opacity ${animationTimeMs};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 0;
  overflow: scroll;
  max-height: calc(100% - 40px);
  position: fixed;
  left: 0;
  right: 0;
  top: 20px;

  p:last-child {
    padding-bottom: 64px;
  }

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    padding: 64px;
    column-gap: 75px;
  }

  .image-wrapper {
    flex: 1;

    .image-inner-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 35px;

      .title {
        padding-bottom: 0;
        width: 80%;
      }

      a {
        font-family: Halyard-Medium;
        font-size: 15px;
        text-decoration: underline;
        color: #4767ad;
        display: block;
      }
    }

    p {
      text-align: center;
    }

    .gatsby-image-wrapper {
      width: 240px !important;
      height: 240px;
      border-radius: 50%;
      margin-bottom: 16px !important;
    }
  }

  .content-wrapper {
    flex: 1.5;
  }

  @media (max-width: 834px) {
    .modal-wrapper {
      padding: 64px 32px;
    }
  }
`
