import React from 'react'

import { LeftCol, RightCol } from './styles'
import HubSpotForm from 'components/HubSpotForm'
import { StyledMarkdown } from 'components/Common'
import _unescape from 'lodash/unescape'

function LockObj({ blok, submitFun }) {
  return (
    <>
      <LeftCol
        width={[1, 1, 1 / 2]}
        isAlign={blok.align_top_left}
        isRevers={blok.is_revers_on_mobile}
        maxWidth={blok.left_column_max_width}
        isMargin={blok.is_left_column_margin}
        paddingTop={blok.left_column_padding_top || 0}
        paddingTopMb={blok.left_column_padding_top_mb || 0}
      >
        {blok?.lock_heading && <h2>{blok.lock_heading}</h2>}
        {blok?.lock_text && (
          <StyledMarkdown source={_unescape(blok.lock_text)} />
        )}
      </LeftCol>
      <RightCol
        width={[1, 1, 1 / 2]}
        mb={[0, 0, 32]}
        ml={[0, 0, blok.align_top_left ? '50%' : 0]}
        isAlign={blok.align_top_right}
        maxWidth={blok.right_column_max_width}
        isMargin={blok.is_right_column_margin}
        paddingTop={blok.right_column_padding_top || 0}
        paddingTopMb={blok.right_column_padding_top_mb || 0}
      >
        <HubSpotForm
          blok={{
            snippet_after_form_submission_function: () => submitFun(),
            form_id: blok.hutspot_id,
          }}
        />
      </RightCol>
    </>
  )
}

export default LockObj
