import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Accordion from './Accordion'

function AccordionsGroup({ blok }) {
  return (
    <SbEditable content={blok}>
      <AccordionsWrapper
        marginTop={blok.margin_top || 0}
        marginBottom={blok.margin_bottom || 0}
      >
        {blok.body.map(item => (
          <Accordion key={item._uid} blok={item} />
        ))}
      </AccordionsWrapper>
    </SbEditable>
  )
}

export default AccordionsGroup

var AccordionsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral};
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`
