import React from 'react'
import SbEditable from 'storyblok-react'

import { SpeakerWrapper } from './styles'

function SpeakerNoImage({ blok }) {
  return (
    <SbEditable content={blok}>
      <SpeakerWrapper>
        <h5>{blok.name}</h5>
        <h5>{blok.title}</h5>
        <p>{blok.description}</p>
      </SpeakerWrapper>
    </SbEditable>
  )
}

export default SpeakerNoImage
