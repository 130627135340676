import React, { useState, useContext } from 'react'
import { getSize } from 'utils/helpers'
import { FormattedMessage } from 'react-intl'

import { Container, Row } from 'components/Grid'
import CommunityNewsItem from './CommunityNewsItem'
import FeaturedItem from './FeaturedItem'
import {
  HoverEffect,
  StyledSection,
  StyledDepList,
  FeaturedCol,
  StyledCol,
  StyledTabButton,
  LightButton,
} from './styles'

import { useAccordion } from 'hooks'
import ContentContext from 'lib/content.context'


const PaginationStep = 10

const NewsSection = React.memo(function NewsSectionComponent(props) {
  const { allCategories } = useContext(ContentContext)
  const allCommunityNews = props.data

  const [activeCategory, setActiveCategory] = useState('all')
  const [currentData, setCurrentData] = useState(props.data)
  const [currentStep, setCurrentStep] = useState(PaginationStep)
  const [
    setFilterHeight,
  ] = useAccordion()

  function getNews(selectedCategory) {
    switch (selectedCategory) {
      case 'all':
        return allCommunityNews
      default:
        return allCommunityNews.filter(news =>
          news.content.categories.includes(selectedCategory),
        )
    }
  }

  function handleCategorySelect(value) {
    setCurrentData(getNews(value))
    setActiveCategory(value)
    // want to hide filters when selecting new category
    setFilterHeight(0)
    setCurrentStep(PaginationStep)
  }

  function loadMoreData() {
    const nextStep = currentStep + PaginationStep
    setCurrentStep(nextStep)
  }

  function getPaginatedData() {
    return currentData.slice(0, currentStep)
  }

  const renderCategories = (
    <CategoriesList
      categories={allCategories}
      handleChange={handleCategorySelect}
      activeCategory={activeCategory}
      allCommunityNews={allCommunityNews}
    />
  )

  const paginatedData = getPaginatedData()
  let featuredItem = paginatedData.shift()

  return (
    <StyledSection>
      {getPaginatedData().length ? (
        <Container>
          <Row mt={79} justifyContent="center">
            <FeaturedCol width={[1, 1, 10 / 12]}>
              <HoverEffect>
                <FeaturedItem node={featuredItem} />
              </HoverEffect>
            </FeaturedCol>
          </Row>
          <Row>
            {paginatedData.map((item, index) => (
              <StyledCol key={index} width={getSize(props.blok.sizes)}>
                <HoverEffect>
                  <CommunityNewsItem node={item} />
                </HoverEffect>
              </StyledCol>
            ))}
          </Row>
          <LightButton
            onClick={loadMoreData}
            disabled={currentStep >= currentData.length}
          >
            <FormattedMessage id="load more" />
          </LightButton>
        </Container>
      ) : null}
    </StyledSection>
  )
})

const SelectTab = React.memo(function SelectTabComponent(props) {
  function handleSelect() {
    props.onSelect(props.value)
  }
  return (
    <StyledTabButton onClick={handleSelect} {...props}>
      {props.children}
    </StyledTabButton>
  )
})

const CategoriesList = React.memo(function CategoriesListComponent(props) {
  function countCategories(category) {
    let allCommunityNewsCategories = []
    let news = props.allCommunityNews.map(newsItem => newsItem.content.categories)

    allCommunityNewsCategories = allCommunityNewsCategories.concat(...news)

    return allCommunityNewsCategories.filter(
      categoryName => categoryName === category,
    ).length
  }

  return (
    <StyledDepList>
      <li>
        <SelectTab
          onSelect={props.handleChange}
          value="all"
          className={props.activeCategory === 'all' ? 'active' : ''}
        >
          <FormattedMessage id="all categories" /> ({props.allCommunityNews.length})
        </SelectTab>
      </li>
      {props.categories.map(({ node }, index) => {
        return (
          <li key={index}>
            <SelectTab
              onSelect={props.handleChange}
              value={node.name}
              className={props.activeCategory === node.name ? 'active' : ''}
            >
              {node.name} ({countCategories(node.name)})
            </SelectTab>
          </li>
        )
      })}
    </StyledDepList>
  )
})

export default NewsSection
