import React, { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { Card, CardRow } from './styles'

import WhiteArrow from 'static/img/chevron-circle-fill-light.svg'
import { removeGlobalSlug, withSlash } from 'utils/helpers'

gsap.registerPlugin(ScrollTrigger)

export default function CardCarousel({ blok, index }) {
  const cardApp = useRef()
  const cardAppTwo = useRef()

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set('.education-card', {
        opacity: 0,
        y: 75,
        scale: 0.6,
      })

      ScrollTrigger.batch('.education-card', {
        onEnter: batch =>
          gsap.to(batch, {
            opacity: 1,
            y: 0,
            scale: 1,
            stagger: 0.15,
          }),
      })
    }, cardApp)
    return () => ctx.revert()
  })

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set('.education-card-two', {
        opacity: 0,
        y: 75,
        scale: 0.6,
      })

      ScrollTrigger.batch('.education-card-two', {
        onEnter: batch =>
          gsap.to(batch, {
            opacity: 1,
            y: 0,
            scale: 1,
            stagger: 0.15,
          }),
      })
    }, cardAppTwo)
    return () => ctx.revert()
  })

  return (
    <>
      <CardRow ref={cardApp} className="row-one">
        {blok.card_row_one.map(card => {
          return (
            <Card className="education-card">
              <a
                styles={{ display: 'flex' }}
                href={card.link ? card.link.url ? card.link.url : withSlash(removeGlobalSlug(card.link.cached_url)) : ''}
              >
                <img
                  className="background-image"
                  src={'https:' + card.background_image}
                  alt="solid color background with dots that form a shape"
                />
                <div class="card-content">
                  <h5>{card.headline}</h5>
                  <h2>{card.subheadline}</h2>
                  <p>{card.intro_text}</p>
                </div>
                <div className="button">
                  <div className="button-content">
                    <p>{card.button_text}</p> <img src={WhiteArrow} />
                  </div>
                </div>
              </a>
            </Card>
          )
        })}
      </CardRow>

      <CardRow ref={cardAppTwo} className="row-two">
        {blok.card_row_two.map(card => {
          return (
            <Card className="education-card-two">
              <a
                styles={{ display: 'flex' }}
                href={card.link ? card.link.url ? card.link.url : withSlash(removeGlobalSlug(card.link.cached_url)) : ''}
              >
                <img
                  className="background-image"
                  src={'https:' + card.background_image}
                  alt="solid color background with dots that form a shape"
                />
                <div class="card-content">
                  <h5>{card.headline}</h5>
                  <h2>{card.subheadline}</h2>
                  <p>{card.intro_text}</p>
                </div>
                <div className="button">
                  <div className="button-content">
                    <p>{card.button_text}</p> <img src={WhiteArrow} />
                  </div>
                </div>
              </a>
            </Card>
          )
        })}
      </CardRow>
    </>
  )
}
