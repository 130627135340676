import React from 'react'
import SbEditable from 'storyblok-react'
import {
  getFixedGatsbyImage,
  getFluidGatsbyImage,
} from 'gatsby-plugin-storyblok-image'
import Img from 'gatsby-image'

import { useDeviceSize } from 'hooks'
import { StyledSection, DoubleImage } from './styles'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const LazySlider = React.lazy(() => import('react-slick'))

const settings = {
  className: 'slider variable-width',
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 500,
  speed: 7500,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  variableWidth: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  swipe: false,
  draggable: false,
}
const mobileSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2500,
  speed: 1000,
  cssEase: 'linear',
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  rows: 2,
  slidesPerRow: 1,
  swipe: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  touchMove: false,
  draggable: false,
  fade: false,
  variableWidth: false,
  accessibility: false,
}

const isSSR = typeof window === 'undefined'

const ImageGallery = React.memo(function ImageGallery({ blok, isMobile }) {
  return (
    <SbEditable content={blok}>
      <StyledSection
        paddingTop={blok.padding_top || 10}
        paddingBottom={blok.padding_bottom || 0}
        paddingTopMb={blok.padding_top_mobile || 10}
        paddingBottomMb={blok.padding_bottom_mobile || 60}
      >
        {!isSSR ? (
          <React.Suspense fallback={<div />}>
            {isMobile !== undefined &&
              (!isMobile ? (
                <LazySlider {...settings}>
                  {blok.items &&
                    blok.items.reduce((acc, item, index, c) => {
                      switch (index % 3) {
                        case 0:
                          acc.push(
                            <div css="margin: 40px" key={item._uid}>
                              <Img fixed={item.mediaFixed} />
                            </div>,
                          )
                          break
                        case 1:
                          const ImagePropsI = c[index].mediaFixed
                          if (c[index + 1]) {
                            const ImagePropsII = c[index + 1].mediaFixed
                            acc.push(
                              <DoubleImage key={item._uid}>
                                <div>
                                  <Img fixed={ImagePropsI} />
                                </div>
                                <div>
                                  <Img fixed={ImagePropsII} />
                                </div>
                              </DoubleImage>,
                            )
                          } else {
                            acc.push(
                              <div css="margin-top: 37px" key={item._uid}>
                                <Img fixed={ImagePropsI} />
                              </div>,
                            )
                          }
                          break
                        default:
                          break
                      }
                      return acc
                    }, [])}
                </LazySlider>
              ) : (
                <LazySlider {...mobileSettings}>
                  {blok.items &&
                    blok.items.map((el, i) => {
                      const ImageProps = el.mediaFluid
                      return (
                        <div
                          key={`image_el__${i}`}
                          css="margin: 11px 0; padding: 0 15px;"
                        >
                          <Img fluid={ImageProps} />
                        </div>
                      )
                    })}
                </LazySlider>
              ))}
          </React.Suspense>
        ) : (
          // else render slides on SSR (eg. to fetch nested images)
          <div style={{ display: 'none' }}>
            {blok.items &&
              blok.items.map((el, i) => {
                const ImageProps = el.mediaFluid
                return (
                  <div
                    key={`image_el__${i}`}
                    css="margin: 11px 0; padding: 0 15px;"
                  >
                    <Img fluid={ImageProps} />
                  </div>
                )
              })}
          </div>
        )}
      </StyledSection>
    </SbEditable>
  )
})

export default function ImageGallerySlider({ blok, ...props }) {
  const { isMobile } = useDeviceSize()

  let items
  // parse items to save all images to local
  if (!items) {
    items = blok.items.map((item, index) => {
      if (item.media && typeof item.media === 'string') {
        let fixedProps
        if (index % 3 === 0) {
          fixedProps =
            item.media &&
            getFixedGatsbyImage(item.media, {
              width: 360,
              height: 280,
              downloadLocal: true,
            })
        } else {
          fixedProps =
            item.media &&
            getFixedGatsbyImage(item.media, {
              width: 262,
              height: 122,
              downloadLocal: true,
            })
        }

        const fluidProps =
          item.media &&
          getFluidGatsbyImage(item.media, {
            maxWidth: 600,
            downloadLocal: true,
          })

        item.mediaFixed = fixedProps
        item.mediaFluid = fluidProps
      }
      return item
    })
  }

  return (
    <ImageGallery {...props} blok={{ ...blok, items }} isMobile={isMobile} />
  )
}
