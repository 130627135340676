import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Content from 'components/Content'
import ImageComponent from '../image'
import Video from 'components/NewVideo/InlineVideo'
import Headline from '../Headline'

function ContentBlock({ blok }) {
  return (
    <SbEditable content={blok}>
      <ContentBlockWrapper className="margin-standard margin-end">
        <div className="content-block-container">
          <div className="content-wrapper">
            <Headline type="h3" blok={blok} className="headline-medium-large" />
            <Content className="body-medium" blok={{ content: blok.content }} />
          </div>

          {blok.image !== '' ? (
            <div className="image-wrapper">
              <ImageComponent blok={{ media: blok.image }} />
            </div>
          ) : (
            <div className="video-wrapper">
              <Video
                blok={{
                  vimeo_link: blok.video_link,
                  thumbnail: blok.video_thumbnail,
                  polyv_link: blok.polyv_link,
                }}
              />
            </div>
          )}
        </div>
      </ContentBlockWrapper>
    </SbEditable>
  )
}

export default ContentBlock

const ContentBlockWrapper = styled.div`
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  .content-block-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-wrapper {
      width: 100%;
      max-width: 792px;

      .gatsby-image-wrapper > div {
        padding-bottom: 66.667% !important;
      }
    }

    .video-wrapper {
      width: 100%;
      max-width: 792px;

      > div {
        padding-bottom: 66.667% !important;
      }
    }

    .content-wrapper {
      width: 80%;
      max-width: 792px;

      p {
        text-align: center;
        margin-bottom: 60px;
      }
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;
  }

  @media (max-width: 500px) {
    .content-block-container {
      .content-wrapper {
        width: 100%;
      }
    }
  }
`
