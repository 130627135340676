import React, { useLayoutEffect, useContext, useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ContentContext from 'lib/content.context'
import styled from 'styled-components'

import { removeGlobalSlug, withSlash } from 'utils/helpers'

import ImageComponent from '../image'
import OpenEdIcon from 'static/img/opened_logo_white.png'

gsap.registerPlugin(ScrollTrigger)

function WOAPostDisplay({ blok }) {
  let { allNews } = useContext(ContentContext)
  const [filteredNews, setFilteredNews] = useState(null)

  useEffect(() => {
    const sortNews = allNews.filter(newsItem => newsItem.content.hide === false)
    setFilteredNews(sortNews)
  }, [allNews])

  useLayoutEffect(() => {
      let ctx = gsap.context(() => {
        gsap.set('.article:nth-child(n+7)', { autoAlpha: 0 })

        ScrollTrigger.batch('.article:nth-child(n+7)', {
          interval: 0.5,
          onEnter: batch => gsap.to(batch, { autoAlpha: 1, stagger: 0.15 }),
        })
      })

      return () => ctx.revert()
  }, [filteredNews])

  return (
    <SbEditable content={blok}>
      <OpenEdWrapper
        className="margin-end margin-standard"
        style={{ minHeight: '100vh' }}
      >
        <div className="article-container">
          {filteredNews &&
            filteredNews.map((article, index) => {
              return (
                <a
                  className="article"
                  href={withSlash(removeGlobalSlug(article.slug))}
                >
                  <div>
                    <div className="image-wrapper">
                      <ImageComponent
                        blok={{
                          media: article.content.cover_image,
                          alt:
                            'image respresenting Avenues` article about ' +
                            article.content.title,
                        }}
                      />
                      <img
                        className="opened-icon"
                        src={OpenEdIcon}
                        alt="Avenues Open.Ed icon"
                      />
                    </div>
                    <h3>
                      {article.content.title}
                      <span> {article.content.subheading}</span>
                    </h3>
                  </div>
                </a>
              )
            })}
        </div>
      </OpenEdWrapper>
    </SbEditable>
  )
}

export default WOAPostDisplay

const OpenEdWrapper = styled.div`
  padding: 0 64px;
  display: flex;
  justify-content: center;

  .article-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    max-width: 1140px;
    width: 100%;

    .image-wrapper {
      position: relative;

      .opened-icon {
        position: absolute;
        top: 7px;
        right: 7px;
        width: 45px;
        height: auto;
      }
    }

    .gatsby-image-wrapper > div {
      padding-bottom: 56.25% !important;
    }

    h3 {
      font-family: Halyard-Medium;
      font-size: 20px;
      line-height: 23.5px;
      letter-spacing: 1px;
      text-transform: capitalize;
      margin-top: 10px;

      span {
        font-family: Halyard-Book;
      }
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;

    .article-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 500px) {
    .article-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`
