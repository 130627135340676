import React from 'react'
import { useIntl } from 'react-intl'
import ValueContainer from './ValueContainer'

export default function CampusValueContainer(baseProps) {
  const intl = useIntl()

  return (
    <ValueContainer
      customProps={{
        noOptionsSelectedMessage: intl.formatMessage({ id: 'all job functions' }),
      }}
      baseProps={baseProps}
    />
  )
}
