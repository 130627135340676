import styled from 'styled-components'
import { media } from 'utils'

export const VideoDiv = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  /* height: auto; */

  & > div[id^='vimeo-video-'] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  iframe,
  img,
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img,
  .gatsby-image-wrapper {
    left: 50% !important;
    transform: translateX(-50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    display: block;
    width: 82px;
    height: 82px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -41px 0 0 -41px;
    cursor: pointer;
    background: transparent;
    border: none;

    svg {
      transform: rotate(-90deg);
      path {
        transition: fill 0.3s ease;
        fill: transparent;
      }
    }
    &:hover {
      svg path {
        fill: #fff;
      }
    }
  }
  .unMuteButton {
    top: 3.8rem;
    left: initial;
    right: 1rem;
    font-size: 12px;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    height: 24px;
    border-radius: 4px;
    outline: none;
  }
  ${media.greaterThan('md')`
    .unMuteButton {
      display: none;
    }
  `}
`
