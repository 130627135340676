import React from 'react'
import SbEditable from 'storyblok-react'

import { Section } from 'components/Common'
import { Container, Row, Col } from 'components/Grid'

import Video from './video'

function VideoBlok({ blok }) {
  return (
    <SbEditable content={blok}>
      <Section
        paddingTop={blok.padding_top || 10}
        paddingBottom={blok.padding_bottom || 50}
      >
        <Container>
          <Row>
            <Col width={[1, 1, 1, 12 / 12]}>
              <Video blok={blok} />
            </Col>
          </Row>
        </Container>
      </Section>
    </SbEditable>
  )
}

export default VideoBlok
