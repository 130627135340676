import React from 'react'
import SbEditable from 'storyblok-react'

import LockHalfGrid from './lock_half_grid'

function LockHalfGridInline({ blok }) {
  return (
    <SbEditable content={blok}>
      <LockHalfGrid blok={blok} isInline />
    </SbEditable>
  )
}

export default LockHalfGridInline
