import { generateMedia } from 'styled-media-query'

const dimensions = ['xs', 'sm', 'md', 'lg', 'xl']

export const container = {
  xs: 38,
  sm: 47,
  md: 57,
  lg: 67.5,
  xl: 74.25,
}

export const breakpoints = {
  xs: 31.25,
  sm: 48,
  md: 58,
  lg: 69,
  xl: 76,
}

const mediaObject = dimensions.reduce(
  (acc, cur) => ({ ...acc, [cur]: `${breakpoints[cur]}em` }),
  {},
)

const media = generateMedia(mediaObject)

export default media
