/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext, useMemo } from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'
import ContentContext from 'lib/content.context'

import styled from 'styled-components'
import { getMoreArticles } from '../utils/getMoreArticles'
import { countWordsFromTextComponents } from '../utils/countWords'

import Components from 'components/components.js'
import SEO from 'components/SEO'
import WOACard from 'components/WoaArticleCard'
import Content from 'components/Content'

function CommunityNewsPostEntry({ blok, location, lang, schemaLD }) {
  const { allCommunityNews } = useContext(ContentContext)
  const [firstThreeStories, setFirstThreeStories] = useState()
  const [hideHero, setHideHero] = useState(false)
  const [chinese, setChinese] = useState(false)

  // GET IMAGE PROPS FOR GATSBY IMAGE PLUGIN
  const fluidProps =
    blok.cover_image &&
    useMemo(
      () =>
        getFluidGatsbyImage(blok.cover_image, {
          maxWidth: 900,
          downloadLocal: true,
        }),
      blok.cover_image,
    )

  /* USE EFFECT TO RENDER THE SOCIAL SHARE ICON SCRIPT
  AND MAKE SURE IT RE-LOADS ON LANGUAGE SWITCH */
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.addtoany.com/menu/page.js'
    script.async = true
    document.head.appendChild(script)

    const promise = new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        resolve()
      })
      script.addEventListener('error', event => {
        reject(new Error(`Failed to load script: ${event.target.src}`))
      })
    })

    promise
      .then(() => {
        // Define a2a as a global variable
        /* global a2a */
        a2a.init_all()
      })
      .catch(error => {
        console.error(error)
      })

    return () => {
      script.removeEventListener('load', () => {})
      script.removeEventListener('error', () => {})
    }
  }, [])

  // USE EFFECT TO HIDE HERO, IF CHECKED IN STORYBLOK
  // AND IF CHINESE IS THE LANGUAGE
  useEffect(() => {
    setHideHero(blok.dont_display_hero_image)
    if (lang === 'zh') {
      setChinese(true)
    }
  })

  useEffect(() => {
    const slicedObjects = getMoreArticles(allCommunityNews)
    setFirstThreeStories(slicedObjects)
  }, [allCommunityNews])

  return (
    <>
      <SEO
        lang={lang}
        pageTitle={
          blok.subheading !== ''
            ? blok.title + ' ' + blok.subheading
            : blok.title
        }
        pageDescription={blok.excerpt}
        pageImage={'https:' + blok.cover_image}
        location={location}
        schemaLD={[
          ...schemaLD,
          {
            '@context': 'https://schema.org/',
            '@type': 'BlogPosting',
            '@id': location.href,
            mainEntityOfPage: location.href,
            headline:
              blok.subheading !== ''
                ? blok.title + ' ' + blok.subheading
                : blok.title,
            name:
              blok.subheading !== ''
                ? blok.title + ' ' + blok.subheading
                : blok.title,
            description: blok.excerpt,
            dateModified: blok.published_at,
            author: {
              '@type': 'Person',
              name: blok.author || 'Avenues: The World School',
            },
            publisher: {
              '@type': 'Organization',
              '@id': 'https://avenues.org',
              name: 'Avenues: The World School | Avenues - Private School',
              logo: {
                '@type': 'ImageObject',
                '@id':
                  'https://a.storyblok.com/f/59501/359x127/8bde45723e/ave-logo.png',
                url: 'https://a.storyblok.com/f/59501/359x127/8bde45723e/ave-logo.png',
                width: '359',
                height: '127',
              },
            },
            image: {
              '@type': 'ImageObject',
              '@id': 'https:' + blok.cover_image,
              url: 'https:' + blok.cover_image,
            },
            url: location.href,
            isPartOf: {
              '@type': 'Blog',
              '@id': 'https://avenues.org/world-of-avenues/',
              name: 'World of Avenues',
              publisher: {
                '@type': 'Organization',
                '@id': 'https://avenues.org',
                name: 'Avenues: The World School',
              },
            },
            wordCount: countWordsFromTextComponents(blok.body) || 'unknown',
          },
        ]}
      />
      <HeroWrapper>
        <div className="intro-text-wrapper">
          <div className="headline-wrapper">
            <h1>{blok.title}</h1>
            {blok.author !== '' ? (
              lang === 'default' ? (
                <h4>By {blok.author}</h4>
              ) : lang === 'pt' ? (
                <h4>Por {blok.author}</h4>
              ) : lang === 'zh' ? (
                <h4>作者 {blok.author}</h4>
              ) : (
                <h4>By {blok.author}</h4>
              )
            ) : (
              ''
            )}
          </div>
          <IconWrapper>
            <div className="share-text">
              {lang === 'default' ? (
                <p>Share:</p>
              ) : lang === 'pt' ? (
                <p>Compartilhar:</p>
              ) : lang === 'zh' ? (
                <p>分享:</p>
              ) : (
                <p>Share:</p>
              )}
            </div>
            <div
              className="a2a_kit a2a_kit_size_32 a2a_default_style"
              data-a2a-icon-color="transparent,black"
            >
              <a
                className="a2a_button_facebook"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_linkedin"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_x"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_email"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_whatsapp"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_wechat"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
            </div>
          </IconWrapper>
        </div>
        {hideHero ? (
          <div className="divider-wrapper">
            <div className="divider" />
          </div>
        ) : (
          <div className="hero-image-wrapper">
            <Img fluid={fluidProps} />
          </div>
        )}
      </HeroWrapper>
      <BodyWrapper chinese={chinese}>
        <div className="text-wrapper">
          <div className="intro-wrapper">
            <Content blok={{ content: blok.intro_paragraph }} />
          </div>

          {blok.body &&
            blok.body.map(el => (
              <div key={el._uid} style={{ marginBottom: '2rem' }}>
                {React.createElement(Components[el.component], {
                  blok: el,
                })}
              </div>
            ))}
        </div>
      </BodyWrapper>
      <MoreStoriesWrapper>
        <div className="more-stories-content">
          {lang === 'default' ? (
            <h2 className="explore-more-headline">
              Explore More of The World of Avenues
            </h2>
          ) : lang === 'pt' ? (
            <h2 className="explore-more-headline">
              Continue explorando o mundo da Avenues
            </h2>
          ) : lang === 'zh' ? (
            <h2 className="explore-more-headline">进一步探索爱文世界</h2>
          ) : (
            <h2 className="explore-more-headline">
              Explore More of The World of Avenues
            </h2>
          )}

          <div className="card-wrapper">
            {firstThreeStories?.map((story, index) => {
              return (
                <div key={index} className="column">
                  <WOACard key={index} blok={story} />
                </div>
              )
            })}
          </div>
        </div>
      </MoreStoriesWrapper>
    </>
  )
}

CommunityNewsPostEntry.defaultProps = {
  schemaLD: [],
}

export default CommunityNewsPostEntry

const HeroWrapper = styled.div`
  .intro-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;
  }

  .headline-wrapper {
    text-align: center;
    max-width: 900px;

    h1 {
      font-family: Halyard-Light;
      font-size: min(max(3rem, 7vw), 90px);
      line-height: 0.95;
    }

    h4 {
      font-family: Halyard-Book;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .hero-image-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 70px;

    .gatsby-image-wrapper {
      width: 100%;
      max-width: 1140px;
      aspect-ratio: 16/9;
    }

    @media (max-width: 834px) {
      margin: 0 32px;
    }
  }

  .divider-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .divider {
      width: 100%;
      height: 1px;
      background-color: black;
      max-width: 913px;
    }
  }

  @media (max-width: 834px) {
    margin-top: 100px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  column-gap: 8px;
  padding-bottom: 60px;
  margin-top: 20px;

  .share-text {
    padding-bottom: 5px;

    p {
      font-family: Halyard-Light;
      font-size: 14px;
    }
  }

  .a2a_default_style:not(.a2a_flex_style) a {
    padding: 0;
  }

  .a2a_kit {
    svg {
      height: 24px;
      width: 24px;
      padding: 1px;
      border-radius: 100%;
      border: 1px solid black;
    }
  }
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 70px 0 70px;

  .text-wrapper {
    max-width: 745px;
  }

  .intro-wrapper {
    font-family: Halyard-Book;
    font-size: ${({ chinese }) => (chinese ? '32px' : '28px')};
    line-height: ${({ chinese }) => (chinese ? '45px' : '36px')};
    margin-bottom: 40px;

    p {
      font-family: Halyard-Book;
      font-size: ${({ chinese }) => (chinese ? '32px' : '28px')};
      line-height: ${({ chinese }) => (chinese ? '45px' : '36px')};
      margin-bottom: 40px;
    }
  }

  @media (max-width: 834px) {
    margin: 40px 32px 0 32px;
  }
`
const MoreStoriesWrapper = styled.div`
  margin: 40px 70px 100px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .more-stories-content {
    max-width: 1140px;
    width: 100%;
  }

  .explore-more-headline {
    font-family: Halyard-Book;
    font-size: 48px;
    margin-bottom: 40px;
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    column-gap: 36px;
    margin-bottom: 36px;

    .column {
      flex: 1;
    }
  }

  a {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 834px) {
    margin: 40px 32px 100px 32px;

    .explore-more-headline {
      font-size: 38px;
      text-align: center;
    }

    .card-wrapper {
      column-gap: 16px;
      flex-direction: column;

      .column {
        margin-bottom: 36px;
      }
    }
  }
`
