import React from 'react'
import SbEditable from 'storyblok-react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'
import _get from 'lodash/get'

import { useDeviceSize } from 'hooks'
import { Container } from 'components/Grid'
import { theme } from 'utils'

import {
  StyledSection,
  StyledCol,
  StyledHeading,
  Background,
  StyledRow,
} from './styles'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

const Hero = React.memo(function Hero({ blok, location, ...props }) {
  const [isClientSide, setClientSide] = React.useState(false)
  const [background, setBackground] = React.useState(false)

  React.useEffect(() => {
    if (blok.background_image != "" || blok.background_video != "") {
      setBackground(true)
    }
  });

  React.useEffect(() => {
    setClientSide(true)
  })
  const backgroundImageProps =
    blok.background_image &&
    getFluidGatsbyImage(blok.background_image, {
      maxWidth: 2200,
      downloadLocal: true,
    })
  const backgroundImageMobileProps =
    blok.background_image_mobile &&
    getFluidGatsbyImage(blok.background_image_mobile, {
      maxWidth: 767,
      downloadLocal: true,
    })

  const isHeadingBig = blok.heading_width === 'big'

  const backgroundVideoSrc = !IsChinaDeploy
    ? blok.background_video
    : blok.background_video_alt

  const isWhite =
    blok.background_image || blok.background_image_mobile || backgroundVideoSrc

  return (
    <SbEditable content={blok}>
      <StyledSection
        className='margin-none'
        fullHeight={blok.full_height}
        paddingBottomMb={blok.padding_bottom_mb || 60}
        paddingBottom={blok.padding_bottom || 60}
        textColor={
          (blok.text_color && theme.colors[blok.text_color]) ||
          (isWhite ? theme.colors.white : theme.colors.dark)
        }
      >
        <Container css="height: 100%; position: relative; z-index: 1;">
          <StyledRow css={{ height: '100%', alignItems: props.alignItems ? props.alignItems : "" }}>
            <StyledCol
              width={isHeadingBig ? [1, 1, 3 / 4, 1] : [1, 1, 3 / 4, 6.6 / 12]}
              textAlign={blok.text_align}
            >
              <StyledHeading
                background={background}
                textAlign={props.textAlign}
                isBig={isHeadingBig}
                size={blok.title_font_size || 'h1'}
                sizeMb={blok.title_font_size_mobile || 'h1'}
                paddingTopMb={blok.padding_top_mb || 60}
                paddingTop={blok.padding_top || 60}
                dangerouslySetInnerHTML={(blok.subheading) ? { __html: blok.title +  `<span> ${blok.subheading} </span>`} : { __html: blok.title}}>         
              </StyledHeading>
            </StyledCol>
          </StyledRow>
        </Container>
        {!!backgroundVideoSrc && isClientSide && (
          <Background opacity={_get(blok, 'background_video_opacity.value', 1)}>
            <video autoPlay loop playsInline muted src={backgroundVideoSrc} />
          </Background>
        )}
        {blok.background_image && !props.isMobile && (
          <Background>
            <Img fluid={backgroundImageProps} />
          </Background>
        )}
        {blok.background_image_mobile && props.isMobile && (
          <Background>
            <Img fluid={backgroundImageMobileProps} />
          </Background>
        )}
      </StyledSection>
    </SbEditable>
  )
})

export default function (props) {
  const { isMobile } = useDeviceSize()
  return <Hero {...props} {...{ isMobile }} />
}
