import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Headline from '../Headline'
import IconButton from '../ButtonBase'

function SectionIntro({ blok }) {
  return (
    <SbEditable content={blok}>
      <SectionIntroWrapper className="margin-small">
        <div className="text-wrapper">
          <Headline
            type="h3"
            className="headline-light-medium"
            blok={blok}
            margin={blok.introduction ? '0 0px 32px 0' : '0'}
          />
          <p className="body-medium">{blok.introduction}</p>
            {blok.button_text && (
              <IconButton
                blok={blok}
                marginTop="0"
                blueText={true}
                noFillIcon={true}
              />
            )}
        </div>
      </SectionIntroWrapper>
    </SbEditable>
  )
}

export default SectionIntro

const SectionIntroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 64px;

  .text-wrapper {
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin-bottom: 32px;
      text-align: center;
    }
  }

  @media (max-width: 834px) {
    padding: 0px 32px;
  }
`
