import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Content from 'components/Content'
import ImageComponent from '../image'
import Headline from '../Headline'
import IconButton from '../ButtonBase'

function TwoColumnContent({ blok }) {
  return (
    <SbEditable content={blok}>
      <TwoColumnWrapper className="margin-standard margin-end">
        <div className="image-column">
          <ImageComponent blok={{ media: blok.image }} />
        </div>
        <div className="content-column">
          <div className="content-wrapper">
            <Headline
              type="h3"
              className="headline-medium-extra-large"
              blok={blok}
              textAlign="left"
            />
            <Content className="body-large" blok={{ content: blok.text }} />
            {blok.button_text && (
              <IconButton
                blok={blok}
                marginTop="0"
                blueText={true}
                noFillIcon={blok.button_type === 'external_link' ? false : true}
                externalIcon={
                  blok.button_type === 'external_link' ? true : false
                }
                leftAlign={true}
              />
            )}
          </div>
        </div>
      </TwoColumnWrapper>
    </SbEditable>
  )
}

export default TwoColumnContent

const TwoColumnWrapper = styled.div`
  display: flex;
  column-gap: 60px;
  max-height: 650px;

  .image-column {
    flex-basis: 50%;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  .content-column {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    padding: 64px 0;

    .content-wrapper {
      width: 65%;
    }
  }

  @media (max-width: 1024px) {
    .content-column {  
      .content-wrapper {
        width: 90%;
      }
    }
  }

  @media (max-width: 834px) {
    flex-direction: column;
    max-height: 100%;
    row-gap: 30px;

    .image-column {
      .gatsby-image-wrapper > div {
        padding-bottom: 56.25% !important;
      }
    }

    .content-column {
      padding: 0 32px;
      justify-content: center;

      .content-wrapper {
        width: 100%;

        h3,
        p {
          text-align: center !important;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .image-column {
      .gatsby-image-wrapper > div {
        padding-bottom: 85.64% !important;
      }
    }
  }
`
