import styled from 'styled-components'
import media from 'utils/media'
import { Row, Col } from 'components/Grid'
import { Section } from 'components/Common'

export const StyledRow = styled(Row)`
  >div{
    margin-bottom: ${({ gap }) => (gap)}px;
  }
  ${media.greaterThan('sm')`
    >div{
      padding-left: ${({ gap }) => (gap / 2)}px;
      padding-right: ${({ gap }) => (gap / 2)}px;
    }
  `}
  ${media.greaterThan('sm')`
    margin-left: -${({ gap }) => (gap / 2)}px;
    margin-right: -${({ gap }) => (gap / 2)}px;
  `}
`

export const StyledCol = styled(Col)`
  display: flex;
  padding: 0;
  margin-bottom: ${({ removeMargin }) => (removeMargin ? 0 : '25px')};

  ${media.greaterThan('sm')`
    margin-bottom: ${({ removeMargin }) => (removeMargin ? 0 : '35px')};
  `}
`
export const Title = styled.h3`
  color: ${({ theme, color }) => theme.colors[color || 'dark']};
  margin-bottom: 10px;
`
export const Description = styled.div`
  color: ${({ theme, color }) => theme.colors[color || 'dark']};
  max-width: 458px;
  margin-bottom: 40px;
  ${media.greaterThan('sm')`
    margin-bottom: 43px;
    margin-left: 0;
    margin-right: 0;
  `}
`

export const StyledSection = styled(Section)`
  >div:first-child{
    >div{
      padding:0px;
    }
    ${media.greaterThan('md')`
    >div{
      padding:0 15px;
    }
  `}
  }
`
