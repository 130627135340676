import styled from 'styled-components'
import Img from 'gatsby-image'

export const CoverImage = styled(Img)`
  margin-bottom: 18px;
`

export const TitleLink = styled.p`
  display: inline;
  line-height: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blueDark};
  border-top: 0;
  border-bottom: ${({ theme }) => `3px solid ${theme.colors.blueLight}`};
  transition: border-color 0.15s ease-in;
  &:hover {
    border-color: transparent;
  }
`
export const TitleSubhead = styled.p`
  display: inline;
  line-height: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blueDark};
  border-top: 0;
  border-bottom: ${({ theme }) => `3px solid ${theme.colors.blueLight}`};
  transition: border-color 0.15s ease-in;
  &:hover {
    border-color: transparent;
  }
`

export const Category = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  margin-right: 20px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:not(.active) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.blueLight};
    transition: border-bottom 0.3s ease-in;
    &:hover {
      border-color: transparent;
    }
  }
`
