import styled from 'styled-components'

import { Section } from 'components/Common'

export const StyledSection = styled(Section)`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
`

export const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: ${({ opacity }) => 1 - opacity || 0};
  }

  video, img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const StyledHeadline = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 60px !important;
`
