import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

const StyledHeading = styled.h4`
  margin-bottom: 4px !important;
  font-size: 1.4em;
  line-height: 0.88;
`
const StyledSubHeading = styled.h5`
  margin-bottom: ${props =>
    props.margin_bottom ? props.margin_bottom + 'px !important' : '20px'};
  font-size: 1em;
  line-height: 1.4em;
`

const ProfileHeading = ({ blok }) => {
  const hasAlt = blok.altfont
  return (
    <SbEditable content={blok}>
      <div>
        <StyledHeading as="h4">{blok.person}</StyledHeading>
        <StyledSubHeading as="div" margin_bottom="10">
          {blok.job}
          <br />
          {blok.description}
        </StyledSubHeading>
      </div>
    </SbEditable>
  )
}

export default ProfileHeading
