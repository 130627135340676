import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import { parseBlokProps, media } from 'utils'

import CardItem from '../Card/CardItem'

const StyledDiv = styled.div`
  width: 100%;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor] || 'transparent'};
  border: solid 1px
    ${({ theme, borderColor }) =>
      theme.colors[borderColor] || theme.colors.dark};
  padding: 20px;
  border-radius: 12px;

  ${media.greaterThan('401px')`
    padding: 40px;
  `}
`

const CardBig = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <StyledDiv {...parseBlokProps(blok)}>
        <h4>{blok.heading}</h4>
        {blok.body &&
          blok.body.map(el => {
            return <CardItem {...el} key={el._uid} />
          })}
      </StyledDiv>
    </SbEditable>
  )
}

export default CardBig
