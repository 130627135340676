import styled from 'styled-components'

export const HorizontalSliderContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;

  .horizontal-slider-wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
  }

  .slider {
    width: 100%;
    display: flex;
    margin-top: 25px;
  }

  .headline-container > h3 {
    padding: 0 64px;
  }

  .margin-small {
    margin-top: 30px;
  }

  @media (max-height: 700px) {
    .horizontal-slider-wrapper {
      justify-content: flex-start;
    }
  }

  @media (max-width: 834px) {
    .headline-container > h3 {
      padding: 0 32px;
    }
  
    .slider {
      margin-bottom: 50px;
    }
  }
`
