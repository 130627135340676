import React from 'react'
import { components } from 'react-select'
import { Chevron } from './styles'

import arrowIcon from 'static/img/arrow_down_green.svg'

export default function DropdownIndicator(props) {
  const { menuIsOpen } = props.selectProps

  return (
    <components.DropdownIndicator {...props}>
      <Chevron src={arrowIcon} menuIsOpen={menuIsOpen} />
    </components.DropdownIndicator>
  )
}
