import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import { parseBlokProps, media } from 'utils'
import { StyledP } from 'components/Common'

const StyledDiv = styled.div`
  width: 100vw;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor] || 'transparent'};
  border: solid 1px ${({ theme }) => theme.colors.neutral};
  padding: 38px 30px 32px 30px;
  margin-bottom: 50px;
  margin-left: -24px;

  ${media.greaterThan('401px')`
    width: auto;
    margin-left: 0;
    max-width: 360px;
    padding: 37px 39px 33px 31px;
  `}
`

const Card = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <StyledDiv {...parseBlokProps(blok)}>
        <h3>{blok.header}</h3>
        <StyledP color="dark">{blok.subheader}</StyledP>
        <h5>{blok.description}</h5>
      </StyledDiv>
    </SbEditable>
  )
}

export default Card
