import React from 'react'

import { BackgroundButtonWrapper, BackgroundButton } from '../styles'

import Aperture from 'static/img/get_started_icon.svg'

export default function BackgroundColorButton({ buttonType, buttonSize, backgroundColor, blok }) {
  return (
    <>
      <BackgroundButtonWrapper
        buttonType={buttonType}
        buttonSize={buttonSize}
        backgroundColor={backgroundColor}
      >
        <BackgroundButton>
          {/* <img src={Aperture} alt="circular arrow" /> */}
          <span className="twoLineButton">{blok.button_text_one}</span>
        </BackgroundButton>
      </BackgroundButtonWrapper>
    </>
  )
}
