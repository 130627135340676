import React from 'react'
import SbEditable from 'storyblok-react'
import { StyledMarkdown } from 'components/Common'

function TextComponent({ blok, fontColor, style }) {
  return (
    <SbEditable content={blok}>
      <div
        style={{
          maxWidth: (blok.max_width && blok.max_width + 'px') || 'unset',
          ...style,
        }}
      >
        <StyledMarkdown
          source={blok.body}
          fontColor={blok.font_color || fontColor}
          textAlign={blok.text_align}
          deleteMargin={blok.delete_margin}
          listStyle={blok.list_style}
          listMarginBottom={blok.list_item_margin_bottom}
          listMarginBottomMobile={blok.list_item_margin_bottom_mb}
          pMarginBottom={blok.p_margin_bottom}
          marginBottom={blok.margin_bottom}
          imageAlign={blok.image_align}
        />
      </div>
    </SbEditable>
  )
}

export default TextComponent
