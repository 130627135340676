import React, { useEffect } from 'react'
import { useTheme } from 'styled-components'

import HorizontalSlider from '../HorizontalSlider'

export default function PatternedCardSlider({ blok, index }) {
  const theme = useTheme()
  const slides = blok.slides

  useEffect(() => {
    const colors = [
      theme.colors.newYellow,
      theme.colors.newRed,
      '#273879',
      theme.colors.newGreen,
    ]

    for (let i = 0; i < slides.length; i++) {
      const colorIndex = i % colors.length
      const backgroundColor = colors[colorIndex]
      slides[i].backgroundColor = backgroundColor
    }
  }, [])

  return (
    <>
      <HorizontalSlider blok={blok} index={index} />
    </>
  )
}
