import React from 'react'
import styled from 'styled-components'
import { media, parseBlokProps } from 'utils'

import Components from 'components/components'

const FlexBox = ({ blok, ...props }) => (
  <StyledBox {...parseBlokProps(blok)}>
    {blok.body &&
      blok.body.map(el => {
        return (
          <>
            {React.createElement(Components[el.component], {
              key: el._uid,
              blok: el,
              ...el,
              ...props,
            })}
          </>
        )
      })}
  </StyledBox>
)

const StyledBox = styled.section`
  position: relative;
  display: flex;
  width: ${props => props.width?props.width+'%':'100%'};
  flex-direction: ${({ directionMobile }) => directionMobile || 'row'};
  justify-content: ${({ justifyContentMobile }) =>
    justifyContentMobile || 'flex-start'};
  align-items: ${({ alignItemsMobile }) => alignItemsMobile || 'flex-start'};

  padding: ${({ paddingMobile }) =>
    paddingMobile ? `${paddingMobile}px` : undefined};
  padding-top: ${({ paddingTopMobile }) =>
    paddingTopMobile ? `${paddingTopMobile}px` : undefined};
  padding-bottom: ${({ paddingBottomMobile }) =>
    paddingBottomMobile ? `${paddingBottomMobile}px` : undefined};
  padding-left: ${({ paddingLeftMobile }) =>
    paddingLeftMobile ? `${paddingLeftMobile}px` : undefined};
  padding-right: ${({ paddingRightMobile }) =>
    paddingRightMobile ? `${paddingRightMobile}px` : undefined};

  margin: ${({ marginMobile }) =>
    marginMobile ? `${marginMobile}px` : undefined};
  margin-top: ${({ marginTopMobile }) =>
    marginTopMobile ? `${marginTopMobile}px` : undefined};
  margin-bottom: ${({ marginBottomMobile }) =>
    marginBottomMobile ? `${marginBottomMobile}px` : undefined};
  margin-left: ${({ marginLeftMobile }) =>
    marginLeftMobile ? `${marginLeftMobile}px` : undefined};
  margin-right: ${({ marginRightMobile }) =>
    marginRightMobile ? `${marginRightMobile}px` : undefined};

  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor] || 'transparent'};

  ${media.greaterThan('md')`
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};

    padding: ${({ padding }) => (padding ? `${padding}px` : undefined)};
    padding-top: ${({ paddingTop }) =>
      paddingTop ? `${paddingTop}px` : undefined};
    padding-bottom: ${({ paddingBottom }) =>
      paddingBottom ? `${paddingBottom}px` : undefined};
    padding-left: ${({ paddingLeft }) =>
      paddingLeft ? `${paddingLeft}px` : undefined};
    padding-right: ${({ paddingRight }) =>
      paddingRight ? `${paddingRight}px` : undefined};

    margin: ${({ margin }) => (margin ? `${margin}px` : undefined)};
    margin-top: ${({ marginTop }) =>
      marginTop ? `${marginTop}px` : undefined};
    margin-bottom: ${({ marginBottom }) =>
      marginBottom ? `${marginBottom}px` : undefined};
    margin-left: ${({ marginLeft }) =>
      marginLeft ? `${marginLeft}px` : undefined};
    margin-right: ${({ marginRight }) =>
      marginRight ? `${marginRight}px` : undefined};
  `}

  > *:not(:last-child) {
    margin-right: ${({ gapRightMobile }) =>
      gapRightMobile ? `${gapRightMobile}px` : 0} !important;
    margin-bottom: ${({ gapBottomMobile }) =>
      gapBottomMobile ? `${gapBottomMobile}px` : 0} !important;

    ${media.greaterThan('md')`
      margin-right: ${({ gapRight }) =>
        gapRight ? `${gapRight}px` : 0} !important;
      margin-bottom: ${({ gapBottom }) =>
        gapBottom ? `${gapBottom}px` : 0} !important;
    `}
  }
`

export default FlexBox
