import React, { useState, useEffect, useContext } from 'react'
import SbEditable from 'storyblok-react'
import ContentContext from 'lib/content.context'

import TeamMemberModal from '../TeamMemberModal'
import ImageComponent from '../image'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

import { TeamMembersWrapper, TeamMember, TeamMembersContainer } from './styles'

function TeamMemberGrid({ blok, index, lang }) {
  let { allTeamProfiles } = useContext(ContentContext)
  const [profiles, setProfiles] = useState()
  const [selectedProfileIndex, setSelectedProfileIndex] = useState(null)

  const openModal = index => {
    setSelectedProfileIndex(index)
  }

  const closeModal = () => {
    setSelectedProfileIndex(null)
  }

  useEffect(() => {
    let URLs = []
    let storyContent = []
    blok?.team_members.forEach(profile => {
      if (profile.team_member_link.cached_url.includes('/pt/')) {
        URLs.push(profile.team_member_link.cached_url.replace('/pt/', 'pt/'))
      } else if (profile.team_member_link.cached_url.includes('/zh')) {
        URLs.push(profile.team_member_link.cached_url.replace('/zh/', 'zh/'))
      } else {
        URLs.push(profile.team_member_link.cached_url)
      }
    })

    // Use map instead of some to maintain order
    storyContent = URLs.map(url => {
      const matchedProfile = allTeamProfiles.find(story => story.slug === url)
      return matchedProfile || null
    })

    setProfiles(storyContent)
  }, [allTeamProfiles, blok?.team_members])

  useEffect(() => {
    let mm = gsap.matchMedia()

    mm.add('(max-width: 550px)', () => {
      if (profiles) {
        const teamMembers = document.querySelectorAll('.team-member')

        teamMembers.forEach(teamMember => {
          let tl = gsap.timeline({
            scrollTrigger: {
              trigger: teamMember,
              start: 'top 30%',
              end: 'bottom bottom',
              refreshPriority: -index,
              // markers: true,
              onLeaveBack: () => {
                gsap.set(teamMember.querySelector('.front'), {
                  rotateY: '0deg',
                })
                gsap.set(teamMember.querySelector('.back'), {
                  rotateY: '180deg',
                })
              },
              onEnter: () => {
                tl.restart()
              },
            },
          })
          tl.to(teamMember.querySelector('.front'), { rotateY: '180deg' })
          tl.to(teamMember.querySelector('.back'), { rotateY: '0deg' }, 0)
        })
      }

      return () => {}
    })
  }, [profiles])

  return (
    <SbEditable content={blok}>
      <TeamMembersWrapper className="margin-end margin-small">
        {blok.section_headline && <h2>{blok.section_headline}</h2>}
        <TeamMembersContainer
          numberAcross={blok.number_of_team_members}
          style={{ minHeight: '375px' }}
        >
          {profiles?.map((profile, index) => (
            <>
              {profile && (
                <>
                  <TeamMember
                    className="team-member"
                    onClick={() => openModal(index)}
                    key={index}
                    style={{ minHeight: '375px' }}
                    numberAcross={blok.number_of_team_members}
                  >
                    <div className="front">
                      <div className="outer-image-wrapper">
                        <ImageComponent
                          blok={{ media: profile.content.image }}
                        />
                      </div>

                      <h5>{profile.content.name}</h5>
                      <p>
                        {profile.content.title}
                        {blok.show_campus &&
                          profile.content.campus &&
                          ((lang === 'zh' && ' ') || ', ') +
                            (lang === 'zh' &&
                            profile.content.campus === 'New York'
                              ? '爱文纽约'
                              : lang === 'zh' &&
                                profile.content.campus === 'São Paulo'
                              ? '爱文圣保罗'
                              : lang === 'zh' &&
                                profile.content.campus === 'Shenzhen'
                              ? '爱文深圳'
                              : lang === 'zh' &&
                                profile.content.campus === 'Online'
                              ? '爱文在线'
                              : lang === 'zh' &&
                                profile.content.campus === 'Silicon Valley'
                              ? '爱文硅谷'
                              : profile.content.campus)}
                      </p>
                    </div>
                    <div className="back">
                      <div className="outer-image-wrapper">
                        {profile.content.image_two ? (
                          <img src={profile.content.image_two} />
                        ) : (
                          <ImageComponent
                            blok={{ media: profile.content.image }}
                          />
                        )}
                      </div>
                      {profile.content.back_description ? (
                        <>
                          <h5>
                            {lang === 'zh'
                              ? '小时候的梦想……'
                              : 'Wanted to be...'}
                          </h5>
                          <p>{profile.content.back_description}</p>
                        </>
                      ) : (
                        <>
                          <h5>{profile.content.name}</h5>
                          <p>
                            {profile.content.title}
                            {blok.show_campus &&
                              profile.content.campus &&
                              ((lang === 'zh' && ' ') || ', ') +
                                (lang === 'zh' &&
                                profile.content.campus === 'New York'
                                  ? '爱文纽约'
                                  : lang === 'zh' &&
                                    profile.content.campus === 'São Paulo'
                                  ? '爱文圣保罗'
                                  : lang === 'zh' &&
                                    profile.content.campus === 'Shenzhen'
                                  ? '爱文深圳'
                                  : lang === 'zh' &&
                                    profile.content.campus === 'Online'
                                  ? '爱文在线'
                                  : lang === 'zh' &&
                                    profile.content.campus === 'Silicon Valley'
                                  ? '爱文硅谷'
                                  : profile.content.campus)}
                          </p>
                        </>
                      )}
                    </div>
                  </TeamMember>
                  {selectedProfileIndex === index && (
                    <TeamMemberModal
                      profile={profile}
                      closeModal={closeModal}
                      lang={lang}
                    />
                  )}
                </>
              )}
            </>
          ))}
        </TeamMembersContainer>
      </TeamMembersWrapper>
    </SbEditable>
  )
}

export default TeamMemberGrid
