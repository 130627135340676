import { useEffect } from 'react'
import ReactDOM from 'react-dom'

export default function Portal(props) {
  let scrollPosition = 0

  function disableScroll() {
    const pageHTML = document.querySelector('html')
    scrollPosition = window.pageYOffset
    pageHTML.style.overflowY = 'hidden'
    pageHTML.style.position = 'fixed'
    pageHTML.style.top = `-${scrollPosition}px`
    pageHTML.style.left = 0
    pageHTML.style.right = 0
    pageHTML.style.bottom = 0
  }

  function enableScroll() {
    const pageHTML = document.querySelector('html')
    pageHTML.style = ''

    window.scrollTo(0, scrollPosition)
  }

  useEffect(() => {
    if (!props.noScrollDisabled) {
      disableScroll()
      return () => enableScroll()
    }
  }, [])

  const el = document.querySelector('#main')
  return ReactDOM.createPortal(props.children, el)
}
