import React from 'react'
import SbEditable from 'storyblok-react'
import { useTheme } from 'styled-components'

import { FastFactsWrapper } from './styles'

import BlueDots from 'static/img/pattern-card-patterns/blue_fast_fact.svg'
import YellowDots from 'static/img/pattern-card-patterns/yellow_fast_fact.svg'
import RedDots from 'static/img/pattern-card-patterns/red_fast_fact.svg'
import GreenDots from 'static/img/pattern-card-patterns/yellow_fast_fact.svg'

export default function FastFacts({ blok }) {
  const theme = useTheme()

  const svgComponentsByColor = {
    [theme.colors.newYellow]: YellowDots,
    [theme.colors.newBlue]: BlueDots,
    [theme.colors.newRed]: RedDots,
    [theme.colors.newGreen]: GreenDots,
  }

  const getBackgroundColor = index => {
    const colors = [
      theme.colors.newYellow,
      theme.colors.newBlue,
      theme.colors.newRed,
      theme.colors.newGreen,
    ]
    return colors[index % colors.length]
  }

  const getTextStyle = backgroundColor => {
    return {
      color:
        blok.card_type === 'colored_pattern' && backgroundColor !== '#F7CF80'
          ? 'white'
          : 'black',
    }
  }

  return (
    <SbEditable content={blok}>
      <FastFactsWrapper
        cardType={blok.card_type}
        className="margin-standard margin-end"
      >
        <div className="fast-facts-container">
          {blok.fast_facts.map((fact, index) => {
            const backgroundColor = getBackgroundColor(index)
            const backgroundImage = svgComponentsByColor[backgroundColor]
            return (
              <div
                key={index}
                style={{
                  ...getTextStyle(backgroundColor),
                  backgroundColor:
                    blok.card_type === 'colored_pattern'
                      ? backgroundColor
                      : 'transparent',
                }}
                className="fast-fact"
              >
                {blok.card_type && blok.card_type === 'colored_pattern' ? (
                  <img
                    className={`dots ${
                      backgroundColor === '#F7CF80'
                        ? 'yellow'
                        : backgroundColor === '#4767AD'
                        ? 'blue'
                        : backgroundColor === '#267355'
                        ? 'green'
                        : 'red'
                    } `}
                    src={backgroundImage}
                    alt="dots pattern"
                  />
                ) : blok.card_type === 'icon' ? (
                  <div className="fact-icon">
                    <img
                      src={fact.icon}
                      alt="icon representing Avenues fast fact"
                    />
                  </div>
                ) : (
                  ''
                )}

                <h4
                  className={
                    blok.card_type === 'colored_pattern'
                      ? 'fast-facts-semibold-Large'
                      : 'fast-facts-light-large'
                  }
                >
                  {fact.fact_line_one}
                  <span>{fact.fact_line_two}</span>
                </h4>
                <p className={
                    blok.card_type === 'colored_pattern'
                      ? 'fast-facts-book-small'
                      : 'fast-facts-book-large'
                  }>{fact.fact_descriptor}</p>
              </div>
            )
          })}
        </div>
      </FastFactsWrapper>
    </SbEditable>
  )
}
