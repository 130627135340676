import React from 'react'
import SbEditable from 'storyblok-react'
import Components from 'components/components'
import styled from 'styled-components'
import media from 'utils/media'

const StyledDiv = styled.div`

position:relative;
height: 600px;
width: 100vw;
    > div:nth-child(2) {
        animation-delay: 10s;
    }
    > div:nth-child(3) {
        animation-delay: 20s;
    }
    > div:nth-child(4) {
        animation-delay: 30s;
    }
    > div:nth-child(5) {
        animation-delay: 40s;
    }

    @keyframes 
imageAnimation {  
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }
  2% {
    animation-timing-function: ease-out;
    opacity: 1;
    z-index:5;
  }
  17% {
    opacity: 1;
    z-index:5;
  }
  20% {
    opacity: 0;
    z-index:0;
  }
  100% {
  opacity: 0;
  z-index:0;
  }
}
${media.greaterThan('sm')`
min-height: 730px;
height: 100vh;
background: #000;
    > div {
    min-height: 730px;  
    height: calc(100vh - 60px);
    }
`}
`

const FullWidthSlider = ({ blok }) => {
    
    const overlay = blok.overlay;  

    return (
        <SbEditable content={blok}>
            <div className="margin-none">
                <div>
                  
                  <StyledDiv>
                  {blok.slides.map(
                        item =>
                        Components[item.component] &&
                        React.createElement(Components[item.component], {
                            key: item._uid,
                            blok: item,
                            overlay: overlay
                        }),
                    )}
                  </StyledDiv>
                  </div>
                </div>
        </SbEditable>              
    ) 
}

export default FullWidthSlider