import styled from 'styled-components'

export const TeamMembersWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 64px;
  margin-bottom: 150px;

  @media (max-width: 834px) {
    padding: 0 32px;
  }
`

export const TeamMembersContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 70px;

  @media (max-width: 834px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
  }
`

export const TeamMember = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  perspective: 1000px;
  cursor: pointer;

  p {
    text-align: center;
  }

  h5 {
    font-size: 24px !important;
    line-height: 26px !important;
  }

  .outer-image-wrapper {
    display: flex;
    justify-content: center;

    .gatsby-image-wrapper {
        border-radius: 50%;
        max-width: 240px;
    
        > div {
          padding-bottom: 100% !important;
        }
      }
  }

  
`
