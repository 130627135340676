const params = {
  backspaceRemovesValue: false,
  hideSelectedOptions: false,
  closeMenuOnSelect: false,
  tabSelectsValue: false,
  isMulti: true,
  isSearchable: false,
  openMenuOnFocus: true,
}

export default params
