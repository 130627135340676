import format from 'date-fns/format'

export function formatTime(date, key, locale) {
  const formatted = format(date, key, { locale })

  if (key === 'PPP' && locale.code === 'en-US') {
    return format(date, 'MMMM d, yyyy', { locale })
  }

  // update time format for pt locale
  if (key === 'p' && locale.code === 'pt') {
    return formatted.replace(':', 'h')
  } else {
    return formatted
  }
}
