/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import ContentContext from 'lib/content.context'

import Components from 'components/components.js'
import SEO from 'components/SEO'
import Content from 'components/Content'
import Headline from 'components/Headline'
import Video from 'components/NewVideo/InlineVideo'
import TeamMemberGridSmall from 'components/TeamMemberGridSmall'
import WOACard from 'components/WoaArticleCard'

import { getMoreArticles } from '../utils/getMoreArticles'
import { countWordsFromTextComponents } from '../utils/countWords'

import OpenEdLogo from 'static/img/opened_logo.png'

function CommunityNewsPostEntry({ blok, location, lang, schemaLD }) {
  const { allOpenEd } = useContext(ContentContext)
  const [chinese, setChinese] = useState(false)
  const [featuredSpeakers, setFeaturedSpeakers] = useState(null)
  const [firstThreeStories, setFirstThreeStories] = useState()

  /* USE EFFECT TO RENDER THE SOCIAL SHARE ICON SCRIPT
  AND MAKE SURE IT RE-LOADS ON LANGUAGE SWITCH */
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.addtoany.com/menu/page.js'
    script.async = true
    document.head.appendChild(script)

    const promise = new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        resolve()
      })
      script.addEventListener('error', event => {
        reject(new Error(`Failed to load script: ${event.target.src}`))
      })
    })

    promise
      .then(() => {
        // Define a2a as a global variable
        /* global a2a */
        a2a.init_all()
      })
      .catch(error => {
        console.error(error)
      })

    return () => {
      script.removeEventListener('load', () => {})
      script.removeEventListener('error', () => {})
    }
  }, [])

  // USE EFFECT TO CHECK IF CHINESE IS THE LANGUAGE
  useEffect(() => {
    if (lang === 'zh') {
      setChinese(true)
    }
    if (blok.featured_speakers.length > 0) {
      setFeaturedSpeakers(blok?.featured_speakers[0]?.featured_speakers?.length)
    }
  }, [featuredSpeakers])

  // USE EFFECT TO GET ADDITIONAL STORIES
  useEffect(() => {
    const slicedObjects = getMoreArticles(allOpenEd)
    setFirstThreeStories(slicedObjects)
  }, [allOpenEd])

  return (
    <>
      <SEO
        lang={lang}
        pageTitle={blok.title + ' ' + blok?.subheading}
        pageDescription={blok.excerpt}
        pageImage={'https:' + blok.cover_image}
        location={location}
        schemaLD={[
          ...schemaLD,
          {
            '@context': 'https://schema.org/',
            '@type': 'BlogPosting',
            '@id': location.href,
            mainEntityOfPage: location.href,
            headline:
              blok.subheading !== ''
                ? blok.title + ' ' + blok.subheading
                : blok.title,
            name:
              blok.subheading !== ''
                ? blok.title + ' ' + blok.subheading
                : blok.title,
            description: blok.excerpt,
            dateModified: blok.published_at,
            author: {
              '@type': 'Person',
              name: blok.author || 'Avenues: The World School',
            },
            publisher: {
              '@type': 'Organization',
              '@id': 'https://avenues.org',
              name: 'Avenues: The World School | Avenues - Private School',
              logo: {
                '@type': 'ImageObject',
                '@id':
                  'https://a.storyblok.com/f/59501/359x127/8bde45723e/ave-logo.png',
                url: 'https://a.storyblok.com/f/59501/359x127/8bde45723e/ave-logo.png',
                width: '359',
                height: '127',
              },
            },
            image: {
              '@type': 'ImageObject',
              '@id': 'https:' + blok.cover_image,
              url: 'https:' + blok.cover_image,
            },
            url: location.href,
            isPartOf: {
              '@type': 'Blog',
              '@id': 'https://avenues.org/world-of-avenues/',
              name: 'World of Avenues',
              publisher: {
                '@type': 'Organization',
                '@id': 'https://avenues.org',
                name: 'Avenues: The World School',
              },
            },
            wordCount: countWordsFromTextComponents(blok.body) || 'unknown',
          },
        ]}
      />
      <HeroWrapper>
        <div className="intro-wrapper">
          <div className="logo-wrapper">
            <img src={OpenEdLogo} alt="Avenues Open.Ed logo" />
          </div>

          <Headline
            type="h1"
            margin="0 0 15px 0"
            className="headline-semibold-large"
            blok={{ headline: blok.title }}
          />
          {blok.subheading && (
            <Headline
              type="h2"
              className="headline-light-medium"
              blok={{ headline: blok.subheading }}
            />
          )}
          <IconWrapper>
            <div className="share-text">
              {lang === 'default' ? (
                <p>Share:</p>
              ) : lang === 'pt' ? (
                <p>Compartilhar:</p>
              ) : lang === 'zh' ? (
                <p>分享:</p>
              ) : (
                <p>Share:</p>
              )}
            </div>
            <div
              className="a2a_kit a2a_kit_size_32 a2a_default_style"
              data-a2a-icon-color="transparent,black"
            >
              <a
                className="a2a_button_facebook"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_linkedin"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_x"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_email"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_whatsapp"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
              <a
                className="a2a_button_wechat"
                href={`https://www.addtoany.com/add_to/email?linkurl=${encodeURIComponent(
                  location.href,
                )}&amp;linkname=${encodeURIComponent(blok.title)}`}
              />
            </div>
          </IconWrapper>

          <Video
            blok={{
              vimeo_link: blok.vimeo_link,
              thumbnail: blok.video_thumbnail,
              polyv_link: blok.polyv_link,
            }}
          />
        </div>
      </HeroWrapper>
      {blok.featured_speakers.length > 0 && (
        <FeaturedSpeakersWrapper featuredSpeakers={featuredSpeakers}>
          <div className="featured-speakers-wrapper">
            <h3>Featured Speakers</h3>
            <TeamMemberGridSmall
              blok={{
                team_members: blok?.featured_speakers[0]?.featured_speakers,
              }}
            />
          </div>
        </FeaturedSpeakersWrapper>
      )}

      <BodyWrapper chinese={chinese}>
        <div className="text-wrapper">
          {blok.intro_paragraph?.content[0]?.content && (
            <div className="intro-wrapper">
              <Content blok={{ content: blok.intro_paragraph }} />
            </div>
          )}
          {blok.body &&
            blok.body.map(el => (
              <div key={el._uid} style={{ marginBottom: '2rem' }}>
                {React.createElement(Components[el.component], {
                  blok: el,
                })}
              </div>
            ))}
        </div>
      </BodyWrapper>
      <MoreStoriesWrapper>
        <div className="more-stories-content">
          {lang === 'default' ? (
            <h2 className="explore-more-headline">
              Explore More Avenues Open.Ed
            </h2>
          ) : lang === 'pt' ? (
            <h2 className="explore-more-headline">
              Explore More Avenues Open.Ed
            </h2>
          ) : lang === 'zh' ? (
            <h2 className="explore-more-headline">
              Explore More Avenues Open.Ed
            </h2>
          ) : (
            <h2 className="explore-more-headline">
              Explore More Avenues Open.Ed
            </h2>
          )}

          <div className="card-wrapper">
            {firstThreeStories?.map((story, index) => {
              return (
                <div key={index} className="column">
                  <WOACard key={index} blok={story} />
                </div>
              )
            })}
          </div>
        </div>
      </MoreStoriesWrapper>
    </>
  )
}

CommunityNewsPostEntry.defaultProps = {
  schemaLD: [],
}

export default CommunityNewsPostEntry

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 64px;

  .intro-wrapper {
    max-width: 1140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-wrapper {
      width: 115px;
      height: auto;
      margin-bottom: 30px;
    }
}
  }

  @media (max-width: 834px) {
    padding: 0 32px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  column-gap: 8px;
  padding-bottom: 60px;
  margin-top: 20px;

  .share-text {
    padding-bottom: 5px;

    p {
      font-family: Halyard-Light;
      font-size: 14px;
    }
  }

  .a2a_default_style:not(.a2a_flex_style) a {
    padding: 0;
  }

  .a2a_kit {
    svg {
      height: 24px;
      width: 24px;
      padding: 1px;
      border-radius: 100%;
      border: 1px solid black;
    }
  }
`

const FeaturedSpeakersWrapper = styled.div`
  padding: 70px 64px 0px 64px;

  .featured-speakers-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-family: Halyard-Semibold;
      font-size: 36px;
      margin-bottom: 45px;
      text-align: center;
    }

    > div {
      padding: 0 !important;

      > div {
        grid-gap: 40px !important;
        grid-template-columns: ${({ featuredSpeakers }) =>
          featuredSpeakers === 1
            ? 'repeat(1,1fr) !important'
            : featuredSpeakers === 2
            ? 'repeat(2,1fr) !important'
            : 'repeat(3,1fr) !important'};
        max-width: ${({ featuredSpeakers }) =>
          featuredSpeakers === 1
            ? '400px !important'
            : featuredSpeakers === 2
            ? '698px !important'
            : '1140px !important'};
      }
    }
  }

  @media (max-width: 834px) {
    padding: 70px 32px 0px 32px;
  }

  @media (max-width: 500px) {
    .featured-speakers-wrapper {
      > div {
        > div {
          grid-template-columns: repeat(1, 1fr) !important;
          max-width: 250px !important;
        }
      }
    }
  }
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 45px 70px 70px 70px;

  .text-wrapper {
    max-width: 745px;
  }

  .intro-wrapper {
    font-family: Halyard-Book;
    font-size: ${({ chinese }) => (chinese ? '32px' : '28px')};
    line-height: ${({ chinese }) => (chinese ? '45px' : '36px')};
    margin-bottom: 40px;

    p {
      font-family: Halyard-Book;
      font-size: ${({ chinese }) => (chinese ? '32px' : '28px')};
      line-height: ${({ chinese }) => (chinese ? '45px' : '36px')};
      margin-bottom: 40px;
    }
  }

  @media (max-width: 834px) {
    margin: 45px 32px 60px 32px;
  }
`

const MoreStoriesWrapper = styled.div`
  margin: 40px 70px 100px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .more-stories-content {
    max-width: 1140px;
    width: 100%;
  }

  .explore-more-headline {
    font-family: Halyard-Book;
    font-size: 48px;
    margin-bottom: 40px;
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    column-gap: 36px;
    margin-bottom: 36px;

    .column {
      flex: 1;
    }
  }

  a {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 834px) {
    margin: 40px 32px 80px 32px;

    .explore-more-headline {
      font-size: 38px;
      text-align: center;
    }

    .card-wrapper {
      column-gap: 16px;
      flex-direction: column;

      .column {
        margin-bottom: 36px;
      }
    }
  }
`
