import React from 'react'
import SbEditable from 'storyblok-react'
import Link from './Common/Link'

function ButtonComponent(props) {
  const {
    type,
    size,
    withArrow,
    withUnderline,
    button_accent,
    arrow_style,
    position_desktop,
    position_mobile,
    ...blok
  } = props.blok
  return (
    <SbEditable content={blok}>
      <Link
        blok={blok}
        type={type}
        size={size}
        withArrow={withArrow}
        arrowStyle={arrow_style}
        withUnderline={withUnderline}
        buttonOption={button_accent}
        position={{ xs: position_mobile, lg: position_desktop }}
        {...(button_accent && { [button_accent]: true })}
      />
    </SbEditable>
  )
}

export default ButtonComponent
