import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import ImageComponent from '../image'

function NewImageComponent({ blok }) {
  return (
    <SbEditable content={blok}>
      <ImageWrapper inline={blok.image_inline} className="margin-standard">
        <div className="image-container">
          <ImageComponent blok={{ media: blok.image }} />
          {blok.caption && <p className="caption">{blok.caption}</p>}
        </div>
      </ImageWrapper>
    </SbEditable>
  )
}

export default NewImageComponent

const ImageWrapper = styled.div`
  padding: ${({ inline }) => (inline ? '0' : '0 64px')};
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    max-width: 924px;
    width: 100%;

    p {
      margin-top: 10px;
    }
  }

  @media (max-width: 834px) {
    padding: ${({ inline }) => (inline ? '0' : '0 32px')};
  }
`
