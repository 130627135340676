export function processNews(news) {
  // Filter out objects with news.content.hide_post === true
  const filteredNews = news.filter(entry => !entry.content || !entry.content.hide_post)

  const rows = []
  let rowIndex = 0

  const transformedEntries = filteredNews.map(entry => {
    const startIndex = entry.id.indexOf('-') + 1 // Find the index of the first dash
    const endIndex = entry.id.indexOf('-', startIndex) // Find the index of the second dash
    const transformedId = entry.id.substring(startIndex, endIndex) // Extract the desired portion of the id
    return {
      ...entry,
      id: transformedId,
    }
  })

  const sortedEntries = transformedEntries.sort((a, b) => {
    const numberA = parseInt(a.id, 10) // Convert the transformed id to a number
    const numberB = parseInt(b.id, 10)
    return numberB - numberA // Sort in descending order
  })

  while (sortedEntries.length > 0) {
    const row = []

    if (rowIndex % 2 === 0) {
      // Even row: 3 articles
      for (let i = 0; i < 2; i++) {
        const article = sortedEntries.shift()
        row.push(article || null) // Add the article or null if it doesn't exist
      }
    } else {
      // Odd row: 2 articles
      for (let i = 0; i < 3; i++) {
        const article = sortedEntries.shift()
        row.push(article || null) // Add the article or null if it doesn't exist
      }
    }

    rows.push(row)
    rowIndex++
  }

  const groups = []
  let startIndex = 0

  while (startIndex < rows.length) {
    const endIndex = startIndex + 4
    const group = rows.slice(startIndex, endIndex)
    groups.push(group)
    startIndex = endIndex
  }

  return groups
}
