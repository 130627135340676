import React from 'react'
import Layout from '../layout'
import styled from 'styled-components'

import Components from './components.js'

export default function Layout_({ blok, ...props }) {
  const body = [blok.header_v2[0], blok.footer[0]]
  return (
    <Layout>
      <Main>
        {body.map(blok => {
          const isHeaderComponent = blok.component === 'header'

          return (
            Components[blok.component] &&
            React.createElement(Components[blok.component], {
              key: blok._uid,
              blok: blok,
              ...(isHeaderComponent && { style: { position: 'sticky' } }),
              ...props,
            })
          )
        })}
      </Main>
    </Layout>
  )
}

const Main = styled.div`
  height: 100%;
  width: 100%;
`
