import React, { useEffect, useRef, useState } from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Content from '../Content'
import Headline from '../Headline'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function SpinningGlobe({ blok }) {
  const videoRef = useRef(null)
  const mobileVideoRef = useRef(null)

  let isSectionActive = false
  let activeTimeUpdateHandler = null

  const playVideo = (startTime, playDuration) => {
    if (videoRef.current !== null) {
      const video = videoRef.current

      const pauseTime = startTime + playDuration

      if (isSectionActive) {
        video.pause()
        video.removeEventListener('timeupdate', activeTimeUpdateHandler)
      }

      const timeUpdateHandler = () => {
        if (video.currentTime >= pauseTime) {
          video.pause()
          video.removeEventListener('timeupdate', timeUpdateHandler)
          isSectionActive = false
        }
      }

      if (video.paused) {
        video.play()
      }

      video.currentTime = startTime
      video.playbackRate = 1.5
      video.addEventListener('timeupdate', timeUpdateHandler)

      activeTimeUpdateHandler = timeUpdateHandler
      isSectionActive = true
    }
  }

  useEffect(() => {
    let mm = gsap.matchMedia()

    mm.add('(min-width: 501px)', () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.globe-container',
          start: 'top top',
          end: 'bottom bottom',
          pin: '.right-content',
          // markers: true,
        },
      })

      const sections = [
        { trigger: '.slide-0', startTime: 0 },
        { trigger: '.slide-1', startTime: 16 },
        { trigger: '.slide-2', startTime: 32 },
      ]

      sections.forEach((section, index) => {
        gsap.to(section.trigger, {
          scrollTrigger: {
            trigger: section.trigger,
            start: 'top 80%',
            end: 'bottom 90%',
            // markers: true,
            onEnter: () => playVideo(section.startTime, 16),
            onEnterBack: () => playVideo(section.startTime, 16),
          },
        })
      })
    })

    mm.add('(max-width: 500px)', () => {
      let video = document.querySelector('#mobile-globe')

      if (video) {
        video.play()
      }

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.globe-container',
          start: 'top top',
          end: 'bottom bottom',
          pin: '.right-content',
        },
      })
    })
  }, [])

  return (
    <SbEditable content={blok}>
      <GlobeWrapper className="margin-standard globe-container">
        <div className="content-wrapper">
          <div class="placeholder"></div>
          {blok?.globe_slide.map((slide, index) => {
            if (slide.component === 'spinning_globe_slide') {
              return (
                <div className={`slide-${index} slide`}>
                  <Headline
                    type="h4"
                    blok={{ headline: slide.headline }}
                    className="headline-semibold-large"
                    textAlign="left"
                  />
                  {slide.icon && (
                    <div className="icon-wrapper">
                      <img
                        src={slide.icon}
                        alt="icon representing Avenues fast fact"
                      />
                    </div>
                  )}
                  <div className="two-column-content">
                    <Content
                      className="body-large"
                      blok={{ content: slide.content }}
                    />
                    {slide.content_row_two && (
                      <Content
                        className="body-large"
                        blok={{ content: slide.content_row_two }}
                      />
                    )}
                  </div>
                </div>
              )
            } else if (slide.component === 'spinning_globe_slide_two') {
              // Different JSX for spinning_globe_slide_two
              return (
                <div className={`slide-${index} slide`}>
                  <Headline
                    type="h4"
                    blok={{ headline: slide.headline }}
                    className="headline-semibold-large"
                    textAlign="left"
                  />
                  <div className="icon-row">
                    <img
                      src={slide.icon_one}
                      alt="icon representing Avenues fast fact"
                    />
                    <p className="body-large">{slide.point_one}</p>
                  </div>

                  {slide.point_two && (
                    <div className="icon-row">
                      <img
                        src={slide.icon_two}
                        alt="icon representing Avenues fast fact"
                      />
                      <p className="body-large">{slide.point_two}</p>
                    </div>
                  )}
                  {slide.point_three && (
                    <div className="icon-row">
                      <img
                        src={slide.icon_three}
                        alt="icon representing Avenues fast fact"
                      />
                      <p className="body-large">{slide.point_three}</p>
                    </div>
                  )}
                </div>
              )
            }

            return null // Return null for other slide components or unhandled cases
          })}
        </div>
        <div class="right-content">
          <div class="video-wrapper">
            <video
              src={
                !IsChinaDeploy
                  ? 'https://player.vimeo.com/progressive_redirect/playback/859140699/rendition/720p/file.mp4?loc=external&signature=d6d322efc064756589f0bc849ea0db521e1ca81824f4b707e8104361b7c3b5c1'
                  : '/img/globe-video/globe_desktop.mp4'
              }
              id="desktop-globe"
              ref={videoRef}
              muted
            />
            <video
              src={
                !IsChinaDeploy
                  ? 'https://player.vimeo.com/progressive_redirect/playback/859140699/rendition/540p/file.mp4?loc=external&signature=28907b13b176db417b438315101709aee803771db9246d5bd57aa2de1a2d4517'
                  : '/img/globe-video/globe_mobile.mp4'
              }
              id="mobile-globe"
              ref={mobileVideoRef}
              muted
              playsInline
              loop
            />
          </div>
        </div>
      </GlobeWrapper>
    </SbEditable>
  )
}

export default SpinningGlobe

const GlobeWrapper = styled.div`
  display: flex;
  padding: 0 64px;
  max-width: 1500px;
  margin: 0 auto;

  .right-content {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-basis: 65%;
    overflow: hidden;
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 135%;
    margin-left: -20%;
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 10%;
    object-fit: cover;
  }

  #desktop-globe {
    display: block;
  }

  #mobile-globe {
    display: none;
  }

  .content-wrapper {
    z-index: 2;
    margin-left: 15%;
    max-width: 500px;
    flex-grow: 1;
    flex-basis: 35%;

    .two-column-content {
      display: flex;
      column-gap: 50px;
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
    }

    .icon-row {
      display: flex;
      align-items: center;
      column-gap: 20px;
      margin-bottom: 40px;

      img {
        height: 70px;
        width: auto;
      }
    }
  }

  .placeholder {
    height: 100vh;
  }

  .slide {
    margin-bottom: 500px;
  }

  @media (max-width: 1200px) {
    .content-wrapper {
      margin-left: 10%;
    }
  }

  @media (max-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 834px) {
    .content-wrapper {
      margin-left: 32px;

      .icon-row {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 16px;
      }
    }

    .icon-wrapper {
      justify-content: flex-start !important;
    }

    .two-column-content {
      flex-direction: column;

      p:first-child {
        margin-bottom: 0;
      }
    }

    .video-wrapper {
      margin-left: 0;
      padding-bottom: 155%;
    }
  }

  @media (max-width: 500px) {
    padding: 0;
    width: 100%;
    overflow: hidden;

    #desktop-globe {
      display: none;
    }

    #mobile-globe {
      display: block;
    }

    .content-wrapper {
      margin-left: 32px;
      width: 100%;
      flex-basis: 25%;
    }

    .placeholder {
      height: 40vh;
      width: 100%;
    }

    .slide {
      margin-bottom: 250px;
      width: 100%;
    }

    .video-wrapper {
      margin-left: 0%;
      height: 560px;
      width: 1000px;
      padding-left: 1000px;
    }

    .two-column-content {
      p {
        font-size: 18px !important;

        :first-child {
          margin-bottom: 0 !important;
        }
      }
    }

    .icon-row {
      margin-bottom: 30px;

      p {
        font-size: 18px !important;
      }
    }
    }
  }
`
