import styled from 'styled-components'

import { Section } from 'components/Common'

export const StyledSection = styled(Section)`
  .slick-slide {
    div {
      cursor: pointer;
    }
  }
`
export const DoubleImage = styled.div`
  outline: none;
  display: flex !important;
  flex-direction: column !important;
  div {
    margin-top: 18px;
    margin-bottom: 0px;
  }
`
