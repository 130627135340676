import React from 'react'

import ImageComponent from '../image'
import Headline from '../Headline'

import { TeamMemberWrapper } from './styles'

function TeamMember({ index, profile, openModal, contactInfo }) {
  return (
    <TeamMemberWrapper
      className="team-member"
      onClick={!contactInfo ? () => openModal(index) : ''}
      key={index}
      contactInfo={contactInfo}
    >
      <div className="media-wrapper">
        {profile && <ImageComponent blok={{ media: profile.content.image }} />}
      </div>

      <div className="content-wrapper">
        <Headline
          type="h5"
          blok={{ headline: profile?.content.name }}
          margin="0 0 5px 0"
          className="bio-semibold-small"
          textAlign="left"
        />
        <p className="bio-book-small">
          {contactInfo ? profile?.content.email : profile?.content.title}
        </p>
        <p className="bio-book-small">
          {contactInfo
            ? profile?.content.phone_number
            : profile?.content.campus}
        </p>
      </div>
    </TeamMemberWrapper>
  )
}

export default TeamMember
