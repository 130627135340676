import styled from 'styled-components'
import { media } from 'utils'

const StyledSection = styled.section`
  position: relative;

  padding-top: ${({ paddingTopMb }) => paddingTopMb}px;
  padding-bottom: ${({ paddingBottomMb }) => paddingBottomMb}px;

  background-color: ${({ theme, color }) =>
    theme.colors[color] || color || 'none'};

  ${media.greaterThan('md')`
    padding-top: ${({ paddingTop }) => paddingTop}px;
    padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  `}
`

export default StyledSection
