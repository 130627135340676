import styled from 'styled-components'
import { media } from 'utils'

export const ContentWrapper = styled.div`
  margin-bottom: 0px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  width: 100%;

  & > * {
    color: ${({ theme, textColor }) =>
      theme.colors[textColor] || textColor || theme.colors.black};
  }

  p {
    font-size: ${({ className }) =>
      className === 'body-medium'
        ? '21px'
        : className === 'body-large'
        ? '26px'
        : className === 'fast-facts-book-large'
        ? '32px'
        : '18px'};
    line-height: ${({ className }) =>
      className === 'body-medium'
        ? '30px'
        : className === 'body-large'
        ? '36px'
        : className === 'fast-facts-book-large'
        ? '35px'
        : '28px'};
    margin-bottom: ${({ marginBottom }) => marginBottom || '2rem'};
    margin-top: ${({ marginTop }) => marginTop || '0'};
  }

  b {
    font-family: Halyard-SemiBold;
  }

  a {
    font-weight: 600;
    border-bottom: 2px solid ${({ theme }) => theme.colors.green};
    transition: border-color 0.2s;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  strong {
    font-weight: 600;
  }

  img {
    display: block;
  }

  span.separator {
    display: block !important;
    height: 3px !important;
  }

  span.spacer {
    display: block !important;
    height: 10px !important;
  }

  ${media.greaterThan('sm')`
  text-align: ${({ textAlign }) => textAlign || 'left'};
  span.separator {
      display: inline-block !important;
      height: 0;
    ;
  }
`}

  @media (max-width: 834px) {
    p {
      font-size: ${({ className }) =>
        className === 'body-medium'
          ? '16px'
          : className === 'body-large'
          ? '21px'
          : className === 'fast-facts-book-large'
          ? '28px'
          : '18px'};
      line-height: ${({ className }) =>
        className === 'body-medium'
          ? '26px'
          : className === 'body-large'
          ? '28px'
          : className === 'fast-facts-book-large'
          ? '33px'
          : '28px'};
      margin-bottom: ${({ marginBottom }) => marginBottom || '2rem'};
      margin-top: ${({ marginTop }) => marginTop || '0'};
    }
  }
`
