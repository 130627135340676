import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import BackgroundColorButton from './Buttons/backgroundButton'
import TextIconButton from './Buttons/textButton'
import { LinkWrapper } from './styles'

import { removeGlobalSlug, withSlash } from 'utils/helpers'

export default function NewButton({ blok, ...props }) {
  //USE STATES FOR STYLING AND TEXT
  const [twoLineButton, setTwoLineButton] = useState(false)
  const [textColor, setTextColor] = useState()
  const [backgroundButton, setBackgroundButton] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('')
  const [buttonSize, setButtonSize] = useState('')
  const [buttonType, setButtonType] = useState('')
  const [underline, setUnderline] = useState(false)

  //VARIABLES FOR LINKING
  const extHref = _get(blok, 'button_link.url') || props.extHref
  const cachedUrl = _get(blok, 'button_link.cached_url', '')
  const intHref = cachedUrl && withSlash(removeGlobalSlug(cachedUrl))

  const attrs = {
    target: blok.new_tab ? '_blank' : '_self',
    rel: blok.new_tab
      ? 'noopener'
      : !_isEmpty(extHref)
      ? 'external'
      : 'noreferrer',
  }

  //USEEFFECT
  useEffect(() => {
    blok.button_text_two !== ''
      ? setTwoLineButton(true)
      : setTwoLineButton(false)

    setTextColor(blok.text_color)

    if (blok.background_color !== '') {
      setButtonType(blok.button_type)
      setBackgroundButton(true)
      setBackgroundColor(blok.background_color)
      setButtonSize(blok.button_size)
    }
  })

  return (
    <>
      <LinkWrapper marginTop={blok.margin_top}>
        {!_isEmpty(extHref) ? (
          <a className="button" href={extHref} {...attrs}>
            {backgroundButton ? (
              <BackgroundColorButton
                blok={blok}
                buttonType={buttonType}
                buttonSize={buttonSize}
                backgroundColor={backgroundColor}
              />
            ) : (
              <TextIconButton
                blok={blok}
                twoLineButton={twoLineButton}
                underline={underline}
                textColor={textColor}
              />
            )}
          </a>
        ) : (
          <Link className="button" to={intHref} {...attrs}>
            {backgroundButton ? (
              <BackgroundColorButton
                blok={blok}
                buttonType={buttonType}
                buttonSize={buttonSize}
                backgroundColor={backgroundColor}
              />
            ) : (
              <TextIconButton
                blok={blok}
                twoLineButton={twoLineButton}
                underline={underline}
                textColor={textColor}
              />
            )}
          </Link>
        )}
      </LinkWrapper>
    </>
  )
}
