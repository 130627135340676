import React from 'react'

import HorizontalSlider from '../HorizontalSlider'

export default function CampusSlider({ blok, index }) {
  return (
    <>
      <HorizontalSlider blok={blok} index={index} />
    </>
  )
}
