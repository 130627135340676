import React, { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { withSlash } from 'utils/helpers'

import { SlideContainer } from './styles'

gsap.registerPlugin(ScrollTrigger)
const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

export default function CampusSlider({ blok, scrollTimeline }) {
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const cards = gsap.utils.toArray('.campus-card')
      const videos = document.querySelectorAll('.cvideo')

      function handleVideo(e) {
        let v = e.querySelector('video')
        videos.forEach(video => {
          video.pause()
          video.classList.add('darken')
        })
        v.play()
        v.classList.remove('darken')
      }

      handleVideo(cards[0])

      cards.forEach(card => {
        gsap.to(card, {
          scrollTrigger: {
            containerAnimation: scrollTimeline,
            trigger: card,
            start: 'left 70%',
            end: 'left 0%',
            scrub: true,
            onEnter: () => {
              handleVideo(card)
            },
            onEnterBack: () => {
              handleVideo(card)
            },
          },
        })
      })
    })

    return () => ctx.revert()
  })

  return (
    <>
      <SlideContainer className="campus-card-container">
        <div class="campus-card">
          <div class="campus-card-text">
            <h3 className='headline-semibold-extra-large'>{blok.headline}</h3>
            <h5>{blok.intro}</h5>
            <div className="button-div">
              {blok.buttons.map(button => {
                return (
                  <div class="button">
                    <a href={withSlash(button.button_link.cached_url)}>
                      {button.button_text_one}
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
          {IsChinaDeploy ? (
            <video
              class="cvideo"
              autoplay=""
              loop="loop"
              playsinline=""
              muted="muted"
              src={blok.shenzhen_background_video}
            ></video>
          ) : (
            <video
              class="cvideo"
              autoplay=""
              loop="loop"
              playsinline=""
              muted="muted"
              src={blok.background_video}
            ></video>
          )}
        </div>
      </SlideContainer>
    </>
  )
}
