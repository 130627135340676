import styled from 'styled-components'
import { media } from 'utils'

export const HeroVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  p:first-child {
    font-family: Halyard-Medium;
  }

  p {
    font-size: 18px;
    font-family: Halyard-Book;
    line-height: 1.25;
    margin: 0;

    ${media.greaterThan('xs')`
      font-size: 24px;
    `}
  }

  h2 {
    font-size: 60px;
    text-align: center;
    line-height: 0.9;

    ${media.greaterThan('xs')`
      font-size: 70px;
      line-height: 0.9;
      text-align: center;
    `}
  }

  #herowrap {
    overflow: hidden;
  }

  .svg-light {
    fill: #ffffff;
  }

  .svg-dark {
    fill: #00000;
  }

  #hero {
    height: 100%;
    min-height: 800px;
    display: flex;
    flex-wrap: nowrap;
    font-family: sans-serif;
    min-height: 600px;
    width: auto;
  }

  #hero-scroll {
    height: 100%;
    min-height: 800px;
    display: flex;
    flex-wrap: nowrap;
  }

  #dots {
    height: 40px;
    position: absolute;
    width: 1000%;
    margin-top: 42vh;
    z-index: -1;
    display: flex;
    margin-left: 100vw;
    opacity: 0;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 75 30'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none%3B%7D.cls-2%7Bfill:%23f1cc7c%3B%7D%3C/style%3E%3C/defs%3E%3Cg id='rethink_possible'%3E%3Cg%3E%3Ccircle class='cls-2' cx='15' cy='15' r='15'/%3E%3Crect class='cls-1' x='15' width='60' height='30'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: repeat-x;

    ${media.greaterThan('xs')`
      margin-left: 75vw;
    `}
  }

  .hero-item {
    height: 100vh;
    position: relative;
  }

  .hero-item img {
    height: 100%;
    max-width: initial;
  }

  #s01rethink {
    height: 130px;
  }

  // .scroller-wrapper {
  //   width: 100%;
  //   font-size: 20px;
  //   display: flex;
  //   padding: 20px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 20px;
  //   margin-top: auto;

  //   .scroller {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     row-gap: 8px;
  //   }

  //   h4 {
  //     margin: 0;
  //     color: black;
  //     font-size: 20px;
  //   }

  //   #arrow {
  //     height: 20px;
  //     width: 20px;
  //   }

  //   @media (max-width: 834px) {
  //     padding-bottom: 60px;
  //   }
  // }

  #s01 {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    #s01-video-wrapper {
      height: auto;
      width: 100%;
      max-height: 100vh;
      max-width: 100vw;

      ${media.greaterThan('xs')`
        max-width: 75vw;
      `}

      ${media.greaterThan('sm')`
        max-height: 70vh;
        max-width: 50vw;
      `}

      #hero-desktop-video {
        display: none;

        ${media.greaterThan('xs')`
          display: block;
        `}
      }

      #s01-canvas {
        display: block;

        ${media.greaterThan('xs')`
          display: none;
        `}
      }

      #hero-mobile-video {
        display: none;
      }
    }
  }

  #s02 {
    margin-left: 30vw;
    position: relative;

    #s02text {
      position: absolute;
      top: 47vh;
      right: 10%;
      width: 100%;

      ${media.greaterThan('xs')`
        width: 300px;
    `}
    }

    #s02rethink {
      position: absolute;
      width: 310px;
      height: 150px;
      top: 16vh;
      left: 100%;
      font-weight: bold;
      font-size: 2.8em;
      line-height: 1em;
      overflow: hidden;
      color: rgba(0, 0, 0, 1);

      img {
        width: 300px;
        height: 130px;
      }
    }
  }

  #s03 {
    margin-left: 20vw;
    position: relative;

    #s03text {
      position: absolute;
      top: 60vh;
      right: 50%;
      width: 250px;

      ${media.greaterThan('xs')`
        top: 60vh;
        right: 65%;
    `}
    }
  }

  #s04 {
    align-self: flex-end;
    width: 1600px;
    bottom: 0;
    height: 100%;
    position: relative;

    ${media.greaterThan('xs')`
      align-self: flex-end;
      width: 1920px;
      bottom: 0;
      height: 100%;
      position: relative;
    `}

    #richard-wrapper {
      display: inline-flex;
      align-items: flex-end;
      position: relative;
      height: 100vh;
      max-width: 1920px;

      ${media.greaterThan('md')`
        max-width: 100%;
      `}
    }

    #s04c {
      height: 93vh;
      display: inline-block;
    }

    #s04text {
      position: absolute;
      bottom: 20%;
      right: 12%;
      width: 200px;

      ${media.greaterThan('xs')`
        bottom: 10%;
      `}
    }

    #s04rethink {
      position: absolute;
      right: 25%;
      top: 16vh;
      height: 130px;
      width: 280px;
    }
  }

  #s05 {
    width: 30vw;
    margin-left: -5vw;
    z-index: -2;
  }

  #s06 {
    margin-left: -33vw;
    position: relative;

    #s06text {
      position: absolute;
      top: 50vh;
      left: -110px;

      ${media.greaterThan('xs')`
        left: -170px;
      `}
    }

    #s06rethink {
      position: absolute;
      height: 130px;
      width: 340px;
      top: 16vh;
      left: 100%;
      font-weight: bold;
      font-size: 3em;
      line-height: 1.2em;
      color: rgba(0, 0, 0, 1);
      text-align: center;
    }

    #s06spacer {
      width: 400px;
      height: 40vh;
      position: absolute;
      left: 5vw;
      z-index: -1;
      background: #fff;
    }
  }

  #s08 {
    margin-left: 30vw;
    z-index: -1;
    position: relative;

    ${media.greaterThan('xs')`
      margin-left: 10vw;
    `}

    #s08text {
      position: absolute;
      top: 62vh;
      right: 35%;
      width: 200px;

      ${media.greaterThan('xs')`
        right: 50%;
      `}
    }
  }

  #heroEnd {
    width: 100vw;
    height: 100vh;

    .spacer {
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    #hero-logo {
      min-width: 50px;
      min-height: 50px;
      width: 15vw;
      height: 15vw;
      max-width: 100px;
      max-height: 100px;
      position: relative;
      margin-top: 40vh;

      #logo-a {
        position: absolute;
        transform-origin: center;
        transform: scale(0);
        transformorigin: '50% 50%';
        z-index: 3;
      }

      #logo-b {
        position: absolute;
        transform-origin: center;
        top: 15vw;
        z-index: 4;
      }
    }
  }
`

export const RethinkPossible = styled.div`
  padding: 80px 20px;
  opacity: 0;
  z-index: 5;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: min(max(5rem, 12vw), 180px);
    margin: 0;
  }

  ${media.greaterThan('xs')`
    padding: 120px 20px;

    h2 {
      font-size: min(max(8rem,12vw),180px);
    }
  `}
`
