import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

function MatriculationCard(props) {
  const graduateMsg = (n) => {
    let g = n == 1?"Graduate":"Graduates";
    return g
  }

  const transformImage =(image,option) =>{
    if (!image) return "";
    if (!option) return "";
    let imageService = "https://img2.storyblok.com/";
    let path = image.replace("https://a.storyblok.com", "");
    return imageService + option + path;
  }
    return (
      <SbEditable content={props.blok}>
        <Card id={props.blok.id}>
            <Title>
                <h2>{props.blok.name}</h2>
                <h5>{props.blok.country}</h5>
            </Title>
            <picture>
              <source type="image/webp" srcset={transformImage(props.blok.logo.filename, '0x60/filters:format(webp)')}/>
              <source type="image/png" srcset={transformImage(props.blok.logo.filename, '0x60/filters:format(png)')}/>
              <img  src={transformImage(props.blok.logo.filename, '0x60')} alt={props.blok.name}/>
            </picture>

            <Students>
                <p>{props.blok.num_students} <FormattedMessage id={graduateMsg(props.blok.num_students)} /> </p>
                <Photos>
                {props.blok.students.map((el,index) => (
                    <div css={`background-image:url(${el.image.filename})`}></div>
                ))}
                </Photos>
            </Students>
        </Card>
      </SbEditable>
    )
  }
  
export default MatriculationCard

const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding:20px;
  border: 1px solid #000;
  border-radius:20px;
  height:100%;
  h2{
    font-size:20px;
    margin-bottom:0px;
    line-height:23px;
    }
    h5{
        font-size:16px;
    }
  picture{
      height:60px;
      margin: 0 auto;
  }
`

const Title = styled.div`
margin-bottom:10px;
`

const Students = styled.div`
 margin-top:10px;
 p{
     font-size: 16px;
     font-weight:600;
    }
`

const Photos = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
div{
    width:20px;
    height:20px;
    border-radius:20px;
    background-position:center;
    background-size:cover;
    margin:2px;
}
`