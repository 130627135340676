import React from 'react'
import { excerptText } from 'utils/helpers'

import { StyledMarkdown, Link } from 'components/Common'
import { CoverImage, TitleLink, TitleSubhead, ArticleDescription } from './styles'

function NewsItem({ node }) {
  const { content, slug } = node
  const { title, subheading, cover_image, body, excerpt } = content
  

  return (
    <Link
      blok={{
        text: title,
        url: {
          cached_url: slug,
        },
      }}
    >
      {cover_image && <CoverImage fluid={cover_image} />}
      <TitleLink>{title} </TitleLink>
      <TitleSubhead>{subheading}</TitleSubhead>
      <ArticleDescription
        as={StyledMarkdown}
        source={excerpt || excerptText(body, 168)}
      />
    </Link>
  )
}

export default NewsItem
