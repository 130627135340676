import Page from './page'
import Events from './Events'
import NewsPage from '../templates/news-entry'
import EventPage from '../templates/event-entry'
import CommunityNewsPage from '../templates/community-news-entry'
import ProfilePage from '../templates/profile-entry'
import AonStoryPage from '../templates/aon-story-entry'
import OpenEdPage from '../templates/opened-entry'

import Layout from './layout-entry'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'

// single elements
import Hero from 'components/Hero'
import HeroRotating from './HeroRotating'
import Content from './Content'
import NewButton from './NewButton'
import NewModal from './NewModal'
import CTABanner from 'components/CTABanner'
import Headline from './Headline'
import FullHeightSlide from './FullHeightSlide'
import TabSwitcher from 'components/TabSwitcher'
import Text from './text'
import Image from './image'
import ImageGallerySlider from 'components/ImageGallerySlider'
import Button from './button'
import NewOpenRoles from './NewOpenRoles'
import NewsSection from './News/News.container'
import CommunityNewsSection from './CommunityNews/CommunityNews.container'
import Slider from './Slider'
import Banner from './Banner'
import Markdown from './markdown'
import Switcher from 'components/Switcher'
import GridInline from 'components/GridInline'
import ParllayForm from './parllay_form'
import Card from 'components/Card'
import CardBig from 'components/CardBig'
import BoxCard from './BoxCard'
import CardVertical from 'components/CardVertical'
import BannerAccordion from 'components/BannerAccordion'
import CustomEmbedded from 'components/custom_embedded'
import ScrollSlider from 'components/ScrollSlider'
import HubSpotForm from 'components/HubSpotForm'
import Box from 'components/Box'
import HeadingWSubhead from 'components/HeadingWSubhead'
import NavButton from './navlink'
import FullWidthSlider from 'components/FullWidthSlider'
import FullWidthSection from 'components/FullWidthSection'
import ProfileHeading from 'components/ProfileHeading'
import Table from 'components/Table'
import Matriculation from 'components/Matriculation'
import HeroAnimated from 'components/HeroAnimated'
import SpeakerImage from 'components/SpeakerImage'
import SpeakerNoImage from 'components/SpeakerNoImage'
import VideoScroll from './VideoScroll'
import HomepageComponents from './HomepageComponents'
import SzContentParser from './SzContentParser'
import SectionIntro from './SectionIntro'
import AddtoCalendar from './AddToCalendar'
import TextHero from './TextHero'
import CampusTimeline from './CampusTimeline'
import WOAPostDisplay from './WOAPostDisplay'
import TeamMemberGrid from './TeamMemberGrid'
import TeamMemberGridSmall from './TeamMemberGridSmall'
import Quote from './Quote'
import WOAFeature from './WOAFeature'
import TwoColumnHorizontalSlider from './TwoColumnHorizSlider'
import CampusIntro from './CampusSlider/CampusIntro'
import HorizontalSlide from './TwoColumnHorizSlider/Slide'
import CampusSlider from './CampusSlider'
import SectionIntroTwo from './SectionIntroTwo'
import PatternedCardSlider from './PatternedCardSlider'
import PatternedCardSlide from './PatternedCardSlider/Slide'
import VideoImageSlider from './VideoImageSlider'
import VideoImageSlide from './VideoImageSlider/Slide'
import TwoColumnContent from './TwoColumnContent'
import NewVideo from './NewVideo'
import SplitImage from './SplitImage'
import SectionIntroBold from './SectionIntroBold'
import FastFacts from './FastFacts'
import Callout from './Callout'
import ContentBlock from './ContentBlock'
import Tabs from './Tabs'
import SpinningGlobe from './SpinningGlobe'
import TwoColumnText from './TwoColumnText'
import ThreeColumnContent from './ThreeColumnContent'
import OpenEdFeature from './OpenEdFeature'
import FeaturedSpeakers from './FeaturedSpeakers'
import OpenEdArticleDisplay from './OpenEdArticleDisplay'
import TextBlock from './TextBlock'
import NewImageComponent from './NewImage'
import AonStoriesDisplay from './AonStoriesDisplay'

// group of elements
import Modals from 'components/Modal'
import CardCarousel from 'components/CardCarousel'
import AccordionsGroup from 'components/AccordionsGroup'
import Multistep from 'components/Multistep'
import HalfGrids from 'components/HalfGrid'
import LockHalfGrids from 'components/LockHalfGrid'
import Video from 'components/Video'
import Rows from 'components/Rows'
import AonHero from 'components/AonHero'
import TextHeroBold from 'components/TextHeroBold'
import Timeline from './Timeline'

export default {
  page: Page,
  layout: Layout,
  event_new: EventPage,
  community_news: CommunityNewsPage,
  team_member_profile: ProfilePage,
  aon_story: AonStoryPage,
  opened: OpenEdPage,
  header: Navbar,
  hero: Hero,
  hero_rotating: HeroRotating,
  cta_banner: CTABanner,
  footer: Footer,
  content: Content,
  new_button: NewButton,
  new_modal: NewModal,
  headline: Headline,
  full_height_slide: FullHeightSlide,
  tab_switcher: TabSwitcher,
  text: Text,
  image: Image,
  image_gallery_slider: ImageGallerySlider,
  button: Button,
  open_roles: NewOpenRoles,
  news: NewsPage,
  news_section: NewsSection,
  community_news_section: CommunityNewsSection,
  slider: Slider,
  banner: Banner,
  markdown: Markdown,
  switcher: Switcher,
  grid_inline: GridInline,
  card: Card,
  card_big: CardBig,
  box_card: BoxCard,
  card_vertical: CardVertical,
  banner_accordion: BannerAccordion,
  custom_embedded: CustomEmbedded,
  scroll_slider: ScrollSlider,
  hub_spot_form: HubSpotForm,
  box: Box,
  heading_with_subhead: HeadingWSubhead,
  nav_button_circle_arrow: NavButton,
  aon_fullwidth_card_section: FullWidthSection,
  profile_heading: ProfileHeading,
  aon_hero: AonHero,
  table: Table,
  sz_content_parser: SzContentParser,
  matriculation: Matriculation,
  hero_animated: HeroAnimated,
  parllay_form: ParllayForm,
  speaker_image: SpeakerImage,
  speaker_no_image: SpeakerNoImage,
  video_scroll: VideoScroll,
  homepage_components: HomepageComponents,
  section_intro: SectionIntro,
  section_intro_two: SectionIntroTwo,
  add_to_calendar: AddtoCalendar,
  text_hero: TextHero,
  campus_timeline: CampusTimeline,
  woa_post_display: WOAPostDisplay,
  team_member_grid: TeamMemberGrid,
  team_member_grid_small: TeamMemberGridSmall,
  quote: Quote,
  news_feature: WOAFeature,
  two_column_horizontal_slider: TwoColumnHorizontalSlider,
  campus_intro: CampusIntro,
  horizontal_slide: HorizontalSlide,
  campus_slider: CampusSlider,
  patterned_cards_slider: PatternedCardSlider,
  patterned_card_slide: PatternedCardSlide,
  video_image_slider: VideoImageSlider,
  video_image_slide: VideoImageSlide,
  two_column_content: TwoColumnContent,
  video: NewVideo,
  split_image: SplitImage,
  section_intro_bold: SectionIntroBold,
  fast_facts: FastFacts,
  callout: Callout,
  content_block: ContentBlock,
  bold_text_hero: TextHeroBold,
  tabs: Tabs,
  spinning_globe: SpinningGlobe,
  two_column_text: TwoColumnText,
  timeline: Timeline,
  three_column_content: ThreeColumnContent,
  opened_feature: OpenEdFeature,
  featured_speakers: FeaturedSpeakers,
  opened_article_display: OpenEdArticleDisplay,
  text_block: TextBlock,
  inline_image: NewImageComponent,
  aon_stories_display: AonStoriesDisplay,

  // group of elements
  ...Events,
  ...Modals,
  ...AccordionsGroup,
  ...Multistep,
  ...HalfGrids,
  ...LockHalfGrids,
  ...Video,
  ...Rows,
  ...FullWidthSlider,
  ...CardCarousel,
}
