import React, { useCallback, useMemo } from 'react'
import ReactSelect from 'react-select'
import customReactSelect from './react-select'
import _uniqBy from 'lodash/uniqBy'
import getComponentsBasedOnSelectVariant from './react-select/components'

export const VARIANTS = {
  JOBFUNCTION: 'job function',
  CAMPUS: 'campus',
}

export default function SelectField({
  nodes,
  variant,
  label,
  onToggle,
  resetKey,
}) {
  const id = `${variant}-select-field`

  const uniqueOptions = _uniqBy(nodes, "node.label")
  const reactSelectOptions = useMemo(() => uniqueOptions.map(({ node }) => ({
    value: node.id,
    label: node.label,
  })), [])

  const handleReactSelectChange = useCallback((_, actionMeta) => {
    const action = actionMeta.action
    if (action === customReactSelect.supportedActions.SELECT_OPTION || action === customReactSelect.supportedActions.DESELECT_OPTION) {
      onToggle(actionMeta.option.value)
    } else {
      throw new Error(`Unsupported react-select action: ${action}`)
    }
  }, [])

  const reactSelectParams = {
    ...customReactSelect.params,
    options: reactSelectOptions,
    styles: customReactSelect.styles,
    components: getComponentsBasedOnSelectVariant(variant),
  }

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <ReactSelect
        id={id}
        key={resetKey}
        onChange={handleReactSelectChange}
        {...reactSelectParams}
      />
    </>
  )
}
