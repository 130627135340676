/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useMemo, useState } from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'

import Content from 'components/Content'
import SEO from '../components/SEO'

import styled from 'styled-components'

function ProfileEntry({ blok, lang, location, schemaLD }) {
  const [email, setEmail] = useState(false)
  const [phone, setPhone] = useState(false)

  const fluidProps =
    blok.image &&
    useMemo(
      () =>
        getFluidGatsbyImage(blok.image, {
          maxWidth: 900,
          downloadLocal: true,
        }),
      blok.image,
    )

  useEffect(() => {
    if (blok.email) {
      setEmail(true)
    }

    if (blok.phone_number) {
      setPhone(true)
    }
  })

  return (
    <>
      <SEO
        lang={lang}
        pageTitle={'Leadership profile for ' + blok.name}
        pageDescription={
          'A leadership profile for ' +
          blok.name +
          ', ' +
          blok.title +
        ' at Avenues: The World School'
        }
        pageImage={'https:' + blok.image}
        location={location}
        schemaLD={[
          ...schemaLD,
          {
            '@context': 'https://schema.org/',
            '@type': 'Person',
            image: blok.image,
            jobTitle: blok.title,
            name: blok.name,
            url: location.href,
          },
        ]}
      />
      <StyledProfile className="margin-end" email={email} phone={phone}>
        <div className="profile-wrapper">
          <div className="image-wrapper">
            <Img fluid={fluidProps} />
          </div>
          <div className="content-wrapper">
            <h5>{blok.name}</h5>
            <p className="title">
              {blok.title}
              {blok.campus &&
                ((lang === 'zh' && ' ') || ', ') +
                  (lang === 'zh' && blok.campus === 'New York'
                    ? '爱文纽约'
                    : lang === 'zh' && blok.campus === 'São Paulo'
                      ? '爱文圣保罗'
                      : lang === 'zh' && blok.campus === 'Shenzhen'
                        ? '爱文深圳'
                        : lang === 'zh' && blok.campus === 'Online'
                          ? '爱文在线'
                          : lang === 'zh' && blok.campus === 'Silicon Valley'
                            ? '爱文硅谷'
                            : blok.campus)}
            </p>

            {blok.email && (
              <a className="email" href={`mailto:${blok.email}`}>
                {blok.email}
              </a>
            )}
            {blok.phone_number && (
              <a className="phone" href={`tel:${blok.phone_number}`}>
                {blok.phone_number}
              </a>
            )}
            <Content blok={{ content: blok.content }} />
          </div>
        </div>
      </StyledProfile>
    </>
  )
}

ProfileEntry.defaultProps = {
  schemaLD: [],
}

export default ProfileEntry

const StyledProfile = styled.div`
  padding: 0 64px;
  display: flex;
  justify-content: center;

  .profile-wrapper {
    display: flex;
    column-gap: 45px;
    max-width: 855px;
  }

  .image-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .gatsby-image-wrapper {
      width: 240px;
      height: 240px;
      margin-bottom: 25px;
      border-radius: 50%;
    }
  }

  .content-wrapper {
    flex: 2;

    a {
      font-family: Halyard-Medium;
      font-size: 15px;
      text-decoration: underline;
      color: #4767ad;
      display: block;
    }

    .email {
      margin-bottom: ${({ phone, email }) => (email && !phone ? '32px' : '0')};
    }

    .phone {
      margin-bottom: 32px;
    }

    h5 {
      font-family: Halyard-Medium;
      font-size: 25px;
      line-height: 28px;
      margin-bottom: 5px;
    }

    .title {
      font-family: Halyard-Medium;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: ${({ email, phone }) => (email || phone ? '0px' : '32px')};
    }
  }

  @media (max-width: 834px) {
    padding: 150px 32px 53px 32px;

    .content-wrapper {
      h5 {
        text-align: center;
      }

      .title {
        text-align: center;
      }
    }

    .profile-wrapper {
      flex-direction: column;
    }
  }
`
