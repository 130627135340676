import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { media } from 'utils'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import Components from 'components/components.js'
import Layout from '../layout'
import Seo from 'components/SEO'
import HubSpotForm from '../components/HubSpotForm'
import StyledMarkdown from '../components/Common/StyledMarkdown'

import calendarIcon from 'static/img/calendar.svg'
import virtualIcon from 'static/img/virtual.svg'
import addressIcon from 'static/img/address.svg'

import { Container, Row, Col } from 'components/Grid'
import { formatTime } from '../components/Events/utils'
import { enUS, pt, zhCN } from 'date-fns/locale'

const locales = {
  en: enUS,
  pt,
  zh: zhCN,
}

function EventPostEntry({ blok, location, lang, schemaLD, ...props }) {
  /*
  USE STATES:
   1) State for whether the Boolean Hide Form setting is checked in Storyblok (changes to one column layout)
   2) State for whether the Boolean Hide Event Details is checked in Storyblok (hides the Event Date, Time and Address/Location)
   3) State for which Timezone to show after Event time based on Location in Storyblok
   4) State for which DataLayer to display for GTM (based on Location in Storyblok)
  */
  const [hideForm, setHideForm] = useState()
  const [hideDetails, setHideDetails] = useState()
  const [timezone, setTimezone] = useState()
  const [dataLayer, setDataLayer] = useState()
  const [eventHasPassed, setEventHasPassed] = useState()

  const combinedHeadline = blok.name + ' ' + blok.subheadline
  const locale = locales[lang.replace('default', 'en')]
  const date = blok.date && new Date(blok.date.replace(/-/g, '/'))
  // Check if the HubSpot Form ID Override is present in Storyblok; otherwise, use Form ID dropdown
  const hubspotFormId = !isEmpty(blok.hubspot_form_override)
    ? blok.hubspot_form_override
    : blok.hubspot_form
  const timezoneAbrvOverride =
    blok?.timezone_override && blok.timezone_override.split(',')[0].trim()
  const timezoneOverride =
    blok?.timezone_override && blok.timezone_override.split(',')[1].trim()

  //* **USE EFFECTS***
  // Set state for Hide Form and Hide Event Details from Storyblok
  useEffect(() => {
    setHideForm(blok.hide_form)
    setHideDetails(blok.hide_details)
  }, [])

  // Sets state for if event has passed so form no longer shows
  useEffect(() => {
    const checkEventTime = () => {
      const campus = blok.location
      const timeZone =
        timezoneOverride ||
        (campus === 'New York' || campus === 'Online'
          ? 'America/New_York'
          : campus === 'Shenzhen'
            ? 'Asia/Shanghai'
            : campus === 'São Paulo'
              ? 'America/Sao_Paulo'
              : campus === 'Silicon Valley'
                ? 'America/Los_Angeles'
                : 'America/New_York')

      const currentTime = moment().tz(timeZone).format('YYYY-MM-DD HH:mm')
      const eventTime = moment(blok.date, 'YYYY-MM-DD HH:mm')
      setEventHasPassed(eventTime.isBefore(currentTime))
    }

    checkEventTime()
    const intervalId = setInterval(checkEventTime, 3600000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  // Set state for timezone
  useEffect(() => {
    if (timezoneAbrvOverride) {
      return setTimezone(timezoneAbrvOverride)
    } else if (blok.location === 'New York' || blok.location === 'Online') {
      return setTimezone('EST')
    } else if (blok.location === 'Shenzhen') {
      return setTimezone('CST')
    } else if (blok.location === 'São Paulo') {
      return setTimezone('BST')
    } else if (blok.location === 'Silicon Valley') {
      return setTimezone('PST')
    } else {
      return ' '
    }
  }, [])

  // Set state for HubSpot Form DataLayer
  useEffect(() => {
    if (blok.location === 'Online') {
      return setDataLayer(
        'window.dataLayer = window.dataLayer || []; window.dataLayer.push({ \'event\': \'AON_PIE\', \'category\': \'AON\', \'action\': \'complete_PIE\', \'label\': \'AON_PIE\' });',
      )
    } else if (blok.location === 'New York') {
      return setDataLayer(
        'window.dataLayer = window.dataLayer || []; window.dataLayer.push({ \'event\': \'NY_PIE\', \'category\': \'NY\', \'action\': \'complete_PIE\', \'label\': \'NY_PIE\' });',
      )
    } else if (blok.location === 'Silicon Valley') {
      return setDataLayer(
        'window.dataLayer = window.dataLayer || []; window.dataLayer.push({ \'event\': \'SV_PIE\', \'category\': \'SV\', \'action\': \'complete_PIE\', \'label\': \'SV_PIE\' });',
      )
    } else if (blok.location === 'São Paulo') {
      return setDataLayer(
        'window.dataLayer = window.dataLayer || []; window.dataLayer.push({ \'event\': \'PIE_Signup\', \'category\': \'Avenues-SP\', \'action\': \'CompleteRegistration\', \'label\': \'Avenues_SP_PIE\' });',
      )
    } else {
      return ' '
    }
  }, [])

  return (
    <Layout lang={lang}>
      {blok.event_category === 'Virtual' ? (
        <Seo
          lang={lang}
          pageTitle={combinedHeadline}
          pageDescription={blok.description}
          location={location}
          schemaLD={[
            ...schemaLD,
            {
              '@context': 'https://schema.org',
              '@type': 'Event',
              name: blok.name,
              startDate: blok.date,
              endDate: blok.date_end,
              eventAttendanceMode:
                'https://schema.org/OnlineEventAttendanceMode',
              location: [
                {
                  '@type': 'VirtualLocation',
                  url: `$siteURL/${location.pathname}`,
                },
              ],
              offers: {
                '@type': 'Offer',
                url: `$siteURL/${location.pathname}`,
                price: 0,
                priceCurrency: 'USD',
                availability: 'https://schema.org/InStock',
                validFrom: blok.date,
              },
              description: blok.description,
              image: [
                'https://a.storyblok.com/f/59501/359x127/8bde45723e/ave-logo.png',
              ],
              organizer: {
                '@type': 'Organization',
                name: 'Avenues: The World School | Avenues - Private School',
                url: '$siteURL',
              },
            },
          ]}
        />
      ) : (
        <Seo
          lang={lang}
          pageTitle={combinedHeadline}
          pageDescription={blok.description}
          location={location}
          schemaLD={[
            ...schemaLD,
            {
              '@context': 'https://schema.org',
              '@type': 'Event',
              name: blok.name,
              startDate: blok.date,
              endDate: blok.date_end,
              eventAttendanceMode:
                'https://schema.org/OnlineEventAttendanceMode',
              location: [
                {
                  '@type': 'Place',
                  name: blok.name,
                  address: {
                    '@type': 'PostalAddress',
                    streetAddress: blok.event_street_address,
                    addressLocality: blok.event_state_region,
                    postalCode: blok.event_postal_code,
                  },
                },
              ],
              offers: {
                '@type': 'Offer',
                url: `$siteURL/${location.pathname}`,
                price: 0,
                priceCurrency: 'USD',
                availability: 'https://schema.org/InStock',
                validFrom: blok.date,
              },
              description: blok.description,
              image: [
                'https://a.storyblok.com/f/59501/359x127/8bde45723e/ave-logo.png',
              ],
              organizer: {
                '@type': 'Organization',
                name: 'Avenues: The World School | Avenues - Private School',
                url: '$siteURL',
              },
            },
          ]}
        />
      )}
      <Hero>
        <Container>
          <Row>
            <Col width={[1, 1, 2 / 3]}>
              <Headline>{blok.name}</Headline>
              <Subheadline>{blok.subheadline}</Subheadline>
              {!hideDetails && blok.event_category === 'Virtual' && (
                <>
                  <DetailsWrapper>
                    <img src={calendarIcon} />
                    <Details>
                      <DateTime>
                        {date && formatTime(date, 'PPP', locale)}{' '}
                      </DateTime>
                      <DateMarker>|</DateMarker>
                      <Type>
                        {date && formatTime(date, 'p', locale)}
                        {blok.date_end &&
                          ' - ' +
                            formatTime(
                              new Date(blok.date_end.replace(/-/g, '/')),
                              'p',
                              locale,
                            )}{' '}
                        {lang !== 'pt' && timezone}
                      </Type>
                    </Details>
                  </DetailsWrapper>
                  <DetailsWrapper>
                    <img src={virtualIcon} />
                    <Details>{blok.event_category}</Details>
                  </DetailsWrapper>
                </>
              )}
              {!hideDetails && blok.event_category === 'In-Person' && (
                <>
                  <DetailsWrapper>
                    <img src={calendarIcon} />
                    <Details>
                      {date && formatTime(date, 'PPP', locale)}{' '}
                      <DateMarker>|</DateMarker>
                      {date && formatTime(date, 'p', locale)}
                      {blok.date_end &&
                        ' - ' +
                          formatTime(
                            new Date(blok.date_end.replace(/-/g, '/')),
                            'p',
                            locale,
                          )}{' '}
                      {lang !== 'pt' && timezone}
                    </Details>
                  </DetailsWrapper>
                  <DetailsWrapper>
                    <img src={addressIcon} />
                    <Details>
                      {blok.event_street_address} {blok.event_city},{' '}
                      {blok.event_state_region} {blok.event_postal_code}
                    </Details>
                  </DetailsWrapper>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Hero>

      <MainWrapper>
        <Container>
          {hideForm && (
            <Row>
              <Col width={[1, 1, 1]}>
                <h5>{blok.event_intro}</h5>
                <Markdown source={blok.event_details} />
                {blok.body &&
                  blok.body.map(
                    blok =>
                      Components[blok.component] &&
                      React.createElement(Components[blok.component], {
                        key: blok._uid,
                        blok,
                        location,
                        ...props,
                      }),
                  )}
              </Col>
            </Row>
          )}

          {!hideForm && (
            <Row>
              <Col width={[1, 1, 1 / 2]}>
                <h5>{blok.event_intro}</h5>
                <Markdown source={blok.event_details} />
                {blok.body &&
                  blok.body.map(
                    blok =>
                      Components[blok.component] &&
                      React.createElement(Components[blok.component], {
                        key: blok._uid,
                        blok,
                        location,
                        ...props,
                      }),
                  )}
              </Col>
              <Col className="hs-column" width={[1, 1, 1 / 2]}>
                {!eventHasPassed ? (
                  <HubSpotForm
                    lang={lang}
                    blok={{
                      form_id: hubspotFormId,
                      snippet_after_form_submission: dataLayer,
                    }}
                  />
                ) : (
                  <h3 className="body-medium">
                    Registration for this event is now closed.
                  </h3>
                )}
              </Col>
            </Row>
          )}
          <SpeakerWrapper>
            <SpeakerSection>
              <h4>{blok.speaker_headline}</h4>
              {blok.speakers &&
                blok.speakers.map(
                  blok =>
                    Components[blok.component] &&
                    React.createElement(Components[blok.component], {
                      key: blok._uid,
                      blok,
                      location,
                      ...props,
                    }),
                )}
            </SpeakerSection>
          </SpeakerWrapper>
        </Container>
      </MainWrapper>
    </Layout>
  )
}

EventPostEntry.defaultProps = {
  schemaLD: [],
}

export default EventPostEntry

const Hero = styled.div`
  margin-top: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MainWrapper = styled.div`
  padding: 80px 0;

  .hs-column {
    h3 {
      width: 100%;
      text-align: center;
      font-family: Halyard-Semibold;
      color: #4767AD;
    }
  }
`

const Headline = styled.h2`
  margin: 0;
  font-size: 64px;
`
const Subheadline = styled.h3`
  font-size: 64px;
`

const Details = styled.p`
  font-family: Halyard-Medium;
  font-size: 24px;

  ${media.greaterThan('sm')`
    display: flex;
  `}
`
const DetailsWrapper = styled.div`
  display: flex;

  img {
    width: 45px;
    height: auto;
    padding-right: 25px;
  }
`
const Markdown = styled(StyledMarkdown)`
  p {
    margin-bottom: 32px;
  }
`
const SpeakerSection = styled.div`
  padding-top: 66px;
  max-width: 755px;

  h4 {
    font-size: 64px;
  }
`
const SpeakerWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const DateMarker = styled.span`
  display: none;

  ${media.greaterThan('sm')`
    display: inline;
    margin: 0 12px 0 12px;
  `}
`
const DateTime = styled.div`
  line-height: 1.2;

  ${media.greaterThan('sm')`
  line-height: 1.8;
`}
`

const Type = styled.div`
  line-height: 1.2;

  ${media.greaterThan('sm')`
  line-height: 1.8;
`}
`
