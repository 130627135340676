import React, { useEffect, useState, useLayoutEffect } from 'react'
import SbEditable from 'storyblok-react'
import Img from 'gatsby-image'

import Content from 'components/Content'
import LineCircleSVG from '../SVGs/lineCircle'
import LineCircleMobileSVG from '../SVGs/lineCircleMobile'

import {
  TimelineWrapper,
  ImageTextWrapper,
  ImageTextWrapperMobile,
  Year,
  TimelineIntro,
} from '../styles'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)

export default function TwoColumnTimeline({ blok, imageProps }) {
  const [singleColumn, setSingleColumn] = useState(false)
  const [noYear, setNoYear] = useState(false)

  useEffect(() => {
    if (blok.content.content.length === 1) {
      setSingleColumn(true)
    }

    if (blok.year === '') {
      setNoYear(true)
    }
  }, [])

  //DYAMIC CLASSES FOR GREENSOCK MOVEMENT
  const getSvgContainer = `svg-${blok?._uid.replace(/-/g, '')}`
  const getSvgContainerMobile = `svg-mobile-${blok?._uid.replace(/-/g, '')}`
  const getYearContainer = `year-${blok?._uid.replace(/-/g, '')}`
  const getIntroContainer = `intro-${blok?._uid.replace(/-/g, '')}`
  const getImageContainer = `image-${blok?._uid.replace(/-/g, '')}`
  const getTextContainer = `text-${blok?._uid.replace(/-/g, '')}`
  const getImageContainerMobile = `image-mobile-${blok?._uid.replace(/-/g, '')}`
  const getTextContainerMobile = `text-mobile-${blok?._uid.replace(/-/g, '')}`
  const getLine = `line-${blok?._uid.replace(/-/g, '')}`
  const getCircle = `circle-${blok?._uid.replace(/-/g, '')}`
  const getLineMobile = `line-mobile-${blok?._uid.replace(/-/g, '')}`
  const getCircleMobile = `circle-mobile-${blok?._uid.replace(/-/g, '')}`

  useLayoutEffect(() => {
    const svgContainer = document.querySelector('.' + getSvgContainer)
    const svgContainerMobile = document.querySelector(
      '.' + getSvgContainerMobile,
    )
    const yearContainer = document.querySelector('.' + getYearContainer)
    const introContainer = document.querySelector('.' + getIntroContainer)
    const imageContainer = document.querySelector('.' + getImageContainer)
    const textContainer = document.querySelector('.' + getTextContainer)
    const imageContainerMobile = document.querySelector(
      '.' + getImageContainerMobile,
    )
    const textContainerMobile = document.querySelector(
      '.' + getTextContainerMobile,
    )
    const line = document.querySelectorAll('#' + getLine)
    const circle = document.querySelector('#' + getCircle)
    const lineMobile = document.querySelectorAll('#' + getLineMobile)
    const circleMobile = document.querySelector('#' + getCircleMobile)

    gsap.set(line, { attr: { 'vector-effect': 'non-scaling-stroke' } })
    gsap.set('.svg-container', { opacity: 1 })
    gsap.set(imageContainer, { opacity: 1 })
    gsap.set(textContainer, { opacity: 1 })
    gsap.set(imageContainerMobile, { opacity: 1 })
    gsap.set(textContainerMobile, { opacity: 1 })

    let mm = gsap.matchMedia(),
      breakPoint = 834

    mm.add(
      {
        // set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
      },
      context => {
        // context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
        let { isDesktop, isMobile } = context.conditions

        gsap
          .timeline({
            scrollTrigger: {
              trigger: isDesktop ? svgContainer : svgContainerMobile,
              start: isDesktop ? 'top 50%' : 'top 80%',
              end: isDesktop ? 'top -100%' : 'top -10%',
              scrub: 2,
              // markers: true,
            },
          })
          .from(isDesktop ? line : lineMobile, {
            drawSVG: '0%',
            ease: 'none',
            duration: 0.99,
          })
          .from(
            isDesktop ? circle : circleMobile,
            { opacity: 0, duration: 0.01 },
            '-=0.4',
          )

        gsap
          .timeline({
            scrollTrigger: {
              trigger: noYear ? yearContainer : introContainer,
              start: 'bottom 60%',
              // markers: true,
            },
          })
          .from(
            isDesktop ? imageContainer : imageContainerMobile,
            { opacity: 0, y: 75, scale: 0.6 },
            0.5,
          )
          .from(
            isDesktop ? textContainer : textContainerMobile,
            { opacity: 0, y: 75, scale: 0.6 },
            0.5,
          )

        return () => {}
      },
    )
  }, [])

  return (
    <>
      <SbEditable content={blok}>
        <TimelineWrapper noYear={noYear}>
          {!noYear && (
            <Year className={`${getYearContainer}`}>{blok.year}</Year>
          )}

          <TimelineIntro className={`timeline-intro ${getIntroContainer}`}>
            {blok.introduction}
          </TimelineIntro>

          <ImageTextWrapper
            style={{ opacity: 0 }}
            className={`${getImageContainer}`}
          >
            <div className="outer-image-wrapper">
              <Img
                fluid={imageProps}
                alt={'image representing ' + blok.introduction}
              />
            </div>
            <LineCircleSVG
              getSvgContainer={getSvgContainer}
              getCircle={getCircle}
              getLine={getLine}
              singleColumn={singleColumn}
            />
            <div
              style={{ opacity: 0 }}
              className={`content-blok ${getTextContainer}`}
            >
              <Content blok={{ content: blok.content }} />
            </div>
          </ImageTextWrapper>

          <ImageTextWrapperMobile
            style={{ opacity: 0 }}
            className={`${getImageContainerMobile}`}
          >
            <div className="outer-image-wrapper">
              <Img
                fluid={imageProps}
                alt={'image representing ' + blok.introduction}
              />
            </div>
            <div
              style={{ opacity: 0 }}
              className={`content-blok ${getTextContainerMobile}`}
            >
              <Content blok={{ content: blok.content }} />
            </div>
            <LineCircleMobileSVG
              getSvgContainerMobile={getSvgContainerMobile}
              getCircleMobile={getCircleMobile}
              getLineMobile={getLineMobile}
              singleColumn={singleColumn}
            />
          </ImageTextWrapperMobile>
        </TimelineWrapper>
      </SbEditable>
    </>
  )
}
