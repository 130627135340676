import React, { useState, useEffect, useContext } from 'react'
import SbEditable from 'storyblok-react'
import ContentContext from 'lib/content.context'

import TeamMemberModal from '../TeamMemberModal'
import TeamMember from '../TeamMember'

import { TeamMembersWrapper, TeamMembersContainer } from './styles'

function TeamMemberGridSmall({ blok, lang }) {
  let { allTeamProfiles } = useContext(ContentContext)
  const [profiles, setProfiles] = useState()
  const [selectedProfileIndex, setSelectedProfileIndex] = useState(null)

  const openModal = index => {
    setSelectedProfileIndex(index)
  }

  const closeModal = () => {
    setSelectedProfileIndex(null)
  }

  useEffect(() => {
    let URLs = []
    let storyContent = []
    blok?.team_members.forEach(profile => {
      if (profile.team_member_link.cached_url.includes('/pt/')) {
        URLs.push(profile.team_member_link.cached_url.replace('/pt/', 'pt/'))
      } else if (profile.team_member_link.cached_url.includes('/zh')) {
        URLs.push(profile.team_member_link.cached_url.replace('/zh/', 'zh/'))
      } else {
        URLs.push(profile.team_member_link.cached_url)
      }
    })

    // Use map instead of some to maintain order
    storyContent = URLs.map(url => {
      const matchedProfile = allTeamProfiles.find(story => story.slug === url)
      return matchedProfile || null
    })

    setProfiles(storyContent)
  }, [allTeamProfiles, blok?.team_members])

  return (
    <SbEditable content={blok}>
      <TeamMembersWrapper className="margin-standard margin-end">
        <TeamMembersContainer>
          {profiles?.map((profile, index) => (
            <>
              {profile && (
                <>
                  <TeamMember
                    index={index}
                    openModal={openModal}
                    profile={profile}
                  />
                  {selectedProfileIndex === index && (
                    <TeamMemberModal
                      profile={profile}
                      closeModal={closeModal}
                      lang={lang}
                    />
                  )}
                </>
              )}
            </>
          ))}
        </TeamMembersContainer>
      </TeamMembersWrapper>
    </SbEditable>
  )
}

export default TeamMemberGridSmall
