import React, { useEffect, useRef } from 'react'
import SbEditable from 'storyblok-react'
import { Container} from 'components/Grid'
import styled from 'styled-components'
import media from 'utils/media'

const HeroAnimated = ({ blok }) => {
    const refHero = useRef();
    const refImage = useRef();
    const refTitle = useRef();
    const maxZoom = 2;
    const minZoom = 1;

    useEffect(() => {
        const containerHeight = refHero.current.offsetHeight;
        const animate = (sp, h, spt) =>{
            const val = 2 - ((sp + 60) / (h * .4));
            const valt = (spt / (h * .3 )) ;
            if (val <= maxZoom && val >= minZoom){
                refImage.current.style.transform = `scale(${val})`
            }
            if(valt <= 1.1){
                refTitle.current.style.opacity = `${valt}`
            }
        }
        const handleScroll = () => {
            const y = document.documentElement.scrollTop;
            let scrollPos = y  - refHero.current.offsetTop;
            let scrollPosT = y  - refTitle.current.offsetTop;   
            animate(scrollPos, containerHeight, scrollPosT);
        }
        
        const listenForScroll = () => {if(window.innerWidth > 500){
            document.addEventListener("scroll",handleScroll); 
        }}

        window.onresize = listenForScroll() ;
        listenForScroll(); 


    }),[]

    return (
        <SbEditable content={blok}>
            <Hero ref={refHero}>
                <ImageContainer >
                    <div ref={refImage} style={{backgroundImage: `url(${blok.image.filename})`}}>
                    </div>
                </ImageContainer>
                <Intro  color = {blok.heading_color} margin_s = {blok.margin_s || '20'} margin_l = {blok.margin_l || '20'}>
                    <h1 ref={refTitle}>
                        {blok.heading}
                    </h1>
                </Intro>
            </Hero>
        </SbEditable>
    )    
}

export default HeroAnimated

const Hero = styled.div`
height:auto;
`

const ImageContainer = styled.div`
top:60px;
position: relative;
overflow:hidden;
div{
    height:300px;
    width:100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
${media.greaterThan('sm')`
    position:sticky;
    div{
        height:100vh;
        width:100vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transform:scale(2);
    }
  `}
`

const Intro = styled(Container)`
    margin-top: ${({ margin_s}) => margin_s + 'vh' || '20vh'};
    position:sticky;
    h1{
        color: ${({ theme, color }) =>
        theme.colors[color] || color || '#000'};
        opacity: 0;
    }
    ${media.greaterThan('sm')`
        margin-top: ${({ margin_l}) => margin_l + 'vh' || '20vh'};
  `}
`

