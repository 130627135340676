import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export function transformImageUrl(image, options) {
  const imageService = '//img2.storyblok.com/'
  const path = image.replace('//a.storyblok.com', '')
  return imageService + options + path
}

export function parseStoryblokResults(edges, filterFn) {
  let formated = []
  if (filterFn) {
    formated = edges.filter(({ node }) => filterFn(node))
  } else {
    formated = edges.map(node => node)
  }

  const content = formated.map(({ node }) => {
    return {
      ...node,
      uuid: node.uuid,
      slug: node.full_slug,
      content: JSON.parse(node.content),
    }
  })
  return content
}

export function getLinkProps(url, file) {
  const { Link } = require('gatsby')
  const isFile = !!file
  return isFile
    ? {
      download: true,
      href: file,
    }
    : url.url
      ? { href: url.url }
      : { as: Link, to: url.cached_url }
}

export function removeGlobalSlug(slug) {
  if (typeof slug !== 'string') return ''
  return slug.indexOf('global/') > -1 ? slug.replace('global/', '') : slug
}
export const removeGlobal = removeGlobalSlug

export function withSlash(slug) {
  return slug.charAt(0) !== '/' ? '/' + slug : slug
}

export function goBack() {
  return window.history.back()
}

export function getSize(size) {
  const options = ['xsmall', 'small', 'medium', 'large', 'xlarge']
  const a = []
  options.map(el => size[el] && a.push(size[el] / 12))
  return a
}

export function excerptText(text, maxLength = 168) {
  const isTooLong = text.length > maxLength
  return isTooLong ? text.substring(0, maxLength) + '…' : text
}

// ###############
//  Theme helpers
// ###############
export function setStyledAccentColor({ theme, accentColor }) {
  // accentColor prop overrides theme accent color
  return accentColor
    ? theme.colors[accentColor] || accentColor
    : theme.colors[theme.accentColor]
}

export function setStyledButtonStyle({ theme, buttonStyle, buttonPrefix }) {
  // accentColor prop overrides theme accent color
  return buttonStyle
    ? theme.buttons[buttonStyle]
    : theme.buttons[theme[`${buttonPrefix}ButtonStyle`]]
}

export function setStyledPrimaryButton(args) {
  return setStyledButtonStyle({ ...args, buttonPrefix: 'primary' })
}

export function setStyledSecondaryButton(args) {
  return setStyledButtonStyle({ ...args, buttonPrefix: 'secondary' })
}

export function getIntlMessage(messages, id) {
  return get(messages, `${id}[0].value`, id)
}

export function notEmpty(arg) {
  return !isEmpty(arg)
}
