import React from 'react'
import SbEditable from 'storyblok-react'
import Components from 'components/components'
import { StyledCol, StyledRow} from './styles'

function TwoCol({ blok, ...props }) {
    return (
      <SbEditable content={blok}>
      <StyledRow
          justifyContent={blok.justify_content || 'start'}
          alignItems={blok.align_items || 'stretch'}
          mDirection={getRow(blok.reverse,blok.m_row)}
          //reverse= {blok.reverse || false}
          mx={[0, 0, -15]}
        >
          <StyledCol
            key={`col__1`}
            width={GetSize(blok.col1_size)[0]}
            removeMargin={blok.remove_margin}
            gapRight = {blok.col_gap / 2}
            gap = {blok.col_gap}
          >
          {blok.col1_body.map(
            item =>
              Components[item.component] &&
              React.createElement(Components[item.component], {
                key: item._uid,
                blok: item,
              }),
          )}
          </StyledCol>
          <StyledCol
            key={`col__2`}
            width= {GetSize(blok.col1_size)[1]}
            removeMargin={blok.remove_margin}
            gapLeft = {blok.col_gap / 2}
          >
          {blok.col2_body.map(
            item =>
              Components[item.component] &&
              React.createElement(Components[item.component], {
                key: item._uid,
                blok: item,
              }),
          )}
          </StyledCol>
        </StyledRow>  
      </SbEditable>
  )
}
export default TwoCol    

function GetSize(size) {
  const options = ['xsmall', 'small', 'medium', 'large', 'xlarge']
  const a = []
  const b =[]
  options.map(el => {
    
    if (size[el]){
      a.push(size[el] / 12)
      size[el] / 12 == 1?b.push(1):b.push(1 - size[el] / 12)
    }
  })
  return [a,b]
}

const getRow = (reverse, mRow) => {
  const r = reverse?'column-reverse':'column';
  return mRow? 'row':r;
}