import React, { useLayoutEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useTheme } from 'styled-components'

import Components from 'components/components'
import SectionIntro from '../SectionIntro'
import Headline from '../Headline'

import { HorizontalSliderContainer } from './styles'

gsap.registerPlugin(ScrollTrigger)

export default function TwoColumnHorizontalSlider({ blok, index, videoUrls }) {
  const [scrollTimeline, setScrollTimeline] = useState()
  const theme = useTheme()

  //DYAMIC CLASSES FOR GREENSOCK MOVEMENT
  const getSliderContainer = `slider-container-${blok?._uid.replace(/-/g, '')}`
  const getSliderWrapper = `slider-wrapper-${blok?._uid.replace(/-/g, '')}`
  const sliderClasses = `margin-standard ${
    blok.headline || blok.headline_slider
      ? 'margin-slider'
      : 'margin-slider-small'
  } margin-end`

  const getBackgroundColor = index => {
    const colors = [
      '#CCAB53',
      theme.colors.newRed,
      theme.colors.newBlue,
      theme.colors.newGreen,
    ]
    return colors[index % colors.length]
  }

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const sliderWrapper = document.querySelector('.' + getSliderWrapper)
      const sliderContainer = document.querySelector('.' + getSliderContainer)

      let scrollTween = gsap.to(sliderContainer, {
        ease: 'none',
        x: () => -(sliderContainer.scrollWidth - window.innerWidth),
        scrollTrigger: {
          trigger: sliderWrapper,
          start: 'center center',
          end: () => '+=' + (sliderContainer.scrollWidth - window.innerWidth),
          scrub: true,
          pin: true,
          invalidateOnRefresh: true,
          refreshPriority: -index,
        },
      })

      setScrollTimeline(scrollTween)
    })

    return () => ctx.revert()
  }, [])

  return (
    <>
      <SbEditable content={blok}>
        <HorizontalSliderContainer className={sliderClasses}>
          <div className={`${getSliderWrapper} horizontal-slider-wrapper`}>
            <div className="headline-container">
              {blok.headline && <SectionIntro blok={blok} />}
              {blok.headline_slider && (
                <Headline
                  className="headline-light-medium"
                  blok={{ headline: blok.headline_slider }}
                  margin=" 30px 0 32px 0"
                  type="h3"
                />
              )}
            </div>

            <div
              style={{ flex: blok.campuses ? 1 : 0 }}
              className={`${getSliderContainer} slider`}
            >
              {blok.campuses &&
                blok.campuses.map(el => {
                  return React.createElement(Components[el.component], {
                    key: el._uid,
                    blok: el,
                    scrollTimeline,
                    videoUrls
                  })
                })}
              {blok.slides &&
                blok.slides.map((el, index) => {
                  const backgroundColor = getBackgroundColor(index)
                  return React.createElement(Components[el.component], {
                    key: el._uid,
                    blok: el,
                    scrollTimeline,
                    backgroundColor,
                    videoUrls
                  })
                })}
            </div>
          </div>
        </HorizontalSliderContainer>
      </SbEditable>
    </>
  )
}
