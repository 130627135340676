import styled from 'styled-components'

export const CardRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  column-gap: 50px;
  height: 100%;

  @media (max-width: 834px) {
    flex-direction: column;
    row-gap: 30px;
  }
`

export const Card = styled.div`
  color: white;
  position: relative;
  flex-grow: 1; 
  flex-basis: 0; 
  min-width: 375px;
  max-width: 500px;
  min-height: 550px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    z-index: 5;

    // ::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 1;
    //   background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.0));
    // }
  }
  
  :hover .button-content p {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  :hover .card-content h2 {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
  }
  

  .card-content {
    position: relative;
    z-index: 3; 
    padding: 32px 42px 0px 42px;

    
    h5 {
      font-family: Halyard-Medium;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 5px;
    }

    h2 {
      font-size: 36px;
      line-height: 44px;
    }

    p {
      font-size: 24px;
      line-height: 34px;
    }
  }

  .button {
    position: relative;
    z-index: 3; 
    padding: 32px 42px;

    img {
      height: 30px;
      width: 30px;
    }

    .button-content {
      display: flex;
      align-items: center;
      column-gap: 8px;

      p {
        font-family: Halyard-Medium;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  @media (max-width: 500px) {
    min-width: 280px;
    min-height: auto;

    .card-content {
      padding: 44px 24px 0px 24px;
  
      
      h5 {
        font-size: 18px;
        line-height: 22px;
      }
  
      h2 {
        font-size: 24px;
        line-height: 28px;
      }
  
      p {
        font-family: Halyard-Medium;
        font-size: 18px;
        line-height: 22px;
      }
    }
  
    .button {
      padding: 44px 24px;
    }
  }
 
`
