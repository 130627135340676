import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import ImageComponent from '../image'
import Content from '../Content'

function ThreeColumnContent({ blok }) {
  return (
    <SbEditable content={blok}>
      <ThreeColumnContentWrapper className="margin-standard">
        <div className="text-wrapper">
          <div className="column-wrapper">
            <div className="column">
              {blok.column_one_image && (
                <ImageComponent blok={{ media: blok.column_one_image }} />
              )}

              {blok.column_one_content && (
                <Content
                  className="headline-medium-small"
                  blok={{ content: blok.column_one_content }}
                />
              )}
            </div>
            <div className="column">
              {blok.column_two_image && (
                <ImageComponent blok={{ media: blok.column_two_image }} />
              )}
              {blok.column_two_content && (
                <Content
                  className="headline-medium-small"
                  blok={{ content: blok.column_two_content }}
                />
              )}
            </div>
            <div className="column">
              {blok.column_three_image && (
                <ImageComponent blok={{ media: blok.column_three_image }} />
              )}
              {blok.column_three_content && (
                <Content
                  className="headline-medium-small"
                  blok={{ content: blok.column_three_content }}
                />
              )}
            </div>
          </div>
        </div>
      </ThreeColumnContentWrapper>
    </SbEditable>
  )
}

export default ThreeColumnContent

const ThreeColumnContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 64px;

  .text-wrapper {
    max-width: 715px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .column-wrapper {
      display: flex;
      align-items: center;
      column-gap: 45px;

      .column {
        width: 100%;

        p {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 834px) {
    padding: 0px 32px;
  }

  @media (max-width: 500px) {
    .column-wrapper {
      flex-direction: column;
      row-gap: 45px;

      .column {
        max-width: 200px;
      }
    }
  }
`
