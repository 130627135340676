import React from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Components from 'components/components'

import { HomepageComponents } from './styles'

gsap.registerPlugin(ScrollTrigger)

export default function HeroComponent({ blok, lang }) {
  return (
    <>
      <HomepageComponents>
        {blok.body &&
          blok.body.map(el => {
            return React.createElement(Components[el.component], {
              key: el._uid,
              blok: el,
              lang: lang,
            })
          })}
      </HomepageComponents>
    </>
  )
}
