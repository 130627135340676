import styled from 'styled-components'

export const Navigation = styled.nav`
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
  padding: 36px 16px;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  overflow: auto;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease;
  }

  @media (min-width: 834px) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    position: relative;
    height: auto;
    padding: 0;
    transform: unset;
    background: transparent;
    overflow: initial;
  }
`

export const List = styled.ul`
  margin: 0;

  @media (min-width: 834px) {
    column-start: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
`

export const Item = styled.li`
  margin-bottom: 14px;

  :last-child {
    margin: 0;
  }

  @media (min-width: 834px) {
    margin: 0 22px 0 0;
  }
`

export const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-self: end;
  padding: 0 0 0 16px;
`

export const ToolbarTwo = styled.div`
  display: none;

  @media (min-width: 834px) {
    display: flex;
    align-items: center;
    justify-self: end;
    padding: 0 0 0 16px;
  }
`
