import React, { useContext } from 'react'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'

import ContentContext from 'lib/content.context'

import News from './News.component'

function NewsContainer(props) {
  const { allNews } = useContext(ContentContext)

  const parsedNews =
    allNews &&
    allNews.map(news => {
      const { cover_image } = news.content
      let imageProps
      if (cover_image && typeof cover_image === 'string') {
        imageProps =
          cover_image &&
          getFluidGatsbyImage(cover_image, {
            maxWidth: 600,
            downloadLocal: true,
          })
        news.content.cover_image = imageProps
      }
      return news
    })

  return <News {...props} data={parsedNews.filter(news =>
    !news.content.hide
  )} />
}

export default NewsContainer
