import styled from 'styled-components'

export const StyledLabel = styled.label`
  padding: 0.75rem 0;
`

const styles = (provided) => ({
  ...provided,
  display: 'flex',
})

export default styles
