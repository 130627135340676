import React from 'react'
import styled from 'styled-components'

const StyledDot = styled.div`
  width: 16px;
  height: 16px;
  margin: 16px 0;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.lightGray};
  &.active {
    background-color: ${({ theme }) => theme.colors.green};
  }
`

export default function Dot({ className, handleClick, id }) {
  function handleSelect() {
    handleClick(id)
  }
  return <StyledDot className={className} onClick={handleSelect} />
}
