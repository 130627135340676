import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import { StyledMarkdown, Select } from 'components/Common'
import Multistep from './Multistep'

function Multioption({ blok }) {
  const availableOptions = blok.body ? blok.body.map(item => item.name) : []
  const [selectedOption, setSelectedOption] = useState(availableOptions[0])

  return (
    <SbEditable content={blok}>
      <StyledMarkdown source={blok.text} />
      <SelectWrapper>
        <Select
          defaultValue={selectedOption}
          options={availableOptions}
          onSelect={setSelectedOption}
        />
      </SelectWrapper>
      {blok.body.reduce((acc, item) => {
        if (item.name === selectedOption) {
          acc = <Multistep blok={item} />
        }
        return acc
      }, null)}
    </SbEditable>
  )
}

export default Multioption

const SelectWrapper = styled.div`
  max-width: 360px;
  margin-bottom: 63px;
`
