import React, { useEffect, useMemo } from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'

import { Container, Row, Col } from 'components/Grid'
import { Link as Button } from 'components/Common'
import DescriptionAccordion from './DescriptionAccordion'

import {
  Title,
  Subheading,
  Background,
  SlideRow,
  StyledVideo,
  MobileContent,
  Aside,
  StyledDiv
} from './styles'

import { notEmpty } from 'utils/helpers'
import { useDeviceType } from 'hooks'

import Text from 'components/text'
import Image from 'components/image'
import Video from 'components/Video/video_inline'
import NavButton from 'components/navlink'

const BodyComponents = {
  text: Text,
  scroll_slider_image: Image,
  scroll_slider_video: Video,
  nav_button_circle_arrow: NavButton,
  image: Image,
  video_inline: Video,
}

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function Slide(props) {
  const isMobile = useDeviceType()

  const { blok, progress } = props

  const videoRef = React.useRef(null)
  const slideWrapper = React.useRef(null)
  const backgroundVideoSrc = !IsChinaDeploy
    ? blok.background_video
    : blok.background_video_alt

  useEffect(() => {
    if (backgroundVideoSrc) {
      videoRef.current.play()
    }
    return () => {
      if (backgroundVideoSrc) {
        videoRef.current.pause()
      }
    }
  }, [])

  function renderButton(component) {
    if (!component) return null
    switch (component.component) {
      case 'link':
        return (
          <Button
            blok={component}
            type={props.buttonStyle}
            buttonOption={blok.button_accent}
            size="big"
            {...(blok.button_accent && { [blok.button_accent]: true })}
          />
        )
      default:
        return null
    }
  }

  const backgroundImageProps =
    blok.background_image &&
    useMemo(
      () =>
        getFluidGatsbyImage(blok.background_image, {
          maxWidth: 2000,
          downloadLocal: true,
        }),
      [blok.background_image],
    )

  function calcTextTransform() {
    let transform = 0
    if (progress <= 5) {
      transform = '100%'
    } else if (progress >= 5 && progress <= 65) {
      transform = Math.max(0, 100 - (progress - 5) * 1.68) + '%'
    } else if (progress >= 65) {
      var acc = props.keepLastSlide && !isMobile ? 2.25 : 2.86
      transform = '-' + String((progress - 65) * acc) + '%'
    }

    return transform
  }

  function calcBackgroundOpacity() {
    let opacity = 1

    if (progress <= 20) {
      opacity = Math.min(1, progress * 0.056)
    } else if (progress < 65) {
      opacity = 1 - (progress - 20) * 0.0165
    } else if (progress >= 65 && progress < 75) {
      opacity = 0.25
    } else {
      var acc = props.keepLastSlide && isMobile ? 0.012 : 0.017
      opacity = 1 - (progress - 32) * acc
    }

    return opacity
  }

  function calcAsideTransform() {
    let transform = 0
    if (progress < 40) {
      transform = '100%'
    } else if (progress >= 30 && progress <= 65) {
      transform = Math.max(0, 100 - (progress - 30) * 2.86) + '%'
    } else if (progress > 65) {
      transform = '-' + String((progress - 65) * 2.84) + '%'
    }

    return transform
  }

  const button = blok.link_item && React.useRef(renderButton(blok.link_item[0])).current

  const body = blok.body && React.useRef(
    blok.body.map(
      el =>
        BodyComponents[el.component] && (
          <MobileContent hideOnDesktop={el.hide_on_desktop}>
            {React.createElement(BodyComponents[el.component], {
              key: el._uid,
              blok: el,
            })}
          </MobileContent>
        ),
    ),
  ).current

  const asideBody = blok.aside && React.useRef(
    blok.aside.map(
      el =>
        BodyComponents[el.component] &&
        React.createElement(BodyComponents[el.component], {
          key: el._uid,
          blok: el,
        }),
    ),
  ).current

  const TitleEl = React.useRef(<Title>{blok.title}</Title>).current
  const SubheadingEl = React.useRef(<Subheading>{blok.subheading}</Subheading>)
    .current

  return (
    <SlideRow ref={slideWrapper}>
      <Container
        id={`slider-container-${props.index}`}
        css="height: 100%; min-height: 100vh;"
      >
        <Row css="height: 100%; min-height: 100vh;">
          <Col
            width={notEmpty(blok.aside) ? [1, 1, 3 / 4, 1 / 2] : [1]}
            css={`
              min-height: 100vh;
              height: 100%;
              z-index: 3;
              /* transition: transform 100ms linear; */
            `}
            style={{
              transform: `translate3d(0, ${calcTextTransform()}, 0)`,
              ...(!notEmpty(blok.aside) && {
                maxWidth: 'calc(750px + 48px)',
                marginLeft: 'auto',
                marginRight: 'auto',
              }),
            }}
          >
            <div>{TitleEl}</div>
            {blok.subheading && <div>{SubheadingEl}</div>}
            {blok.description_expandable && (
              <div>
                <DescriptionAccordion accentColor={props.accentColor}>
                  {blok.description_expandable}
                </DescriptionAccordion>
              </div>
            )}
            <StyledDiv>
              {body}
            </StyledDiv>
            {notEmpty(blok.link_item) && (
              <div style={{ marginTop: '45px' }}>{button}</div>
            )}
          </Col>
          {notEmpty(blok.aside) && (
            <Col
              width={[0, 0, 0, 1 / 2]}
              css={`
                height: 100%;
                min-height: 100vh;
                display: flex;
                align-items: center;
                z-index: 3;
                /* transition: transform 165ms ease-out; */
              `}
              style={{
                transform: `translate3d(0, ${calcAsideTransform()}, 0)`,
              }}
            >
              <Aside css="width: 100%;">{asideBody}</Aside>
            </Col>
          )}
        </Row>
      </Container>

      {/* background image */}
      <Background>
        {blok.background_image && (
          <Img
            fluid={backgroundImageProps}
            style={{ opacity: calcBackgroundOpacity() }}
          />
        )}
        {!!backgroundVideoSrc && (
          <StyledVideo
            loop
            muted
            playsInline
            src={backgroundVideoSrc}
            ref={videoRef}
            style={{ opacity: calcBackgroundOpacity() }}
          />
        )}
      </Background>
    </SlideRow>
  )
}

export default Slide
