import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col } from 'components/Grid'
import { Portal } from 'components/Common'

import XIcon from 'static/img/x_icon_dark.svg'

import { renderComponent, renderButton } from '../helpers'

import { StyledContent } from './styles'

import { ANIMATION_TIME, StyledModal, CloseButton, Heading } from '../styles'

function Modal({ isUsedAsChildInComponents = false, ...props }) {
  const [isModalVisible, setModalVisible] = useState(false)
  const [wasFirstRender, setFirstRender] = useState(false)
  const portalRef = useRef(null)

  const blok = props.blok || props

  useEffect(() => {
    setFirstRender(true)
  }, [])

  function togglePortalVisibility() {
    if(isUsedAsChildInComponents) {
      if(props.showModal) {
        portalRef.current.classList.add('hide')
        return setTimeout(() => props.toggleModal(), ANIMATION_TIME)
      }
      props.toggleModal()
    } else {
      if (isModalVisible) {
        portalRef.current.classList.add('hide')
        return setTimeout(() => setModalVisible(false), ANIMATION_TIME)
      }
      setModalVisible(true)
    }
  }

  function renderContent() {
    return (
      <StyledContent preset={blok.preset}>
        <CloseButton onClick={togglePortalVisibility}>
          <img src={XIcon} width={14} />
        </CloseButton>
        {blok.heading &&
        <Container>
          <Row>
            <Col width={1}>
              {blok.heading && <Heading>{blok.heading}</Heading>}
              {blok.subheading && <h4>{blok.subheading}</h4>}
            </Col>
          </Row>
        </Container>}
        {blok.body && blok.body.map(renderComponent)}
      </StyledContent>
    )
  }

  const button = blok.button && blok.button[0]

  return (
    <>
      {button && renderButton(button, togglePortalVisibility)}
      {(props.showModal || isModalVisible) && (
        <Portal>
          <StyledModal ref={portalRef}>{renderContent()}</StyledModal>
        </Portal>
      )}
      {!wasFirstRender && (
        <StyledModal style={{ display: 'none' }}>{renderContent()}</StyledModal>
      )}
    </>
  )
}

export default Modal
