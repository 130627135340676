import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Components from 'components/components'

import { useAccordion } from 'hooks'
import { media } from 'utils'

import MinusIcon from 'static/img/minus_dark.svg'
import PlusIcon from 'static/img/plus_icon_dark.svg'

function Accordion({ blok }) {
  let [ref, toggleAccordion, height] = useAccordion()

  
  return (
    <SbEditable content={blok}>
      <AccordionWrapper>
        <Heading onClick={toggleAccordion}>
          <p>{blok.title}</p>
          <img src={height ? MinusIcon : PlusIcon} alt="expand" width={17} />
        </Heading>
        <Content ref={ref} height={height}>
          {blok.body &&
            blok.body.map(el => {
              return React.createElement(Components[el.component], {
                key: el._uid,
                blok: el,
              })
            })}
        </Content>
      </AccordionWrapper>
    </SbEditable>
  )
}

export default Accordion

var AccordionWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral};
`

var Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 7px 12px 0;
  cursor: pointer;
  p {
    margin: 0;
    max-width: calc(100% - 44px);
    font-size: 14px;
    font-weight: normal;

    ${media.greaterThan('md')`
      font-size: 16px;
    `}
  }
  img {
    padding-top: 7px;
  }
`

var Content = styled.div`
  transition: height 0.35s ease;
  height: ${({ height }) => height}px;
  overflow: hidden;

  p {
    font-size: 14px;
    font-weight: normal;

    ${media.greaterThan('md')`
      font-size: 16px;
    `}
  }
`
