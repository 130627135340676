import React from 'react'
import SbEditable from 'storyblok-react'
import { Container } from 'components/Grid'
import Components from 'components/components'
import styled, { css } from 'styled-components'
import media from 'utils/media'


const StyledDiv = styled.div`  
position:relative;
    ${media.greaterThan('md')`
        ${({ isImage }) => {
            if (isImage) {
            return css`
                background-image: url(${props => props.image});
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                a[type="green"]{
                    padding: 0 15px;
                    font-size: .85em !important;
                  }
                `
            }
        }}
        min-height:calc(100vh - 60px);
        scroll-snap-align: start;
    `}     
`

const StyledImage = styled.div`
${({ isImage }) => {
    if (isImage) {
      return css`
        background-image: url(${props => props.image});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height:300px;
        width:100%;
        margin-bottom: 30px;
        ${media.greaterThan('md')`
            display:none;
        `}
      `
    }
}}
`

const StyledContainer = styled(Container)`
    ${media.greaterThan('md')`
        display:flex;
        height:100%;
        align-items: flex-start;
        justify-content: ${props => props.justify?props.justify:'flex-start'};
        ${({ hasAside }) => {
            if (hasAside) {
                return css`
                justify-content: flex-start;
                flex-direction: ${props => props.order?'row-reverse':'row'};
                `
            } 
        }}
    `}
`
const Card = styled.div`
    h4{margin-bottom:0;
    font-size: 2rem;}
    h5{font-size:1.3em;
    margin-bottom:.7em;}
    ${({ hasSubhead }) => {
        if (!hasSubhead) {
          return css`
            h4{margin-bottom: 12px;}
          `
        }
    }}
    p{margin-bottom:.67em;}
    p strong{
        font-weight:bold;
    }
    ${media.greaterThan('md')`
        width: ${props => props.cardwidth?props.cardwidth+'vw':'55vw'};
        background: rgba(255,255,255,.9);
        padding: 40px;
        margin-top: ${props => props.margin?props.margin+'vh':'20vh'};
        margin-bottom: 40px;
        h4{font-size: 2.67rem}
        p{1em;}
        > div{
            > div{
                margin:0;
                >div{
                    padding:0;
                    >div{
                        margin-bottom: 0;
                    }
                }
            }
        }
        ${({ hasAside }) => {
            if (hasAside) {
                return css`
                    width: 50%;
                    flex: 1;
                    margin-right:${props => props.order?'0':'10px'};
                `
            } 
        }}

    `}
    ${media.greaterThan('xl')`
        width: ${props => props.cardwidth?props.cardwidth+'vw':'45vw'};
    `}
    
`
const Aside = styled.div`
    ${media.greaterThan('md')`
        width: 50%;
        margin-top: ${props => props.margin?props.margin+'vh':'20vh'};
        background: rgba(255,255,255,.9);
        padding: 20px;
        margin-right:${props => props.order?'10px':'0px'};
    `}
`

export {Card, StyledContainer, StyledDiv, StyledImage}

const FullWidthSection = ({ blok }) => {
    const isImage = !!blok.image 
    const hasAside = blok.aside && Object.keys(blok.aside).length ? true:false;
    const hasSubhead = !!blok.subheading

    

    return(
        <SbEditable content={blok}>
        <StyledDiv
        isImage={isImage}
        image={blok.image}   
        className="margin-none"      
        >
            <StyledImage
            as ="div"
            isImage={isImage}
            image={blok.image}>
            </StyledImage> 
            <StyledContainer
            justify = {blok.horizontal}
            hasAside={hasAside}
            order = {blok.order}>            
                <Card
                margin = {blok.vertical}
                cardwidth = {blok.width}
                hasSubhead = {hasSubhead}
                hasAside= {hasAside}
                order = {blok.order}
                >  
                    
                    <h4>{blok.heading}</h4>
                    {blok.subheading && <h5>{blok.subheading}</h5> }
                    {blok.body.map(
                    blok =>
                        Components[blok.component] &&
                        React.createElement(Components[blok.component], {
                        key: blok._uid,
                        blok,
                        }),
                    )}
                </Card>
                {hasAside &&
                <Aside
                margin = {blok.vertical}
                order = {blok.order}>
                    {blok.aside.map(
                        blok =>
                            Components[blok.component] &&
                            React.createElement(Components[blok.component], {
                            key: blok._uid,
                            blok,
                            }),
                    )}
                </Aside>
                }       
                
            </StyledContainer>
        </StyledDiv>
        </SbEditable>
    )
}

export default FullWidthSection 