import { createGlobalStyle } from 'styled-components'
import { theme, defaultFonts } from 'utils'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    min-height: 100vh;
    max-width: 100%;
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
    overflow-x: hidden;
  }

  body {
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100%;
    margin: 0;
    padding: 0;
    color: ${theme.colors.dark};
  }

  html.modalOpen, body.modalOpen {
    overflow-y: hidden;
  }

  a, a:hover {
    color: inherit;
    text-decoration: none;
  }

  p, a, li, span {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  p.small, small {
    font-size: 14px;
    line-height: 1.71;
  }

  button {
    font-size: 12px;
    line-height: 2;
  }

  img {
    margin: 0;
  }

  .color-dark {
    color: #4a4a4a;
  }

  .color-white {
    color: #fff;
  }

  video {
    width: 100%;
    max-width: 100%;
  }

  ${defaultFonts}

  h2 + h2 {
    margin-top: min(max(7vw), 100px);
  }

  mark {
    background-color: #ff0;
    color: #000
  }

  small {
    font-size: 80%
  }
`
