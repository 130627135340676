import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import Select from './Select'

const Filters = ({ blok, handleFilter, defaultval}) => {
 
    const availableOptions = blok.map((el) => (el));

    return (
        <SbEditable content={blok}>
            <Container>            
                
                <Select 
                    defaultValue={defaultval}
                    options={availableOptions}
                    onSelect={handleFilter}
                />
            
            </Container>
        </SbEditable>
    )        
}

export default Filters

const Container = styled.div`   
    margin: 20px 20px;
`

