import React from 'react'
import styled, { css } from 'styled-components'
import SbEditable from 'storyblok-react'
import isEmpty from 'lodash/isEmpty'

import { Section, StyledMarkdown } from 'components/Common'
import { Container, Row, Col } from 'components/Grid'
import Components from 'components/components'

import media from 'utils/media'

const StyledSection = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  ${({ isImage }) => {
    if (isImage) {
      return css`
        background-image: url(${props => props.image});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      `
    } else {
      return css`
        background-color: ${({ theme, color }) =>
          theme.colors[color] || color || 'transparent'} !important;
      `
    }
  }}

  ${media.greaterThan('sm')`
    align-items: center;
    text-align: ${({ textAlign }) => textAlign || 'left'};
  `}
`

function Banner({ blok }) {
  const isImage = !!blok.background_image
  return (
    <SbEditable content={blok}>
      <StyledSection
        isImage={isImage}
        color={blok.background_color}
        image={blok.background_image}
        paddingTop={blok.padding_top || 160}
        paddingTopMb={blok.padding_top_m || 25}
        paddingBottom={blok.padding_bottom || 160}
        paddingBottomMb={blok.padding_bottom_m || 25}
        textAlign={blok.text_align}
        textAlignMobile={blok.text_align_mobile}
      >
        <Container>
          <Row justifyContent="center">
            {blok.cta_button && (
              <Col
                css={`
                  display: flex;
                  justify-content: center;
                  
                `}
              >
                {blok.cta_button.map(
                  blok =>
                    Components[blok.component] &&
                    React.createElement(Components[blok.component], {
                      key: blok._uid,
                      blok,
                    }),
                )}
              </Col>
            )}
            {blok.text && (
              <Col
                css={`
                  max-width: ${blok.max_width || 750}px;
                `}
              >
                <StyledMarkdown
                  source={blok.text}
                  fontColor={blok.text_color}
                  textAlign={blok.text_align}
                  textAlignMb={blok.text_align_mobile}
                />
              </Col>
            )}
            {!isEmpty(blok.body) && (
              <Col
                css={`
                  max-width: ${blok.max_width || 750}px;
                `}
              >
                {blok.body.map(
                  blok =>
                    Components[blok.component] &&
                    React.createElement(Components[blok.component], {
                      key: blok._uid,
                      blok,
                    }),
                )}
              </Col>
            )}
          </Row>
        </Container>
      </StyledSection>
    </SbEditable>
  )
}

export default Banner
