import React, { useEffect, useLayoutEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import Img from 'gatsby-image'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

import Content from 'components/Content'
import CircleSVG from '../SVGs/circle'
import LineSVG from '../SVGs/line'
import LineMobileSVG from '../SVGs/lineMobile'

import {
  TimelineWrapper,
  FirstAdditionalPoint,
  OddAdditionalPoints,
  EvenAdditionalPoints,
  Year,
  ImageTextWrapper,
  ImageTextWrapperMobile,
  TimelineIntro,
  AdditionalPointText,
} from '../styles'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)

export default function MultiPointTimeline({ blok, multiPoint, imageProps }) {
  const [singleColumn, setSingleColumn] = useState(false)

  useEffect(() => {
    if (blok.content.content.length === 1) {
      setSingleColumn(true)
    }
  }, [])

  //DYAMIC CLASSES FOR GREENSOCK MOVEMENT
  const getSvgContainer = `svg-${blok?._uid.replace(/-/g, '')}`
  const getSvgContainerMobile = `svg-mobile-${blok?._uid.replace(/-/g, '')}`
  const getYearContainer = `year-${blok?._uid.replace(/-/g, '')}`
  const getImageContainer = `image-${blok?._uid.replace(/-/g, '')}`
  const getTextContainer = `text-${blok?._uid.replace(/-/g, '')}`
  const getLine = `line-${blok?._uid.replace(/-/g, '')}`
  const getLineMobile = `line-mobile-${blok?._uid.replace(/-/g, '')}`
  const getCircle = `circle-${blok?._uid.replace(/-/g, '')}`
  const getAdditionalPointContainer = `point-${blok?._uid.replace(/-/g, '')}`
  const getImageContainerMobile = `image-mobile-${blok?._uid.replace(/-/g, '')}`
  const getTextContainerMobile = `text-mobile-${blok?._uid.replace(/-/g, '')}`

  useLayoutEffect(() => {
    const svgContainer = document.querySelector('.' + getSvgContainer)
    const svgContainerMobile = document.querySelector(
      '.' + getSvgContainerMobile,
    )
    const yearContainer = document.querySelector('.' + getYearContainer)
    const imageContainer = document.querySelector('.' + getImageContainer)
    const textContainer = document.querySelector('.' + getTextContainer)
    const lines = document.querySelectorAll('#' + getLine)
    const firstLineMobile = document.querySelector('#' + getLineMobile)

    // Convert NodeList to an array
    const linesArray = Array.from(lines)
    // Move the mobile line to the beginning of the array
    linesArray.unshift(firstLineMobile)

    const circles = document.querySelectorAll('#' + getCircle)
    const additionalPoints = document.querySelectorAll(
      '#' + getAdditionalPointContainer,
    )
    const imageContainerMobile = document.querySelector(
      '.' + getImageContainerMobile,
    )
    const textContainerMobile = document.querySelector(
      '.' + getTextContainerMobile,
    )

    gsap.set(lines, { attr: { 'vector-effect': 'non-scaling-stroke' } })

    let mm = gsap.matchMedia(),
      breakPoint = 834

    mm.add(
      {
        // set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
      },
      context => {
        // context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
        let { isDesktop, isMobile } = context.conditions

        gsap
          .timeline({
            scrollTrigger: {
              trigger: yearContainer,
              start: 'bottom 60%',
              // markers: true,
            },
          })
          .from(
            isDesktop ? imageContainer : imageContainerMobile,
            { opacity: 0, y: 75, scale: 0.6 },
            0.5,
          )
          .from(
            isDesktop ? textContainer : textContainerMobile,
            { opacity: 0, y: 75, scale: 0.6 },
            0.5,
          )

        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: isDesktop ? svgContainer : svgContainerMobile,
            start: isDesktop ? 'top 40%' : 'top 50%',
            end: isDesktop ? 'top -100%' : 'top -40%',
            scrub: 3,
            // markers: true,
          },
        })

        if (isMobile) {
          tl.from(firstLineMobile, {
            drawSVG: '0%',
            ease: 'none',
            duration: 0.99,
          })
        }

        lines.forEach((line, index) => {
          const drawDuration = index === 0 ? 6 : 2

          tl.from(line, {
            drawSVG: '0%',
            ease: 'none',
            duration: isDesktop ? drawDuration : 0.99,
          })
          tl.from(
            circles[index],
            { opacity: 0, duration: isDesktop ? 0.1 : 0.01 },
            '-=0.4',
          )
          tl.from(additionalPoints[index], { opacity: 0, duration: 1 }, '-=1.5')
        })

        return () => {}
      },
    )
  }, [])

  return (
    <>
      <SbEditable content={blok}>
        <TimelineWrapper>
          <Year className={`${getYearContainer}`}>{blok.year}</Year>
          <TimelineIntro className="timeline-intro">
            {blok.introduction}
          </TimelineIntro>

          <ImageTextWrapper
            className={`timeline-image-text-wrapper ${getImageContainer}`}
          >
            <div className="outer-image-wrapper">
              <Img
                fluid={imageProps}
                alt={'image representing ' + blok.introduction}
              />
            </div>
            <LineSVG
              multiPoint={multiPoint}
              getSvgContainer={getSvgContainer}
              getLine={getLine}
            />
            <div className={`content-blok ${getTextContainer}`}>
              <Content blok={{ content: blok.content }} />
            </div>
          </ImageTextWrapper>
          <ImageTextWrapperMobile className={`${getImageContainerMobile}`}>
            <div className="outer-image-wrapper">
              <Img
                fluid={imageProps}
                alt={'image representing ' + blok.introduction}
              />
            </div>
            <div className={`content-blok ${getTextContainerMobile}`}>
              <Content blok={{ content: blok.content }} />
            </div>
            <LineMobileSVG
              getSvgContainerMobile={getSvgContainerMobile}
              getLineMobile={getLineMobile}
              singleColumn={singleColumn}
            />
          </ImageTextWrapperMobile>
        </TimelineWrapper>
        {blok.additional_timeline_points.map((item, index) => {
          if (index === 0) {
            return (
              <FirstAdditionalPoint key={index}>
                <div class="container">
                  <div class="first-div">
                    <CircleSVG getCircle={getCircle} />
                  </div>
                  <div id={`${getAdditionalPointContainer}`} class="second-div">
                    <AdditionalPointText>{item.headline}</AdditionalPointText>
                  </div>
                </div>
              </FirstAdditionalPoint>
            )
          } else if (index % 2 !== 0) {
            return (
              <OddAdditionalPoints key={index}>
                <LineSVG
                  singleColumn={singleColumn}
                  getSvgContainer={getSvgContainer}
                  getLine={getLine}
                />
                <div class="container">
                  <div class="first-div">
                    <CircleSVG getCircle={getCircle} />
                  </div>
                  <div id={`${getAdditionalPointContainer}`} class="second-div">
                    <AdditionalPointText>{item.headline}</AdditionalPointText>
                  </div>
                </div>
              </OddAdditionalPoints>
            )
          } else if (index % 2 === 0) {
            return (
              <EvenAdditionalPoints key={index}>
                <LineSVG
                  singleColumn={singleColumn}
                  getSvgContainer={getSvgContainer}
                  getLine={getLine}
                />
                <div class="container">
                  <div class="first-div">
                    <CircleSVG getCircle={getCircle} />
                  </div>
                  <div id={`${getAdditionalPointContainer}`} class="second-div">
                    <AdditionalPointText>{item.headline}</AdditionalPointText>
                  </div>
                </div>
              </EvenAdditionalPoints>
            )
          }
        })}
        <EvenAdditionalPoints>
          <LineSVG
            singleColumn={singleColumn}
            getSvgContainer={getSvgContainer}
            getLine={getLine}
          />
          <div class="container">
            <div class="first-div">
              <CircleSVG getCircle={getCircle} />
            </div>
          </div>
        </EvenAdditionalPoints>
      </SbEditable>
    </>
  )
}
