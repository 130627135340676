import React, { useRef } from 'react'
import SbEditable from 'storyblok-react'

import Components from 'components/components'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function SzContentParser({ blok }) {
  return (
    <SbEditable content={blok}>
      {!IsChinaDeploy
        ? blok.global_components &&
          blok.global_components.map(el => {
            return React.createElement(Components[el.component], {
              key: el._uid,
              blok: el,
            })
          })
        : blok.shenzhen_components &&
          blok.shenzhen_components.map(el => {
            return React.createElement(Components[el.component], {
              key: el._uid,
              blok: el,
            })
          })}
    </SbEditable>
  )
}

export default SzContentParser
