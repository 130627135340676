import React from 'react'

export var defaultTheme = {
  accentColor: 'blueLight',
  primaryButtonStyle: 'light',
  secondaryButtonStyle: 'secondary',
}

var ThemeContext = React.createContext(defaultTheme)

export default ThemeContext
