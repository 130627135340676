import styled from 'styled-components'

export const Container = styled.div`
  overflow-x: hidden;
  height: 100%;
  width: 100%;

  .education-cards-wrapper {
    padding: 0 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    height: 100%;
    margin-bottom: 50px;
  }

  @media (max-width: 500px) {
    padding: 0 0 32px 0;

    .education-cards-wrapper {
      padding: 0 32px;
      row-gap: 30px;
    }
  }

`
