import React, { useEffect } from 'react'
import moment from 'moment'
import momentTz from 'moment-timezone'

const AddtoCalendar = () => {
  // HELPER FUNCTIONS FOR ICS FILE
  const convertTime = timeStr => {
    const [time, modifier] = timeStr.split(' ')
    let [hours, minutes] = time.split(':')
    if (hours === '12') {
      hours = '00'
    }
    if (hours.length === 1) {
      hours = '0' + hours
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }
    return `${hours}:${minutes}`
  }

  function create_UUID() {
    let dt = new Date().getTime()
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      },
    )
    return uuid
  }

  function isPtTime(inputStr) {
    const pattern = /^\d{2}h\d{2}\s*-\s*\d{2}h\d{2}$/

    return pattern.test(inputStr)
  }

  // Time conversion for SP Events
  function convertTimeFormat(timeStr) {
    const [startTime, endTime] = timeStr.split(' - ')

    const startMoment = moment(startTime, 'HH[h]mm')

    const duration = moment.duration(
      moment(endTime, 'HH[h]mm').diff(startMoment),
    )
    const endMoment = startMoment.clone().add(duration)

    const formattedStartTime = startMoment.format('h:mm A')
    const formattedEndTime = endMoment.format('h:mm A')

    const formattedTimeStr = `${formattedStartTime} - ${formattedEndTime}`

    return formattedTimeStr
  }

  // USE EFFECT TO CREATE THE ICS FILE
  useEffect(() => {
    const fileName = 'event_reminder.ics'
    const UUID = create_UUID()
    const queryParams = new URLSearchParams(window.location.search)
    const format = (queryParams.get('format') || '').trim()
    const name = (queryParams.get('name') || '').trim()
    const description = (queryParams.get('description') || '').trim()
    const date = (queryParams.get('date') || '').trim()
    const location = (queryParams.get('location') || '').trim()
    const campus = (queryParams.get('campus') || '').trim()
    const initialTime = (queryParams.get('time') || '').trim()
    const timezoneOverride = (queryParams.get('timezone') || '').trim()

    const encodedName = encodeURIComponent(name)
    const encodedDescription = encodeURIComponent(description)
    const encodedLocation = encodeURIComponent(location)

    const checkTimeFormat = isPtTime(initialTime)
    const time = checkTimeFormat
      ? convertTimeFormat(initialTime)?.split(' ')
      : initialTime?.split(' ') || ''

    const startTime = time !== '' ? time[0] + ' ' + time[1] : ''
    const endTime = time !== '' ? time[3] + ' ' + time[4] : ''
    const finalStart = convertTime(startTime) + ':00'
    const finalEnd = convertTime(endTime) + ':00'

    const timeZone =
      timezoneOverride && timezoneOverride !== ' '
        ? timezoneOverride
        : campus === 'NY' || campus === 'AON'
        ? 'America/New_York'
        : campus === 'SZ'
        ? 'Asia/Shanghai'
        : campus === 'SP'
        ? 'America/Sao_Paulo'
        : campus === 'SV'
        ? 'America/Los_Angeles'
        : 'America/New_York'

    const encodedTimezone = encodeURIComponent(timeZone)

    if (format === 'Gmail') {
      const dateStart =
        moment(date).format('YYYYMMDD') +
        'T' +
        moment(finalStart, 'HH:mm:ss').format('HHmmss')
      const dateEnd =
        moment(date).format('YYYYMMDD') +
        'T' +
        moment(finalEnd, 'HH:mm:ss').format('HHmmss')

      const encodedDateStart = encodeURIComponent(dateStart)
      const encodedDateEnd = encodeURIComponent(dateEnd)

      window.location.replace(
        `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedName}&details=${encodedDescription}&location=${encodedLocation}&ctz=${encodedTimezone}&dates=${encodedDateStart}/${encodedDateEnd}`,
      )
    } else if (format === 'Outlook') {
      const dateStart =
        moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD') + 'T' + finalStart

      const dateEnd =
        moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD') + 'T' + finalEnd

      const outlookStart = momentTz.tz(dateStart, timeZone).utc().format()
      const outlookEnd = momentTz.tz(dateEnd, timeZone).utc().format()

      const encodedOutlookStart = encodeURIComponent(outlookStart)
      const encodedOutlookEnd = encodeURIComponent(outlookEnd)

      window.location.replace(
        `https://outlook.live.com/owa/?path=/calendar/action/compose&subject=${encodedName}&body=${encodedDescription}&location=${encodedLocation}&startdt=${encodedOutlookStart}&enddt=${encodedOutlookEnd}`,
      )
    } else {
      const dateStart =
        moment(date).format('YYYYMMDD') +
        'T' +
        moment(finalStart, 'HH:mm:ss').format('HHmmss')
      const dateEnd =
        moment(date).format('YYYYMMDD') +
        'T' +
        moment(finalEnd, 'HH:mm:ss').format('HHmmss')

      const save = function (fileURL) {
        if (!window.ActiveXObject) {
          const save = document.createElement('a')
          save.href = fileURL
          save.target = '_blank'
          save.download = fileName || 'unknown'

          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          })
          save.dispatchEvent(evt)
          ;(window.URL || window.webkitURL).revokeObjectURL(save.href)
        } else if (!!window.ActiveXObject && document.execCommand) {
          var _window = window.open(fileURL, '_blank')
          _window.document.close()
          _window.document.execCommand('SaveAs', true, fileName || fileURL)
          _window.close()
        }
      }

      const escapeICSCharacters = text => {
        return text
          .replace(/,/g, '\\,')
          .replace(/;/g, '\\;')
          .replace(/\n/g, '\\n')
          .replace(/\r/g, '\\r')
          .replace(/'/g, "\\'")
          .replace(/’/g, "'")
          .replace(/–/g, '-')
          .replace(/&/g, 'and')
      }

      const ics_lines = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'PRODID:-//Avenues: The World School',
        'METHOD:REQUEST',
        'BEGIN:VEVENT',
        'UID:event-' + UUID,
        'DTSTART;' + `TZID=${timeZone}:${dateStart}`,
        'DTEND;' + `TZID=${timeZone}:${dateEnd}`,
        'DESCRIPTION:' + escapeICSCharacters(description),
        'SUMMARY:' + escapeICSCharacters(name),
        'LOCATION:' + escapeICSCharacters(location),
        'END:VEVENT',
        'END:VCALENDAR',
      ]

      const dlurl = 'data:text/calendar;base64,' + btoa(ics_lines.join('\r\n'))

      save(dlurl)
    }
  }, [])
  return <></>
}

export default AddtoCalendar
