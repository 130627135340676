import styled, { css } from 'styled-components'
import media from 'utils/media'
import { Col } from 'components/Grid'

export const MainWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.blueDark};
  overflow: hidden;
`
export const TopWrapper = styled.div`
  position: relative;
  min-height: ${props => props.minHeight}px;
  height: calc(100vh - 60px);
  transition: height 0.1s linear;

  ${media.greaterThan('sm')`
    height: calc(100vh - ${({ disabledTabs }) =>
      !disabledTabs ? '180px' : '60px'});
  `}
  ${media.greaterThan('lg')`
    max-height: 1600px;
  `}
`
export const BottomWrapper = styled.div`
  display: none;
  padding-bottom: 45px;
  z-index: 2;
  position: relative;
  ${media.greaterThan('sm')`
    display: block;
  `}
`

export const StyledCol = styled(Col)`
  position: relative;

  :not(:last-child):after {
    content: '';
    background-color: #ffffff;
    position: absolute;
    right: 0;
    top: 20px;
    opacity: 0.25;
    height: 40px;
    width: 1px;
  }
`
export const DotsDiv = styled.div`
  display: none;
  position: absolute;
  right: 0;
  margin-right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;

  ${media.greaterThan('lg')`
    display: block;
  `}
  ${media.greaterThan('1440px')`
    margin-right: 42px;
  `}
`

export const ArrowPrev = styled.img`
  display: block;
  width: 16px;
  height: 16px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
  transform: rotate(180deg);
`

export const ArrowNext = styled.img`
  display: block;
  width: 16px;
  height: 16px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
`

export const Section = styled.section`
  .sticky-roll > div > span {
    margin-top: 20vh;
  }

  // Fix for Stickyroll anchors
  // - there is one that is generated unnecessarily after last page
  // - this code hides it so that it does not take up empty space
  ${({ pagesCount }) =>
    css`
      .sticky-roll > div > span:nth-child(${pagesCount + 1}) {
        display: none;
      }
    `};

  .sticky-roll > div {
    height: 100vh;
    top: 0px;
    /* height: calc(100vh + 95px); */
  }
`
