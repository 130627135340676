import React from 'react'

import { LeftCol } from './styles'

import Components from 'components/components'

function UnlockObj({ blok }) {
  return (
    <LeftCol
      width={[1, 1, 1]}
      isAlign={blok.align_top_left}
      isRevers={blok.is_revers_on_mobile}
      maxWidth={blok.left_column_max_width}
      isMargin={blok.is_left_column_margin}
      paddingTop={blok.left_column_padding_top || 0}
      paddingTopMb={blok.left_column_padding_top_mb || 0}
    >
      {blok.left_column.map(
        item =>
          Components[item.component] &&
          React.createElement(Components[item.component], {
            key: item._uid,
            blok: item,
          }),
      )}
    </LeftCol>
  )
}

export default UnlockObj
