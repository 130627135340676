import { removeGlobalSlug, withSlash } from 'utils/helpers'

export function getMoreArticles(allArticles) {
  let numberOfItemsToSlice = 0

  // Determine how many items to slice based on the length of allAonStories
  if (allArticles.length >= 3) {
    numberOfItemsToSlice = 3
  } else if (allArticles.length === 2) {
    numberOfItemsToSlice = 2
  } else if (allArticles.length === 1) {
    numberOfItemsToSlice = 1
  }

  return allArticles
    .filter(
      object =>
        withSlash(removeGlobalSlug(object.full_slug)) !== location.pathname,
    )
    .slice(0, numberOfItemsToSlice)
}
