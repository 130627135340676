import React from 'react'
import SbEditable from 'storyblok-react'
import styled, { css } from 'styled-components'
import { StyledMarkdown } from 'components/Common'
import MultistepItem from './MultistepItem'
import { media } from 'utils'

function Multistep({ blok }) {
  return (
    <SbEditable content={blok}>
      <StyledMarkdown source={blok.text} />
      <StepsWrapper>
        {blok.body.map((step, idx) => (
          <StepWrapper
            key={step._uid}
            step={idx + 1}
            isLast={idx + 1 === blok.body.length}
            hideNumber = {blok.hide_number}
          >
            <MultistepItem blok={step} />
          </StepWrapper>
        ))}
      </StepsWrapper>
    </SbEditable>
  )
}

export default Multistep

var StepsWrapper = styled.div`
  width: 100%;
  padding-top: 80px;
  padding-left: 8px;
  position: relative;

  ${media.greaterThan('sm')`
    padding-top: 0;
    padding-left: ${({hideNumber}) => hideNumber? '24px' : '0px'};
  `}
`

var StepWrapper = styled.div`
  padding-bottom: 130px;
  position: relative;
  ${({hideNumber}) => hideNumber? css``: css`
  &:before {
    content: '${({ step }) => step}';
    position: absolute;
    top: -59px;
    left: 0px;
    border: 1px solid ${({ theme }) => theme.colors.neutral};
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: #fff;
  }`};

  ${media.greaterThan('sm')`
    padding-left: ${({hideNumber}) => hideNumber? '0px' : '70px'};
    border-left: ${({ isLast, theme }) =>
      isLast ? 'none' : `1px solid ${theme.colors.neutral}`};
    padding-bottom: 90px;

    &:before {
      top: 0;
      left: -24px;
    }
  `}
`
