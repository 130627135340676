import React from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { Container } from './styles'
import Card from '../Card/index'

gsap.registerPlugin(ScrollTrigger)

export default function CardCarousel({ blok }) {
  return (
    <>
      <Container className="education-container margin-standard">
        <div class="education-cards-wrapper">
          <Card blok={blok} />
        </div>
      </Container>
    </>
  )
}
