import React from 'react'
import SbEditable from 'storyblok-react'
import {
  render,
  NODE_UL,
  NODE_OL,
} from 'storyblok-rich-text-react-renderer'

import { ContentWrapper } from './styles'
import UnorderedList from './SubComponents/unorderedList'
import OrderedList from './SubComponents/orderedList'

export default function RichText({ blok, ...props }) {
  const document = blok.content

  const transformedChildren = render(document, {
    nodeResolvers: {
      [NODE_UL]: children => (
        <UnorderedList
          blok={children}
          listStyle={blok.list_style}
          listMarginBottom={blok.list_item_margin_bottom}
          listMarginBottomMobile={blok.list_item_margin_bottom_mb}
        />
      ),
      [NODE_OL]: children => (
        <OrderedList
          blok={children}
          listStyle={blok.list_style}
          listMarginBottom={blok.list_item_margin_bottom}
          listMarginBottomMobile={blok.list_item_margin_bottom_mb}
        />
      ),
    },
  })

  return (
    <SbEditable content={blok}>
      <ContentWrapper
        textColor={blok.text_color || "black"}
        textAlign={blok.text_align || props.textAlign}
        marginBottom={props.marginBottom}
        marginTop={props.marginTop}
        className={props.className}
      >
        {transformedChildren}
      </ContentWrapper>
    </SbEditable>
  )
}
