import styled from 'styled-components'

export const SlideContainer = styled.div`
    flex: 0 0 100vw;
    padding: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -2;


  .campus-card {
    width: 90vw;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    z-index: -2;

    .campus-card-text {
      display: flex;
      z-index: 2;
      flex-direction: column;
      font-family: sans-serif;
      color: #fff;
      justify-content: center;
      align-items: center;
      width: 70%;

      h3 {
        font-family: Halyard-SemiBold;
        font-size: 42px;
        line-height: 1.15;
      }

      h5 {
        font-family: Halyard-Medium;
        font-size: 24px;
        line-height: 1.15;
        text-align: center;
        font-weight: normal;
      }

      .button-div {
        display: flex;
        align-items: center;
        column-gap: 24px;
      }

      .button {
        font-family: Halyard-Medium;
        font-size: 20px;
        line-height: 24px;
        border: 1px solid #fff;
        padding: 10px 24px;
        margin-top: 30px;
        cursor: pointer;
        text-align: center;

        :hover {
          background: #fff;
          color: black;
        }
      }
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      z-index: -1;
      opacity: 0.8;
      transition: all 0.8s ease-out;
    }

    .darken {
      opacity: 0.5;
    }
  }

  @media (max-width: 600px) {
    align-items: flex-start; 
    
    .campus-card {
      height: 95%;

      .campus-card-text {
        width: 100%;
        padding: 32px 16px;
  
        h3 {
          font-size: 32px;
          margin-bottom: 16px;
        }
  
        h5 {
          font-size: 20px;
          margin-bottom: 16px;
        }
  
        .button-div {
         flex-direction: column;
        }
  
        .button {
          margin-top: 8px;
          font-size: 18px;
          line-height: 20px;
        }
      }
  }
`
