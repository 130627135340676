import React from 'react'
import { excerptText } from 'utils/helpers'

import { Link } from 'components/Common'
import Content from 'components/Content'
import { CoverImage, TitleLink, TitleSubhead } from './styles'

function CommunityNewsItem({ node }) {
  const { content, slug } = node
  const { title, subheading, cover_image, body, excerpt } = content

  return (
    <Link
      blok={{
        text: title,
        url: {
          cached_url: slug,
        },
      }}
    >
      {cover_image && <CoverImage fluid={cover_image} />}
      <TitleLink>{title} </TitleLink>
      <TitleSubhead>{subheading}</TitleSubhead>
      <Content
        marginTop={"16px"}
        marginBottom={"21px"}
        blok={{ content: excerpt || excerptText(body, 168) }}
      />
    </Link>
  )
}

export default CommunityNewsItem
