import React, { useEffect, useState, useMemo } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'

import TwoColumnTimeline from './TimelineBlocks/twoColumnTimeline'
import SingleColumnTimeline from './TimelineBlocks/singleColumnTimeline'
import MultiPointTimeline from './TimelineBlocks/multiPointTimeline'
import SingleColumnMultiPoint from './TimelineBlocks/singleColumnMultiPoint'

import { EndingLineWrapper } from './styles'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)

export default function CampusTimeline({ blok }) {
  const [timelineBlocks, setTimelineBlocks] = useState()

  useEffect(() => {
    setTimelineBlocks(blok.timeline_blocks)
    document.getElementById('divider-wrapper').style.opacity = 1
  }, [])

  const imagePropsArray = blok.timeline_blocks.map(block => {
    if (block.image) {
      return getFluidGatsbyImage(block.image, {
        maxWidth: 2000,
        downloadLocal: true,
      })
    }
    return null
  })

  return (
    <>
      <div className="margin-small" style={{height: '100%', minHeight: '100vh'}}>
        {timelineBlocks?.map((block, index) => {
          const imageProps = imagePropsArray[index]

          return block?.content?.content?.length === 1 &&
            block?.additional_timeline_points?.length > 0 ? (
            <SingleColumnMultiPoint
              isLast={index === timelineBlocks.length - 1}
              blok={block}
              imageProps={imageProps}
            />
          ) : block?.content?.content?.length === 1 ? (
            <SingleColumnTimeline
              isLast={index === timelineBlocks.length - 1}
              blok={block}
              imageProps={imageProps}
            />
          ) : block?.additional_timeline_points?.length > 0 ? (
            <MultiPointTimeline
              isLast={index === timelineBlocks.length - 1}
              blok={block}
              multiPoint={true}
              imageProps={imageProps}
            />
          ) : (
            <TwoColumnTimeline
              isLast={index === timelineBlocks.length - 1}
              blok={block}
              imageProps={imageProps}
            />
          )
        })}
        <EndingLineWrapper id="divider-wrapper" style={{ opacity: 0 }}>
          <hr></hr>
          <hr></hr>
        </EndingLineWrapper>
      </div>
    </>
  )
}
