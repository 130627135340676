import React from 'react'
import SbEditable from 'storyblok-react'
import styled, { css } from 'styled-components'

const StyledTable = styled.table `
${(props) => {
  if(props.type == 'tuition'){
    return css`
      border-spacing: 0px 5px;
      border-collapse: separate;
      thead{
        th{
          font-weight: normal
        }
      }
      tbody{
        tr{
          color: #fff;
          background: rgb(0, 42, 88);
          
          td{
            padding:15px;
          }
          td:first-child{
            font-weight:bold;
          }
        }
      }
    `
  }

}}
`

const Table = ({ blok }) => (
  <SbEditable content={blok}>
    <StyledTable
    type = {blok.style}>
      {blok.style === 'tuition'?
      <thead>
        {blok.table_content.thead && 
          !blok.hide_header &&
          <tr>
            {blok.table_content.thead.map(thdata => 
              <th key={thdata._uid}>{thdata.value}</th>
            )}
          </tr>
          }
      </thead>
      :''}
      <tbody>
      {blok.table_content.tbody &&
        blok.table_content.tbody.map(row => (
          <tr key={row._uid}>
            {row.body &&
              row.body.map(tdata => <td key={tdata._uid}>{tdata.value}</td>)}
          </tr>
        ))}
        </tbody>
    </StyledTable>
    </SbEditable>  
)

export default Table
