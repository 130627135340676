import React from 'react'
import { Row, Col } from 'components/Grid'
import SbEditable from 'storyblok-react'

import { StyledMarkdown } from 'components/Common'
import { StyledRow, StyledCol, Title, Description, StyledSection } from './styles'
import Components from 'components/components'

function GridInline({ blok, ...props }) {

  return (
    <SbEditable content={blok}>
      <StyledSection
        as="div"
        paddingTop={blok.padding_top || 0}
        paddingBottom={blok.padding_bottom || 0}
        paddingTopMb={blok.padding_top_mobile || 0}
        paddingBottomMb={blok.padding_bottom_mobile || 0}
        color="transparent"
        width='100%'
      >
        <Row
          mx={[0, 0, -15]}
        >
          {blok.heading && (
            <Col>
              <Title color={blok.text_color}>{blok.heading}</Title>
            </Col>
          )}
          {blok.description && (
            <Col>
              <Description color={blok.text_color}>
                <StyledMarkdown
                  source={blok.description}
                  fontColor={blok.text_color}
                />
              </Description>
            </Col>
          )}
          </Row>
          <StyledRow
          justifyContent={blok.justify_content || 'start'}
          alignItems={blok.align_items || 'stretch'}
          mx={[0, 0, -15]}
          gap={blok.elements_gap}
          removeGap={blok.remove_gap}
          >
          {blok.body &&
            blok.body.map((el, idx) => {
              return (
                <StyledCol
                  key={`col__${idx}`}c
                  width={GetSize(blok.sizes, blok.elements_gap?blok.elements_gap:30)}
                  removeMargin={blok.remove_margin}
                  removeGap={blok.remove_gap}
                >
                  {React.createElement(Components[el.component], {
                    key: el._uid,
                    blok: el,
                    ...el,
                    ...props,
                  })}
                </StyledCol>
              )
            })}
            </StyledRow>
        
      </StyledSection>
    </SbEditable>
  )
}
export default GridInline

function GetSize(size) {
  const options = ['xsmall', 'small', 'medium', 'large', 'xlarge']
  const a = []
  const bootStrapSizes = []
  options.map(el => {
    if (size[el] === 'auto') {
      a.push('auto')

      return
    }

    size[el] && bootStrapSizes.push(size[el])
    size[el] && a.push(size[el] / 12)
  })

  const allSizes = [];
  bootStrapSizes.forEach((bootstrapSize, index) => {
    allSizes.push(`${a[index] * 100}%`) 
    } 
  )
  
  return allSizes
}
