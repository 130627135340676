import React from 'react'

import Headline from '../../Headline'

// import YellowPattern from 'static/img/pattern-card-patterns/yellow.jpg'
// import RedPattern from 'static/img/pattern-card-patterns/red.png'
// import BluePattern from 'static/img/pattern-card-patterns/blue.png'
 import TestDots from 'static/img/pattern-card-patterns/white_dots_small.svg'

import { SlideContainer } from './styles'

export default function PatternedCardSlide({ blok, backgroundColor }) {
  return (
    <>
      <SlideContainer
        YellowPattern={TestDots}
        RedPattern={TestDots}
        BluePattern={TestDots}
        backgroundColor={backgroundColor}
      >
        <div className="slide">
          <div className="slide-content">
            <Headline
              className="headline-semibold-extra-large"
              blok={blok}
              margin=" 0 0 35px 0"
              type="h3"
            />
            <p className="body-medium">{blok.text}</p>
          </div>
        </div>
      </SlideContainer>
    </>
  )
}
