import React from 'react'
import styled from 'styled-components'

export default function OrderedList({ blok }) {
  const liChildren = blok.map((child) => {
    return child.props.children[0].props.children[0]
  })

  return (
    <>
      <OList>
        {liChildren.map((li) => {
          return (<li>{li}</li>)
        })}
      </OList>
    </>
  )
}

const OList = styled.ol`
  margin-left: 0px;
  padding-left: 20px;
  text-indent: 0px;
  list-style-type: decimal;

  li {
    padding-left: 10px;
    margin-bottom: ${({ listMarginBottom, listMarginBottomMobile }) =>
      listMarginBottom
        ? listMarginBottom + 'px'
        : listMarginBottomMobile
        ? listMarginBottomMobile + 'px'
        : '0px'};
  }
`