import React, { useEffect, useContext, useState } from 'react'
import SbEditable from 'storyblok-react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ContentContext from 'lib/content.context'
import styled from 'styled-components'
import { processNews } from '../../utils/parseNews'

import WOACard from 'components/WoaArticleCard'

gsap.registerPlugin(ScrollTrigger)

function WOAPostDisplay({ blok }) {
  let { allAonStories } = useContext(ContentContext)
  const [rows, setRows] = useState()

  useEffect(() => {
    const groups = processNews(allAonStories)

    setRows(groups)
  }, [allAonStories])

  useEffect(() => {
    let mm = gsap.matchMedia()

    mm.add('(min-width: 834px)', () => {
      gsap.set('.parent-row:not(:first-child)', { autoAlpha: 0 })

      ScrollTrigger.batch('.parent-row:not(:first-child)', {
        interval: 0.5,
        onEnter: batch => gsap.to(batch, { autoAlpha: 1, stagger: 0.25 }),
      })

      return () => {}
    })
  }, [rows])

  return (
    <SbEditable content={blok}>
      <WOAWrapper
        className="margin-end margin-standard"
        style={{ minHeight: '100vh' }}
      >
        {rows?.map((parentRow, parentRowIndex) => (
          <div key={parentRowIndex} className="parent-row">
            <div className="rows">
              {parentRow.map((subRow, subRowIndex) => (
                <div key={subRowIndex} className="row">
                  {subRow.map((article, articleIndex) => (
                    <div key={articleIndex} className="column">
                      {article && <WOACard blok={article} />}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </WOAWrapper>
    </SbEditable>
  )
}

export default WOAPostDisplay

const WOAWrapper = styled.div`
  padding: 0 64px;
  height: 100%;

  .rows {
    max-width: 1600px;
    margin: 0 auto;
  }

  .row {
    display: flex;
    column-gap: 30px;
    margin-bottom: 30px;
  }

  .column {
    flex: 1;
  }

  @media (max-width: 834px) {
    padding: 0 32px;
    margin: 0 0 60px 0;

    .row {
      flex-direction: column;
      margin-bottom: 0px;
    }

    .column {
      margin-bottom: 36px;
    }
  }
`