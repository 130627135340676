import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
import SbEditable from 'storyblok-react'
import ContentContext from 'lib/content.context'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import TeamMemberModal from '../TeamMemberModal'
import ImageComponent from '../image'
import Headline from '../Headline'

import { QuoteWrapper } from './styles'

gsap.registerPlugin(ScrollTrigger)

function Quote({ blok, index, lang }) {
  let { allTeamProfiles } = useContext(ContentContext)
  const [quoteColor, setQuoteColor] = useState()
  const [modalNeeded, setModalNeeded] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [profile, setProfile] = useState()

  //DYAMIC CLASSES FOR GREENSOCK MOVEMENT
  const getQuoteContainer = `quote-${blok?._uid.replace(/-/g, '')}`
  const getRightColumnContainer = `right-column-${blok?._uid.replace(/-/g, '')}`

  useEffect(() => {
    let URLs = []
    let storyContent

    if (blok.profile_link.cached_url.includes('/pt/')) {
      URLs.push(blok.profile_link.cached_url.replace('/pt/', 'pt/'))
    } else if (blok.profile_link.cached_url.includes('/zh')) {
      URLs.push(blok.profile_link.cached_url.replace('/zh/', 'zh/'))
    } else {
      URLs.push(blok.profile_link.cached_url)
    }

    allTeamProfiles?.some(story => {
      if (URLs.includes(story.slug)) {
        storyContent = story
      }
    })

    setProfile(storyContent)
  }, [allTeamProfiles])

  useEffect(() => {
    setQuoteColor(blok.quote_color)

    if (blok.profile_link.cached_url !== '') {
      setModalNeeded(true)
    }
  }, [])

  useLayoutEffect(() => {
    const quoteContainer = document.querySelector('.' + getQuoteContainer)
    const rightColumnContainer = document.querySelector(
      '.' + getRightColumnContainer,
    )

    let ctx = gsap.context(() => {
      gsap.set(rightColumnContainer, {
        y: 75,
      })

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: quoteContainer,
          start: 'top 50%',
          refreshPriority: -index,
          // end: '+=500',
          // markers: true,
        },
      })

      // add animations and labels to the timeline
      tl.to(rightColumnContainer, { opacity: 1, y: 0 })
    })
    return () => ctx.revert()
  }, [])

  const open = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <SbEditable content={blok}>
      <QuoteWrapper
        className={`quote-wrapper margin-large margin-end ${getQuoteContainer}`}
        modalNeeded={modalNeeded}
        quoteColor={quoteColor}
      >
        <div onClick={open} className="quote-content">
          <div className="columns-container">
            <div className="left-column">
              <ImageComponent blok={{ media: blok.image }} />
            </div>
            <div
              style={{ opacity: 0 }}
              className={`right-column ${getRightColumnContainer}`}
            >
              <div className="quote-wrapper">
                <svg
                  width="94"
                  height="78"
                  viewBox="0 0 94 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M38.8281 45.75V74C11.3281 77.75 1.57813 63.25 1.57813 48C1.57813 23.5 21.0781 5.49999 35.5781 -1.32322e-05L36.5781 2.49998C11.8281 11.75 0.828126 45.75 38.8281 45.75ZM93.8281 45.75V74C66.3281 77.75 56.5781 63.25 56.5781 48C56.5781 23.5 76.0781 5.49999 90.5781 -1.32322e-05L91.5781 2.49998C66.8281 11.75 55.8281 45.75 93.8281 45.75Z" />
                </svg>
                <div className="quote-text">
                  <div className="quote-book-medium quote">{blok.quote}</div>
                  <div className="name-information-wrapper">
                    <div className="mobile-image">
                      <ImageComponent blok={{ media: blok.image }} />
                    </div>
                    <div className="name-information">
                      <Headline
                        type="h4"
                        className="name quote-semibold-small"
                        blok={{ headline: blok.name }}
                        textAlign="right"
                        margin="0"
                        textTransform="uppercase"
                        letterSpacing="1px"
                      />
                      <Headline
                        type="h5"
                        className="title quote-semibold-small"
                        blok={{ headline: blok.title }}
                        textAlign="right"
                        margin="0"
                        textTransform="uppercase"
                        letterSpacing="1px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modalNeeded && openModal && (
          <TeamMemberModal
            profile={profile}
            closeModal={closeModal}
            lang={lang}
          />
        )}
      </QuoteWrapper>
    </SbEditable>
  )
}

export default Quote
