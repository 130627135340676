import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Content from '../Content'

function TextBlock({ blok }) {
  return (
    <SbEditable content={blok}>
      <TextBlockWrapper className="margin-standard margin-end">
        <div className="text-wrapper">
          <Content
            className="body-small"
            blok={{ content: blok.content }}
            textAlign={blok.text_align}
          />
        </div>
      </TextBlockWrapper>
    </SbEditable>
  )
}

export default TextBlock

const TextBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 64px;

  .text-wrapper {
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      line-height: 1.8;
    }
  }

  @media (max-width: 834px) {
    padding: 0px 32px;
  }
`
