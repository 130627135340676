import React, { useState, useEffect } from 'react'
import { useDeviceSize } from 'hooks'
import SbEditable from 'storyblok-react'

import Components from 'components/components'

import { TabSection, StyledButton, StyledDiv } from './styles'

const Switcher = React.memo(function({ blok, isMobile }) {
  const [active, setActive] = useState(0)
  const [wasFirstRender, setFirstRender] = useState(false)
  const activeSlide = blok.elements[active]

  useEffect(() => {
    setFirstRender(true)
  }, [])

  function changeTab(e) {
    setActive(e.target.value)
  }

  function renderSlide(slide) {
    return (
      <StyledDiv key={slide._uid} color={blok.content_background_color}>
        {React.createElement(Components[slide.component], {
          blok: slide,
        })}
      </StyledDiv>
    )
  }

  return (
    <SbEditable content={blok}>
      <section>
        <TabSection color={blok.tab_background_color}>
          {blok.elements &&
            blok.elements.map((el, i) => {
              return (
                isMobile !== undefined &&
                (isMobile ? (
                  <StyledButton
                    key={`tab_element__${i}`}
                    isActive={Number(active) === i}
                    onClick={changeTab}
                    value={i}
                    maxWidth={el.button_width_mobile || el.button_width}
                    css="margin: 10px 12px;"
                  >
                    {el.key_mobile || el.key}
                  </StyledButton>
                ) : (
                  <StyledButton
                    key={`tab_element__${i}`}
                    isActive={Number(active) === i}
                    onClick={changeTab}
                    value={i}
                    maxWidth={el.button_width}
                    css="margin: 15px; max-width: 165px;"
                  >
                    {el.key}
                  </StyledButton>
                ))
              )
            })}
        </TabSection>
        {activeSlide.elements.map(renderSlide)}

        {/* need to render slide on build to handle nested images */}
        {!wasFirstRender && (
          <div style={{ display: 'none' }}>
            {blok.elements.map(slide => slide.elements.map(renderSlide))}
          </div>
        )}
      </section>
    </SbEditable>
  )
})

function SwitcherContainer(props) {
  const { isMobile } = useDeviceSize()

  return <Switcher {...props} {...{ isMobile }} />
}
export default SwitcherContainer
