import styled from 'styled-components'

export const QuoteWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 64px;

  .quote-content {
    display: flex;
    max-width: 729px;
    cursor: ${({ modalNeeded }) => (modalNeeded ? 'pointer' : 'auto')};
  }

  .columns-container {
    display: flex;
    flex: 1;
  }

  .left-column {
    z-index: -1;
    width: 100%;
    max-width: 335px;
    flex: 1;
  }

  .gatsby-image-wrapper {
    width: 335px;
    max-width: 100%;
    border-radius: 50%;
    overflow: hidden;

    > div {
      padding-bottom: 100% !important;
    }
  }

  .right-column {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: -12%;
    width: 100%;
  }

  .quote-wrapper {
    display: flex;
    padding-top: 3%;

    svg {
      width: 100%;
      max-width: 50px;
      margin-right: 15px;

      path {
        fill: ${({ theme, quoteColor }) => theme.colors[quoteColor]};
      }
    }

    .quote {
      margin: 40px 0 35px 0;
    }
  }

  .name-information-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;

    .name-information {
      width: 70%;
    }

    .mobile-image {
      display: none;
    }

    .name {
      color: ${({ theme, quoteColor }) => theme.colors[quoteColor]};
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;

    .right-column {
      flex: 2;
      margin-left: -8%;
    }

    .name-information-wrapper {
      .name-information {
        width: 70%;
      }
    }
  }

  @media (max-width: 500px) {
    .quote-content {
      max-width: 472px;
    }

    .right-column {
      margin-left: 0%;
    }

    .quote-wrapper {
      padding-top: 0%;

      svg {
        max-width: 40px;
        margin-right: 10px;
      }

      .quote {
        font-size: 18px;
        line-height: 30px;
        margin: 40px 0 20px 0;
      }
    }

    .name-information-wrapper {
      flex-direction: row;
      align-items: flex-start;
      column-gap: 17px;

      .name-information {
        width: 100%;

        .name, .title {
          text-align: left !important;
        }
      }

      .mobile-image {
        display: block !important;
        flex: 0 0 80px !important;
      }
    }

    .left-column {
      display: none;
    }
  }
`
