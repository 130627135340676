import React, { useState, useEffect, useContext } from 'react'
import SbEditable from 'storyblok-react'
import ContentContext from 'lib/content.context'

import TeamMemberModal from '../TeamMemberModal'
import ImageComponent from '../image'
import Headline from '../Headline'

import { TeamMembersWrapper, TeamMember, TeamMembersContainer } from './styles'

function TeamMemberGrid({ blok, lang }) {
  let { allTeamProfiles } = useContext(ContentContext)
  const [profiles, setProfiles] = useState()
  const [selectedProfileIndex, setSelectedProfileIndex] = useState(null)

  const openModal = index => {
    setSelectedProfileIndex(index)
  }

  const closeModal = () => {
    setSelectedProfileIndex(null)
  }

  useEffect(() => {
    let URLs = []
    let storyContent = []
    blok?.featured_speakers.forEach(profile => {
      if (profile.team_member_link.cached_url.includes('/pt/')) {
        URLs.push(profile.team_member_link.cached_url.replace('/pt/', 'pt/'))
      } else if (profile.team_member_link.cached_url.includes('/zh')) {
        URLs.push(profile.team_member_link.cached_url.replace('/zh/', 'zh/'))
      } else {
        URLs.push(profile.team_member_link.cached_url)
      }
    })

    // Use map instead of some to maintain order
    storyContent = URLs.map(url => {
      const matchedProfile = allTeamProfiles.find(story => story.slug === url)
      return matchedProfile || null
    })

    setProfiles(storyContent)
  }, [allTeamProfiles, blok?.featured_speakers])

  return (
    <SbEditable content={blok}>
      <TeamMembersWrapper className="margin-end margin-small">
        <TeamMembersContainer>
          {profiles?.map((profile, index) => (
            <>
              {profile && (
                <>
                  <TeamMember
                    className="team-member"
                    onClick={() => openModal(index)}
                    key={index}
                  >
                    <div className="front">
                      <div className="outer-image-wrapper">
                        <ImageComponent
                          blok={{ media: profile.content.image }}
                        />
                      </div>

                      <Headline
                        className="bio-semibold-medium"
                        blok={{ headline: profile.content.name }}
                        margin=" 10px 0"
                        type="h5"
                      />
                      <p>{profile.content.title}</p>
                    </div>
                  </TeamMember>
                  {selectedProfileIndex === index && (
                    <TeamMemberModal
                      profile={profile}
                      closeModal={closeModal}
                      lang={lang}
                    />
                  )}
                </>
              )}
            </>
          ))}
        </TeamMembersContainer>
      </TeamMembersWrapper>
    </SbEditable>
  )
}

export default TeamMemberGrid
