import { resolve } from 'path'
import { CACHE_DIR } from '../defaults'

export default async function cacheImageInfo({ imgSize, spaceId, fileId, imageName, src, srcWebp, srcSets }) {
  if (typeof window === 'undefined') {
    // importing fs cause issues with gatsby-image
    const fs = require('fs-extra')
    const filePath = resolve(`${CACHE_DIR}/${fileId}---${imageName}---${imgSize.width}x${imgSize.height}`)

    try {
      await fs.ensureDir(CACHE_DIR)
      const exists = await fs.pathExists(filePath + '.json')

      let data = {
        name: imageName,
        spaceId,
        fileId,
        src,
        srcSet: srcSets.base,
        srcWebp,
        srcSetWebp: srcSets.webp
      }

      if (exists) {
        console.log('file already exists')
        return
      }

      await fs.writeJson(filePath + '.json', data)

      console.log('success!')
    } catch (err) {
      console.error(err)
    }
  }
}
