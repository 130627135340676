import { useStaticQuery, graphql } from 'gatsby'

function useLanguages() {
  const {
    internLang: { edges: internLang },
  } = useStaticQuery(graphql`
    query {
      internLang: allStoryblokDatasource(
        filter: { data_source: { eq: "languages" } }
      ) {
        edges {
          node {
            name
            value
          }
        }
      }
    }
  `)

  const allLanguages = internLang

  return allLanguages.map(({ node }) => ({ ...node }))
}

export default useLanguages
