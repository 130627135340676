import React from 'react'
import { Row, Col } from 'components/Grid'
import styled, { css } from 'styled-components'
import media from 'utils/media'

import Components from 'components/components'

function HalfGrid({ blok, isInline }) {
  return (
    <StyledRow isCenter={blok.isCenter} {...(isInline && { px: 0 })}>
      {blok.heading && (
        <Col
          width={[1, 1, blok.align_top_right ? 1 / 2 : 1]}
          mr={[0, 0, blok.align_top_right ? 1 / 2 : 0]}
          mb={blok.align_top_right ? [0] : [0, 0, 24]}
          order="-1"
        >
          <StyledHeading
            fontSizeMobile={blok.heading_size_mobile}
            fontSize={blok.heading_size}
            headingPosition={blok.heading_position}
            headingPositionMobile={blok.heading_position_mobile}
          >
            {blok.heading}
          </StyledHeading>
        </Col>
      )}
      <LeftCol
        width={[1, 1, 1 / 2]}
        isAlign={blok.align_top_left}
        isRevers={blok.is_revers_on_mobile}
        maxWidth={blok.left_column_max_width}
        isMargin={blok.is_left_column_margin}
        paddingTop={blok.left_column_padding_top || 0}
        paddingTopMb={blok.left_column_padding_top_mb || 0}
      >
        {blok.left_column.map(
          item =>
            Components[item.component] &&
            React.createElement(Components[item.component], {
              key: item._uid,
              blok: item,
            }),
        )}
      </LeftCol>
      <RightCol
        width={[1, 1, 1 / 2]}
        mb={[0, 0, 32]}
        ml={[0, 0, blok.align_top_left ? '50%' : 0]}
        isAlign={blok.align_top_right}
        maxWidth={blok.right_column_max_width}
        isMargin={blok.is_right_column_margin}
        paddingTop={blok.right_column_padding_top || 0}
        paddingTopMb={blok.right_column_padding_top_mb || 0}
      >
        {blok.right_column.map(
          item =>
            Components[item.component] &&
            React.createElement(Components[item.component], {
              key: item._uid,
              blok: item,
            }),
        )}
      </RightCol>
    </StyledRow>
  )
}

export default HalfGrid

const StyledHeading = styled.h3`
  font-size: ${({ fontSizeMobile }) => fontSizeMobile || '32px'};
  line-height: ${({ fontSizeMobile }) =>
    fontSizeMobile && Number(fontSizeMobile.replace('px', '')) > 32
      ? '1.4'
      : '1.33'};
  ${({ headingPositionMobile }) => {
    switch (headingPositionMobile) {
      case 'right':
        return css`
          text-align: right;
        `
      case 'center':
        return css`
          text-align: left;
          padding-left: 54%;
        `
      case 'align-center':
        return css`
          text-align: center;
        `
      default:
        return css`
          text-align: left;
        `
    }
  }}

  ${media.greaterThan('md')`
    ${({ headingPosition }) => {
      switch (headingPosition) {
        case 'right':
          return css`
            text-align: right;
          `
        case 'center':
          return css`
            text-align: left;
            padding-left: calc(50% + 24px);
          `
        case 'align-center':
          return css`
            text-align: center;
          `
        default:
          return css`
            text-align: left;
          `
      }
    }}
  `}

  ${media.greaterThan('lg')`
    font-size: ${({ fontSize }) => fontSize || '32px'};
    line-height: ${({ fontSize }) =>
      fontSize && Number(fontSize.replace('px', '')) > 40 ? '1.13' : '1.5'};
  `}
`

const StyledCol = styled(Col)`
  max-width: ${props => (props.maxWidth ? props.maxWidth + 'px' : 'unset')};
  padding-top: ${props => props.paddingTopMb}px;
  /* padding-left: 0 !important;
  padding-right: 0 !important; */
  ${({ isMargin }) => {
    switch (isMargin) {
      case 'none':
        return css`
          padding-left: 0;
          padding-right: 0;
        `
      default:
        return css`
          padding-left: 24px;
          padding-right: 24px;
        `
    }
  }}
  img {
    padding-bottom: 15px;
  }
  ${media.greaterThan('md')`
    padding-top: ${props => props.paddingTop}px;
    img {
      padding-bottom: 0;
    }
  `}
`

const LeftCol = styled(StyledCol)`
  order: ${props => (props.isRevers ? '3' : '0')};
  ${media.greaterThan('md')`
    order: 0;
    ${({ isAlign }) => {
      if (isAlign) {
        return css`
          position: absolute;
          display: blok;
          top: 0;
          left: 0;
        `
      } else {
        return css``
      }
    }}
  `}
`

const RightCol = styled(StyledCol)`
  ${media.greaterThan('md')`
    ${({ isAlign }) => {
      if (isAlign) {
        return css`
          position: absolute;
          top: 0;
          right: 0;
        `
      } else {
        return css`
          text-align: left;
        `
      }
    }}
  `}
`

const StyledRow = styled(Row)`
  position: relative;
  ${({ isCenter }) =>
    isCenter
      ? css`
          display: flex;
          justify-content: center;
        `
      : css``};
`
