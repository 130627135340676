import { Container, Col } from 'components/Grid'
import styled from 'styled-components'
import media from 'utils/media'

export const TabMenu = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  > div{
    max-width:1200px!important;
    > div{
      padding: 0 20px  !important;
    }
  }
`

export const Lead = styled.h3`
  margin: 36px 0 20px!important;
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'Halyard-SemiBold', sans-serif;
  font-feature-settings: 'ss18';
  font-size: 60px;
  line-height: 1.05;

  ${media.lessThan('sm')`
      font-size: 45px;
  `}

  ${media.greaterThan('md')`
    margin: 160px 0 20px;
  `}
`

export const Note = styled.div`
  p {
    font-size: 26px;
    color: ${({ theme }) => theme.colors.dark};
    margin-bottom: 35px;
    line-height: 1.5;
    letter-spacing: 0.27px;
  }
`

export const StyledTab = styled(Col)`
  p,
  span {
    font-size: 20px;
    line-height: 1.4;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0 !important;
    color: ${({ theme }) => theme.colors.dark};
  }

  position: relative;
  padding-top: 28px;
  padding-bottom: 28px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: ${({ active, theme }) => active ? '#fff' : theme.colors.lightGray};

  ${media.greaterThan('sm')`
    padding-left: 12px !important;
    padding-right: 12px !important;
  `}

  ${media.greaterThan('md')`
    padding-left: 20px !important;
    padding-right: 20px !important;
  `}

  cursor: pointer;
  display: flex;
  align-items: center;

  letter-spacing: 0.69px;

  &:not(:last-of-type):after {
    content: '';
    background-color: #ffffff;
    position: absolute;
    right: 0;
    opacity: 0.25;
    height: 70px;
    width: 1px;
  }

  div {
    word-break: break-word;
    max-width: 100%;
    margin: 0 auto;
  }
`

export const Image = styled.div`
  width: 100%;

  .gatsby-image-wrapper,
  img,
  picture {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
`

export const StyledContainer = styled(Container)`
  max-width: 994px;
  margin-bottom: 40px;
`

export const AccordionWrapper = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
`
export const StyledAccordion = styled.div`
  background-color: ${({ active, theme }) =>
    active ? '#fff' : theme.colors.lightGray};
  margin: 0;
  color: #000;
`
export const VideoDiv = styled.div`
  position: relative;
  padding-bottom: 57.666667%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  ${media.greaterThan('lg')`
    padding-bottom: 47.466667%;
  `}

  iframe {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 946px;
    transform: translateX(-50%);
  }
`
