import React, { useEffect, useState, useContext } from 'react'
import SbEditable from 'storyblok-react'
import ContentContext from 'lib/content.context'

import TeamMember from '../TeamMember'
import DownloadArrow from 'static/img/button-arrows/download_arrow.svg'

import { TabWrapper, TabContentWrapper } from './styles'

function Tabs({ blok }) {
  const [activeTab, setActiveTab] = useState(0)
  let { allTeamProfiles } = useContext(ContentContext)
  const [profiles, setProfiles] = useState()

  useEffect(() => {
    let URLs = []
    let storyContent = []

    blok?.tab.forEach(tab => {
      if (tab.tab_content[0]?.profile_link.cached_url.includes('/pt/')) {
        URLs.push(
          tab.tab_content[0]?.profile_link.cached_url.replace('/pt/', 'pt/'),
        )
      } else if (tab.tab_content[0]?.profile_link.cached_url.includes('/zh')) {
        URLs.push(
          tab.tab_content[0]?.profile_link.cached_url.replace('/zh/', 'zh/'),
        )
      } else {
        URLs.push(tab.tab_content[0]?.profile_link.cached_url)
      }
    })

    // Use map instead of some to maintain order
    storyContent = URLs.map(url => {
      const matchedProfile = allTeamProfiles.find(story => story.slug === url)
      return matchedProfile || null
    })

    setProfiles(storyContent)
  }, [allTeamProfiles])

  return (
    <SbEditable content={blok}>
      <TabWrapper className="margin-standard margin-end">
        <div className="tab-container">
          <div className="tab-names-wrapper">
            {blok?.tab.map((tab, index) => (
              <div
                key={index}
                className="tab-names"
                onClick={() => setActiveTab(index)}
                style={{
                  textDecoration: activeTab === index ? 'underline' : 'none',
                }}
              >
                <p className="tabs-medium-medium tab-name">{tab.tab_name}</p>
              </div>
            ))}
          </div>
          <TabContentWrapper>
            <div className="tab-container">
              {blok.tab[activeTab].tab_content[0].profile_link.cached_url &&
                blok.tab[activeTab].tab_content[0].profile_link.cached_url !==
                  '/pt/' &&
                blok.tab[activeTab].tab_content[0].profile_link.cached_url !==
                  '/zh/' && (
                  <div className="profile">
                    {profiles && (
                      <TeamMember
                        contactInfo={true}
                        profile={profiles[activeTab]}
                      />
                    )}
                  </div>
                )}
              <div className="links">
                {blok.tab[activeTab].tab_content[0].downloads.map(
                  (download, index) => (
                    <div key={index}>
                      <a
                        href={download.download_link.url}
                        download
                        target="_blank"
                      >
                        <div className="download-arrow-button">
                          <img src={DownloadArrow} alt="download arrow" />
                          <p className="body-medium">{download.asset_name}</p>
                        </div>
                      </a>
                    </div>
                  ),
                )}
              </div>
            </div>
          </TabContentWrapper>
        </div>
      </TabWrapper>
    </SbEditable>
  )
}

export default Tabs
