import styled from 'styled-components'

export const LinkWrapper = styled.div`
  margin-top: ${({ marginTop }) =>
    marginTop ? marginTop + 'px !important' : '32px'};
  display: flex;
  justify-content: ${({ leftAlign }) => (leftAlign ? 'flex-start' : 'center')};

  @media (max-width: 834px) {
    justify-content: center;
  }
`

export const PrimaryButtonWrapper = styled.button`
  background: ${({ theme, blueText, solidBackground }) =>
    blueText
      ? 'transparent'
      : solidBackground
      ? theme.colors.newBlue
      : 'transparent'};
  border: ${({ theme, blueText, solidBackground, whiteOutline }) =>
    blueText
      ? 'none'
      : solidBackground
      ? `1px solid ${theme.colors.newBlue}`
      : whiteOutline
      ? '1px solid #fff'
      : 'none'};
  padding: ${({ blueText, solidBackground, whiteOutline }) =>
    blueText ? '0' : solidBackground || whiteOutline ? '10px 24px' : '0'};
  cursor: pointer;
  font-family: Halyard-Medium;
  font-size: 24px;
  font-size: ${({ blueText, solidBackground, whiteOutline }) =>
    blueText ? '24px' : solidBackground || whiteOutline ? '20px' : '24px'};
  color: ${({ theme, blueText, solidBackground, whiteOutline }) =>
    blueText
      ? theme.colors.newBlue
      : solidBackground || whiteOutline
      ? '#fff'
      : '#000'};
  line-height: 32px;
  text-align: ${({ leftAlign }) => (leftAlign ? 'left !important' : 'center')};

  :hover {
    background: ${({ blueText, solidBackground, whiteOutline }) =>
      blueText
        ? 'transparent'
        : solidBackground
        ? 'transparent'
        : whiteOutline
        ? '#fff'
        : 'transparent'};
    border: ${({ theme, blueText, solidBackground, whiteOutline }) =>
      blueText
        ? 'none'
        : solidBackground
        ? `1px solid ${theme.colors.newBlue}`
        : whiteOutline
        ? '1px solid "#fff"'
        : 'none'};
    text-decoration: ${({ blueText, solidBackground, whiteOutline }) =>
      blueText
        ? 'underline'
        : solidBackground
        ? 'none'
        : whiteOutline
        ? 'none'
        : 'none'};
    color: ${({ theme, blueText, solidBackground }) =>
      blueText
        ? theme.colors.newBlue
        : solidBackground
        ? theme.colors.newBlue
        : '#000'};
  }
`

export const PrimaryButton = styled.div`
  display: flex;
  gap: 2;
  align-items: center;
  column-gap: 12px;
`
