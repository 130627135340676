import React from 'react'
import SbEditable from 'storyblok-react'
import { StyledMarkdown } from 'components/Common'
import styled from 'styled-components'

const StyledHeading = styled.h4`
  margin-bottom: 0;
`

const StyledSubHeading = styled.h5`
  margin-top: 0;
  margin-bottom: 20px;
`

function TextComponent({ blok, fontColor, style }) {

  return (
    <SbEditable content={blok}>
      <div
        style={{
          maxWidth: (blok.max_width && blok.max_width + 'px') || 'unset',
          background: 'rgba(255, 255, 255, 0.85)',
          padding: '30px',
          marginBottom: '30px',
          ...style,
        }}
      >
        <StyledHeading>{blok.title}</StyledHeading>
        <StyledSubHeading>{blok.sub_title}</StyledSubHeading>
        <StyledMarkdown
          source={blok.description}
          fontColor={blok.font_color || fontColor}
          textAlign={blok.text_align}
          deleteMargin={blok.delete_margin}
          listStyle={blok.list_style}
        />
      </div>
    </SbEditable>
  )
}

export default TextComponent
