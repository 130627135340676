import React, { useMemo } from 'react'
import SbEditable from 'storyblok-react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'

function ImageComponent({ blok, style }) {
  const fluidProps = (blok.media === "") ? "Placeholder" : (blok.media &&
    useMemo(
      () =>
        getFluidGatsbyImage(blok.media, {
          maxWidth: blok.max_width || 900,
          downloadLocal: true,
        }),
      blok.media,
    ))
    

  return (
    <SbEditable content={blok}>
      {fluidProps && (
        <Img
          fluid={fluidProps}
          alt={blok.alt}
          style={{ width: '100%', 'margin-bottom':'0px', ...style }}
        />
      )}
    </SbEditable>
  )
}

export default ImageComponent