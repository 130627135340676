import React, { useEffect, useState, useContext } from 'react'
import ContentContext from 'lib/content.context'
import WOACard from '../WoaArticleCard'
import { WOAContainer, WOAWrapper } from './styles'

export default function WOAFeature({ blok }) {
  const { allCommunityNews } = useContext(ContentContext)
  const [woaArticles, setWoaArticles] = useState()

  useEffect(() => {
    const URLs = blok?.news_slides.map(news => {
      if (news.link_to_article.cached_url.includes('/pt/')) {
        return news.link_to_article.cached_url.replace('/pt/', 'pt/')
      } else if (news.link_to_article.cached_url.includes('/zh')) {
        return news.link_to_article.cached_url.replace('/zh/', 'zh/')
      } else {
        return news.link_to_article.cached_url
      }
    })

    const storyContent = URLs.map(url => {
      const matchingStory = allCommunityNews.find(story => story.slug === url)
      return matchingStory || null
    })

    const rows = []
    let rowIndex = 0
    let rowSize = 2

    while (rowIndex < storyContent.length) {
      rows.push(storyContent.slice(rowIndex, rowIndex + rowSize))
      rowIndex += rowSize
      rowSize = rowIndex === 2 ? 3 : 2
    }

    setWoaArticles(rows)
  }, [allCommunityNews])

  return (
    <WOAContainer className='margin-end margin-standard'>
      <WOAWrapper className="container">
        {woaArticles?.map((row, rowIndex) => (
            <div className="row" key={rowIndex}>
              {row?.map((article, articleIndex) => (
                <div className="column" key={articleIndex}>
                  {article && <WOACard blok={article} />}
                </div>
              ))}
            </div>
          ))}
      </WOAWrapper>
    </WOAContainer>
  )
}
