import { css } from 'styled-components'
import { breakpoints } from './media'
import { media } from 'utils'

import triangleWhiteSVG from 'static/img/triangle_white.svg'

const colors = {
  dark: '#000',
  black: '#000',
  lightGray: '#f2f2f3',
  gray: '#dbd9db',
  darkGray: '#565656',
  white: '#fff',
  magenta: '#c36192',
  purple: '#783b70',
  indigo: '#544a8d',
  blue: '#7fadcf',
  green: '#248e68',
  lightgreen: '#85b95d',
  darkgreen: '#165942',
  yellow: '#ecb642',
  orange: '#ea8941',
  red: '#df5834',
  tan: '#B7B09C',
  newBlue: '#4767AD',
  newGreen: '#267355',
  newYellow: '#F7CF80',
  newRed: '#C3411E',
  buttonActiveBlue: '#38528A',
  buttonActiveGreen: '#234434',
  buttonActiveBlack: 'rgba(0, 0, 0, 0.3)',
}

const buttonBase = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0 32px;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.25s;
  
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`

const buttonPrimary = css`
  ${buttonBase};

  color: rgba(255, 255, 255, 0.9) !important;
  background: ${colors.mustard};
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';

  &:focus,
  &:active,
  &:before {
    background-color: ${colors.mustardDarker};
  }
`

const buttonSecondary = css`
  ${buttonBase};

  color: rgba(255, 255, 255, 0.9) !important;
  background-color: ${colors.blueDark};
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';

  &:hover,
  &:focus,
  &:active {
    &:before {
      background-color: ${colors.navy};
    }
  }
  &:disabled {
    color: ${colors.greyishBrown};
  }
`

const buttonTertiary = css`
  ${buttonBase};

  color: ${colors.blueDark} !important;
  background-color: #fff;
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';

  &:before {
    background-color: ${colors.lightPink};
  }

  &:focus,
  &:active {
    background-color: #fff;
  }
`

const buttonTertiaryBordered = css`
  ${buttonBase};

  background-color: transparent;
  color: ${colors.darkGray} !important;
  border: 1px solid ${colors.darkGray} !important;
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';
`

const buttonTertiaryBorderedWhite = css`
  ${buttonBase};

  background-color: transparent;
  color: ${colors.white} !important;
  border: 1px solid ${colors.white} !important;
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';
`

const buttonLight = css`
  ${buttonBase};

  color: #fff !important;
  background-color: ${colors.blueLight};
  &:before {
    background-color: #5e879e;
  }
`

const buttonPeriwinkle = css`
  ${buttonBase};

  color: #fff !important;
  background-color: ${colors.purple};
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';
  &:before {
    background-color: #8074aa;
  }
`

const buttonGreen = css`
  ${buttonBase};

  color: #fff !important;
  background-color: ${colors.green};
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';
  &:hover {
    background-color: #165941;
  }
`

const buttonOrange = css`
  ${buttonBase};

  color: #fff !important;
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';
  background-color: ${colors.orange};
  &:before {
    background-color: #ff5d35;
  }
`

const buttonAqua = css`
  ${buttonBase};

  color: #fff !important;
  background-color: ${colors.aqua};
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';
  &:before {
    background-color: #01a1ab;
  }
`

const textLink = css`
  display: flex;
  height: 63px !important;
  width: 187px;
  align-items: flex-end !important;
  border: none;
  border-top: 6px solid ${colors.mustard};
  font-size: 16px;
  position: relative;
  text-decoration: none;
  color: ${colors.blueDark} !important;
  background-color: transparent;
  padding-right: 15px;

  > img {
    margin-left: auto !important;
    margin-bottom: 7px !important;
    padding-bottom: 0 !important;
    transform: translateX(8px) !important;
    transition: transform 0.2s ease-out;
  }

  &:hover {
    > img {
      transform: translateX(13px) !important;
    }
  }
`
const buttonStandard = css`
  min-height: 38px;
`
const buttonSmall = css`
  font-size: 12px !important;
  min-width: 75px;
  max-width: 169px;
  min-height: 40px;
  padding: 0 5px;
`
const buttonSmallAutoSize = css`
  font-size: 12px !important;
  min-width: 75px;
  min-height: 40px;
  padding: 0 5px;
  width: auto !important;
`
const buttonBig = css`
  min-height: 51px;
`
const buttonAutoSize = css`
  width: auto;
  min-height: 51px;
  ${media.greaterThan('sm')`
    width: max-content;
    max-width: 100%;
  `}
`

const arrowButton = css`
  align-items: center !important;
  position: relative;
  justify-content: flex-start;
  text-transform: none !important;

  > img {
    padding-bottom: 0 !important;
    margin-left: auto !important;
    transition: transform 0.2s ease-in;
    transform: translateX(11px);
    width: 17px;
    opacity: 0.9;
  }
  &:hover > img {
    transform: translateX(14px);
  }
`
const underLineButton = css`
  border-bottom: 3px solid ${colors.mustard};
`
const smallUnderLineButton = css`
  &:after {
    content: '';
    position: absolute;
    bottom: -25px;
    width: 59px;
    border-bottom: 6px solid ${colors.mustard};
  }
`
const triangleButton = css`
  position: relative;
  padding-left: 45px;

  &:after {
    content: '';
    background-image: url(${triangleWhiteSVG});
    background-repeat: no-repeat;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-55%);
    height: 15px;
    width: 15px;
  }
`

export default {
  // Colors
  colors,

  // default styles
  buttons: {
    standard: {},
    primary: buttonPrimary,
    secondary: buttonSecondary,
    tertiary: buttonTertiary,
    tertiaryBordered: buttonTertiaryBordered,
    tertiaryBorderedWhite: buttonTertiaryBorderedWhite,
    light: buttonLight,
    periwinkle: buttonPeriwinkle,
    green: buttonGreen,
    aqua: buttonAqua,
    orange: buttonOrange,
    textLink: textLink,
  },
  buttonsSize: {
    small: buttonSmall,
    standard: buttonStandard,
    big: buttonBig,
    autoSize: buttonAutoSize,
    smallAutoSize: buttonSmallAutoSize,
  },
  buttonOptions: {
    withArrow: arrowButton,
    withUnderline: underLineButton,
    withSmallUnderline: smallUnderLineButton,
    withTriangle: triangleButton,
  },

  textLink,

  // GRID
  space: [0, 5, 10, 15, 24],
  breakpoints: Object.values(breakpoints).map(breakpoint => breakpoint + 'em'),
}
