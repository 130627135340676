/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { parseStoryblokResults } from 'utils/helpers'
import Components from 'components/components.js'

import Hero from 'components/Hero'
import SEO from 'components/SEO'

import Markdown from 'components/markdown'
import { Section } from 'components/Common'
import { Container, Row, Col } from 'components/Grid'

function NewsPostEntry({ blok, location, lang, schemaLD }) {
  var { singleNewsEntry } = useStaticQuery(
    graphql`
      query {
        singleNewsEntry: allStoryblokEntry(
          filter: { slug: { eq: "single-news" } }
        ) {
          edges {
            node {
              lang
              content
            }
          }
        }
      }
    `,
  )

  function filterByCurLang(node) {
    return node.lang === lang
  }

  const singleNews =
    singleNewsEntry &&
    parseStoryblokResults(singleNewsEntry.edges, filterByCurLang)[0]?.content
  const [seo] = blok.seo || [{}]

  return (
    <>
      <SEO
        lang={lang}
        pageTitle={seo.title}
        pageDescription={seo.description}
        pageImage={seo.image}
        location={location}
        schemaLD={[
          ...schemaLD,
          {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': '$siteURL/open-ed',
            },
            headline: blok.title,
            image: [blok.cover_image],
            datePublished: blok.first_published_at,
            dateModified: blok.published_at,
            author: {
              '@type': 'Person',
              name: 'Avenues',
            },
            publisher: {
              '@type': 'Organization',
              name: 'Avenues',
              logo: {
                '@type': 'ImageObject',
                url: '$siteURL/assets/ave-logo.png',
              },
            },
          },
        ]}
      />
      <Hero
        blok={{
          title: blok.title,
          subheading: blok.subheading,
          full_height: false,
          breadcrumbs_show_current: true,
          breadcrumbs_visible: true,
          breadcrumbs_override_current: blok.title,
          heading_width: 'big',
          title_font_size: 'h3',
          title_font_size_mobile: 'h3',
          breadcrumbs_spacing_bottom: 71,
          breadcrumbs_spacing_bottom_mobile: 35,
          padding_bottom: 106,
          padding_bottom_mb: 64,
          padding_top_mb: 60,
          padding_top: 90,
        }}
        location={location}
      />
      <Markdown blok={blok} />
      {!blok.body && (
        <Section
          paddingTop={blok.padding_top || 0}
          paddingTopMb={blok.padding_top_mb || 0}
          paddingBottom={blok.padding_bottom || 0}
          paddingBottomMb={blok.padding_bottom_mb || 0}
        >
          <Container>
            <Row justifyContent="center">
              <Col width={[1, 1, 1, 8 / 12]}>
                {blok.new_body &&
                  blok.new_body.map(el => (
                    <div key={el._uid} style={{ marginBottom: '2rem' }}>
                      {React.createElement(Components[el.component], {
                        blok: el,
                      })}
                    </div>
                  ))}
              </Col>
            </Row>
          </Container>
        </Section>
      )}

      {singleNews &&
        singleNews.body &&
        singleNews.body.map(el =>
          React.createElement(Components[el.component], {
            key: el._uid,
            blok: el,
          }),
        )}
    </>
  )
}

NewsPostEntry.defaultProps = {
  schemaLD: [],
}

export default NewsPostEntry
