import React, { useState, useEffect, useRef } from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'
import Player from '@vimeo/player'

import Headline from '../Headline'
import Content from '../Content'
import OpenEdIcon from 'static/img/opened_logo_white.png'

import { PolyvVideo } from 'components/Common'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function Video({ blok }) {
  const videoContainerRef = useRef(null)

  const [isVideoVisible, setVideo] = useState(false)
  const [videoPlayer, setVideoPlayer] = useState()
  const [hideUnMuteButton, setHideUnMuteButton] = useState(false)
  const [thumbnail] = useState(getThumbnail())

  const videoSrc = !IsChinaDeploy
    ? blok.video_link.vimeo_oembed?.response
    : blok.polyv_link

  useEffect(() => {
    if (isVideoVisible && !IsChinaDeploy) {
      setVideoPlayer(
        new Player(`vimeo-video-${blok.video_link._uid}`, {
          id: blok.video_link.vimeo_raw,
          autoplay: 1,
          muted: window.innerWidth < 1024 ? 1 : 0,
        }),
      )
    }
  }, [isVideoVisible])

  function playVideo() {
    /* fire GTM event */
    if (typeof window.__vimeoRefresh === 'function') {
      window.__vimeoRefresh()
    }

    setVideo(true)
  }

  function getThumbnail() {
    let src = ''
    if (IsChinaDeploy) {
      src = getFluidGatsbyImage(get(blok, 'video_thumbnail'), {
        maxWidth: 1200,
        downloadLocal: true,
      })
    } else {
      src =
        get(blok, 'video_thumbnail') ||
        get(blok, 'video_link.vimeo_oembed.response.thumbnail_url', '').replace(
          /_\d*x\d*./g /* remove sizing from URL */,
          '.',
        )
    }
    return src
  }

  const handleUnMuteMobile = () =>
    videoPlayer.setMuted(0).then(() => {
      setHideUnMuteButton(true)
    })

  const playButtonSVG = (
    <svg
      width="93"
      height="52"
      viewBox="0 0 93 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 34V20.72H29.44C33.14 20.72 34.92 22.14 34.92 25C34.92 27.84 33.04 29.34 29.34 29.34H27.54V34H25ZM27.54 27.32H29.34C31.5 27.3 32.38 26.62 32.38 25C32.38 23.42 31.52 22.76 29.38 22.76H27.54V27.32ZM36.582 34V20.72H39.122V31.82H45.122V34H36.582ZM45.9006 34L50.9006 20.72H53.8006L58.6406 34H55.9406L54.6806 30.46H49.7006L48.4206 34H45.9006ZM50.4406 28.36H53.9806L52.2406 23.44L50.4406 28.36ZM61.5372 34V28L56.7572 20.72H59.6372L62.8972 25.84L66.1372 20.72H68.8972L64.0772 28.02V34H61.5372Z"
        fill="white"
      />
      <rect
        x="0.75"
        y="0.75"
        width="91.5"
        height="50.5"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  )

  return (
    <SbEditable content={blok}>
      <OpenEdVideoWrapper className="margin-standard margin-end">
        <div className="video-container">
          {!isVideoVisible && thumbnail ? (
            <>
              <VideoDiv videoPlayer={videoPlayer} ref={videoContainerRef}>
                <div className="image-wrapper">
                  {typeof thumbnail === 'string' ? (
                    <img src={thumbnail} />
                  ) : (
                    <Img fluid={thumbnail} />
                  )}
                </div>
              </VideoDiv>
              <div className="content-wrapper">
                <img
                  className="opened-icon"
                  src={OpenEdIcon}
                  alt="Avenues Open.Ed icon"
                />
                <Headline
                  type="h4"
                  blok={{ headline: blok.title }}
                  className="headline-medium-large"
                  margin="0"
                />
                <Headline
                  type="h4"
                  blok={{ headline: blok.subheading }}
                  className="fast-facts-book-large"
                />
                <p className="fast-facts-book-large">Featured Speakers:</p>
                <Content
                  className="fast-facts-book-large"
                  blok={{ content: blok.featured_speakers }}
                />

                <button type="button" aria-label="play" onClick={playVideo}>
                  {playButtonSVG}
                </button>
              </div>
            </>
          ) : !IsChinaDeploy ? (
            <VideoDiv className="margin-standard">
              <div id={`vimeo-video-${blok.video_link._uid}`} />
              {!hideUnMuteButton && (
                <button
                  className="unMuteButton"
                  aria-label="unmute"
                  onClick={handleUnMuteMobile}
                >
                  UNMUTE
                </button>
              )}
            </VideoDiv>
          ) : (
            <PolyvVideo className="margin-standard" videoId={videoSrc} />
          )}
        </div>
      </OpenEdVideoWrapper>
    </SbEditable>
  )
}

export default Video

const OpenEdVideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 64px;
  position: relative;

  .video-container {
    max-width: 1140px;
    width: 100%;
  }

  .content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1140px;

    button {
      margin-top: 32px;
      background: transparent;
      border: none;
      cursor: pointer;
      transition: all .2s ease-in-out; 

      :hover {
        transform: scale(1.1); 
      }
    }

    .opened-icon {
      margin-bottom: 20px;
    }

    h4,
    p {
      color: white;
    }
    p {
      text-align: center;
      margin: 0;
    }
  }

  @media (max-width: 834px) {
    padding 0 32px;
  }
`

const VideoDiv = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  min-height: 722px;

  .image-wrapper {
    ::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.45);
    }
  }

  & > div[id^='vimeo-video-'] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  iframe,
  img,
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img,
  .gatsby-image-wrapper {
    left: 50% !important;
    transform: translateX(-50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .unMuteButton {
    display: none;
  }

  @media (max-width: 834px) {
    min-height: 0;

    .image-wrapper {
      padding-bottom: 60%;
    }
  }

  @media (max-width: 500px) {
    .image-wrapper {
      padding-bottom: 160%;
    }
  }
`
