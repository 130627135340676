import styled from 'styled-components'
import { Col } from 'components/Grid'
import { media } from 'utils'

export const Wrapper = styled.div`
  background-color: ${props =>
    props.isVisible ? '#ffffff' : ({ theme }) => theme.colors.LightGray};
`
export const Tab = styled.div`
  position: relative;
  border-top: solid 1px #fff;
  padding-bottom: 20px;
  padding-top: 20px;
  cursor: pointer;
  background-color: ${props =>
    props.isVisible ? '#ffffff' : ({ theme }) => theme.colors.LightGray};
`
export const Title = styled.p`
  margin: 0;
  max-width: 87%;
  letter-spacing: 0.69px;
  margin: 28px 0;
  text-transform: uppercase;
  font-weight: normal;
  color: ${props =>
    props.isVisible ? ({ theme }) => theme.colors.Black : '#000'};

  ${media.greaterThan('320px')`
    max-width: 80%;
  `}
`
export const HiddenContent = styled.div`
  max-height: ${props => (props.isVisible ? props.height : '0')};
  transform: ${props => (props.isVisible ? 'scaleY(1)' : 'scaleY(0)')};
  transition: transform 0.4s ease, max-height 0.5s ease;
  margin-top: 5px;
  transform-origin: top;
  background-color: ${props =>
    props.isVisible ? '#ffffff' : ({ theme }) => theme.colors.blueDark};
`
export const FlexCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  display: -webkit-box;
  -webkit-box-pack: space-between;
  -webkit-box-align: center;

  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  img {
    margin: 0;
  }
`
