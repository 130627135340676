import React from 'react'
import { TextButtonWrapper, TextButton } from '../styles'

import BlueIcon from 'static/img/floating_blue_button.svg'

export default function TextIconButton({
  textColor,
  twoLineButton,
  blok,
  underline,
}) {
  return (
    <>
      <TextButtonWrapper
        twoLineButton={twoLineButton}
        underline={underline}
        textColor={textColor}
      >
        <TextButton>
          {twoLineButton ? (
            <span className="twoLineButton">
              {blok.button_text_one}
              <br />
              {blok.button_text_two}
            </span>
          ) : (
            <span className="oneLineButton">{blok.button_text_one}</span>
          )}
          <img src={BlueIcon} alt="circular arrow" />
        </TextButton>
      </TextButtonWrapper>
    </>
  )
}
