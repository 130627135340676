import { VARIANTS } from 'components/NewOpenRoles/components/JobsFilters/SelectField/SelectField'
import removedComponents from 'components/NewOpenRoles/components/JobsFilters/SelectField/react-select/config/removed-components'
import DropdownIndicator from './DropdownIndicator'
import ValueContainers from './ValueContainer'
import Option from './Option'

const customComponents = {
  DropdownIndicator,
  Option,
}

export default function getComponentsBasedOnSelectVariant(variant) {
  let variantBasedCustomComponents = {}

  switch (variant) {
    case VARIANTS.CAMPUS:
      variantBasedCustomComponents = { ValueContainer: ValueContainers.CampusesValueContainer }
      break
    case VARIANTS.JOBFUNCTION:
      variantBasedCustomComponents = { ValueContainer: ValueContainers.DepartmentsValueContainer }
      break
    default:
      throw new Error('Unknown select component variant')
  }

  return {
    ...customComponents,
    ...variantBasedCustomComponents,
    ...removedComponents,
  }
}
