import React, { useLayoutEffect } from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

import Headline from '../Headline'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)

function Timeline({ blok, index }) {
  //DYAMIC CLASSES FOR GREENSOCK MOVEMENT
  const getLine = `line-${blok?._uid.replace(/-/g, '')}`
  const getCircle = `circle-${blok?._uid.replace(/-/g, '')}`

  useLayoutEffect(() => {
    const lines = document.querySelectorAll('#' + getLine)
    const circles = document.querySelectorAll('#' + getCircle)

    gsap.set(lines, { attr: { 'vector-effect': 'non-scaling-stroke' } })

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.timeline-container',
        start: 'top 30%',
        end: '70%',
        scrub: 2,
        // markers: true,
        refreshPriority: -index
      },
    })

    circles.forEach((circle, index) => {
      tl.from(circle, { opacity: 0, duration: 1 })
      tl.from(
        lines[index],
        {
          drawSVG: '0%',
          ease: 'none',
          duration: 2,
        },
        '<',
      )
    })
  }, [])

  return (
    <SbEditable content={blok}>
      <TimelineWrapper className="margin-standard">
        <div className="timeline-container">
          <div className="point-container">
            {blok?.timeline_points.map((point, index) => {
              const isLastItem = index === blok?.timeline_points.length - 1
              return (
                <div className="point">
                  <div className="svg-div">
                    <svg
                      width="21"
                      height="21"
                      xmlns="http://www.w3.org/2000/svg"
                      id={`${getCircle}`}
                      className="circle-svg"
                    >
                      <circle
                        cx="10.5"
                        cy="10.5"
                        r="10"
                        fill="none"
                        stroke="black"
                        stroke-width="1"
                      />
                    </svg>
                    {!isLastItem && (
                      <div className="line-container">
                        <svg
                          id="line-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 10 105"
                        >
                          <path
                            d="M5,0 L5,200"
                            stroke="black"
                            stroke-width="1"
                            className="line"
                            id={`${getLine}`}
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="point-content">
                    <Headline
                      type="h4"
                      className="headline-light-small"
                      blok={{ headline: point.headline }}
                      textAlign="left"
                      margin="0 0 15px 0"
                    />
                    <p>{point.content}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </TimelineWrapper>
    </SbEditable>
  )
}

export default Timeline

const TimelineWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 64px;

  .timeline-container {
    display: flex;
    width: 100%;
    max-width: 744px;
  }

  .point {
    display: flex;
    column-gap: 30px;

    h4 {
      letter-spacing: 1px !important;
    }

    .point-content {
      margin-top: -9px;
    }

    .line-container {
      display: flex;
      justify-content: center;
      margin-top: -7px;

      #line-svg {
        display: flex;
        align-items: center;
        height: 210px;

        .line {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;
  }

  @media (max-width: 500px) {
    .point {
      .svg-div {
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle-svg:last-child {
          margin-left: 3px;
        }
      }

      .line-container {
        margin-top: 0px;

        #line-svg {
          height: 280px;
        }
      }
    }
  }
`
