import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import SolidBlueArrow from 'static/img/button-arrows/solid_blue_arrow.svg'
import TransparentBlueArrow from 'static/img/button-arrows/transparent_blue_icon.svg'
import ExternalLink from 'static/img/button-arrows/external_link_blue.svg'

import { LinkWrapper, PrimaryButtonWrapper, PrimaryButton } from './styles'

import { removeGlobalSlug, withSlash } from 'utils/helpers'

export default function IconButton({
  blok,
  marginTop,
  blueText,
  solidBackground,
  whiteOutline,
  noFillIcon,
  leftAlign,
  externalIcon,
  ...props
}) {
  //VARIABLES FOR LINKING
  const extHref = _get(blok, 'button_link.url') || props.extHref
  const cachedUrl = _get(blok, 'button_link.cached_url', '')
  const intHref = cachedUrl && withSlash(removeGlobalSlug(cachedUrl))
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const attrs = {
    target: blok.new_tab ? '_blank' : '_self',
    rel: blok.new_tab
      ? 'noopener'
      : !_isEmpty(extHref)
      ? 'external'
      : 'noreferrer',
  }

  return (
    <>
      <LinkWrapper leftAlign={leftAlign} marginTop={marginTop}>
        {!_isEmpty(extHref) ? (
          <a className="button" href={extHref} {...attrs}>
            <PrimaryButtonWrapper
              solidBackground={solidBackground}
              blueText={blueText}
              leftAlign={leftAlign}
              whiteOutline={whiteOutline}
            >
              <PrimaryButton
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {blok.button_text}
                {blueText && (
                  <img
                    src={
                      isHovered && !externalIcon
                        ? SolidBlueArrow
                        : noFillIcon
                        ? TransparentBlueArrow
                        : externalIcon
                        ? ExternalLink
                        : SolidBlueArrow
                    }
                    alt="icon to indicate button type"
                  />
                )}
              </PrimaryButton>
            </PrimaryButtonWrapper>
          </a>
        ) : (
          <Link className="button" to={intHref} {...attrs}>
            <PrimaryButtonWrapper
              solidBackground={solidBackground}
              blueText={blueText}
              leftAlign={leftAlign}
              whiteOutline={whiteOutline}
            >
              <PrimaryButton
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {blok.button_text}
                {blueText && (
                  <img
                    src={
                      isHovered && !externalIcon
                        ? SolidBlueArrow
                        : noFillIcon
                        ? TransparentBlueArrow
                        : externalIcon
                        ? ExternalLink
                        : SolidBlueArrow
                    }
                    alt="icon to indicate button type"
                  />
                )}
              </PrimaryButton>
            </PrimaryButtonWrapper>
          </Link>
        )}
      </LinkWrapper>
    </>
  )
}
