import React, { useState, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import MatriculationCard from './MatriculationCard'
import Filters from './Filters'
import { Container, Row, Col } from 'components/Grid'
import mixitup from 'mixitup';

const Matriculation = ({ blok }) => {

    const [campus, setCampus] = useState('all-campus');
    const [category, setCategory] = useState('all-category');
    const handleCampus = (e) =>{
        setCampus(e);
    }
    const handleCategory = (e) =>{
        setCategory(e);
    }
    const setClass = (id) => {
        let c = '';
        let t = '';
        const group = [blok.filters, blok.campuses];
        group.forEach((el) =>{
            el.map((el) =>(
                t = el.id + ' ',
                el.Schools.map((el,i) =>(
                    el.id == id?c+=t:''
                ))
            ))
        })
        return c
    }

    useEffect(() => {
        const filterList = document.querySelectorAll('.filter-list'); 
        var mixer = mixitup(filterList); 
        mixer.filter('.'+category+'.'+campus);
    }),[]

    return (
        <SbEditable content={blok}>
            <Container>
                <Row>
                    <FilterCon width={1}>
                        <Filters 
                            blok={blok.filters} 
                            handleFilter={handleCategory}
                            defaultval={blok.filters[0].title}/>
                        <Filters 
                            blok={blok.campuses}
                            handleFilter={handleCampus}
                            defaultval={blok.campuses[0].title}/>
                    </FilterCon>
                </Row>    
                <Row className='filter-list'>
                    {blok.cards.map((el,index) => (
                        <StyledCol
                            key={`card__${index}`}
                            width={[1/2, 1 / 3, 1 / 4, 1 / 6]}
                            px={2}
                            py={2}
                            className = {'ucard mix all-category all-campus ' + setClass(el.id)} 
                        >
                            <MatriculationCard 
                                blok={el}
                            />
                        </StyledCol>
                    ))}    
                </Row>
            </Container>
        </SbEditable>
    )   
}

export default Matriculation

const StyledCol = styled(Col)`
    &.hide{display:none}
`

const FilterCon = styled(Col)`
    margin-bottom: 30px;
    display: flex;
    flex-wrap:wrap;
    justify-content:center;

`

