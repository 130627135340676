import styled, { keyframes } from 'styled-components'
import { media } from 'utils'

export const ANIMATION_TIME = 330
export const animationTimeMs = ANIMATION_TIME + 'ms'

export const fadeIn = keyframes`
  0% {
    transform: scale(0);
  }
  100%: {
    transform: scale(1);
  }
`

export const ModalWrapper = styled.div`
  .request-info-btn {
    border: 1px solid #000 !important;
    padding: 10px 24px 10px 24px !important;
    margin-top: 39px !important;
    font-family: Halyard-Medium !important;
    font-size: 20px !important;
    line-height: 24px !important;
    cursor: pointer !important;
    border-radius: 0 !important;
    background: transparent !important;
    color: #000 !important;

    :hover {
      background: #000 !important;
      color: rgb(247, 207, 128) !important;
      cursor: pointer !important;
    }
  }
`

export const StyledModal = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 2s;
  backdrop-filter: blur(2px);
  min-height: 100vh;
  padding: 0;
`

export const CloseButton = styled.button`
  background-color: #fff;
  border: none;
  position: sticky;
  text-align: right;
  padding: 20px 25px;
  cursor: pointer;
  top: 0px;
  z-index: 3;
`

export const StyledModalContent = styled.div`
  position: relative;
  background-color: #fff;
  width: 98%;
  max-width: 720px;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  overflow: auto;
  padding: 20px 0px 0;
  animation: ${fadeIn} ${animationTimeMs};
  transition: transform ${animationTimeMs}, opacity ${animationTimeMs};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  overflow: scroll;
  max-height: calc(100% - 40px);
  position: fixed;
  left: 0;
  right: 0;
  top: 20px;

  ${media.greaterThan('md')`
      width: 600px;
    `}

  p:last-child {
    margin-bottom: 0;
  }
`
