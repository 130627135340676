import React from 'react'
import SbEditable from 'storyblok-react'
import { Container } from 'components/Grid'
import Components from 'components/components'
import styled, { css } from 'styled-components'
import { StyledMarkdown } from 'components/Common'
import media from 'utils/media'

const StyledDiv = styled.div`  
position:relative;
animation: imageAnimation 50s linear infinite 0s; 
backface-visibility: hidden;
left: 0px;
position: absolute;
top: 0px;
background: #fff;
opacity: 0;
z-index: 0;
    
    h1{
        font-size: 2em;
        line-height: 1em;
        margin-bottom:10px;
    }
    h5{
        font-size: 1.3em;
        line-height: 1.5em;
        margin-bottom:15px;
        strong{
            font-weight:bold;
        }
    }
    a{
        min-width:200px;
    }
    
${media.greaterThan('sm')`
    ${({ isImage }) => {
        if (isImage) {
        return css`
            background-image: url(${props => props.image});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            `
        }
    }}
    
    width: 100vw;
    
    h1{
        padding-top:20%;
        color:#fff;
        font-size: min(max(1rem,7vw),56px);
    }
    h5{
        color:#fff;
        font-size: min(max(1rem,7vw),28px);
        font-family: 'Halyard-Book', 'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif;
        margin-left: 50px;
        text-indent:-19px;
        font-weight: normal;
        line-height: 1.25em;
        strong{
            font-weight: 700;
        }
    }
    section{
        z-index: 3;
    }
    :after{
        content:'';
        position:absolute;
        width: 100%;
        height: 50vh;
        background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 70%);
        bottom:0;
    }
`}  

`
const StyledImage = styled.div`
${({ isImage }) => {
    if (isImage) {
      return css`
        background-color:#000;
        background-image: url(${props => props.image});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height:300px;
        width:100vw;
        margin-bottom: 30px;
        :after{
            content:'';
            position:absolute;
            width: 100%;
            height: 300px;
            background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 55%);
            top:0;
        }
        ${media.greaterThan('sm')`
            display:none;
        `}
      `
    }
}}
`
const StyledBanner = styled.div`
position: absolute; 
left: 20px;
z-index: 2;
width: 200px;
top:230px;
    ${media.greaterThan('sm')`  
        width: 400px;
        top: auto;
        bottom: 40px;
        left: 20px;
        z-index: 2;
        img{ height: 84px;
        width: 280px;
    `}
    ${media.greaterThan('lg')`  
        bottom: 10vh;
    `}
`

const StyledContainer = styled(Container)`
    ${media.greaterThan('sm')`
        position: relative;
        height: calc(100vh - 60px);
        min-height: 730px;
    `}
`

const FullWidthSliderSlide = ({ blok, overlay }) => {
    const isImage = !!blok.background_image 
    return(
        <SbEditable content={blok}>
        <StyledDiv
        isImage={isImage}
        image={blok.background_image}
        >
            <StyledImage
            as ="div"
            isImage={isImage}
            image={blok.background_image}>
                <StyledBanner>
                        <img src={overlay}/>
                </StyledBanner>
            </StyledImage> 
            <StyledContainer>
            <StyledMarkdown
                  source={blok.heading}
                  textAlign="left"
                  textAlignMb="left"
                />
                <StyledMarkdown
                  source={blok.subheading}
                  textAlign="left"
                  textAlignMb="left"
                />
                {blok.buttons.map(
                  blok =>
                    Components[blok.component] &&
                    React.createElement(Components[blok.component], {
                      key: blok._uid,
                      blok,
                    }),
                )}
                <StyledBanner>
                    <img src={overlay}/>
                </StyledBanner>
            </StyledContainer>
        </StyledDiv>
        </SbEditable>
    )
}

export default FullWidthSliderSlide
