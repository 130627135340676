import valueContainer from './components/ValueContainer/styles'
import option from './components/Option/styles'

export default {
  valueContainer,

  option,

  control: () => ({
    backgroundColor: 'white',
    display: 'flex',
    color: 'black',
    border: '2px solid black',
    borderRadius: '8px',
    padding: '0 10px',
    outline: 'none',
    width: '100%',
    appearance: 'none',
  }),

  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
    margin: '9px 0',
    border: '2px solid black',
    borderRadius: '8px',
  }),

  menuList: (provided) => ({
    ...provided,
    padding: '8px 0',
  }),
}
