import styled, { css } from 'styled-components'
import media from 'utils/media'
import { Row, Col } from 'components/Grid'
import { Section, StyledMarkdown } from 'components/Common'

import { defaultFonts } from 'utils/typography'

export const StyledSection = styled(Section)`
  position: relative;
  padding-top: 22px;
  color: ${({ textColor }) => textColor};

  ${({ fullHeight, background }) =>
    fullHeight || background
      ? css`
          height: 100vh;
        `
      : css`
          height: auto;
        `};    
`
export const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
`
export const StyledHeading = styled.h1`
  margin-top: ${({ paddingTopMb }) => paddingTopMb < 70 ? '70px !important' : paddingTopMb + 'px !important'};
  padding-top: ${({ background }) => background ? '60px !important' : '20px!important'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  ${({ sizeMb }) =>
  css`
    ${defaultFonts[sizeMb]}
  `}
  width: 100%;
  color: inherit;
  ${media.greaterThan('sm')`
    margin-top: ${({ paddingTop }) => paddingTop ? paddingTop + 'px !important' : '154px!important'};
    padding-top: ${({ background }) => background ? '80px !important' : '100px!important'};
    ${({ size }) =>
      css`
        ${defaultFonts[size]}
      `}
  `}
  span {
    font-weight: 100;
    font-family: 'Halyard-Book';
  }
  p {
    margin: 0;
    padding: 0;
    color: inherit;
    position: relative;
    hyphens: none;
    word-wrap: normal;

    ${media.greaterThan('md')`
      ${({ size }) =>
        css`
          ${defaultFonts[`h${Number(size.replace('h', '')) + 1}`]}
        `}
    `}
    ${media.greaterThan('lg')`
      ${({ size }) =>
        css`
          ${defaultFonts[size]}
        `}
      }
    `}
  }
`

export const StyledDescription = styled(StyledMarkdown)`
  color: inherit;

  & * {
    color: inherit;
  }
`

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: ${props => props.isBig && '100% !important'};
  ${media.greaterThan('xl')`
    justify-content: ${({ textAlign }) => textAlign || 'flex-end'};
  `}
  a {
    margin-bottom: 33px;
    ${media.greaterThan('md')`
      margin-bottom: 0;
    `}
  }
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  &,
  .gatsby-image-wrapper,
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: ${({ opacity }) => 1 - opacity || 0};
  }
`
