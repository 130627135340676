import React from 'react'

import { Container, Row } from 'components/Grid'

import { TabMenu, StyledTab } from './styles'
import { StyledMarkdown } from 'components/Common'

function TabSwitcherDesktop({
  items,
  renderTabContent,
  activeItem,
  setActive,
}) {
  return (
    <div>
      <TabMenu>
        <Container>
          <Row flexWrap="nowrap" px={[0, 0, 0, 94]}>
            {items.map((tab, i) => (
              <Tab
                handleClick={setActive}
                id={i}
                isActive={i === activeItem}
                key={tab._uid}
              >
                <StyledMarkdown source={tab.title} />
              </Tab>
            ))}
          </Row>
        </Container>
      </TabMenu>
      {renderTabContent(items[activeItem])}
    </div>
  )
}

var Tab = function Tab(props) {
  function handleSelect() {
    props.handleClick(props.id)
  }
  return (
    <StyledTab onClick={handleSelect} active={props.isActive}>
      <div>{props.children}</div>
    </StyledTab>
  )
}

export default TabSwitcherDesktop
