import { useState, useEffect } from 'react'

const useOpenModalFromEvent = () => {
  const [modalOpen, setModalOpen] = useState('')

  useEffect(() => {
    const docElement = document.documentElement
    const body = document.body
    if (modalOpen) {
      docElement.classList.add('modalOpen')
      body.classList.add('modalOpen')
    } else {
      docElement.classList.remove('modalOpen')
      body.classList.remove('modalOpen')
    }
  }, [modalOpen])

  const toggleModalVisibility = modalId =>
    setModalOpen(modalOpen ? '' : modalId)

  return { modalOpen, toggleModalVisibility }
}

export default useOpenModalFromEvent
