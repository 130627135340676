import React, { useLayoutEffect, useRef, useEffect } from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import IconButton from '../ButtonBase'
import Headline from '../Headline'

import { SplitImageContainer, SplitImageWrapper } from './styles'

gsap.registerPlugin(ScrollTrigger)

export default function SplitImage({ blok, index }) {
  const splitImageRef = useRef()

  const getSplitWrapper = `wrapper-${blok?._uid.replace(/-/g, '')}`
  const getLeftSide = `left-${blok?._uid.replace(/-/g, '')}`
  const getRightSide = `right-${blok?._uid.replace(/-/g, '')}`
  const getLeftSideMobile = `left-mobile${blok?._uid.replace(/-/g, '')}`
  const getRightSideMobile = `right-mobile${blok?._uid.replace(/-/g, '')}`

  const nestedArray = []
  const numberOfPoints = blok.number_of_points

  if (numberOfPoints === '1') {
    for (let i = 0; i < blok.split_image_points.length; i++) {
      const subarray = [blok.split_image_points[i]]
      nestedArray.push(subarray)
    }
  } else {
    for (let i = 0; i < blok.split_image_points.length; i += 2) {
      const subarray = blok.split_image_points.slice(i, i + 2)
      nestedArray.push(subarray)
    }
  }

  useEffect(() => {
    function handleResize() {
      ScrollTrigger.refresh()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useLayoutEffect(() => {
    const splitWrapper = document.querySelector('.' + getSplitWrapper)
    const leftSide = document.querySelector('.' + getLeftSide)
    const rightSide = document.querySelector('.' + getRightSide)
    const leftSideMobile = document.querySelector('.' + getLeftSideMobile)
    const rightSideMobile = document.querySelector('.' + getRightSideMobile)

    let mm = gsap.matchMedia()

    mm.add('(min-width: 501px)', () => {
      gsap.set(leftSide, {
        clipPath: 'polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)',
      })
      gsap.set(rightSide, {
        clipPath: 'polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%)',
      })

      const splitImageTimeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: splitWrapper,
            start: 'top top',
            end: '+=4500px',
            scrub: true,
            pin: true,
            refreshPriority: -index,
          },
        })
        .to(leftSide, {
          x: -(window.innerWidth / 6),
          duration: 1,
        })
        .to(
          rightSide,
          {
            x: window.innerWidth / 6,
            duration: 1,
          },
          '<',
        )

      nestedArray.forEach((point, index) => {
        splitImageTimeline.fromTo(
          `.text-column-${index}`,
          {
            autoAlpha: 0,
            top: '110%',
          },
          {
            autoAlpha: 1,
            top: '50%',
            duration: 3,
          },
        )

        if (index !== nestedArray.length - 1) {
          splitImageTimeline.to(`.text-column-${index}`, {
            autoAlpha: 0,
            top: 0,
            duration: 3,
            delay: 2,
          })
        }
      })
    })

    mm.add('(max-width: 500px)', () => {
      gsap.set(leftSideMobile, {
        clipPath: 'polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)',
      })
      gsap.set(rightSideMobile, {
        clipPath: 'polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%)',
      })

      const splitImageTimeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: splitWrapper,
            start: 'top top',
            end: '+=4500px',
            scrub: true,
            pin: true,
            refreshPriority: -index,
          },
        })
        .to(leftSideMobile, {
          x: -window.innerWidth,
          duration: 6,
        })
        .to(
          rightSideMobile,
          {
            x: window.innerWidth,
            duration: 6,
          },
          '<',
        )

      nestedArray.forEach((point, index) => {
        splitImageTimeline.fromTo(
          `.text-column-${index}`,
          {
            autoAlpha: 0,
            top: '110%',
          },
          {
            autoAlpha: 1,
            top: '50%',
            duration: 3,
          },
        )

        if (index !== nestedArray.length - 1) {
          splitImageTimeline.to(`.text-column-${index}`, {
            autoAlpha: 0,
            top: 0,
            duration: 3,
            delay: 2,
          })
        }
      })
    })
    return () => mm.revert();
  }, [])

  return (
    <>
      <SplitImageContainer className="margin-standard" ref={splitImageRef}>
        <SplitImageWrapper className={`${getSplitWrapper}`}>
          <div className={`split-image-side desktop ${getLeftSide}`}>
            <img
              className="slide-background-image"
              src={blok.image}
              alt="pattern of dots that form the shape of a world map"
            />
          </div>
          <div className={`split-image-side desktop ${getRightSide}`}>
            <img
              className="slide-background-image"
              src={blok.image}
              alt="pattern of dots that form the shape of a world map"
            />
          </div>
          <div className={`split-image-side mobile ${getLeftSideMobile}`}>
            <img
              className="slide-background-image"
              src={blok.mobile_image}
              alt="pattern of dots that form the shape of a world map"
            />
          </div>
          <div className={`split-image-side mobile ${getRightSideMobile}`}>
            <img
              className="slide-background-image"
              src={blok.mobile_image}
              alt="pattern of dots that form the shape of a world map"
            />
          </div>

          {nestedArray?.map((point, index) => {
            return (
              <div className={`split-image-text-column text-column-${index}`}>
                {point?.map(point => {
                  return (
                    <div className="point-content">
                      {point.icon && (
                        <img
                          src={point.icon}
                          alt="icon representing Avenues' fact"
                        />
                      )}

                      <Headline
                        type="h4"
                        blok={point}
                        className="bio-semibold-medium"
                        margin="16px 0 24px 0"
                      >
                        {point.headline}
                      </Headline>
                      <p className="body-medium">{point.content}</p>
                      {point.button_text && (
                        <IconButton
                          blok={point}
                          marginTop="16"
                          blueText={true}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </SplitImageWrapper>
      </SplitImageContainer>
    </>
  )
}
