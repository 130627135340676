import React from 'react'

import { SVGContainer } from './styles'

export default function LineCircleSVG({
  getLine,
  getSvgContainer,
  getCircle,
  singleColumn,
  getLineMobile,
  getCircleMobile,
}) {
  return (
    <>
      <SVGContainer
        singleColumn={singleColumn}
        className={`svg-container ${getSvgContainer}`}
      >
        <svg
          id="line-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 105"
        >
          <path
            d="M5,0 L5,200"
            stroke="black"
            stroke-width="1"
            id={`${getLine}`}
            className={`line ${getLineMobile}`}
          />
        </svg>
        <svg
          id={`${getCircle}`}
          className={`circle-svg ${getCircleMobile}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 300 300"
          width="100"
          height="100"
        >
          <ellipse
            rx="25"
            ry="25"
            transform="translate(150 150)"
            fill="none"
            stroke="#000"
            stroke-width="2.5"
          />
        </svg>
      </SVGContainer>
    </>
  )
}
