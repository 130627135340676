import React from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'
import media, { container } from 'utils/media'

const StyledBox = styled(Box)`
  max-width: ${container.xs + 'rem'};
  display: block;
  width: 100%;

  ${media.greaterThan('sm')`
    max-width: ${container.sm + 'rem'};
  `};

  ${media.greaterThan('md')`
    max-width: ${container.md + 'rem'};
  `};

  ${media.greaterThan('lg')`
    max-width: ${container.lg + 'rem'};
  `};

  ${media.greaterThan('xl')`
    max-width: ${container.xl + 'rem'};
  `};
`

const Container = props => <StyledBox {...props} />

Container.defaultProps = {
  mx: 'auto',
  px: 4,
}

export default Container
