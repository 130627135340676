import React, { useState } from 'react'
import { Container, Row } from 'components/Grid'
import WhitePlus from 'static/img/white_plus.svg'
import DarkPlus from 'static/img/dark_plus.svg'
import DarkMinus from 'static/img/dark_minus.svg'

import { Wrapper, Tab, FlexCol, Title } from './styles'
import { useAccordion } from 'hooks'

function HideContentElement({ myKey, ...props }) {
  const [
    filtersRef,
    toggleAccordion,
    filtersHeight,
    setFilterHeight,
  ] = useAccordion()
  const [isText, setText] = useState(false)
  const handleOpen = () => {
    if (isText) {
      setFilterHeight(0)
      setText(!isText)
    } else {
      setText(!isText)
      toggleAccordion()
    }
  }
  return (
    <Wrapper isVisible={isText}>
      <Tab onClick={handleOpen} isVisible={isText}>
        <Container>
          <Row>
            <FlexCol>
              <Title isVisible={isText}>{props.title}</Title>
              <img
                src={!isText ? DarkPlus : DarkMinus}
                alt={!isText ? 'Expand Tab' : 'Hide Tab'}
              />
            </FlexCol>
          </Row>
        </Container>
      </Tab>
      <div
        ref={filtersRef}
        style={{
          transition: 'height .4s ease-in-out',
          height: `${filtersHeight}px`,
          overflow: 'hidden',
        }}
        css="background-color: #fff"
      >
        {props.render()}
      </div>
    </Wrapper>
  )
}

export default HideContentElement
