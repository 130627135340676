import { useStaticQuery, graphql } from 'gatsby'
import compact from 'lodash/compact'
import find from 'lodash/find'

import { languages } from 'utils/config'

export function getBreadcrumbsAliases() {
  const {
    allStoryblokDatasource: { edges },
  } = useStaticQuery(graphql`
    query {
      allStoryblokDatasource(filter: { data_source: { eq: "breadcrumbs" } }) {
        edges {
          node {
            value
            name
          }
        }
      }
    }
  `)

  return edges.map(({ node }) => node)
}

export function addBreadcrumbs(config) {
  const { overrideCurrent, showCurrent, aliases, location } = config
  const allSlugs = compact(location.pathname.split('/'))
  const langPath = isLanguagePath(allSlugs[0]) ? allSlugs[0] : ''
  const breadcrumbs = allSlugs.reduce(
    (acc, path, index) => {
      const slug = allSlugs.slice(0, index + 1).join('/')
      const isLast = index + 1 === allSlugs.length
      if (!isLanguagePath(path) && !(isLast && !showCurrent)) {
        acc.push({
          slug: '/' + slug,
          name: isLast && overrideCurrent ? overrideCurrent : getAlias(path),
        })
      }
      return acc
    },
    [{ slug: `/${langPath}`, name: 'home' }],
  )

  return breadcrumbs

  function isLanguagePath(path) {
    return !!languages[path]
  }

  function getAlias(path) {
    const slugAlias = find(aliases, alias => alias.name === path)
    return slugAlias ? slugAlias.value : path
  }
}
