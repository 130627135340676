// This helper function basically parses StoryBlok snake_cased object keys to camelCased.
export default function parseBlokProps(props) {
  return Object.keys(props).reduce((acc, key) => {
    if (key.startsWith('_')) {
      return {
        ...acc,
        [key]: props[key],
      }
    }

    const getCamelCase = snakeString =>
      snakeString
        .split('_')
        .map((word, index) =>
          index === 0 ? word : word.replace(word[0], word[0]?.toUpperCase() || ''),
        )
        .join('')

    return {
      ...acc,
      [getCamelCase(key)]: props[key],
    }
  }, {})
}
