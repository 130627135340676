import styled from 'styled-components'

export const SVGContainer = styled.div`
  display: flex;
  flex-direction: column;

  .circle-svg {
    margin-top: -42px;
  }

  #line-svg {
    display: flex;
    align-items: center;
    height: ${({ singleColumn }) => (singleColumn ? '136px' : '820px')};

    .line {
      height: 100%;
    }
  }

  @media (min-width: 935px) and (max-width: 1075px) {
    #line-svg {
      height: ${({ singleColumn, multiPoint }) =>
        singleColumn ? '136px' : multiPoint ? '980px' : '920px'};
    }
  }

  @media (min-width: 835px) and (max-width: 934px) {
    #line-svg {
      height: ${({ singleColumn, multiPoint }) =>
        singleColumn ? '136px' : multiPoint ? '1175px' : '920px'};
    }
  }

  @media (max-width: 834px) {
    #line-svg {
      height: 112px;
    }
  }
`
