import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { useAccordion } from 'hooks'

import arrowIcon from 'static/img/arrow_down_green.svg'
import checkIcon from 'static/img/check_icon_white.svg'

function Select(props) {
  const [filtersRef, toggleAccordion, height, setHeight] = useAccordion()
  const [selectedOption, setSelectedOption] = useState(props.defaultValue)

  function onSelect(value) {
    props.onSelect(value)
    setSelectedOption(value)
    setHeight(0)
  }

  return (
    <Wrapper>
      <SelectDiv onClick={toggleAccordion} isOpen={height > 0}>
        {selectedOption}
        <ArrowImg src={arrowIcon} isOpen={height > 0} />
      </SelectDiv>

      <List ref={filtersRef} height={height}>
        {props.options &&
          props.options.map(option => (
            <MenuItem
              key={option}
              value={option}
              onSelect={onSelect}
              active={selectedOption === option}
            >
              {option}
            </MenuItem>
          ))}
      </List>
    </Wrapper>
  )
}

export default Select

function MenuItem({ value, onSelect, children, active }) {
  function handleSelect() {
    onSelect(value)
  }
  return (
    <Option onClick={handleSelect} active={active}>
      {children}
    </Option>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const List = styled.ul`
  width: 100%;
  font-size: 20px;
  position: absolute;
  top: 64px;
  left: 0;
  margin: 0;
  z-index: 3;
  background-color: #ffffff;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 10px;

  ${({ height }) => css`
    height: ${height}px;
    opacity: ${height === 0 ? 0 : 1};
    transition: opacity 0.13s linear, height 0s ${height === 0 ? 0.25 : 0}s;
  `}
`

const Option = styled.li`
  padding: 8px 50px 8px 20px;
  width: 100%;
  font-size: 20px;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.darkGray : '#fff'};
  color: ${({ active, theme }) => (active ? '#fff' : theme.colors.darkGray)};

  &:after {
    position: absolute;
    width: 17px;
    height: 14px;
    content: '';
    background-image: url(${checkIcon});
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${({ active, theme }) => (active ? 1 : 0)};
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.colors.darkGray : theme.colors.lightGray};
  }
`

const hoverState = css`
  border-color: ${({ theme }) => theme.colors.darkGray};
`
const SelectDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  width: 100%;
  height: 60px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 8px 20px;
  border: 2px solid ${({ theme }) => theme.colors.neutral};
  border-radius: 10px;
  transition: border-color 0.2s;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    width: 14px;
    height: 9px;
  }

  &:hover {
    ${hoverState}
  }
  ${({ isOpen }) => isOpen && hoverState}
`

const ArrowImg = styled.img`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.25s;
  will-change: transition;
`
