import React from 'react'
import styled, { css } from 'styled-components'

import checkIcon from 'static/img/check_icon.svg'

export default function UnorderedList({ blok }) {
  const liChildren = blok.map((child) => {
    return child.props.children[0].props.children[0]
  })

  return (
    <>
      <UList>
        {liChildren.map((li) => {
          return (<li>{li}</li>)
        })}
      </UList>
    </>
  )
}

const UList = styled.ul`
  margin-left: 0px;
  padding-left: 20px;
  text-indent: 0px;
  list-style: none;

  li {
    padding-left: 10px;
    margin-bottom: ${({ listMarginBottom, listMarginBottomMobile }) =>
      listMarginBottom
        ? listMarginBottom + 'px'
        : listMarginBottomMobile
        ? listMarginBottomMobile + 'px'
        : '0px'};
    ${getListStyle}
  }
`
function getListStyle({ listStyle }) {
  if (listStyle === 'check' || !listStyle) {
    return css`
      list-style: none;
      margin-left: -30px;
      padding-left: 30px;
      &:before {
        content: '';
        display: inline-block;
        margin-left: -20px;
        margin-right: 10px;
        height: 8px;
        width: 10px;
        background-image: url(${checkIcon});
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: left center;
      }
    `
  } else {
    return css`
      list-style: ${listStyle};
    `
  }
}
