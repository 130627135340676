import styled, { css } from 'styled-components'

export const Checkbox = styled.label`
  display: block;
  position: relative;
  padding: 15px 0 12px 60px;
  user-select: none;
  pointer-events: none;
  
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 14px;
    left: 20px;
    height: 25px;
    width: 25px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.red};
    border-radius: 3px;
  }
  
  &:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.gray};
  }

  ${({ isFocused }) => isFocused && css`
    input ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.gray};
    }
  `}
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkmark:after {
    left: 8px;
    top: 2px;
    width: 8px;
    height: 15px;
    border: solid ${({ theme }) => theme.colors.green};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

const styles = () => ({
  cursor: 'pointer',
})

export default styles
