import styled from 'styled-components'

export const FastFactsWrapper = styled.div`
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  .fast-facts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 60px;
    max-width: 1140px;
  }

  .fast-fact {
    width: calc(33.33% - 60px);
    display: flex;
    flex-direction: column;
    align-items: ${({ cardType }) =>
      cardType === 'colored_pattern' ? 'flex-start' : 'center'};
    margin: 30px;
    padding: ${({ cardType }) =>
      cardType === 'colored_pattern' ? '40px 30px 80px 30px' : '0'};
    position: relative;
    z-index: -2;
    overflow: hidden;

    .fact-icon {
      padding-bottom: 10px;
    }

    .yellow, .green, .red, .blue {
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
    }

    h4 span {
      font-size: 40px;
    }

    p {
      text-align: ${({ cardType }) =>
        cardType === 'colored_pattern' ? 'left' : 'center'};
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;

    .fast-facts-container {
      row-gap: 62px;
    }

    .fast-fact {
      width: 100%;
      margin: 0;
      min-height: ${({ cardType }) =>
        cardType === 'colored_pattern' ? '250px' : ''};
      padding: ${({ cardType }) =>
        cardType === 'colored_pattern' ? '32px' : '0'};

      p {
        width: 50%;
      }
    }
  }

  @media (max-width: 600px) {
    .fast-fact {
      min-height: ${({ cardType }) =>
        cardType === 'colored_pattern' ? '300px' : ''};
      p {
        width: 80%;
      }
    }
  }

  @media (max-width: 400px) {
    .fast-fact {
      p {
        width: 100%;
      }
    }
  }
`
