import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Components from 'components/components'

function MultistepItem({ blok }) {
  return (
    <SbEditable content={blok}>
      <StepWrapper>
        <Heading>{blok.heading}</Heading>
        {blok.body.map(
          blok =>
            Components[blok.component] &&
            React.createElement(Components[blok.component], {
              key: blok._uid,
              blok,
            }),
        )}
      </StepWrapper>
    </SbEditable>
  )
}

export default MultistepItem

var StepWrapper = styled.div``

var Heading = styled.h5`
  margin-bottom: 5px;
`
