import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import { parseBlokProps } from 'utils'
import Components from 'components/components'
import { navigate } from 'gatsby'

import { removeGlobalSlug, withSlash } from 'utils/helpers'
import Modal from '../Modal/Modal'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justification }) =>
    justification ? justification : 'space-between'};
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor] || backgroundColor || 'none'};
  color: ${({ theme, fontColor }) =>
    theme.colors[fontColor] || fontColor || theme.colors.white};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : 0};
  border: solid ${({ borderColor }) => (borderColor ? '1px' : '0px')}
    ${({ theme, borderColor }) => theme.colors[borderColor] || 'transparent'};
  padding: ${({ backgroundColor, padding }) =>
    backgroundColor ? padding + 'px' || '40px' : padding + 'px' || '20px'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom + 'px' : '0px'};
  height: 100%;
  width: 100%;
  overflow: hidden;
  width: 100%;
  cursor: ${({ hasEvent }) => (hasEvent ? 'pointer' : 'default')};

  h3 {
    font-family: 'Halyard-Book';
    font-weight: normal;
    font-size: min(max(1rem, 7vw), 32px);
    margin-top: 0;
  }

  h5 {
    font-family: 'Halyard-SemiBold';
    font-feature-settings: 'ss18';
  }

  a {
    color: inherit;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  *:not(:last-child) {
    margin-bottom: 20px;
  }
  small {
    font-size: 40%;
  }
`
// It's related to the padding value.

const fullWidthImageStyles = {
  width: 'auto',
}

const BoxCard = ({ blok }) => {
  const [showModal, setShowModal] = useState(false)

  const hasClickEvent = blok.on_click_event && blok.on_click_event.length > 0

  const handleOnClickBox = () => {
    const onClickEventComponent = blok.on_click_event[0]
    // Either link or modal
    if (onClickEventComponent.component === 'link') {
      // Either internal or url
      if (onClickEventComponent.url.linktype === 'url') {
        window.open(onClickEventComponent.url.url)
      } else {
        const intHref =
          onClickEventComponent.url.cached_url &&
          withSlash(removeGlobalSlug(onClickEventComponent.url.cached_url))
        navigate(intHref)
      }
    } else {
      setShowModal(true)
    }
  }

  const toggleModal = () => {
    if (showModal) {
      setShowModal(false)
    } else {
      setShowModal(true)
    }
  }

  return (
    <>
      <Modal
        isUsedAsChildInComponents={true}
        blok={blok.on_click_event && blok.on_click_event[0]}
        showModal={showModal}
        toggleModal={toggleModal}
      />
      <SbEditable content={blok}>
        <StyledDiv
          hasEvent={!!hasClickEvent}
          {...parseBlokProps(blok)}
          onClick={hasClickEvent ? handleOnClickBox : undefined}
        >
          {blok.header && <h3>{blok.header}</h3>}
          {blok.body &&
            blok.body.map((el, index) => {
              return React.createElement(Components[el.component], {
                key: el._uid,
                blok: el,
                fontColor: blok.font_color,
                style:
                  el.component === 'text'
                    ? { flex: blok.justification === 'space-between' ? 1 : 0 }
                    : el.component === 'image' && blok.is_full_width_image
                    ? index === 0
                      ? {
                          ...fullWidthImageStyles,
                          marginTop: blok.padding
                            ? '-' + blok.padding + 'px'
                            : '-40px',
                          marginBottom: blok.padding_image_bottom
                            ? '-' + blok.padding_image_bottom + 'px'
                            : '20px',
                          marginLeft: blok.padding
                            ? '-' + blok.padding + 'px'
                            : '-40px',
                          marginRight: blok.padding
                            ? '-' + blok.padding + 'px'
                            : '-40px',
                          maxHeight: '400px',
                        }
                      : fullWidthImageStyles
                    : undefined,
              })
            })}
        </StyledDiv>
      </SbEditable>
    </>
  )
}

export default BoxCard
