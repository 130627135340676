import styled from 'styled-components'
import { media } from 'utils'

export const TabSection = styled.div`
  background-color: ${props =>
    props.color
      ? ({ theme }) => theme.colors[props.color]
      : ({ theme }) => theme.colors.neutral};
  display: flex;
  justify-content: center;
  padding-top: 53px;
  padding-bottom: 43px;
  ${media.greaterThan('sm')`
    padding-top: 47px;
    padding-bottom: 49px;
  `}
`
export const StyledButton = styled.button`
  ${({ theme, isActive }) =>
    isActive ? theme.buttons.green : theme.buttons.tertiary};
  ${({ theme }) => theme.buttonsSize.big};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : 'unset')};
  border: 1px solid ${({ theme }) => theme.colors.neutral};
`
export const StyledDiv = styled.div`
  background-color: ${props =>
    props.color ? ({ theme }) => theme.colors[props.color] : '#ffffff'};
`
