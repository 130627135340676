import styled from 'styled-components'

export const SlideContainer = styled.div`
  flex: 0 0 60vw;
  max-width: 1000px;
  display: flex;

  :first-child {
    margin-left: 64px;
  }

  .slide {
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    margin-right: 10%;
    padding: 10% 0;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: white;

    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 250px;
      background: ${({
        YellowPattern,
        RedPattern,
        BluePattern,
        backgroundColor,
      }) =>
        backgroundColor === '#C3411E'
          ? `url(${RedPattern})`
          : backgroundColor === '#F7CF80'
          ? `url(${YellowPattern})`
          : `url(${BluePattern})`};
      background-size: cover;
      z-index: 1;
      opacity: .4;
    }

    .slide-content {
      width: 75%;
      z-index: 2;

      p {
        text-align: center;
      }
    }
  }

  @media (min-width: 1500px) {
    .slide {
      ::before {
        width: 100%;
        height: 300px;
      }
    }  
  }

  @media (max-width: 834px) {
    flex: 0 0 90vw;

    :first-child {
      margin-left: 32px;
    }
  }

  @media (max-width: 500px) {
    flex: 0 0 100vw;
    justify-content: center;

    :first-child {
      margin-left: 0px;
    }

    .slide {
      margin-right: 0px;
      flex: 0 0 90vw;

      .slide-content {
        width: 80%;

        h3 {
          font-size: 38px;
          line-height: 48px;
        }
      }
    }
  }
`
