import React from 'react'
import SbEditable from 'storyblok-react'

import { Section } from 'components/Common'
import { Container } from 'components/Grid'
import HalfGrid from './half_grid'

function HalfGridSection({ blok }) {
  return (
    <SbEditable content={blok}>
      <Section
        paddingTop={blok.padding_top || 106}
        paddingBottom={blok.padding_bottom || 64}
        paddingTopMb={blok.padding_top_mobile || 120}
        paddingBottomMb={blok.padding_bottom_mobile || 80}
      >
        <Container>
          <HalfGrid blok={blok} />
        </Container>
      </Section>
    </SbEditable>
  )
}

export default HalfGridSection
