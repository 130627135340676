import useWindowSize from 'react-use/lib/useWindowSize'
import { breakpoints } from 'utils/media'

export default function useDeviceSize() {
  var { width } = useWindowSize()
  var curWidth, isMobile, isDesktop, isTablet

  if (isFinite(width)) {
    curWidth = width
    isMobile = curWidth < breakpoints.sm * 16
    isTablet = curWidth < breakpoints.md * 19
    isDesktop = curWidth >= breakpoints.lg * 16
  } else {
    curWidth = 0
    isMobile = undefined
    isTablet = undefined
    isDesktop = undefined
  }

  return { width: curWidth, isMobile, isTablet, isDesktop }
}
