import styled, { keyframes } from 'styled-components'
import { theme } from 'utils'

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const showContainer = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-7px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const HubspotFormWrapper = styled.div`
  position: relative;
  overflow: auto;
  padding: 20px 40px;

  h2 {
    font-size: 20px;
    text-align: left;
    font-weight: 500;
    padding-top: 0.5rem;
  }

  & > div > div {
    animation: ${showContainer} 0.4s ease-out;
  }
  .hs-form-field {
    margin-bottom: 1.7rem;
    text-align: left;
    & > label {
      display: block;
      margin-bottom: 0.7rem;
    }
  }

  button.pika-prev,
  button.pika-next {
    right: 0;
  }

  .input {
    input {
      height: 48px;
      border: 1px solid rgb(233, 232, 231);
      background-color: white;
      width: 100%;
      padding: 1rem;
      outline: 0;
      &:focus {
        border-color: #004592;
      }
    }
    textarea {
      border: 1px solid #cbd6e2;
      padding: 1rem;
      width: 100%;
      min-height: 130px;
      outline: 0;
      &:focus {
        border-color: #004592;
      }
    }
    select {
      height: 58px;
      width: 100%;
      border: 1px solid rgb(233, 232, 231);
      background-color: white;
      padding: 1rem;
    }
  }
  .hs-fieldtype-checkbox,
  .hs-fieldtype-radio,
  .hs-fieldtype-booleancheckbox {
    & > label {
      display: block;
      margin-bottom: 0.85rem;
    }
    .inputs-list {
      margin: 0;
      li {
        list-style: none;
        margin-bottom: 0.5rem;
      }
      label {
        cursor: pointer;
        input {
          display: none;
          &:checked {
            & ~ span:before {
              background: ${theme.colors.green};
            }
          }
        }
        span {
          position: relative;
          padding-left: 32px;
          color: black;
        }
        span:before {
          content: '';
          height: 20px;
          width: 20px;
          position: absolute;
          left: 0;
          top: 0;
          border: 1px solid rgb(233, 232, 231);
        }
      }
    }
  }
  .hs-fieldtype-radio {
    .inputs-list {
      label {
        span:before {
          border-radius: 50%;
        }
      }
    }
  }

  .actions {
    input[type='submit'] {
      min-height: 40px;
      background-color: ${theme.colors.green};
      color: white;
      border: 0;
      padding: 0.7rem 2.5rem;
      border-radius: 50px;
      cursor: pointer;
    }
  }
  .no-list {
    margin-left: 0;
    li {
      list-style: none;
      font-size: 12px;
      color: rgb(255, 49, 41);
    }
  }

  .submitted-message {
    text-align: center;
    padding: 2rem 1rem 1rem 1rem;
    p {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .hs-dependent-field > div {
    margin-bottom: 1.5rem;
  }

  .hs-form-required {
    color: rgb(255, 49, 41);
  }

  .hs_submit {
    margin-top: 3rem;
  }
  .hs-richtext {
    h4 {
      font-family: 'Halyard-Book', 'SF Pro Display', '-apple-system',
        'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica',
        'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif;
      font-size: min(max(1rem, 7vw), 32px);
      line-height: 1.5;
      font-weight: normal;
      padding-bottom: 20px;
    }
  }

  .pika-label {
    transition: all 0.2s ease-in-out;
    margin-right: 5px !important;

    &:after {
      content: '  ▾';
    }
  }

  .pika-label:hover {
    color: #267355 !important;
    transform: scale(1.1);
  }

  .fn-date-picker .is-today .pika-button {
    color: #d3ae6f !important;
  }

  .fn-date-picker .pika-button {
    color: #267355 !important;
  }

  .fn-date-picker .is-selected .pika-button {
    background-color: #d3ae6f !important;
    box-shadow: inset 0 1px 3px #d3ae6f !important;
  }

  .fn-date-picker:hover .pika-button:hover {
    background-color: #267355 !important;
  }
`

export const LoaderStyled = styled.div`
  display: block;
  position: relative;
  width: 60px;
  height: 60px;
  margin: 3rem auto;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: ${loaderAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #002a58 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

export const CalendarDiv = styled.div`
text-align: center;

  #calendar-button {
    background-color: #248e68;
    color: white;
    border: 0;
    padding: 0.7rem 2.5rem;
    border-radius: 60px;
    cursor: pointer;
    font-family: Halyard-SemiBold;
    font-feature-settings: 'ss18';
  }

  span {
    margin-left: 8px;
  }
`
