import React from 'react'
import styled, { css } from 'styled-components'
import Markdown from 'markdown-to-jsx'
import { removeGlobal } from 'utils/helpers'

import { media } from 'utils'
import checkIcon from 'static/img/check_icon.svg'

function AComponent(p) {
  return <a href={removeGlobal(p.href)}>{p.children}</a>
}

function BrComponent() {
  return <br />
}
export const defaultRenderers = {
  a: AComponent,
  br: BrComponent,
}

export default function CMarkdown({
  source,
  renderers,
  options = {},
  children,
  ...props
}) {
  return (
    <MarkdownWrapper {...props}>
      <Markdown
        options={{
          overrides: {
            ...defaultRenderers,
            ...renderers,
            ...options.overrides,
          },
          forceBlock: true,
        }}
      >
        {source || children || ' '}
      </Markdown>
    </MarkdownWrapper>
  )
}

const imageAlignment = imageAlign =>{
  switch(imageAlign){
    case 'left':
      return '0px';
    case 'right':
      return '0 0 0 auto';  
    default:  
      return 'auto';
  }  
};

const MarkdownWrapper = styled.div`
  margin-bottom: 0px;
  text-align: ${({ textAlign, textAlignMb }) =>
    textAlignMb || textAlign || 'left'};
  width: 100%;
  margin-bottom:${({ marginBottom }) => marginBottom + 'rem' || '2rem'};

  & > * {
    color: ${({ theme, fontColor }) =>
      theme.colors[fontColor] || fontColor || theme.colors.black};
  }
  p {
    margin-bottom: ${({ pMarginBottom }) => pMarginBottom + 'rem' || '2rem'};
  }

  a {
    font-weight: 600;
    border-bottom: 2px solid ${({ theme }) => theme.colors.green};
    transition: border-color 0.2s;

    &:hover {
      border-bottom-color: transparent;
    }
  }
  strong {
    font-weight: 600;
  }
  img {
    display: block;
    margin: ${({imageAlign}) => imageAlignment(imageAlign)};
  }

  ul,
  ol {
    margin-left: 0px;
    padding-left: 20px;
    text-indent: 0px;
    list-style:none;
  }
  ul > li {
    padding-left:10px;
    margin-bottom: ${({ listMarginBottom, listMarginBottomMobile }) => (listMarginBottom ? listMarginBottom + 'px' : listMarginBottomMobile ? listMarginBottomMobile + 'px' : "0px")};
    ${getListStyle}
  }
  span.separator {
    display: block !important;
    height: 3px !important;
  }

  span.spacer {
    display: block !important;
    height: 10px !important;
  }

  ${media.greaterThan('sm')`
    text-align: ${({ textAlign }) => textAlign || 'left'};
    span.separator {
        display: inline-block !important;
        height: 0;
      ;
    }
  `}
`

function getListStyle({ listStyle }) {
  if (listStyle === 'check' || !listStyle) {
    return css`
      list-style: none;
      margin-left: -30px;
      padding-left: 30px;
      &:before {
        content: '';
        display: inline-block;
        margin-left: -20px;
        margin-right: 10px;
        height: 8px;
        width: 10px;
        background-image: url(${checkIcon});
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: left center;
      }
    `
  } else {
    return css`
      list-style: ${listStyle};
    `
  }
}
