import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Text = styled.span`
  font-size: 14px;
  position: relative;
  color: ${({ theme, type }) =>
    type === 'white' ? '#fff' : theme.colors.dark};
  ${({ isHoverUnderLine }) =>
    isHoverUnderLine
      ? css`
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            opacity: 0;
            background-color: ${({ theme }) => theme.colors.dark};
            transition: opacity 0.3s ease;
          }
          &:hover:before {
            opacity: 1;
          }
        `
      : css``};
`
const Image = styled.img`
  max-height: 24px;
  max-width: 24px;
  margin-bottom: 1px;
  margin-left: 10px;
`

function IconButton({
  icon,
  text,
  onClick,
  cssStyle,
  textStyle,
  isHoverUnderLine,
  type,
  alt,
  className,
}) {
  return (
    <Wrapper onClick={onClick} css={cssStyle} type={type} className={className}>
      <Text css={textStyle} isHoverUnderLine={isHoverUnderLine}>
        {text}
      </Text>
      {icon &&
        (typeof icon === 'function' ? icon() : <Image src={icon} alt={alt} />)}
    </Wrapper>
  )
}

export default React.memo(IconButton)
