import styled from 'styled-components'

export const TeamMembersWrapper = styled.div`
  padding: 0 64px;
  display: flex;
  justify-content: center;

  @media (max-width: 834px) {
    padding: 0 32px;
  }
`

export const TeamMembersContainer = styled.div`
  max-width: 1330px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 55px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-gap: 25px;
  }
`
