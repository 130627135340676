import React from 'react'

export default function CircleSVG({ getCircle }) {
  return (
    <>
      <svg
        id={`${getCircle}`}
        class="circle-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
        width="100"
        height="100"
      >
        <ellipse
          rx="25"
          ry="25"
          transform="translate(150 150)"
          fill="none"
          stroke="#000"
          stroke-width="2.5"
        />
      </svg>
    </>
  )
}
