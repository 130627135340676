import React, { useEffect, useState, useLayoutEffect } from 'react'
import SbEditable from 'storyblok-react'
import Img from 'gatsby-image'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

import CircleSVG from '../SVGs/circle'
import LineSVG from '../SVGs/line'

import {
  TimelineWrapper,
  FirstAdditionalPoint,
  OddAdditionalPoints,
  EvenAdditionalPoints,
  Year,
  TimelineIntro,
  SingleColumnImage,
  SingleColumnVideo,
  AdditionalPointText,
} from '../styles'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)

export default function MultiPointTimeline({ blok, imageProps }) {
  const [singleColumn, setSingleColumn] = useState(false)

  const [image, setImage] = useState(false)

  useEffect(() => {
    if (blok.content.content.length === 1) {
      setSingleColumn(true)
    }

    if (blok.video_link === '' && blok.content.content.length === 1) {
      setImage(true)
    }
  }, [])

  //DYAMIC CLASSES FOR GREENSOCK MOVEMENT
  const getSvgContainer = `svg-${blok?._uid.replace(/-/g, '')}`
  const getYearContainer = `year-${blok?._uid.replace(/-/g, '')}`
  const getLine = `line-${blok?._uid.replace(/-/g, '')}`
  const getCircle = `circle-${blok?._uid.replace(/-/g, '')}`
  const getVideoContainer = `video-${blok?._uid.replace(/-/g, '')}`
  const getImageContainer = `image-${blok?._uid.replace(/-/g, '')}`
  const getAdditionalPointContainer = `point-${blok?._uid.replace(/-/g, '')}`

  useLayoutEffect(() => {
    const svgContainer = document.querySelector('.' + getSvgContainer)
    const yearContainer = document.querySelector('.' + getYearContainer)
    const lines = document.querySelectorAll('#' + getLine)
    const circles = document.querySelectorAll('#' + getCircle)
    const videoContainer = document.querySelector('.' + getVideoContainer)
    const additionalPoints = document.querySelectorAll(
      '.' + getAdditionalPointContainer,
    )

    gsap.set(lines, { attr: { 'vector-effect': 'non-scaling-stroke' } })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: yearContainer,
          start: 'bottom 60%',
          //   markers: true,
        },
      })
      .from(videoContainer, { opacity: 0, y: 75, scale: 0.6 }, 0.5)

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: svgContainer,
        start: 'top 50%',
        end: 'top top',
        scrub: 3,
        // markers: true,
      },
    })

    lines.forEach((line, index) => {
      tl.from(line, {
        drawSVG: '0%',
        ease: 'none',
        duration: 0.99,
      })
      tl.from(circles[index], { opacity: 0, duration: 0.01 }, '-=0.4')
      tl.from(additionalPoints[index], { opacity: 0, duration: 0.5 }, '-=1.25')
    })
  }, [])

  useEffect(() => {
    const imageContainer = document.querySelector('.' + getImageContainer)
    const yearContainer = document.querySelector('.' + getYearContainer)

    gsap
      .timeline({
        scrollTrigger: {
          trigger: yearContainer,
          start: 'bottom 60%',
          // markers: true,
        },
      })
      .from(imageContainer, { opacity: 0, y: 75, scale: 0.6 }, 0.5)
  }, [image])

  return (
    <>
      <SbEditable content={blok}>
        <TimelineWrapper image={image}>
          <Year className={`${getYearContainer}`}>{blok.year}</Year>
          <TimelineIntro className="timeline-intro">
            {blok.introduction}
          </TimelineIntro>

          {image ? (
            <SingleColumnImage className={`image-wrapper ${getImageContainer}`}>
              <Img fluid={imageProps} alt={"image representing " + blok.introduction} />
            </SingleColumnImage>
          ) : (
            <SingleColumnVideo className={`video-wrapper ${getVideoContainer}`}>
              <video
                src={!IsChinaDeploy ? blok.video_link : blok.video_link_china}
                autoPlay
                loop
                muted
                playsInline
              />
            </SingleColumnVideo>
          )}

          <LineSVG
            singleColumn={singleColumn}
            getSvgContainer={getSvgContainer}
            getLine={getLine}
          />
        </TimelineWrapper>
        {blok.additional_timeline_points.map((item, index) => {
          if (index === 0) {
            return (
              <FirstAdditionalPoint
                className={`${getAdditionalPointContainer}`}
                key={index}
              >
                <div class="container">
                  <div class="first-div">
                    <CircleSVG getCircle={getCircle} />
                  </div>
                  <div class="second-div">
                    <AdditionalPointText>{item.headline}</AdditionalPointText>
                  </div>
                </div>
              </FirstAdditionalPoint>
            )
          } else if (index % 2 !== 0) {
            return (
              <OddAdditionalPoints
                className={`${getAdditionalPointContainer}`}
                key={index}
              >
                <LineSVG
                  singleColumn={singleColumn}
                  getSvgContainer={getSvgContainer}
                  getLine={getLine}
                />
                <div class="container">
                  <div class="first-div">
                    <CircleSVG getCircle={getCircle} />
                  </div>
                  <div class="second-div">
                    <AdditionalPointText>{item.headline}</AdditionalPointText>
                  </div>
                </div>
              </OddAdditionalPoints>
            )
          } else if (index % 2 === 0) {
            return (
              <EvenAdditionalPoints
                className={`${getAdditionalPointContainer}`}
                key={index}
              >
                <LineSVG
                  singleColumn={singleColumn}
                  getSvgContainer={getSvgContainer}
                  getLine={getLine}
                />
                <div class="container">
                  <div class="first-div">
                    <CircleSVG getCircle={getCircle} />
                  </div>
                  <div class="second-div">
                    <AdditionalPointText>{item.headline}</AdditionalPointText>
                  </div>
                </div>
              </EvenAdditionalPoints>
            )
          }
        })}
        <EvenAdditionalPoints>
          <LineSVG
            singleColumn={singleColumn}
            getSvgContainer={getSvgContainer}
            getLine={getLine}
          />
          <div class="container">
            <div class="first-div">
              <CircleSVG getCircle={getCircle} />
            </div>
          </div>
        </EvenAdditionalPoints>
      </SbEditable>
    </>
  )
}
