import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Content from 'components/Content'
import Headline from '../Headline'

function SectionIntroTwo({ blok }) {
  return (
    <SbEditable content={blok}>
      <SectionIntroWrapper className="margin-standard">
        <div className="text-wrapper">
          <div className="headline-wrapper">
            <Headline
              type="h4"
              blok={{ headline: blok.superheadline }}
              className="headline-medium-large"
              textAlign="center"
              margin="0"
            />
            <Headline
              type="h3"
              blok={blok}
              className="headline-light-large"
              textAlign="center"
              margin="0"
            />
          </div>
          <div className="content-wrapper">
            <Content
              className="body-medium"
              blok={{ content: blok.introduction }}
            />
          </div>
        </div>
      </SectionIntroWrapper>
    </SbEditable>
  )
}

export default SectionIntroTwo

const SectionIntroWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 64px;

  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .headline-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1140px;
    width: 100%;
    margin-bottom: 60px;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;

    p {
      max-width: 682px;
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;
  }
`
