import { parse } from 'intl-messageformat-parser'

export default {
  menu: parse('Menu'),
  search: parse('Search'),
  close: parse('Close'),
  'read article': parse('Read Article'),
  featured: parse('Featured'),
  date: parse('Date'),
  time: parse('Time'),
  location: parse('Campus'),
  watch: parse('Watch'),
  virtual: parse('Virtual'),
  ondemand: parse('On-demand'),
  inperson: parse('In-person'),
  tour: parse('Tour'),
  opened: parse('Open.Ed'),
  'filter articles': parse('Filter Posts'),
  'all categories': parse('All Categories'),
  'load more': parse('Load More'),
  home: parse('Home'),
  register: parse('Register'),
  'back to top': parse('back to top'),
  roles: parse('Open Roles'),
  'filtering-no-roles': parse('Currently displaying no positions'),
  'filtering-role': parse('Currently displaying 1 position'),
  'filtering-many-roles': parse('Currently displaying all {jobsCount} positions'),
  'filtering-filter-no-roles': parse('Currently displaying no positions with the above filters applied'),
  'filtering-filter-role': parse('Currently displaying 1 position with the above filters applied'),
  'filtering-filter-many-roles': parse('Currently displaying {filteredJobsCount} positions with the above filters applied'),
  'filtering-no-filters-applied': 'No filters applied',
  'filtering-one-filter-applied': '1 filter applied',
  'filtering-many-filters-applied': '{appliedFiltersCount} filters applied',
  'filtering-clear-all-filters': 'Clear all filters',
  department: parse('Department'),
  'all campuses': parse('All Campuses'),
  departments: parse('Departments'),
  locations: parse('Locations'),
  'all job functions': parse('All Job Functions'),
  'job function': parse('Job Function'),
  'all offices': parse('All Locations'),
  'filter roles': parse('Filter Roles'),
  showMore: parse('Show More'),
  showLess: parse('Show Less'),
  'upcoming dates': parse('Upcoming Dates'),
  admissions: parse('Admissions'),
  'view details': parse('View Details'),
  'close details': parse('Close Details'),
  'job description': parse('Job Description'),
  'all locations': parse('All Locations'),
  Graduates: parse('Graduates'),

  // CITIES & PAGES
  'new york': parse('New York'),
  'são paulo': parse('São Paulo'),
  shenzhen: parse('Shenzhen'),
  about: parse('About'),
  online: parse('Online'),
  careers: parse('Careers'),
  'open-roles': parse('Open Roles'),
  'college-counseling': parse('College Counseling'),

  // EVENT FORM
  'First Name': parse('First Name'),
  'Last Name': parse('Last Name'),
  Email: parse('Email'),
  Mobile: parse('Mobile'),
  'Zip/Post Code': parse('Zip/Post Code'),
  'RG/RNE/Passport ID': parse('RG/RNE/Passport ID'),
  'How did you hear about Avenues?': parse('How did you hear about Avenues?'),
  'Avenues employee': parse('Avenues employee'),
  'Friend or family': parse('Friend or family'),
  'Online Advertising': parse('Online Advertising'),
  'Print Advertising': parse('Print Advertising'),
  'Internet Search': parse('Internet Search'),
  'News Article': parse('News Article'),
  'Relocation/Educational consultant': parse(
    'Relocation/Educational consultant',
  ),
  Other: parse('Other'),
  Select: parse('Select...'),
  Gender: parse('Gender'),
  'Date of Birth': parse('Date of Birth'),
  'Entry Date': parse('Entry Date'),
  January: parse('January'),
  February: parse('February'),
  March: parse('March'),
  April: parse('April'),
  May: parse('May'),
  June: parse('June'),
  July: parse('July'),
  August: parse('August'),
  September: parse('September'),
  October: parse('October'),
  November: parse('November'),
  December: parse('December'),
  'Guest Information': parse('Guest Information'),
  Guests: parse('Guests'),
  'Just me, no additional attendees': parse('Just me, no additional attendees'),
  Male: parse('Male'),
  Female: parse('Female'),
  'Prefer not to say': parse('Prefer not to say'),
  Child: parse('Child'),
  Information: parse(' - Information'),
  'Total Number of Additional Attendees': parse(
    'Total Number of Additional Attendees',
  ),

  Month: parse('Month'),
  Year: parse('Year'),

  'Current school': parse('Current school'),
  'Data Consent': parse('Data Consent'),

  // Form Errors
  'required field': parse('This field is required'),
  'not valid email': parse('Please provide a valid email address'),
  'not valid phone': parse('Please provide a valid phone number'),
  'not valid postal code': parse('Please provide a valid postal code'),
  'at least 1 field required': parse('Please select at least one field'),
}
