import React from 'react'

import { SVGContainer } from './styles'

export default function LineSVG({ getLine, getSvgContainer, singleColumn, multiPoint}) {
  
  return (
    <>
      <SVGContainer
        singleColumn={singleColumn}
        multiPoint={multiPoint}
        className={`svg-container ${getSvgContainer}`}
      >
        <svg
          id="line-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 105"
        >
          <path
            d="M5,0 L5,200"
            stroke="black"
            stroke-width="1"
            id={`${getLine}`}
            className='line'
          />
        </svg>
      </SVGContainer>
    </>
  )
}
