import styled from 'styled-components'

import { media } from 'utils'

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.dark};

  .gatsby-image-wrapper,
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const SlideRow = styled.div`
  position: static;
  width: 100%;
  padding-bottom: 35px;
  min-height: 100%;
  height: auto;
  overflow: hidden;

  ${media.greaterThan('md')`
    padding-bottom: 0px;
  `}
`
