import React from 'react'
import styled from 'styled-components'
import Components from 'components/components'

function CardItem(prop) {
  const { header } = prop
  return (
    <Wrapper>
      <Header>{header}</Header>
      {prop.body.map(el => {
        return React.createElement(Components[el.component], {
          key: el._uid,
          blok: el,
        })
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-of-type) {
    margin-bottom: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }
`

const Header = styled.div`
  color: ${({ isWhite }) =>
    isWhite ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)'};
  font-size: 18px;
`

export default CardItem
