import styled from 'styled-components'
import media from 'utils/media'
import { Row, Col } from 'components/Grid'

export const StyledRow = styled(Row)`
  flex-direction:${props => props.mDirection ? props.mDirection : 'column'};
  margin-left:0;
  margin-right:0;  
  align-items: ${props => props.alignItems ? props.alignItems : 'stretch'};
  ${media.greaterThan('sm')`
    flex-direction:row;
  `}
`
export const StyledCol = styled(Col)`
  margin-bottom:${props => props.gap ? props.gap + 'px' : '20px'};  
  padding-left:${props => props.mDirection === 'row' ? props.gapLeft + 'px' : '0px'};
  padding-right:${props => props.mDirection === 'row' ? props.gapRight + 'px' : '0px'};;
  ${media.greaterThan('sm')`
    margin-bottom:0;
    padding-left: ${props => props.gapLeft ? props.gapLeft + 'px' : '0px'};
    padding-right:${props => props.gapRight ? props.gapRight + 'px' : '0px'};
  `}
`
