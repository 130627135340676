import React from 'react'
import SbEditable from 'storyblok-react'
import { Stickyroll } from '@stickyroll/stickyroll'
import { scrollTo } from '@stickyroll/utils'

import { DotsDiv, Section, ArrowPrev, ArrowNext } from './styles'

import arrowIcon from 'static/img/arrow_down_light.svg'

import Dot from './Dots'
import Slide from './Slide'
import TwoColumnSlide from './TwoColumnSlide'

function DotsSlider({ blok }) {
  const isInBrowser = typeof window !== 'undefined'

  function goToSlide(id) {
    scrollTo(id, null, { noFocus: true, noHash: true })
  }

  function renderSlide(slide, index) {
    return (
      <Slide
        progress={0}
        page={index + 1}
        blok={slide}
        buttonStyle={blok.button_style}
        accentColor={blok.accent_color}
        key={slide._uid}
        index={index}
      />
    )
  }

  return (
    <SbEditable content={blok}>
      <Section
        style={{ position: 'relative', marginBottom: '0px' }}
        pagesCount={blok.items.length}
      >
        <Stickyroll
          pages={blok.items}
          factor={3}
          anchors=""
          className="sticky-roll"
          render={({ pageIndex, progress }) => {
            const slideProps = {
              progress:
                pageIndex === 0 ? Math.max(20, progress * 100) : progress * 100,
              page: pageIndex + 1,
              allPages: blok.items.length,
              blok: blok.items[pageIndex],
              buttonStyle: blok.button_style,
              accentColor: blok.accent_color,
              key: pageIndex,
              index: pageIndex,
              keepLastSlide: pageIndex === blok.items.length - 1,
            }
            return (
              <div
                style={{
                  height: '100%',
                }}
              >
                {blok.items[pageIndex].component === 'two_column_slide' ? (
                  <TwoColumnSlide {...slideProps} />
                ) : (
                  <Slide {...slideProps} />
                )}
                {/* right side dots */}
                {!blok.disable_tabs && blok.items.length > 1 && (
                  <Dots
                    items={blok.items}
                    current={pageIndex}
                    onClick={goToSlide}
                    onArrowPrevClick={() => {
                      if (pageIndex >= 1) {
                        goToSlide(pageIndex)
                      }
                    }}
                    isArrowPrevDisabled={pageIndex === 0}
                    onArrowNextClick={() => {
                      if (blok.items.length - 1 !== pageIndex) {
                        goToSlide(pageIndex + 2)
                      }
                    }}
                    isArrowNextDisabled={blok.items.length - 1 === pageIndex}
                  />
                )}
              </div>
            )
          }}
        />
      </Section>
      {/* render rest of the slides on build time so gatsby-node can save images to local  */}
      {!isInBrowser && (
        <div style={{ display: 'none' }}>
          {blok.items.slice(1).map(renderSlide)}
        </div>
      )}
    </SbEditable>
  )
}
export default DotsSlider

const Dots = React.memo(
  ({
    items,
    current,
    onClick,
    onArrowPrevClick,
    onArrowNextClick,
    isArrowPrevDisabled,
    isArrowNextDisabled,
  }) => {
    return (
      <DotsDiv>
        <ArrowPrev
          src={arrowIcon}
          onClick={onArrowPrevClick}
          disabled={isArrowPrevDisabled}
        />
        {items.map((el, i) => {
          return (
            <Dot
              key={`slider_dot__${el._uid}`}
              id={i + 1}
              className={current === i ? 'active' : ''}
              handleClick={onClick}
            />
          )
        })}
        <ArrowNext
          src={arrowIcon}
          onClick={onArrowNextClick}
          disabled={isArrowNextDisabled}
        />
      </DotsDiv>
    )
  },
)
