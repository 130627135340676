import React from 'react'
import SbEditable from 'storyblok-react'

import Video from './video'

function VideoInline({ blok }) {
  return (
    <SbEditable content={blok}>
      <Video blok={blok} />
    </SbEditable>
  )
}

export default VideoInline
