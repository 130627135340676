import styled from 'styled-components'
import { media } from 'utils'

export const SpeakerWrapper = styled.div`
  padding-bottom: 80px;

  ${media.greaterThan('sm')`
    display: flex;
    column-gap: 40px;
    `}

  h5 {
    margin: 0;
    font-family: Halyard-Medium;
    font-size: 24px;
  }

  p {
    padding-top: 24px;
  }
`

export const ImageDiv = styled.div`
  padding-bottom: 20px;

  ${media.greaterThan('sm')`
    padding-bottom: 0px;
    `}
  
    .speaker-image {
      height: 180px; 
      width: 180px;
      border-radius: 50%;
    }
`
