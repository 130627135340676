import React, { useLayoutEffect, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Content from 'components/Content'

import { HeroVideoContainer, RethinkPossible } from './styles'
// import ArrowRight from '/static/img/arrow_down_dark.svg'

gsap.registerPlugin(ScrollTrigger)
const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function VideoScroll({ blok }) {
  const vidRef = useRef()
  const mobileVidRef = useRef()
  const canvasRef = useRef()

  useLayoutEffect(() => {
    let container = document.querySelector('#hero')
    let scrollsection = document.querySelector('#hero-scroll')

    let mm = gsap.matchMedia()

    mm.add('(min-width: 500px)', () => {
      if (vidRef.current !== null) {
        vidRef.current.play()
      }

      let scrollTween = gsap
        .timeline({
          scrollTrigger: {
            trigger: '#herowrap',
            pin: true,
            end: () => container.offsetWidth * 3.5,
            scrub: 7,
            invalidateOnRefresh: true,
            // markers: true,
          },
        })
        .to(container, {
          x: () => -(scrollsection.offsetWidth - window.innerWidth),
          ease: 'none',
          duration: 8,
        })
        .to('#logo-a', {
          scale: 1,
          transformOrigin: '50% 50%',
          duration: 0.3,
          immediateRender: false,
        })
        .to(
          '#logo-b',
          {
            top: 0,
            duration: 0.3,
          },
          '<25%',
        )
        .to('#hero-logo', {
          marginTop: '10vh',
          duration: 0.2,
        })
        .to('#rethink_possible', {
          marginTop: '0px',
          opacity: 1,
          duration: 0.2,
        })
        .to('.spacer-rethink', {
          opacity: 1,
          duration: 1.75,
        })

      let tldots = gsap.timeline({
        scrollTrigger: {
          trigger: '#dots',
          containerAnimation: scrollTween,
          start: 'left 40%',
          end: 'left 40%',
          scrub: 2,
          // markers: true,
          id: 'dots',
        },
      })

      tldots.to('#dots', {
        opacity: 1,
      })

      const wordsArray = [
        blok.word_one,
        blok.word_two,
        blok.word_three,
        blok.word_four,
        blok.word_five,
        blok.word_six,
        blok.word_seven,
        blok.word_eight,
      ]
      let duration = 0.3

      let textTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: '#heroEnd',
          containerAnimation: scrollTween,
          start: 'left 55%',
          end: 'left 40%',
          scrub: true,
          // markers: true,
          id: 'word-rotator',
        },
      })
      wordsArray.forEach((obj, i) => {
        textTimeline.set(
          '.switch-text',
          {
            innerHTML: wordsArray[i],
          },
          i * duration,
        )
      })

      let tlheroend = gsap.timeline({
        scrollTrigger: {
          trigger: '#heroEnd',
          containerAnimation: scrollTween,
          start: 'left 100%',
          end: 'left 80%',
          scrub: 2,
          // markers: true,
          id: 'ending',
          invalidateOnRefresh: true,
        },
      })

      tlheroend.to('#dots', {
        opacity: 0,
      })

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#s02',
            containerAnimation: scrollTween,
            start: 'left 100%',
            end: 'left 20%',
            scrub: 2,
            // markers: true,
            id: '2',
            invalidateOnRefresh: true,
          },
        })
        .fromTo(
          '#s02text',
          {
            opacity: 0.5,
          },
          {
            opacity: 1,
            duration: 0.1,
          },
        )
        .fromTo(
          '#s02',
          {
            x: () => window.innerWidth * 0.1,
          },
          {
            x: 0,
          },
          '<',
        )

      let tls03 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s03',
          containerAnimation: scrollTween,
          start: 'left 100%',
          scrub: true,
          // markers: true,
          id: '3',
          invalidateOnRefresh: true,
        },
      })

      tls03
        .fromTo(
          '#s03text',
          {
            opacity: 0.5,
          },
          {
            opacity: 1,
            duration: 0.25,
          },
        )
        .fromTo(
          '#s03',
          {
            x: () => window.innerWidth * 0.3,
          },
          {
            x: 0,
          },
          '<',
        )

      let tls04 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s04',
          containerAnimation: scrollTween,
          start: 'left 110%',
          end: 'left 10%',
          scrub: 2,
          // markers: true,
          id: '4',
          invalidateOnRefresh: true,
        },
      })

      tls04.from('#s04c', {
        scale: 2,
        y: 1080,
        immediateRender: false,
      })

      tls04.fromTo(
        '#s04text',
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      )

      let tls05 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s05',
          containerAnimation: scrollTween,
          start: 'left 140%',
          end: 'left 50%',
          scrub: 2,
          //markers: true,
          id: '5',
          invalidateOnRefresh: true,
        },
      })

      tls05
        .fromTo(
          '#s05',
          {
            x: () => window.innerWidth * -0.3,
          },
          {
            duration: 0.5,
            x: 0,
          },
        )
        .set(
          '#s05',
          {
            opacity: 0,
          },
          '<99%',
        )

      let tls06 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s06',
          containerAnimation: scrollTween,
          start: 'left 140%',
          end: 'left 55%',
          scrub: 2,
          //markers: true,
          id: '6',
          invalidateOnRefresh: true,
        },
      })

      tls06
        .fromTo(
          '#s06text',
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.1,
          },
        )
        .fromTo(
          '#s06',
          {
            x: () => window.innerWidth * 0.12,
          },
          {
            x: 0,
          },
          '<',
        )

      let tls08 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s08',
          containerAnimation: scrollTween,
          start: 'left 140%',
          end: 'left 50%',
          scrub: 2,
          //markers: true,
          id: '8',
          invalidateOnRefresh: true,
        },
      })

      tls08
        .fromTo(
          '#s08',
          {
            x: () => window.innerWidth * 0.3,
          },
          {
            x: 0,
          },
        )
        .to(
          '#s08text',
          {
            opacity: 1,
          },
          '<',
        )

      //Canvas Stuff///

      const rcanvas = document.getElementById('s04c')
      const WIDTH = 1920
      const HEIGHT = 1080

      let cw = (rcanvas.width = WIDTH)
      let ch = (rcanvas.height = HEIGHT)

      function createCanvasFrames(c, fc, frameUrls) {
        const images = []
        const animobject = { frame: 0 }
        const ccontext = c.getContext('2d')

        for (let i = 0; i < fc; i++) {
          const img = new Image()
          img.src = frameUrls[i]
          images.push(img)
        }

        c.width = WIDTH
        c.height = HEIGHT

        return {
          canvas: c,
          ccontext: ccontext,
          frameCount: fc,
          images: images,
          anim: animobject,
        }
      }

      let richardObj
      if (IsChinaDeploy) {
        let frameUrls = [
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00000.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00001.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00002.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00003.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00004.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00005.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00006.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00007.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00008.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00009.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00010.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00011.png',
        ]
        richardObj = createCanvasFrames(rcanvas, 12, frameUrls)
      } else {
        let frameUrls = [
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
        ]
        richardObj = createCanvasFrames(rcanvas, 12, frameUrls)
      }

      gsap.to(richardObj.anim, {
        frame: richardObj.frameCount - 1,
        snap: 'frame',
        ease: 'none',
        scrollTrigger: {
          trigger: '#s04',
          containerAnimation: scrollTween,
          start: 'left 80%',
          end: 'left 30%',
          scrub: 2,
          //markers: true,
          id: '4a',
          invalidateOnRefresh: true,
        },
        onUpdate: function () {
          render(richardObj)
        },
      })

      function render(o) {
        o.ccontext.clearRect(0, 0, cw, ch)
        o.ccontext.drawImage(o.images[o.anim.frame], 0, 0, cw, ch)
      }
    })

    mm.add('(max-width: 499px)', () => {
      let scrollTween = gsap
        .timeline({
          scrollTrigger: {
            trigger: '#herowrap',
            pin: true,
            end: () => container.offsetWidth * 6,
            scrub: 8,
            invalidateOnRefresh: true,
            // markers: true,
          },
        })
        .to(container, {
          x: () => -(scrollsection.offsetWidth - window.innerWidth),
          ease: 'none',
          duration: 8,
        })
        .to('#logo-a', {
          scale: 1,
          transformOrigin: '50% 50%',
          duration: 0.2,
          immediateRender: false,
        })
        .to(
          '#logo-b',
          {
            top: 0,
            duration: 0.2,
          },
          '<25%',
        )
        .to('#hero-logo', {
          marginTop: '10vh',
          duration: 0.2,
        })
        .to('#rethink_possible', {
          marginTop: '0px',
          opacity: 1,
          duration: 0.4,
        })
        .to('.spacer-rethink', {
          opacity: 1,
          duration: 1,
        })

      let tldots = gsap.timeline({
        scrollTrigger: {
          trigger: '#dots',
          containerAnimation: scrollTween,
          start: 'left 40%',
          end: 'left 40%',
          scrub: 2,
          // markers: true,
          id: 'dots',
        },
      })

      tldots.to('#dots', {
        opacity: 1,
      })

      const wordsArray = [
        blok.word_one,
        blok.word_two,
        blok.word_three,
        blok.word_four,
        blok.word_five,
        blok.word_six,
        blok.word_seven,
        blok.word_eight,
      ]
      let duration = 0.3

      let textTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: '#heroEnd',
          containerAnimation: scrollTween,
          start: 'left 80%',
          end: 'left 60%',
          scrub: true,
          // markers: true,
          id: 'word-rotator',
        },
      })
      wordsArray.forEach((obj, i) => {
        textTimeline.set(
          '.switch-text',
          {
            innerHTML: wordsArray[i],
          },
          i * duration,
        )
      })

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#s02',
            containerAnimation: scrollTween,
            start: 'left 100%',
            end: 'left 20%',
            scrub: 2,
            // markers: true,
            id: '2',
            invalidateOnRefresh: true,
          },
        })
        .fromTo(
          '#s02text',
          {
            opacity: 0.5,
          },
          {
            opacity: 1,
            duration: 0.1,
          },
        )
        .fromTo(
          '#s02',
          {
            x: () => window.innerWidth * 0.1,
          },
          {
            x: 0,
          },
          '<',
        )

      let tls03 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s03',
          containerAnimation: scrollTween,
          start: 'left 100%',
          scrub: true,
          // markers: true,
          id: '3',
          invalidateOnRefresh: true,
        },
      })

      tls03
        .fromTo(
          '#s03text',
          {
            opacity: 0.5,
          },
          {
            opacity: 1,
            duration: 0.25,
          },
        )
        .fromTo(
          '#s03',
          {
            x: () => window.innerWidth * 0.3,
          },
          {
            x: 0,
          },
          '<',
        )

      let tls04 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s04',
          containerAnimation: scrollTween,
          start: 'left 110%',
          end: 'left 10%',
          scrub: 2,
          // markers: true,
          id: '4',
          invalidateOnRefresh: true,
        },
      })

      tls04.from('#s04c', {
        scale: 2,
        y: 1080,
        immediateRender: false,
      })

      tls04.fromTo(
        '#s04text',
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      )

      let tls05 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s05',
          containerAnimation: scrollTween,
          start: 'left 140%',
          end: 'left 50%',
          scrub: 2,
          //markers: true,
          id: '5',
          invalidateOnRefresh: true,
        },
      })

      tls05
        .fromTo(
          '#s05',
          {
            x: () => window.innerWidth * -0.6,
          },
          {
            duration: 0.5,
            x: 0,
          },
        )
        .set(
          '#s05',
          {
            opacity: 0,
          },
          '<99%',
        )

      let tls06 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s06',
          containerAnimation: scrollTween,
          start: 'left 140%',
          end: 'left 55%',
          scrub: 2,
          //markers: true,
          id: '6',
          invalidateOnRefresh: true,
        },
      })

      tls06.fromTo(
        '#s06',
        {
          x: () => window.innerWidth * 0.12,
        },
        {
          x: 0,
        },
        '<',
      )

      let tls08 = gsap.timeline({
        scrollTrigger: {
          trigger: '#s08',
          containerAnimation: scrollTween,
          start: 'left 200%',
          end: 'left 50%',
          scrub: 2,
          // markers: true,
          id: '8',
          invalidateOnRefresh: true,
        },
      })

      tls08
        .fromTo(
          '#s08',
          {
            x: () => window.innerWidth * 0.3,
          },
          {
            x: 0,
          },
        )
        .to(
          '#s08text',
          {
            opacity: 1,
          },
          '<',
        )
        .to(
          '#dots',
          {
            opacity: 0,
          },
          '-=99%',
        )

      //Canvas Stuff///

      const rcanvas = document.getElementById('s04c')
      const WIDTH = 1920
      const HEIGHT = 1080

      let cw = (rcanvas.width = WIDTH)
      let ch = (rcanvas.height = HEIGHT)

      function createCanvasFrames(c, fc, frameUrls) {
        const images = []
        const animobject = { frame: 0 }
        const ccontext = c.getContext('2d')

        for (let i = 0; i < fc; i++) {
          const img = new Image()
          img.src = frameUrls[i]
          images.push(img)
        }

        c.width = WIDTH
        c.height = HEIGHT

        return {
          canvas: c,
          ccontext: ccontext,
          frameCount: fc,
          images: images,
          anim: animobject,
        }
      }

      let richardObj
      if (IsChinaDeploy) {
        let frameUrls = [
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00000.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00001.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00002.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00003.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00004.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00005.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00006.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00007.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00008.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00009.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00010.png',
          'https://avenues.org/img/home-hero-richard/RICHARD_steps_00011.png',
        ]
        richardObj = createCanvasFrames(rcanvas, 12, frameUrls)
      } else {
        let frameUrls = [
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
          'https://a.storyblok.com/f/112543/1920x1080/255f500264/student3.png',
        ]
        richardObj = createCanvasFrames(rcanvas, 12, frameUrls)
      }

      gsap.to(richardObj.anim, {
        frame: richardObj.frameCount - 1,
        snap: 'frame',
        ease: 'none',
        scrollTrigger: {
          trigger: '#s04',
          containerAnimation: scrollTween,
          start: 'left 80%',
          end: 'left 30%',
          scrub: 2,
          //markers: true,
          id: '4a',
          invalidateOnRefresh: true,
        },
        onUpdate: function () {
          render(richardObj)
        },
      })

      function render(o) {
        o.ccontext.clearRect(0, 0, cw, ch)
        o.ccontext.drawImage(o.images[o.anim.frame], 0, 0, cw, ch)
      }
    })
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    const video = mobileVidRef.current

    video.addEventListener('loadedmetadata', () => {
      canvas.width = 640
      canvas.height = 360
    })

    const drawVideoFrame = () => {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      requestAnimationFrame(drawVideoFrame)
    }

    const handleCanPlayThrough = () => {
      video.removeEventListener('canplaythrough', handleCanPlayThrough)
      drawVideoFrame()
    }

    video.addEventListener('canplaythrough', handleCanPlayThrough)
    video.play()

    return () => {
      video.removeEventListener('canplaythrough', handleCanPlayThrough)
    }
  }, [])

  return (
    <HeroVideoContainer className='margin-none'>
      <div id="herowrap">
        <div id="hero">
          <div id="dots"></div>
          <div id="hero-scroll">
            <div id="s01" class="hero-item">
              <div id="s01-video-wrapper">
                {IsChinaDeploy ? (
                  <>
                    <video
                      src="https://avenues.org/img/homepage-videos/s01.mp4"
                      ref={vidRef}
                      autoPlay
                      loop
                      playsInline
                      muted
                      id="hero-desktop-video"
                    />
                    <video
                      src="https://avenues.org/img/homepage-videos/s01.mp4"
                      ref={mobileVidRef}
                      loop
                      playsInline
                      muted
                      id="hero-mobile-video"
                    />

                    <div id="s01-canvas">
                      <canvas
                        id="myCanvas"
                        style={{ width: '100%', height: '110%' }}
                        ref={canvasRef}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <video
                      src="https://player.vimeo.com/progressive_redirect/playback/812082182/rendition/720p/file.mp4?loc=external&signature=ef44c9d260968bccd538a59fbcdb01e7d55e50cc80a82f9a8ea1f9ed5a828b4f"
                      ref={vidRef}
                      autoPlay
                      loop
                      playsInline
                      muted
                      id="hero-desktop-video"
                    />
                    <video
                      src="https://player.vimeo.com/progressive_redirect/playback/812082182/rendition/360p/file.mp4?loc=external&signature=581af5fed4c1f2dd681590924c647ea6499084477c0610b2643ebbda902d746f"
                      ref={mobileVidRef}
                      loop
                      playsInline
                      muted
                      id="hero-mobile-video"
                    />

                    <div id="s01-canvas">
                      <canvas
                        id="myCanvas"
                        style={{ width: '100%', height: '110%' }}
                        ref={canvasRef}
                      />
                    </div>
                  </>
                )}
              </div>

              <div class="rethink" id="s01rethink">
                <Content blok={{ content: blok.rethink_one }} />
              </div>
              {/* <div className="scroller-wrapper">
                <div className="scroller">
                  <h4>Scroll</h4>
                  <img id="arrow" src={ArrowRight} />
                </div>
              </div> */}
            </div>
            <div id="s02" class="hero-item">
              <div id="s02text">
                <Content blok={{ content: blok.person_one }} />
              </div>
              <div class="rethink" id="s02rethink">
                <Content blok={{ content: blok.rethink_two }} />
              </div>
              <img
                src="https://a.storyblok.com/f/112543/532x1600/fd40a3fe65/s02_compressed.png"
                alt="Boy in a dynamic single handstand ballet pose"
              />
            </div>

            <div id="s03" class="hero-item">
              <div id="s03text">
                <Content blok={{ content: blok.person_two }} />
              </div>
              <img
                src="https://a.storyblok.com/f/112543/850x1600/8db83a286a/s03_compressed.png"
                alt="Boy playing the violin"
              />
            </div>

            <div id="s04" class="hero-item">
              <div id="richard-wrapper">
                <canvas id="s04c"></canvas>
                <div id="s04text">
                  <Content blok={{ content: blok.person_three }} />
                </div>
              </div>
              <div class="rethink" id="s04rethink">
                <Content blok={{ content: blok.rethink_three }} />
              </div>
            </div>

            <div id="s05" class="hero-item">
              {/*<img
                src="https://a.storyblok.com/f/112543/480x1600/a3a9b0425f/s05_compressed.png"
                alt="Young girl reading a book on the floor"
                id="s05-image"
              />*/}
            </div>

            <div id="s06" class="hero-item">
              <div id="s06text">
                <Content blok={{ content: blok.person_four }} />
              </div>
              <img
                src="https://a.storyblok.com/f/112543/813x1600/6106123e42/s06_compressed.png"
                alt="Two high school girls one is holding a potted plant and the other is watering it"
              />
              <div class="rethink" id="s06rethink">
                <Content blok={{ content: blok.rethink_four }} />
              </div>
            </div>
            <div id="s08" class="hero-item">
              <div id="s08text">
                <Content blok={{ content: blok.person_five }} />
              </div>
              <img
                src="https://a.storyblok.com/f/112543/813x1600/a43360bf12/s08.png"
                alt="High school girl spiking a volleyball"
              />
            </div>
            <div id="heroEnd">
              <div class="spacer">
                <div id="hero-logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 179 179"
                    id="logo-a"
                  >
                    <g id="A">
                      <circle fill="black" cx="89.5" cy="89.5" r="89.5" />
                    </g>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 143.22 188.45"
                    id="logo-b"
                  >
                    <g id="B">
                      <polygon
                        class="cls-1"
                        points="0 141.82 71.61 0 143.22 141.82 143.22 188.45 0 188.45 0 141.82"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
                <RethinkPossible id="rethink_possible">
                  <h2 class="rethink-rotator">Rethink</h2>
                  <h2 className="switch-text">Play</h2>
                  <span className="spacer-rethink"></span>
                </RethinkPossible>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeroVideoContainer>
  )
}

export default VideoScroll
