import React from 'react'
import SbEditable from 'storyblok-react'
import {StyledDiv, StyledImage, StyledContainer, Card } from 'components/FullWidthSection'
import media from 'utils/media'
import styled from 'styled-components'
import Components from 'components/components'

const Hero = styled(StyledDiv)`  
    ${media.greaterThan('lg')`
        height: 100vh;
    `}     
`

const StyledCard = styled(Card)`
    transition: width .2s;
    h1{
        font-size: 44px;
        line-height: 1.14;
        margin-bottom: 5px;
        
    }
    h2{
        font-family: 'Halyard-Book', 'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif;
        font-size: 24px;
        line-height: 1.4em;
    }
    p{margin-bottom:1em;}
    p strong{
        font-weight:bold;
    }
    a, a[type="green"] {
        min-width: 200px;
        font-size: 1em !important;
        padding:0 12px !important; 
    }
    ${media.greaterThan('sm')`
        background: rgba(255,255,255,.90);
        color: #000;
        padding-bottom:20px;
        margin-bottom: 60px;
        h1{
            font-size: 2.44rem;
            margin-bottom: 10px;
        }
        h2{
            font-size: 24px;
        }
        min-width: 615px;
        width: 35vw;
    ` }   
    ${media.greaterThan('lg')`
        width: 55vw;
    `}
    ${media.greaterThan('xl')`
        width: 35vw;
    `}
    
`
const HeaderContainer = styled(StyledContainer)`
    ${media.greaterThan('md')`
        height: calc(100vh - 60px);
        align-items: flex-end;
        justify-content: ${props => props.justify?props.justify:'flex-start'};
    `}
`
const AonHero = ({ blok }) => {
    const isImage = !!blok.image 
    return(
        <SbEditable content={blok}>
        <Hero
        className="margin-none"
        isImage={isImage}
        image={blok.image}>
            <StyledImage
            as ="div"
            isImage={isImage}
            image={blok.image}>
            </StyledImage> 
            <HeaderContainer
            justify = {blok.horizontal}>            
                <StyledCard
                margin = {blok.vertical}
                width = {blok.width}>
                    <h1>{blok.heading}</h1>
                    <h2>{blok.subheading}</h2>
                    {blok.body.map(
                    blok =>
                        Components[blok.component] &&
                        React.createElement(Components[blok.component], {
                        key: blok._uid,
                        blok,
                        }),
                    )}
                </StyledCard>
            </HeaderContainer>
        </Hero>
        </SbEditable>
    )
}

export default AonHero