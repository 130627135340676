import React from 'react'
import SbEditable from 'storyblok-react'
import get from 'lodash/get'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import { Container, Row, Col } from 'components/Grid'
import Components from 'components/components.js'
import { useAccordion } from 'hooks'

import plusIconDark from 'static/img/plus_icon_dark.svg'
import minusIconDark from 'static/img/minus_dark.svg'

import { PolyvVideo } from 'components/Common'
import { VideoDiv } from 'components/Video/styles'
import { StyledSection, Title, Description, ViewDetailsButton } from './styles'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function BannerAccordion({ blok }) {
  const [ref, toggleAccordion, height] = useAccordion()

  const response = !IsChinaDeploy
    ? get(blok, 'video.vimeo_oembed.response')
    : blok.video_alt
  const imageProps =
    blok.image &&
    getFluidGatsbyImage(blok.image, {
      maxWidth: 555,
      maxHeight: 281,
    })
    const View = <FormattedMessage id="view details" />  
    const Close = <FormattedMessage id="close details" />  
  return (
    <>
      <SbEditable content={blok}>
        <StyledSection
          paddingTop={blok.padding_top || 65}
          paddingBottom={blok.padding_bottom || 78}
          paddingTopMb={blok.padding_top_mobile || 39}
          paddingBottomMb={blok.padding_bottom_mobile || 26}
        >
          <Container>
            <Row>
              <Col width={[1, 1, 1 / 2]}>
                <Title>{blok.header}</Title>
                <Description>{blok.description}</Description>
              </Col>
              {response ? (
                <Col width={[1, 1, 1, 1 / 2]}>
                  {!IsChinaDeploy ? (
                    <VideoDiv
                      dangerouslySetInnerHTML={{ __html: response.html }}
                    />
                  ) : (
                    <PolyvVideo videoId={response} />
                  )}
                </Col>
              ) : (
                imageProps &&
                blok.image && (
                  <Col width={[1, 1, 1, 1 / 2]}>
                    <Img fluid={imageProps} />
                  </Col>
                )
              )}
              {!!blok.body.length && (
                <Col>
                  <ViewDetailsButton
                    onClick={toggleAccordion}
                    isExpanded={!height}
                  >
                    {height ? Close : View}
                    <img
                      src={height ? minusIconDark : plusIconDark}
                      alt={`${height ? 'retract icon' : 'expand icon'}`}
                      css={`
                        width: 17px;
                        height: 17px;
                      `}
                    />
                  </ViewDetailsButton>
                </Col>
              )}
            </Row>
          </Container>
        </StyledSection>
      </SbEditable>
      <div
        ref={ref}
        style={{
          transition: 'height .4s ease-in-out',
          height: `${height || 0}px`,
          overflow: 'hidden',
        }}
      >
        {blok.body &&
          blok.body.map(el => {
            return React.createElement(Components[el.component], {
              key: el._uid,
              blok: el,
            })
          })}
      </div>
    </>
  )
}

export default BannerAccordion
