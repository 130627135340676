import en from './en'
import pt from './pt'
import zh from './zh'

var messages = {
  en,
  pt,
  zh,
}

export default messages
