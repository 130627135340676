import styled from 'styled-components'

import media from 'utils/media'

export const MobileWrap = styled.div`
  display: block;
  ${media.greaterThan('sm')`
    display: none;
  `}
`
