import styled from 'styled-components'

import { media } from 'utils'

export const TextAnimation = styled.div`
  padding-top: 10px;
  margin-top: -10px;
  overflow: hidden;
`
export const Title = styled.h3`
  margin-top: 35px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 22px;

  ${media.greaterThan('355px')`
    margin-top: 35px;
    font-size: 28px !important;
  `}

  ${media.greaterThan('md')`
    margin-top: 108px;
    max-width: 75%;
  `}
`
export const Subheading = styled.h4`
  color: #ffffff;
  font-size: 18px;
  ${media.greaterThan('355px')`
    font-size: 20px;
  `}
  ${media.greaterThan('md')`
    max-width: 75%;
  `}
`
export const Description = styled.p`
  color: #ffffff;
  margin-bottom: 15px;
  overflow: hidden;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.67;
  ${media.greaterThan('md')`
    margin-bottom: 30px;
    font-size: 16px;
  `}
`
export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.dark};

  .gatsby-image-wrapper,
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const SlideRow = styled.div`
  position: static;
  width: 100%;
  padding-bottom: 35px;
  min-height: 100%;
  height: auto;
  overflow: hidden;

  ${media.greaterThan('md')`
    padding-bottom: 0px;
  `}
`

export const StyledVideo = styled.video`
  opacity: ${({ opacity }) => Number(opacity)};
`

export const MobileContent = styled.div`
  ${media.greaterThan('md')`
    display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'initial')};
  `}

  img,
  video,
  .gatsby-image-wrapper {
    margin-bottom: 32px;
  }
`

export const Aside = styled.div`
  position: relative;
  display: none;

  ${media.greaterThan('lg')`
    display: block;
  `}
`

export const StyledDiv = styled.div`
  h3 {
    font-family: Halyard-SemiBold;
    font-feature-settings: 'ss18';
  }
`
