import React from 'react'
import SbEditable from 'storyblok-react'
import styled, { css } from 'styled-components'
import Link from './Common/Link'
import { media } from 'utils'

const StyledDiv = styled.div`
    cursor:pointer;
    padding-bottom: ${props => props.pBottomMd? props.pBottomMd+'px': '0px'};
    padding-top: ${props => props.pTopMb? props.pTopMb+'px': '0px'};
    ${media.greaterThan('sm')`
        padding-bottom: ${props => props.pBottom? props.pBottom+'px': '0px'};
        padding-top: ${props => props.pTop? props.pTop+'px': '0px'};
    `}
    
    div{
        display: inline-block;
        font-family: 'Halyard-Book','SF Pro Display','-apple-system','BlinkMacSystemFont','San Francisco','Helvetica Neue','Helvetica','Ubuntu','Roboto','Noto','Segoe UI','Arial',sans-serif;
        color: ${({ theme, tColor }) => theme.colors[tColor] || '#000'} !important;
        div:first-child{
            display: block;
            font-family: 'Halyard-SemiBold', 'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif;
            font-size: 1.4em;
            line-height: .8em;
            font-feature-settings: 'ss18';
            svg{
                display:inline-block;
                width: 1em;
                vertical-align: middle;
            }
            path{
                fill:#299069;
            }
        } 
    }             
    
`
const Thumbnail = styled.div`

    ${({ isImage }) => {
        if (isImage) {
        return css`
            background-image: url(${props => props.image});
            background-position:center;
            background-size:cover;
            display: inline-block;
            width: 45px;
            height: 45px;
            margin-right: 10px;
            `
        }
    }}

`

const NavButton = ({ blok }) => {
    const isImage = !!blok.thumbnail 
    return (
        <SbEditable content={blok}>
            <Link
            blok={blok}
            as={blok.as}>
                <StyledDiv
                pBottom = {blok.padding_bottom}
                pBottomMd = {blok.padding_bottom_md}  
                pTop = {blok.padding_top}
                pTopMb = {blok.padding_top_mb}
                tColor = {blok.color?blok.color:'#000'}
                >
                    {isImage && (
                        <Thumbnail 
                        isImage={isImage}
                        image={blok.thumbnail}>
                        </Thumbnail>
                    ) }
                    <div>
                        <div>
                            {blok.text + " "} 
                            {blok.icon === 'download'?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.6 22.6"><path d="M11.3 0C5.1 0 0 5.1 0 11.3s5.1 11.3 11.3 11.3 11.3-5.1 11.3-11.3S17.5 0 11.3 0zM6.6 9.6C6.6 9.5 6.7 9.5 6.6 9.6l2.9-0.1c0.1 0 0.1-0.1 0.1-0.1l0.1-3.9c0-0.1 0.1-0.1 0.1-0.1h2.7c0.1 0 0.1 0.1 0.1 0.1v3.9c0 0.1 0.1 0.1 0.1 0.1h2.7c0.1 0 0.1 0.1 0.1 0.1 0 0.1 0 0.1-0.1 0.1l-4.2 4.4c-0.1 0.1-0.1 0.1-0.2 0L6.6 9.8C6.5 9.7 6.5 9.6 6.6 9.6zM15.9 16.9C15.9 16.9 15.9 16.9 15.9 16.9l-9.2 0.1c-0.1 0-0.1-0.1-0.1-0.1v-2.2c0-0.1 0.1-0.1 0.1-0.1h9.1c0.1 0 0.1 0.1 0.1 0.1V16.9z"/></svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.6 22.6"><path d="M13.2 0.2C5.5-1.1-1.1 5.5 0.2 13.2c0.8 4.7 4.5 8.5 9.2 9.2 7.7 1.3 14.3-5.3 13.1-13.1C21.7 4.7 17.9 0.9 13.2 0.2zM17.8 11.3C17.7 11.3 17.7 11.3 17.8 11.3l-6.1 6.3c-0.1 0.1-0.2 0.1-0.3 0 0 0-0.1-0.1-0.1-0.2v-3.9c0-0.1-0.1-0.2-0.2-0.2l-5.4-0.1c-0.1 0-0.2-0.1-0.2-0.2V9.5c0-0.1 0.1-0.2 0.2-0.2h5.4c0.1 0 0.2-0.1 0.2-0.2V5.3c0-0.1 0.1-0.2 0.2-0.2 0.1 0 0.1 0 0.1 0.1l6.1 5.9C17.8 11.1 17.8 11.3 17.8 11.3z"/></svg>
                            }
                        </div>
                        {blok.description &&
                        <div>
                            {blok.description}
                        </div>
                        }
                    </div>
                </StyledDiv>
            </Link>
        </SbEditable>
    )        
}

export default NavButton