import React, { useEffect, useState } from 'react'

import HorizontalSlider from '../HorizontalSlider'

export default function VideoImageSlider({ blok, index }) {
  const [videoUrls, setVideoUrls] = useState([])

  useEffect(() => {
    blok.slides.map(slide => {
      videoUrls.push(slide.video_link.vimeo_raw)
    })
  }, [])

  return (
    <>
      <HorizontalSlider blok={blok} index={index} videoUrls={videoUrls} />
    </>
  )
}
