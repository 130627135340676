import styled, { css } from 'styled-components'

export const StyledInput = styled.input`
  ${({ theme, icon }) => css`
    background: ${theme.colors.white} url(${icon}) no-repeat;
  `}
  
  background-position: left 18px top 17px;
  background-size: 16px;
  color: ${({ theme }) => theme.colors.dark};
  border: 2px solid ${({ theme }) => theme.colors.dark};
  border-radius: 8px;
  padding: 0.85rem 18px 0.85rem 45px;
  outline: none;
  width: 100%;
  appearance: none;

  &:focus,
  &:active,
  &:hover {
    border-color: ${({ theme }) => theme.colors.dark};
  }
`
