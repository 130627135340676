import React from 'react'
import Components from 'components/components.js'
import { CButton } from 'components/Common'

export function renderComponent(blok) {
  return (
    Components[blok.component] &&
    React.createElement(Components[blok.component], {
      key: blok._uid,
      blok,
      ...(blok.component === 'text' && {
        style: {
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 40,
          paddingRight: 40,
        },
      }),
    })
  )
}

export function renderButton(button, fun) {
  switch (button.component) {
    case 'button':
      return (
        <CButton {...button} onClick={fun} as="a" key={button._uid}>
          {button.text}
        </CButton>
      )
    default:
      return <div onClick={fun}>{renderComponent(button)}</div>
  }
}
