import React from 'react'
import styled from 'styled-components'

import ImageComponent from '../image'
import Headline from '../Headline'

import { removeGlobalSlug, withSlash } from 'utils/helpers'

export default function NewsSlide({ blok }) {
  return (
    <>
      <SlideWrapper>
        <a href={withSlash(removeGlobalSlug(blok.slug))}>
          <div className="outer-image-wrapper">
            <ImageComponent
              blok={{
                media: blok.content.cover_image,
                alt: 'image respresenting Avenues` article about ' + blok.title,
              }}
            />
          </div>
          <Headline
            blok={{
              headline: blok.content.title + ' ' + blok.content?.subheading,
            }}
            className="bio-semibold-medium"
            margin="15px 0"
            textAlign='left'
            type="h3"
          />
        </a>
      </SlideWrapper>
    </>
  )
}

const SlideWrapper = styled.div`
  :hover h3 {
    text-decoration: underline;
  }

  :hover .gatsby-image-wrapper {
    transform: scale(1.05);
  }

  .outer-image-wrapper {
    width: 100%;
    height: auto;
    max-height: 320px;
    aspect-ratio: 16/9;
    object-fit: cover;
    overflow: hidden;

    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
      transition: transform 0.5s ease-in-out;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`
