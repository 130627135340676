const DONT_RENDER = () => null

const removedComponents = {
  IndicatorSeparator: DONT_RENDER,
  ClearIndicator: DONT_RENDER,
  MultiValueContainer: DONT_RENDER,
  Input: DONT_RENDER,
  Placeholder: DONT_RENDER,
}

export default removedComponents
