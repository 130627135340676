import styled from 'styled-components'

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 64px;

  .tab-names-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 35px;

    .tab-names {
      cursor: pointer;
      text-align: center;
      text-underline-offset: 3px;
      text-decoration-thickness: 2px !important;

      :hover {
        text-decoration: underline !important;
        text-underline-offset: 3px;
        text-decoration-thickness: 2px !important;
      }

      .tab-name {
        color: #000;
      }
    }
  }

  @media (max-width: 1024px) {
    .tab-names-wrapper {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 10px;
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;

    .tab-names-wrapper {
      column-gap: 20px;
    }
  }
`

export const TabContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;

  .tab-container {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 60px;

    .profile {
      width: 50%;
    }

    .download-arrow-button {
      display: flex;
      color: ${({ theme }) => theme.colors.newBlue};
      column-gap: 16px;
      align-items: center;
      margin-bottom: 15px;

      :hover p {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 700px) {
    .tab-container {
      flex-direction: column;
      align-items: center;
      row-gap: 20px;

      .links,
      .profile {
        width: auto;
      }
    }
  }
`
