import React from 'react'
import { Title, Meta } from 'react-head'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import curry from 'lodash/curry'

import { getIntlMessage } from 'utils/helpers'
import { getBreadcrumbsAliases, addBreadcrumbs } from 'utils/breadcrumbs'

function useSiteMetadata() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            siteUrl
            title
            description
            seo {
              og {
                type
              }
              twitter {
                card
                creator
              }
            }
          }
        }
      }
    `,
  )

  return site.siteMetadata
}

const SEO = ({
  lang,
  pageTitle,
  pageDescription,
  pageImage,
  schemaLD,
  location,
}) => {
  const { name, siteUrl, title, description, seo } = useSiteMetadata()
  const documentTitle = pageTitle || title
  const documentDescription = pageDescription || description

  const pageAliases = getBreadcrumbsAliases()
  const breadcrumbs = addBreadcrumbs({
    aliases: pageAliases,
    showCurrent: true,
    location,
  })
  const intl = useIntl()
  const intlParse = curry(id => getIntlMessage(intl.messages, id))

  return (
    <>
      <Title>{documentTitle}</Title>
      <Meta charSet="utf-8" />
      <Meta name="referrer" content="origin" />
      <Meta name="description" content={documentDescription} />
      <Meta property="og:url" content={siteUrl} />
      <Meta property="og:type" content={seo.og.type} />
      <Meta property="og:locale" content={lang} />
      <Meta property="og:site_name" content={name} />
      <Meta property="og:title" content={documentTitle} />
      <Meta property="og:description" content={documentDescription} />

      {pageImage && (
        <>
          <Meta name="image" content={pageImage} />
          <Meta property="og:image" content={pageImage} />
          <Meta property="twitter:image" content={pageImage} />
        </>
      )}

      <Meta name="twitter:site" content={seo.twitter.creator} />
      <Meta name="twitter:card" content={seo.twitter.card} />
      <Meta name="twitter:title" content={documentTitle} />

      <Meta
        name="google-site-verification"
        content="IstnzFsdnWCO4c3Z1O8pfkUPSUjLfi20yK3bv1bJxCo"
      />

      {/* SchemaLD json */}
      <Meta
        data-pn={location.pathname}
        tag="script"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify([
            ...(schemaLD || []),
            {
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbs.map((page, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                name: intlParse(page.name),
                item: `$siteURL${page.slug}`,
              })),
            },
          ]).replace(/\$siteURL/g, siteUrl),
        }}
      />
    </>
  )
}

export default SEO
