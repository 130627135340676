import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { useAccordion } from 'hooks'

import { Description } from './styles'
import { FormattedMessage } from 'react-intl'

function DescriptionAccordion(props) {
  const [accordionRef, toggleAccordion, height] = useAccordion()
  // const [expandHeight, setExpandHeight] = useState(0)
  // const [wasTopWrapperExpanded, setTopWrapperExpandState] = useState(0)
  let wrapperRef = useRef()

  // COMMENTED FUNCTION TO CALCULATE NEEDED DESCRIPTION HEIGHT
  // DISABLED ASS WE BLOCK SCROLL TO PERFORM SLIDES CHANGE

  // useEffect(() => {
  //   let TopWrapper = document.getElementById('SliderTopWrapper')

  //   if (
  //     height !== 0 &&
  //     wrapperRef.current.getBoundingClientRect().bottom + height >
  //       TopWrapper.getBoundingClientRect().bottom
  //   ) {
  //     let expandValue = height + 35
  //     setTopWrapperExpandState(true)
  //     TopWrapper.style.height = TopWrapper.getBoundingClientRect().height + expandValue + 'px'
  //     setExpandHeight(expandValue)
  //   }

  //   if (height === 0 && wasTopWrapperExpanded) {
  //     TopWrapper.style.height = TopWrapper.getBoundingClientRect().height - expandHeight + 'px'
  //   }
  // }, [height])

  return (
    <div ref={wrapperRef}>
      <div
        ref={accordionRef}
        style={{
          transition: 'height .4s ease-in-out',
          height: `${height}px`,
          overflow: 'hidden',
        }}
      >
        <Description>{props.children}</Description>
      </div>
      <ViewMoreButton
        onClick={toggleAccordion}
        accentColor={props.accentColor}
        isExpanded={!height}
      >
        {<FormattedMessage id={height ? 'showLess' : 'showMore'} />}
      </ViewMoreButton>
    </div>
  )
}

export default DescriptionAccordion

const ViewMoreButton = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 3px solid
    ${({ theme, accentColor }) => theme.colors[accentColor]};
  font-size: 20px;
  margin-bottom: 35px;
  font-weight: normal;
  padding: 0;
  color: #fff;
  cursor: pointer;
  transition: margin 0.3s;
  outline: none;
`
