import styled, { keyframes } from 'styled-components'

import { setStyledAccentColor } from 'utils/helpers'
import { media } from 'utils'

export const ANIMATION_TIME = 330
export const animationTimeMs = ANIMATION_TIME + 'ms'

export const fadeIn = keyframes`
  0% {
    transform: scale(0);
  }
  100%: {
    transform: scale(1);
  }
`

export const Content = styled.div`
  position: relative;
  background-color: #fff;
  width: 98%;
  max-width: 720px;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  overflow: auto;
  padding: 20px 0px 0;
  animation: ${fadeIn} ${animationTimeMs};
  transition: transform ${animationTimeMs}, opacity ${animationTimeMs};
  margin: 0 auto;

  ${media.greaterThan('md')`
    width: 90%;
    height: auto;
    max-height: 100%;
    max-width: 1267px;
  `}
`

export const StyledModal = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity ${animationTimeMs};
  backdrop-filter: blur(2px);
  min-height: 100vh;
  padding: 0;

  &.hide {
    opacity: 0;
    ${Content} {
      transform: scale(0);
      opacity: 0;
    }
  }
`

export const CloseButton = styled.button`
  background-color: #fff;
  border: none;
  position: sticky;
  text-align:right;
  padding: 20px 25px;
  cursor: pointer;
  top: 0px;
  z-index: 3;
`

export const Heading = styled.h2`
  text-transform: uppercase;
  border-bottom: 6px solid ${setStyledAccentColor};
  margin-bottom: 9px;
`
