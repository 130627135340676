import styled from 'styled-components'

export const SpeakerWrapper = styled.div`
    padding-bottom: 80px;

    h5 {
        margin: 0; 
        font-family: Halyard-Medium;
        font-size: 24px;
    }

    p {
        padding-top: 24px;
    }
`
