import React from 'react'
import { excerptText } from 'utils/helpers'
import {
  CoverImage,
  WrapperDescription,
  FeaturedTitle,
  FeaturedSubhead,
  FeaturedText,
  ReadButton,
} from './styles'
import { StyledMarkdown, Link } from 'components/Common'
import { FormattedMessage } from 'react-intl'

function FeaturedItem({ node }) {
  const { content, slug } = node
  const { title, subheading, cover_image, body, excerpt } = content

  return (
    <div>
      <Link
        blok={{
          text: title,
          url: {
            cached_url: slug,
          },
        }}
      >
        <FeaturedTitle>{title}</FeaturedTitle>
        <h5>{subheading}</h5>
        {cover_image && <CoverImage fluid={cover_image} />}
        <WrapperDescription>
          <FeaturedText>
            <FormattedMessage id="featured" />
          </FeaturedText>
          <StyledMarkdown source={excerpt || excerptText(body, 274)} />
        </WrapperDescription>
      </Link>
    </div>
  )
}

export default FeaturedItem
