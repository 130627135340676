import styled from 'styled-components'
import { Col } from 'components/Grid'

import { media } from 'utils'

export const HoverEffect = styled.div`
  transition: 0.15s ease-in-out;
  padding: 8px 8px 15px;

  &:hover {
    background-color: rgba(0, 182, 255, 0.07);
  }

  .gatsby-image-wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.blueDark};
    filter: opacity(0.5);
    height: 0;
    width: 100%;
    transition: height 0.3s ease;
  }

  &:hover {
    .gatsby-image-wrapper::after {
      height: 100%;
    }
  }
`

export const StyledSection = styled.section`
  padding-bottom: 106px;
  ${media.greaterThan('md')`
    padding-bottom: 144px;
  `}
`

export const DarkRow = styled.div`
  padding: 49px 0 30px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.neutral};
    opacity: 0.5;
    z-index: -1;
  }

  ${media.greaterThan('lg')`
    margin-bottom: 86px;
  `}
`

export const FilterTab = styled.div`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutral};
  padding: 12px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SectionTitle = styled.h6`
  display: none;
  margin-bottom: 32px;

  ${media.greaterThan('lg')`
    display: block;
  `}
`

export const StyledDepList = styled.ul`
  list-style: none;
  margin-left: 0;

  li {
    margin-bottom: 6px;
  }
`

export const FeaturedCol = styled(Col)`
  margin-bottom: 125px;

  ${media.greaterThan('md')`
    margin-bottom: 146px
  `}
`

export const StyledCol = styled(Col)`
  margin-bottom: 65px;

  ${media.greaterThan('md')`
    margin-bottom: 79px
  `}
`

export const StyledTabButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: 16px;
  line-height: 1.6;
  cursor: pointer;
  text-transform: capitalize;

  &:not(.active) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.blueLight};
  }
  &.active {
    font-weight: 300;
  }
`

export const LightButton = styled.button`
  ${({ theme }) => theme.buttons.tertiary};
  ${({ theme }) => theme.buttonsSize.big};
  width: 282px;
  border: 1px solid ${({ theme }) => theme.colors.neutral};
  margin: 60px auto 0;
  text-transform: none;

  ${media.greaterThan('md')`
    width: 262px;
  `}
`
