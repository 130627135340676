import React, { useEffect, useMemo } from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'

import { Container, Row, Col } from 'components/Grid'

import { Background, SlideRow } from './styles'

import { notEmpty } from 'utils/helpers'
import { useDeviceType } from 'hooks'

import Text from 'components/text'
import Video from 'components/Video/video_inline'
import SliderText from './SliderText'

const BodyComponents = {
  text: Text,
  scroll_slide_text: SliderText,
  video_inline: Video,
}

function Slide(props) {
  const isMobile = useDeviceType()

  const { blok, progress } = props

  const slideWrapper = React.useRef(null)

  const backgroundImageProps =
    blok.background &&
    useMemo(
      () =>
        getFluidGatsbyImage(blok.background, {
          maxWidth: 2000,
          downloadLocal: true,
        }),
      [blok.background],
    )

  function calcTextTransform() {
    let transform = 0
    if (progress <= 5) {
      transform = '100%'
    } else if (progress >= 5 && progress <= 65) {
      transform = Math.max(0, 100 - (progress - 5) * 1.68) + '%'
    } else if (progress >= 65) {
      var acc = props.keepLastSlide && !isMobile ? 2.25 : 2.86
      transform = '-' + String((progress - 65) * acc) + '%'
    }

    return transform
  }

  function calcBackgroundOpacity() {
    let opacity = 1

    if (progress <= 50) {
      opacity = Math.min(1, progress * 0.056)
    } else if (progress < 65) {
      opacity = 1 - (progress - 20) * 0.0165
    } else if (progress >= 65 && progress < 75) {
      opacity = 0.25
    } else {
      var acc = props.keepLastSlide && isMobile ? 0.012 : 0.017
      opacity = 1 - (progress - 32) * acc
    }

    return opacity
  }

  const body = React.useRef(
    blok.body.map(
      el =>
        BodyComponents[el.component] && (
          <Col width={[1, 1 / 2]}>
            {React.createElement(BodyComponents[el.component], {
              key: el._uid,
              blok: el,
            })}
          </Col>
        ),
    ),
  ).current

  return (
    <SlideRow ref={slideWrapper}>
      <Container
        id={`slider-container-${props.index}`}
        css="height: 100%; min-height: 100vh;"
      >
        <Row css="height: 100%; min-height: 100vh;">
          <Row
            css={`
              min-height: 100vh;
              height: 100%;
              width: 100%;
              z-index: 3;
              flex-direction: ${blok.align};
              /* transition: transform 100ms linear; */
            `}
            style={{
              transform: `translate3d(0, ${calcTextTransform()}, 0)`,
              ...(!notEmpty(blok.aside) && {
                marginLeft: 'auto',
                marginRight: 'auto',
              }),
            }}
          >
            {body}
          </Row>
        </Row>
      </Container>

      {/* background image */}
      <Background>
        {blok.background && (
          <Img
            fluid={backgroundImageProps}
            style={{ opacity: calcBackgroundOpacity() }}
          />
        )}
      </Background>
    </SlideRow>
  )
}

export default Slide
