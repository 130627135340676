import styled from 'styled-components'

export const SplitImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
  display: block;
`

export const SplitImageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .split-image-side {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;

    .slide-background-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .split-image-text-column {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .point-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        text-align: center;
      }
    }
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  @media (max-width: 500px) {
    .split-image-text-column {
      width: 80%;
    }

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }
  }
`
