import React, { useEffect, useState, useLayoutEffect } from 'react'
import SbEditable from 'storyblok-react'
import Img from 'gatsby-image'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

import LineCircleSVG from '../SVGs/lineCircle'
import LineCircleMobileSVG from '../SVGs/lineCircleMobile'

import {
  TimelineWrapper,
  Year,
  TimelineIntro,
  SingleColumnImage,
  SingleColumnVideo,
  LineCircleWrapper,
  LineCircleMobileWrapper,
} from '../styles'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)

export default function SingleColumnTimeline({ blok, isLast, imageProps }) {
  const [singleColumn, setSingleColumn] = useState(false)
  const [noYear, setNoYear] = useState(false)
  const [image, setImage] = useState(false)
  const [noMedia, setNoMedia] = useState(false)

  useEffect(() => {
    if (blok.content.content.length === 1) {
      setSingleColumn(true)
    }

    if (blok.year === '') {
      setNoYear(true)
    }

    if (blok.video_link === '' && blok.content.content.length === 1) {
      setImage(true)
    }

    if (blok.video_link === '' && blok.image === '') {
      setNoMedia(true)
    }
  }, [])

  //DYAMIC CLASSES FOR GREENSOCK MOVEMENT
  const getSvgContainer = `svg-${blok?._uid.replace(/-/g, '')}`
  const getYearContainer = `year-${blok?._uid.replace(/-/g, '')}`
  const getLine = `line-${blok?._uid.replace(/-/g, '')}`
  const getCircle = `circle-${blok?._uid.replace(/-/g, '')}`
  const getVideoContainer = `video-${blok?._uid.replace(/-/g, '')}`
  const getImageContainer = `image-${blok?._uid.replace(/-/g, '')}`
  const getSvgContainerMobile = `svg-mobile-${blok?._uid.replace(/-/g, '')}`
  const getLineMobile = `line-mobile-${blok?._uid.replace(/-/g, '')}`
  const getCircleMobile = `circle-mobile-${blok?._uid.replace(/-/g, '')}`

  useLayoutEffect(() => {
    const svgContainer = document.querySelector('.' + getSvgContainer)
    const yearContainer = document.querySelector('.' + getYearContainer)
    const videoContainer = document.querySelector('.' + getVideoContainer)
    const line = document.querySelectorAll('#' + getLine)
    const circle = document.querySelector('#' + getCircle)
    const svgContainerMobile = document.querySelector(
      '.' + getSvgContainerMobile,
    )
    const lineMobile = document.querySelectorAll('#' + getLineMobile)
    const circleMobile = document.querySelector('#' + getCircleMobile)

    gsap.set(line, { attr: { 'vector-effect': 'non-scaling-stroke' } })

    let mm = gsap.matchMedia(),
      breakPoint = 834

    mm.add(
      {
        // set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
      },
      context => {
        // context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
        let { isDesktop, isMobile } = context.conditions

        gsap
          .timeline({
            scrollTrigger: {
              trigger: isDesktop ? svgContainer : svgContainerMobile,
              start: 'top 40%',
              end: 'top -10%',
              scrub: 1,
              // markers: true,
            },
          })
          .from(isDesktop ? line : lineMobile, {
            drawSVG: '0%',
            ease: 'none',
            duration: 0.99,
          })
          .from(
            isDesktop ? circle : circleMobile,
            { opacity: 0, duration: 0.01 },
            singleColumn ? '-=0.2' : '-=0.4',
          )

        gsap
          .timeline({
            scrollTrigger: {
              trigger: yearContainer,
              start: 'bottom 20%',
              // markers: true,
            },
          })
          .from(videoContainer, { opacity: 0, y: 75, scale: 0.6 }, 0.5)

        return () => {}
      },
    )
  }, [])

  useEffect(() => {
    const imageContainer = document.querySelector('.' + getImageContainer)
    const yearContainer = document.querySelector('.' + getYearContainer)

    gsap
      .timeline({
        scrollTrigger: {
          trigger: yearContainer,
          start: 'bottom 60%',
          // markers: true,
        },
      })
      .from(imageContainer, { opacity: 0, y: 75, scale: 0.6 }, 0.5)
  }, [image])

  return (
    <>
      <SbEditable content={blok}>
        <TimelineWrapper image={image} noYear={noYear}>
          <Year className={`${getYearContainer}`}>{blok.year}</Year>
          <TimelineIntro className="timeline-intro">
            {blok.introduction}
          </TimelineIntro>
          {!noMedia && (
            <>
              {image ? (
                <SingleColumnImage
                  className={`image-wrapper ${getImageContainer}`}
                >
                  <Img
                    fluid={imageProps}
                    alt={'image representing ' + blok.introduction}
                  />
                </SingleColumnImage>
              ) : (
                <SingleColumnVideo
                  className={`video-wrapper ${getVideoContainer}`}
                >
                  <video
                    src={
                      !IsChinaDeploy ? blok.video_link : blok.video_link_china
                    }
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                </SingleColumnVideo>
              )}
            </>
          )}

          {!isLast && (
            <>
              <LineCircleWrapper>
                <LineCircleSVG
                  getSvgContainer={getSvgContainer}
                  getCircle={getCircle}
                  getLine={getLine}
                  singleColumn={singleColumn}
                />
              </LineCircleWrapper>
              <LineCircleMobileWrapper>
                <LineCircleMobileSVG
                  getSvgContainerMobile={getSvgContainerMobile}
                  getCircleMobile={getCircleMobile}
                  getLineMobile={getLineMobile}
                  singleColumn={singleColumn}
                />
              </LineCircleMobileWrapper>
            </>
          )}
        </TimelineWrapper>
      </SbEditable>
    </>
  )
}
