import styled from 'styled-components'

export const LinkWrapper = styled.div`
  margin-top: ${({ marginTop }) => (marginTop !== '' ? marginTop + 'px !important' : '0')};
`

export const TextButtonWrapper = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

    .oneLineButton {
      font-family: Halyard-Medium;
      font-size: 24px;
      color: ${({ theme, textColor }) => theme.colors[textColor]};
      line-height: 24px;
      text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')}
    }

    .twoLineButton {
        font-family: Halyard-Book;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
      }
  }
`

export const TextButton = styled.div`
  display: flex;
  gap: 2;
  align-items: center;
`

export const BackgroundButtonWrapper = styled.button`
  cursor: pointer;

  &:hover {
    opacity: ${({ buttonType }) => (buttonType === 'outline' ? '1' : '.9')};
    border: ${({ buttonType }) =>
      buttonType === 'outline' ? '1px solid #000' : 'none'};
    background: #000;
    color: rgb(247, 207, 128);
    cursor: pointer;
  }

  border: ${({ theme, buttonType }) =>
    buttonType === 'outline' ? '1px solid #000' : 'none'};
  background: ${({ theme, backgroundColor, buttonType }) =>
    buttonType === 'outline' ? 'transparent' : theme.colors[backgroundColor]};
  color: ${({ theme, buttonType }) =>
    buttonType === 'outline' ? '#000' : '#ffffff'};
  padding: ${({ buttonSize }) =>
    buttonSize === 'large' ? '15px 0px' : '10px 24px'};
  border-radius: ${({ buttonSize }) => (buttonSize === 'large' ? '2px' : '0')};
  font-family: Halyard-Medium;
  font-size: 20px;
  line-height: 24px;
  margin-top: ${({ marginTop }) => (marginTop !== '' ? marginTop : '0')};
`

export const BackgroundButton = styled.div`
  display: flex;
  column-gap: 8px;
  gap: 2;
  align-items: center;
  justify-content: center;
`
