import React from 'react'
import SbEditable from 'storyblok-react'

import Content from 'components/Content'
import Components from 'components/components'

import { ContentWrapper } from './styles'
import MapLight from '/static/img/map-light.svg'

export default function RichText({ blok }) {
  return (
    <SbEditable content={blok}>
      <ContentWrapper textSize={blok.text_size} className="margin-standard">
        <div className="slide-background">
          <img
            className="slide-background-image"
            src={MapLight}
            alt="pattern of dots that form the shape of a world map"
          />
        </div>
        <div class="cta">
          <Content blok={{ content: blok.text }} />
          {blok.button &&
            blok.button.map(el => {
              return React.createElement(Components[el.component], {
                key: el._uid,
                blok: el,
              })
            })}
        </div>
      </ContentWrapper>
    </SbEditable>
  )
}
