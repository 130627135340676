import React, { useLayoutEffect, useState, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import ImageComponent from '../../image'
import Video from 'components/NewVideo/InlineVideo'
import Headline from '../../Headline'

import { SlideContainer } from './styles'

gsap.registerPlugin(ScrollTrigger)

export default function VideoImageSlide({ blok, scrollTimeline, videoUrls }) {
  const [videoPlayer, setVideoPlayer] = useState(null)

  const handleVideoPlayerChange = newPlayer => {
    setVideoPlayer(newPlayer)
  }

  const pauseVideo = videoUrl => {
    if (videoPlayer && blok.video_link.vimeo_raw === videoUrl) {
      videoPlayer.pause()
    }
  }

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const slides = gsap.utils.toArray('.slide-wrapper')

      slides.forEach((slide, index) => {
        const videoUrl = videoUrls[index]

        const tl = gsap.timeline({
          scrollTrigger: {
            containerAnimation: scrollTimeline,
            trigger: slide,
            start: '50% left',
            scrub: true,
            // markers: true,
            onEnter: () => pauseVideo(videoUrl),
          },
        })

        const tlTwo = gsap.timeline({
          scrollTrigger: {
            containerAnimation: scrollTimeline,
            trigger: slide,
            start: '40% 90%',
            end: '40% 90%',
            scrub: true,
            // markers: true,
            onEnterBack: () => pauseVideo(videoUrl),
          },
        })
      })
    })

    return () => ctx.revert()
  }, [videoPlayer])

  return (
    <>
      <SlideContainer>
        <div className="slide-wrapper">
          <div className="slide-content">
            <Headline
              margin="0 0 18px 0"
              type="h4"
              blok={blok}
              className="headline-medium-large"
            />
            <p className="body-small">{blok.introduction}</p>
          </div>
          <div className="slide-media">
            {blok.video_link.vimeo_oembed && (
              <div class="video-wrapper">
                <Video
                  blok={{
                    vimeo_link: blok.video_link,
                    thumbnail: blok.video_thumbnail,
                    polyv_link: blok.polyv_link,
                  }}
                  onVideoPlayerChange={handleVideoPlayerChange}
                />
              </div>
            )}
            {blok.image && (
              <div class="image-wrapper">
                <ImageComponent blok={{ media: blok.image }} />
              </div>
            )}
          </div>
        </div>
      </SlideContainer>
    </>
  )
}
