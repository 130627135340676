import styled, { css } from 'styled-components'
import media from 'utils/media'
import { Row, Col } from 'components/Grid'

export const StyledHeading = styled.h3`
  font-size: ${({ fontSizeMobile }) => fontSizeMobile || '32px'};
  line-height: ${({ fontSizeMobile }) =>
    fontSizeMobile && Number(fontSizeMobile.replace('px', '')) > 32
      ? '1.4'
      : '1.33'};
  ${({ headingPositionMobile }) => {
    switch (headingPositionMobile) {
      case 'right':
        return css`
          text-align: right;
        `
      case 'center':
        return css`
          text-align: left;
          padding-left: 54%;
        `
      case 'align-center':
        return css`
          text-align: center;
        `
      default:
        return css`
          text-align: left;
        `
    }
  }}

  ${media.greaterThan('md')`
    ${({ headingPosition }) => {
      switch (headingPosition) {
        case 'right':
          return css`
            text-align: right;
          `
        case 'center':
          return css`
            text-align: left;
            padding-left: calc(50% + 24px);
          `
        case 'align-center':
          return css`
            text-align: center;
          `
        default:
          return css`
            text-align: left;
          `
      }
    }}
  `}

  ${media.greaterThan('lg')`
    font-size: ${({ fontSize }) => fontSize || '32px'};
    line-height: ${({ fontSize }) =>
      fontSize && Number(fontSize.replace('px', '')) > 40 ? '1.13' : '1.5'};
  `}
`

const StyledCol = styled(Col)`
  max-width: ${props => (props.maxWidth ? props.maxWidth + 'px' : 'unset')};
  padding-top: ${props => props.paddingTopMb}px;

  ${({ isMargin }) => {
    switch (isMargin) {
      case 'none':
        return css`
          padding-left: 0;
          padding-right: 0;
        `
      default:
        return css`
          padding-left: 24px;
          padding-right: 24px;
        `
    }
  }}
  img {
    padding-bottom: 15px;
  }
  ${media.greaterThan('md')`
    padding-top: ${props => props.paddingTop}px;
    img {
      padding-bottom: 0;
    }
  `}
`

export const LeftCol = styled(StyledCol)`
  order: ${props => (props.isRevers ? '3' : '0')};
  ${media.greaterThan('md')`
    order: 0;
    ${({ isAlign }) => {
      if (isAlign) {
        return css`
          position: absolute;
          display: blok;
          top: 0;
          left: 0;
        `
      } else {
        return css``
      }
    }}
  `}
`

export const RightCol = styled(StyledCol)`
  ${media.greaterThan('md')`
    ${({ isAlign }) => {
      if (isAlign) {
        return css`
          position: absolute;
          top: 0;
          right: 0;
        `
      } else {
        return css`
          text-align: left;
        `
      }
    }}
  `}
`

export const StyledRow = styled(Row)`
  position: relative;
  ${({ isCenter }) =>
    isCenter
      ? css`
          display: flex;
          justify-content: center;
        `
      : css``};
`
