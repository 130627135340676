import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import InlineVideo from './InlineVideo/index'

function Video({ blok }) {
  return (
    <SbEditable content={blok}>
      <VideoWrapper inline={blok.video_inline} className='margin-standard margin-end'>
        <div className="video-container">
          <InlineVideo blok={blok} />
          {blok.caption && <p className='caption'>{blok.caption}</p>}
        </div>
      </VideoWrapper>
    </SbEditable>
  )
}

export default Video

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ inline }) => (inline ? '0' : '0 64px')};

  .video-container {
    max-width: 924px;
    width: 100%;

    p {
      margin-top: 10px;
    }
  }

  @media (max-width: 834px) {
    padding: ${({ inline }) => (inline ? '0' : '0 32px')};
  }
`
