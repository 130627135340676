import React, { useEffect, useState } from 'react'
import { Col } from 'components/Grid'

import { createGlobalState } from 'react-hooks-global-state'
import cookie from 'react-cookies'

import { StyledHeading, StyledRow } from './styles'

import LockText from './LockTest'

const isSSR = typeof window !== 'undefined'
const initialState = { lockCookieValue: false }
const { useGlobalState } = createGlobalState(initialState)

function LockHalfGrid({ blok, isInline }) {
  const [lock, setLock] = useGlobalState('lockCookieValue')
  const [getCookie, setCookie] = useState(cookie.load('lockCookieValue'))

  const onSubmitDelay = () => {
    setLock(true)
  }

  const onSubmit = () => {
    cookie.save('lockCookieValue', true, { path: '/' })
    setTimeout(() => {
      onSubmitDelay()
    }, 3000)
  }

  useEffect(() => {
    setCookie(cookie.load('lockCookieValue'))
  }, [lock])

  return (
    <StyledRow isCenter={blok.isCenter} {...(isInline && { px: 0 })}>
      {blok.heading && (
        <Col
          width={[1, 1, blok.align_top_right ? 1 / 2 : 1]}
          mr={[0, 0, blok.align_top_right ? 1 / 2 : 0]}
          mb={blok.align_top_right ? [0] : [0, 0, 24]}
          order="-1"
        >
          <StyledHeading
            fontSizeMobile={blok.heading_size_mobile}
            fontSize={blok.heading_size}
            headingPosition={blok.heading_position}
            headingPositionMobile={blok.heading_position_mobile}
          >
            {blok.heading}
          </StyledHeading>
        </Col>
      )}
      {isSSR && (
        <LockText blok={blok} submitFun={onSubmit} getCookie={getCookie} />
      )}
    </StyledRow>
  )
}

export default LockHalfGrid
