import React, { useMemo } from 'react'
import SbEditable from 'storyblok-react'
import _isEmpty from 'lodash/isEmpty'

import Components from 'components/components'
import Content from 'components/Content'

import { Link } from 'components/Common'
import Logo from 'static/img/atws_full_logo.svg'

import { FooterWrapper, ContentWrapper } from './styles'
const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function Footer({ blok }) {
  const currentYear = new Date().getFullYear()
  const copyrightString = `Copyright © ${currentYear} Avenues: The World School All rights reserved.`

  return (
    <SbEditable content={blok}>
      <FooterWrapper className="footer-wrapper">
        <ContentWrapper>
          <div className="image-wrapper">
            <img src={Logo} alt="Avenues: The World School with bug" />
          </div>
          <div className="text-wrapper">
            <div className="column-one-wrapper">
              <p className="summary-text">{blok.summary}</p>
              {blok.button &&
                blok.button.map(el => {
                  return React.createElement(Components[el.component], {
                    key: el._uid,
                    blok: el,
                  })
                })}
            </div>
            <div className="column-two-wrapper">
              <div className="column-inner-wrapper">
                {blok?.columns?.map(column => {
                  return (
                    <div>
                      <h5>
                        {(IsChinaDeploy && column.title === 'Headquarters') ||
                        (IsChinaDeploy &&
                          column.title === 'Escritório central') ||
                        (IsChinaDeploy && column.title === '爱文总部')
                          ? 'ICP'
                          : column.title}
                      </h5>
                      {column?.links?.map(link => {
                        return link.component === 'link' ? (
                          <p className="link-text">
                            <Link blok={link}>{link.text}</Link>
                          </p>
                        ) : (
                          <Content
                            blok={{
                              content:
                                (IsChinaDeploy &&
                                  column.title === 'Headquarters') ||
                                (IsChinaDeploy &&
                                  column.title === 'Escritório central') ||
                                (IsChinaDeploy && column.title === '爱文总部')
                                  ? blok.icp_info
                                  : link.address,
                            }}
                          />
                        )
                      })}
                    </div>
                  )
                })}
              </div>
              <div className="copyright-wrapper">
                <p>{copyrightString}</p>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </FooterWrapper>
    </SbEditable>
  )
}

export default Footer
