import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  width: 100vw;
  min-height: 475px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 64px 60px 64px;

  @media (max-width: 834px) {
    padding: 50px 32px 65px 32px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  width: 100%;

  .image-wrapper {
    margin-bottom: 42px;
  }

  .column-one-wrapper {
    width: 35%;

    .request-info-btn {
      border: 1px solid #fff !important;
      padding: 10px 24px 10px 24px !important;
      font-family: Halyard-Medium !important;
      font-size: 20px !important;
      line-height: 24px !important;
      cursor: pointer !important;
      border-radius: 0 !important;
      background: transparent !important;
      color: #fff !important;
      margin: 0 !important;

      :hover {
        background-color: #fff !important;
        color: #000 !important;
      }
    }

    .button {
      button {
        border: 1px solid #fff !important;
        padding: 10px 24px 10px 24px !important;
        font-family: Halyard-Medium !important;
        font-size: 20px !important;
        line-height: 24px !important;
        cursor: pointer !important;
        border-radius: 0 !important;
        background: transparent !important;
        color: #fff !important;
        margin: 0 !important;

        :hover {
          background-color: #fff !important;
          color: #000 !important;
        }
      }
    }

    .summary-text {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 79px;
      color: white;
    }
  }

  .column-two-wrapper {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a:hover {
      text-decoration: underline;
    }

    .copyright-wrapper {
      display: flex;
      justify-content: flex-end;

      p {
        font-size: 12px;
        line-height: 20px;
        color: white;
      }
    }
  }

  .text-wrapper {
    display: flex;
    column-gap: 70px;
  }

  .column-inner-wrapper {
    display: flex;

    div {
      width: 190px;
      margin-right: 20px;
      color: white;

      h5 {
        font-family: Halyard-SemiBold;
        font-size: 12px;
        line-height: 23px;
        text-transform: uppercase;
        margin-bottom: 24px;
        color: white;
      }

      p {
        font-family: Halyard-Book;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.03em;
        color: white !important;
        margin: 0;
      }
    }

    div:last-child {
      margin-right: 0px;
    }
  }

  @media (max-width: 1024px) {
    .column-inner-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 0px;
    }

    .column-one-wrapper {
      width: 45%;
    }

    .column-two-wrapper {
      width: 55%;

      .copyright-wrapper {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 834px) {
    .text-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
    }

    .column-one-wrapper {
      width: 100%;

      .summary-text {
        margin-bottom: 40px;
      }
    }

    .column-two-wrapper {
      width: 100%;
    }

    .column-inner-wrapper {
      div {
        width: 100%;
      }
    }
  }

  @media (max-width: 500px) {
    .column-inner-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
`
