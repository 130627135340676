import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Content from 'components/Content'
import ImageComponent from '../image'
import Headline from '../Headline'
import IconButton from '../ButtonBase'

function Callout({ blok }) {
  return (
    <SbEditable content={blok}>
      <CalloutWrapper className="margin-large margin-end">
        <div className="callout-container">
          <div className="image-wrapper">
            <ImageComponent blok={{ media: blok.image }} />
          </div>
          <div className="content-wrapper">
            <Headline blok={blok} type="h3" className="headline-medium-large" />
            <Content className="body-large" blok={{ content: blok.content }} />
            {blok.button_text && (
              <IconButton
                blok={blok}
                marginTop="0"
                blueText={true}
              />
            )}
          </div>
        </div>
      </CalloutWrapper>
    </SbEditable>
  )
}

export default Callout

const CalloutWrapper = styled.div`
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  .callout-container {
    max-width: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-wrapper {
      width: 100%;
      max-width: 100px;
      height: 100%;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;

      p {
        text-align: center;
      }
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;
  }
`
