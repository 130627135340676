import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'
import styled, { css } from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { Row, Col } from 'components/Grid'
import { Link, Section, StyledMarkdown, PolyvVideo } from 'components/Common'
import Modals from 'components/Modal'
import Mobile from './Mobile'
import Desktop from './Desktop'

import { Lead, Note, Image, StyledContainer, VideoDiv } from './styles'

import { useDeviceSize } from 'hooks'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function parseTabs(tabs) {
  return tabs.map(item => {
    if (item.image && typeof item.image === 'string') {
      const fluidProps = getFluidGatsbyImage(item.image, {
        maxWidth: 1280,
        quality: 100,
        downloadLocal: true,
      })
      item.image = fluidProps
    }
    return item
  })
}

var TabSwitcher = function TabSwitcher({ blok }) {
  const { isMobile } = useDeviceSize()
  const [activeItem, setActive] = useState(0)
  const [tabs] = useState(parseTabs(blok.items))
  // let tabs
  // // parse tabs to save images to local
  // if (!tabs) {
  //   tabs = blok.items.map(item => {
  //     if (item.image && typeof item.image === 'string') {
  //       let fluidProps = getFluidGatsbyImage(item.image, {
  //         maxWidth: 1280,
  //         quality: 100,
  //         downloadLocal: true,
  //       })
  //       item.image = fluidProps
  //     }
  //     return item
  //   })
  // }

  function renderTabContent(data) {
    const videoSrc = !IsChinaDeploy
      ? get(data.video, 'vimeo_oembed.response.html')
      : data.video_alt

    return (
      <SbEditable content={blok}>
        <StyledContainer>
          <Row>
            {data.description && (
              <Col width={1}>
                <Lead>{data.description}</Lead>
              </Col>
            )}
            {data.image && (
              <Col width={1}>
                <Image>
                  <Img fluid={data.image} />
                </Image>
              </Col>
            )}
            {videoSrc && (
              <Col width={1}>
                {!IsChinaDeploy ? (
                  <VideoDiv dangerouslySetInnerHTML={{ __html: videoSrc }} />
                ) : (
                  data.video_alt && <PolyvVideo videoId={data.video_alt} />
                )}
              </Col>
            )}

            {data.note && (
              <Col width={1}>
                <Note>
                  <StyledMarkdown source={data.note} />
                </Note>
              </Col>
            )}
          </Row>
          <Row
            flexDirection={['column', 'row']}
            justifyContent={['flex-start']}
            mx={0}
          >
            {data.links && data.links.map(renderLink)}
          </Row>
        </StyledContainer>
      </SbEditable>
    )

    function renderLink(link, i) {
      var button = (link.button && link.button[0]) || {}
      button.textLink = 'textLink'

      if (link.component === 'link') {
        return (
          <StyledLink type="textLink" blok={link} {...link}>
            {link.text}
          </StyledLink>
        )
      } else {
        return React.createElement(Modals[link.component], {
          ...link,
          ...button,
          key: link._iud,
        })
      }
    }
  }

  return (
    <Section
      paddingTop={blok.padding_top || 0}
      paddingBottom={blok.padding_bottom || 210}
      paddingTopMb={blok.padding_top_mobile || 0}
      paddingBottomMb={blok.padding_bottom_mobile || 0}
    >
      {isMobile !== undefined &&
        (isMobile ? (
          <Mobile {...{ items: tabs, renderTabContent }} />
        ) : (
          <Desktop
            {...{ items: tabs, renderTabContent, setActive, activeItem }}
          />
        ))}
    </Section>
  )
}

const StyledLink = styled(Link)`
  margin-right: 32px;
  min-height: 60px;
`

export default function TabWrapper(props) {
  const { isMobile } = useDeviceSize()

  return <TabSwitcher {...props} isMobile={isMobile} />
}
