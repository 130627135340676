import styled from 'styled-components'

export const TeamMembersWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 64px;
  margin-bottom: 150px;

  h2 {
    font-size: 72px;
    line-height: 110px;
    margin-bottom: 110px;
    text-align: center;
  }

  @media (max-width: 834px) {
    padding: 0 32px;
  }

  @media (max-width: 550px) {
    margin-bottom: 50px;

    h2 {
      font-size: 42px;
      line-height: 1.15;
      margin-bottom: 42px;
    }
  }
`

export const TeamMembersContainer = styled.div`
  width: 100%;
  max-width: ${({ numberAcross }) =>
    numberAcross === 'three' ? '922px' : '1140px'};
  display: grid;
  grid-template-columns: ${({ numberAcross }) =>
    numberAcross === 'three' ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)'};
  grid-gap: 70px;

  @media (max-width: 834px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
`

export const TeamMember = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  perspective: 1000px;
  cursor: pointer;

  .front,
  .back {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.6s;
    backface-visibility: hidden;
  }

  .back {
    transform: rotateY(180deg);

    .outer-image-wrapper {
      width: 240px;
      height: 240px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  h5 {
    font-family: Halyard-SemiBold;
    font-size: 24px !important;
    line-height: 26px !important;
    margin-bottom: 10px;
  }

  p {
    line-height: 28px;
  }

  .outer-image-wrapper {
    margin-bottom: 16px;

    .gatsby-image-wrapper {
      width: 240px !important;
      height: 240px;
      border-radius: 50%;
    }
  }

  @media (max-width: 1150px) {
    .outer-image-wrapper {
      .gatsby-image-wrapper {
        width: ${({ numberAcross }) =>
          numberAcross === 'three' ? '240px' : '200px !important'};
        height: ${({ numberAcross }) =>
          numberAcross === 'three' ? '240px' : '200px !important'};
        border-radius: 50%;
      }
    }

    .back {
      .outer-image-wrapper {
        width: ${({ numberAcross }) =>
          numberAcross === 'three' ? '240px' : '200px !important'};
        height: ${({ numberAcross }) =>
          numberAcross === 'three' ? '240px' : '200px !important'};
      }
    }
  }

  @media (max-width: 950px) {
    .outer-image-wrapper {
      .gatsby-image-wrapper {
        width: 175px !important;
        height: 175px !important;
        border-radius: 50%;
      }
    }

    .back {
      .outer-image-wrapper {
        width: 175px !important;
        height: 175px !important;
      }
    }
  }

  @media (max-width: 834px) {
    .outer-image-wrapper {
      .gatsby-image-wrapper {
        width: 240px !important;
        height: 240px !important;
        border-radius: 50%;
      }
    }

    .back {
      .outer-image-wrapper {
        width: 240px !important;
        height: 240px !important;
      }
    }
  }

  @media (min-width: 550px) {
    :hover .front {
      transform: rotateY(180deg);
    }

    :hover .back {
      transform: rotateY(0);
    }
  }
`
