import { useEffect, useState } from 'react'

export default function useDeviceType() {
  const [isMobile, setMobile] = useState()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMobile(window.isMobile)
    }
  }, [])

  return isMobile
}
