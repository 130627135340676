import React, { useState } from 'react'
import { RemoveScroll } from 'react-remove-scroll'

import Components from 'components/components'
import XIcon from 'static/img/x_icon_dark.svg'

import {
  ModalWrapper,
  StyledModal,
  StyledModalContent,
  CloseButton,
} from './styles'

export default function NewModal({ blok }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleButtonClick = () => {
    setIsModalOpen(true)
    let header = document.getElementsByTagName("header")[0];
		header.style.zIndex = 0;
  }

  const handleXClick = () => {
    setIsModalOpen(false);
    let header = document.getElementsByTagName("header")[0];
		header.style.zIndex = 9999;
  }

  return (
    <ModalWrapper>
      <button class="request-info-btn" onClick={handleButtonClick}>
        {blok.button_text}
      </button>
      {isModalOpen ? (
        <RemoveScroll>
          <StyledModal>
            <StyledModalContent>
              <CloseButton onClick={handleXClick}>
                <img src={XIcon} width={14} />
              </CloseButton>
              {blok.modal_body &&
                blok.modal_body.map(el => {
                  return React.createElement(Components[el.component], {
                    key: el._uid,
                    blok: el,
                  })
                })}
            </StyledModalContent>
          </StyledModal>
        </RemoveScroll>
      ) : (
        ''
      )}
    </ModalWrapper>
  )
}
