import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Headline from '../Headline'
import Content from '../Content'

import IconButton from '../ButtonBase'

function TextHeroBold({ blok }) {
  return (
    <SbEditable content={blok}>
      <TextHeroBoldWrapper
        className="margin-small"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px 64px',
        }}
      >
        <div className="text-wrapper">
          <Headline
            type="h1"
            margin="0 0 15px 0"
            className="headline-semibold-large"
            blok={{ headline: blok.superheading }}
          />
          <Headline type="h2" className="headline-light-medium" blok={blok} />
          <Content
            className="body-medium"
            blok={{ content: blok.introduction }}
          />
          {blok.button_text && blok.button_type === "icon_button" && (
            <IconButton blok={blok} marginTop="0" blueText={true} />
          )}
          {blok.button_text && blok.button_type === "solid_background" && (
            <IconButton blok={blok} marginTop="0" solidBackground={true} />
          )}
        </div>
      </TextHeroBoldWrapper>
    </SbEditable>
  )
}

export default TextHeroBold

const TextHeroBoldWrapper = styled.div`
  .text-wrapper {
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
    }
  }

  @media (max-width: 834px) {
    padding: 0px 32px !important;
  }
`
