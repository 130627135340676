import React from 'react'
import { Box } from '@rebass/grid'

const Col = props => <Box {...props} />

Col.defaultProps = {
  px: 4,
  width: [1],
}

export default Col
