import loadable from '@loadable/component'

const events_section_gray_grid = loadable(() =>
  import(/*
  webpackChunkName: "tree-events",
*/ './events_section_gray_grid'),
)

const EventPage = loadable(() =>
  import(/*
  webpackChunkName: "tree-events",
*/ './EventPage'),
)

export default {
  event: EventPage,
  events_section_gray_grid,
}
