import styled from 'styled-components'
import { Section } from 'components/Common'
import { media } from 'utils'

export const StyledSection = styled(Section)`
  min-height: unset;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.lightGray};

  ${media.greaterThan('sm')`
    min-height: unset;
    margin-bottom: 11px;
  `}
`

export const Title = styled.h4`
  line-height: 1.33;
  margin-bottom: 49px;
  max-width: 456px;

  ${media.greaterThan('sm')`
    margin-bottom: 10px;
  `}
`

export const Description = styled.p`
  max-width: 456px;
  margin-bottom: 20px;

  ${media.greaterThan('sm')`
    margin-bottom: 58px;
  `}
`

export const ViewDetailsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 16px;
  height: 32px;
  width: 100%;
  padding: 0;
  transition: margin 0.3s;
  margin-top: 39px;
  color: ${({ theme, fontColor }) =>
    fontColor ? theme.colors[fontColor] : theme.colors.blueDark};

  &:focus {
    outline: none;
  }

  ${media.greaterThan('sm')`
    max-width: 459px;
    margin-top: 15px;
  `}
`
