import React, { useEffect, useRef } from 'react'

function CustomEmbedded({ blok }) {
  const elRef = useRef()
  useEffect(function loadEmbeddedForm() {
    const parentEl = blok.append_to_element ? elRef.current : document.body
    if (blok.script_src) {
      const script = document.createElement('script')
      script.src = blok.script_src.trim()
      script.onload = injectCustomScript
      parentEl.appendChild(script)
    }

    if (blok.custom_script && !blok.script_src) {
      injectCustomScript()
    }

    function injectCustomScript() {
      let customScriptHTML = blok.custom_script.replace(/[“”"]/g, "'")
      const customScript = document.createElement('script')
      customScript.innerHTML = customScriptHTML
      parentEl.appendChild(customScript)
    }
  }, [])
  let parsedHTML = blok.html && blok.html.replace(/[“”"]/g, "'")
  return (
    <div
      ref={elRef}
      {...(parsedHTML && { dangerouslySetInnerHTML: { __html: parsedHTML } })}
    />
  )
}

export default CustomEmbedded
