import styled from 'styled-components'

export const SlideContainer = styled.div`
  flex: 0 0 70vw;
  max-width: 1000px;
  display: flex;

  :first-child {
    margin-left: 64px;
  }

  .slide-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-right: 10%;
  }

  .slide-content {
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;

    p {
      text-align: center;
      line-height: 24px;
    }
  }

  .slide-media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .image-wrapper {
      width: 85%;

      .gatsby-image-wrapper > div {
        padding-bottom: 52.25% !important;
      }
    }

    .video-wrapper {
      width: 85%;

      > div {
        padding-bottom: 56.25% !important;
      }
    }
  }

  @media (max-width: 834px) {
    flex: 0 0 100vw;
    justify-content: center;

    :first-child {
      margin-left: 0px;
    }

    .slide-wrapper {
      flex: 0 0 90vw;
      margin-right: 0%;
    }

    .image-wrapper, .video-wrapper, .slide-content {
      width: 90% !important;
    }
  }
`
