import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Headline from '../Headline'

import IconButton from '../ButtonBase'

function TextHero({ blok }) {
  return (
    <SbEditable content={blok}>
      <TextHeroWrapper
        className="margin-standard"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px 64px',
        }}
      >
        <div className="text-wrapper">
          <Headline
            type="h1"
            className="headline-light-extra-large"
            blok={blok}
          />
          <p className="body-medium">{blok.introduction}</p>
            {blok.button_text && (
              <IconButton
                blok={blok}
                marginTop="32"
                blueText={true}
              />
            )}
        </div>
      </TextHeroWrapper>
    </SbEditable>
  )
}

export default TextHero

const TextHeroWrapper = styled.div`
  padding: 0 64px;
  
  .text-wrapper {
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
    }
  }

  @media (max-width: 834px) {
    padding: 0px 32px !important;
  }
`
