import styled from 'styled-components'

export const TeamMemberWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 25px;
  cursor: pointer;
  cursor: ${({ contactInfo }) => contactInfo ? 'row' : 'column'};

  :hover h5 {
    text-decoration: underline;
  }

  .media-wrapper {
    flex-shrink: 0;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    overflow: hidden;

    .gatsby-image-wrapper > div {
      padding-bottom: 100% !important;
    }
  }

  @media (max-width: 700px) {
    flex-direction: ${({ contactInfo }) => contactInfo ? 'row' : 'column'};
    justify-content: ${({ contactInfo }) => contactInfo ? 'center' : 'flex-start'};
    row-gap: 10px;

    .media-wrapper {
      width: 100px;
      height: 100px;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: ${({ contactInfo }) => contactInfo ? 'flex-start' : 'center'};

      h5 {
        text-align: ${({ contactInfo }) => contactInfo ? 'left' : 'center !important'};
      }

      p {
        text-align: ${({ contactInfo }) => contactInfo ? 'left' : 'center'};
      }
    }
  }
`
