import styled from 'styled-components'

export const StyledParagraph = styled.p`
  text-align: center;
  padding-top: 2rem;
`

export const StyledButton = styled.button`
  all: unset;
  color: #238E69;
  text-decoration: underline;
  margin-left: 5px;
  
  &:hover {
    cursor: pointer;
  }
`
