import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import media from 'utils/media'

const StyledDiv = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.neutral};
  padding: 36px 24px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  margin-right: 20px;
  max-width: 275px;
`

const TextLeft = styled.p`
  line-height: 1.1;
`

const TextRight = styled.h4`
  font-size: 20px;

  ${media.greaterThan('sm')`
    font-size: 24px;
  `}
`

const CardVertical = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <StyledDiv marginTop={blok.margin_top} marginBottom={blok.margin_bottom}>
        <div>
          <TextLeft className={'color-dark'}>{blok.text_left}</TextLeft>
        </div>
        <div>
          <TextRight>{blok.text_right}</TextRight>
        </div>
      </StyledDiv>
    </SbEditable>
  )
}

export default CardVertical
