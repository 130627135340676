export const defaultFonts = {
  h1: {
    fontSize: 'min(max(1rem, 12vw), 180px)',
    lineHeight: 0.88,
    fontFeatureSettings: '\'ss18\'',
  },
  h2: {
    fontSize: 'min(max(1rem, 7vw), 112px)',
    lineHeight: 1.107,
    fontFeatureSettings: '\'ss18\'',
  },
  h3: {
    fontSize: 'min(max(1rem, 7vw), 80px)',
    lineHeight: 1.15,
  },
  h4: {
    fontSize: 'min(max(1rem, 7vw), 56px)',
    lineHeight: 1.14,
    fontFeatureSettings: '\'ss18\'',
  },
  // h5 is used as a short "big" text block. Treat it like a "bigger" paragraph.
  h5: {
    fontSize: 'min(max(1rem, 7vw), 32px)',
    lineHeight: 1.5,
    fontWeight: 'normal',
  },
  p: {
    fontSize: 'min(max(1rem, 4vw), 18px)',
    lineHeight: 1.8,
    letterSpacing: 0.5,
  },
  li: {
    fontSize: 'min(max(1rem, 4vw), 18px)',
    lineHeight: 1.8,
  },
}
