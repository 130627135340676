import React from 'react'
import Accordion from './Accordion'

import { AccordionWrapper, StyledAccordion } from './styles'

function TabSwitcherMobile(props) {
  return (
    <AccordionWrapper n={props.items.length}>
      <StyledAccordion>
        {props.items.map((el, i) => {
          const render = () => props.renderTabContent(el)
          return (
            <Accordion
              myKey={`accordion-element--${el._uid}`}
              key={el._uid}
              title={el.title}
              render={render}
            />
          )
        })}
      </StyledAccordion>
    </AccordionWrapper>
  )
}

export default TabSwitcherMobile
