import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import { RawIntlProvider, createIntl } from 'react-intl'

import { theme } from 'utils'
import GlobalStyle from './GlobalStyles'
import ThemeContext, { defaultTheme } from 'lib/theme.context'

import messages from 'lib/intl'

const Layout = ({ children, ...props }) => {
  const [pageTheme, setPageTheme] = useState(defaultTheme)

  const lang = props.lang === 'default' ? 'en' : props.lang
  const intl = createIntl({ locale: lang, messages: messages[lang] })

  return (
    <>
      <ThemeProvider theme={{ ...theme, ...pageTheme }}>
        <ThemeContext.Provider value={{ pageTheme, setPageTheme }}>
          <RawIntlProvider value={intl}>
            <Helmet>
              <html lang={lang} />
            </Helmet>
            <Main id="main">{children}</Main>
          </RawIntlProvider>
        </ThemeContext.Provider>
      </ThemeProvider>
      <GlobalStyle />
    </>
  )
}

export default Layout

const Main = styled.div`
  min-height: calc(100vh - 225px);
`
