import styled from 'styled-components'

export const SlideContainer = styled.div`
  flex: 0 0 100vw;
  display: flex;

  .slide-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;
    width: 100%;
    column-gap: 60px;
  }

  .slide-content {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0;

    h4,
    p {
      width: 80%;
    }
  }

  .slide-media,
  .slide-content {
    width: 50%;
  }

  .slide-media {
    display: flex;
    align-items: center;
    justify-content: center;

    .image-wrapper {
      width: 100%;

      .gatsby-image-wrapper > div {
        padding-bottom: 56.25% !important;
      }
    }

    .video-wrapper {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    flex: 0 0 100vw;
    justify-content: center;

    :first-child {
      margin-left: 0px;
    }

    .slide-wrapper {
      flex: 0 0 80vw;
      margin-right: 0%;
      flex-direction: column;
    }

    .slide-media,
    .slide-content {
      width: 100%;
    }

    .slide-content {
      align-items: center;
      padding: 0;

      h4,
      p {
        text-align: center !important;
        width: 100%;
      }

      h4 {
        font-size: 28px;
        line-height: 28px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  @media (max-width: 834px) {
    .slide-wrapper {
      flex: 0 0 90vw;
    }
  }

  @media (max-width: 500px) {
  }
`
