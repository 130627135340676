import React from 'react'

import LockObj from './LockObj'
import UnlockObj from './UnlockObj'

function LockText({ blok, submitFun, getCookie }) {
  return !getCookie ? (
    <LockObj blok={blok} submitFun={submitFun} />
  ) : (
    <UnlockObj blok={blok} />
  )
}

export default LockText
