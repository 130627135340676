import React from 'react'

var ContentContext = React.createContext({
  allEvents: [],
  allCategories: [],
  allNews: {},
  allCommunityNews: {},
  allOpenEd: {},
  allAonStories: {},
})

export default ContentContext
