import React, {useEffect, Component} from 'react'
import SbEditable from 'storyblok-react'
import { ScriptTarget } from 'typescript';

function ParllayForm({ blok }) {

  useEffect(() => {
      const script = document.createElement('script');
      script.src = `https://m.parllay.cn/form/script.js?formId=${blok.form_id}`;
      script.setAttribute('fht-form-id', blok.form_id);
      script.async = true;
      document.getElementById("parllay").appendChild(script)
    
  }, [])

  return (
    <SbEditable content={blok}>
      <div id="parllay"></div>
    </SbEditable>
  )
}

export default ParllayForm
