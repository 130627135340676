import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Headline from '../Headline'
import Content from '../Content'
import IconButton from '../ButtonBase'

function BoldHeadline({ blok }) {
  return (
    <SbEditable content={blok}>
      <BoldHeadlineWrapper className="margin-small">
        <div className="text-wrapper">
          <Headline
            type="h3"
            className="headline-semibold-extra-large"
            blok={blok}
          />
          {blok.introduction && (
            <Content
              className="body-medium"
              blok={{ content: blok.introduction }}
            />
          )}
          {blok.button_text && (
            <IconButton
              blok={blok}
              marginTop="0"
              blueText={true}
              noFillIcon={true}
            />
          )}
        </div>
      </BoldHeadlineWrapper>
    </SbEditable>
  )
}

export default BoldHeadline

const BoldHeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 64px;

  .text-wrapper {
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin-bottom: 32px;
      text-align: center;
    }
  }

  @media (max-width: 834px) {
    padding: 0px 32px;
  }
`
