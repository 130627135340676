import React, { useEffect } from 'react'
import styled from 'styled-components'

const polyvScriptSrc = '//player.polyv.net/script/polyvplayer.min.js'

function PolyvVideo(props) {
  useEffect(function loadPolyvScript() {
    if (window.polyvObject) {
      loadVideo()
    } else {
      injectPolyvScript(polyvScriptSrc, loadVideo)
    }

    function loadVideo() {
      return embedPolyvObject(props.videoId)
    }

    function embedPolyvObject(videoId) {
      window.polyvObject(`#plv_${videoId}`).videoPlayer({
        // dimensions are changed via css to always take full available space
        width: '600',
        height: '338',
        vid: videoId,
        forceH5: true,
      })
    }

    function injectPolyvScript(src, fn) {
      const script = document.createElement('script')
      script.src = src
      script.onload = fn
      document.body.appendChild(script)
    }
  }, [])

  return (
    <Wrapper>
      <Video id={`plv_${props.videoId}`} />
    </Wrapper>
  )
}

export default PolyvVideo

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 57.666667%;
  overflow: hidden;
  width: 100%;
`

export const Video = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  > div {
    width: 100% !important;
    height: 100% !important;
  }
`
