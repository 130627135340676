import styled, { css } from 'styled-components'

import { media } from 'utils'

export const Wrapper = styled.div`
  padding-top: 35px;
  margin: 0 16px;

  ${media.greaterThan('sm')`
    padding-top: 0px;
  `}

  ${({ equalItemsHeight }) =>
    equalItemsHeight &&
    css`
      .slick-list {
        display: flex;
        margin: 0 -10px;
      }
      .slick-track {
        display: flex;
      }
      .slick-slide {
        float: none;
        display: flex;
        margin: 0 10px;
        & > div {
          display: flex;
          width: 100%;
        }
      }
    `}
`

export const DotsDiv = styled.div`
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: ${({ dots_position }) => dots_position || 'flex-start'};
  margin-bottom: 40px;
  button {
    opacity: 0;
    padding: 0;
    margin: 0;
  }
  ul {
    display: flex;
    padding: 0;
    margin: 0;
  }
  li {
    width: 16px;
    height: 16px;
    margin: 0 8px;
    border-radius: 50%;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightGray};
    &.slick-active {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }
`

export const ArrowLeft = styled.img`
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
`

export const ArrowRight = styled.img`
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
`
