import React, { useState, useEffect, useRef } from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'
import Player from '@vimeo/player'

import { PolyvVideo } from 'components/Common'

import { VideoDiv } from './styles'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function Video({ blok }) {
  const [isVideoVisible, setVideo] = useState(false)
  const [videoPlayer, setVideoPlayer] = useState()
  const [hideUnMuteButton, setHideUnMuteButton] = useState(false)
  const [thumbnail] = useState(getThumbnail())

  const videoSrc = !IsChinaDeploy
    ? blok.video.vimeo_oembed?.response
    : blok.video_alt


  useEffect(() => {
    if (isVideoVisible && !IsChinaDeploy) { 
      setVideoPlayer(
        new Player(`vimeo-video-${blok.video._uid}`, {
          id: blok.video.vimeo_raw,
          autoplay: 1,
          muted: window.innerWidth < 1024 ? 1 : 0,
        }),
      )
    }
  }, [isVideoVisible])

  function playVideo() {
    /* fire GTM event */
    if (typeof window.__vimeoRefresh === 'function') {
      window.__vimeoRefresh()
    }

    setVideo(true)
  }

  function getThumbnail() {
    let src = ''
    if (IsChinaDeploy) {
      src = getFluidGatsbyImage(get(blok, 'video_thumbnail'), {
        maxWidth: 1200,
        downloadLocal: true,
      })
    } else {
      src =
        get(blok, 'video_thumbnail') ||
        get(blok, 'video.vimeo_oembed.response.thumbnail_url', '').replace(
          /_\d*x\d*./g /* remove sizing from URL */,
          '.',
        )
    }
    return src
  }

  const handleUnMuteMobile = () =>
    videoPlayer.setMuted(0).then(() => {
      setHideUnMuteButton(true)
    })

  return !isVideoVisible && thumbnail ? (
    <VideoDiv>
      {typeof thumbnail === 'string' ? (
        <img src={thumbnail} />
      ) : (
        <Img fluid={thumbnail} />
      )}
      <button type="button" aria-label="play" onClick={playVideo}>
        {playButtonSVG}
      </button>
    </VideoDiv>
  ) : !IsChinaDeploy ? (
    <VideoDiv>
      <div id={`vimeo-video-${blok.video._uid}`} />
      {!hideUnMuteButton && (
        <button
          className="unMuteButton"
          aria-label="unmute"
          onClick={handleUnMuteMobile}
        >
          UNMUTE
        </button>
      )}
    </VideoDiv>
  ) : (
    <PolyvVideo videoId={videoSrc} />
  )
}

const playButtonSVG = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 104 104"
    enableBackground="new 0 0 104 104"
    xmlSpace="preserve"
  >
    <path
      stroke="#000000"
      strokeWidth="4"
      strokeMiterlimit="10"
      d="M26,35h52L52,81L26,35z"
    />
    <circle
      className="video-play-circle"
      fill="none"
      stroke="#000000"
      strokeWidth="4"
      strokeMiterlimit="10"
      cx="52"
      cy="52"
      r="50"
    />
  </svg>
)

export default Video
