import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'
import media from 'utils/media'

const StyledDiv = styled.div`
margin-bottom: ${props => (props.margin_bottom_m ? props.margin_bottom_m + 'px !important' : '20px')};
margin-top: ${props => (props.margin_top ? props.margin_top + 'px !important' : '0px')};
text-align: ${props =>(props.heading_align ? props.heading_align : 'left') };
${media.greaterThan('md')`
    margin-bottom: ${props => (props.margin_bottom ? props.margin_bottom + 'px !important' : '20px')};
`}
`

const StyledHeading = styled.h1`
span{
    font-family: 'Halyard-Book', 'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif;
}
font-size: ${props => (props.headingSizeM ? props.headingSizeM + 'em !important' : 'auto')};
color: ${props =>(props.heading_color?props.heading_color:'#000')};
margin-bottom: ${props => (props.h_margin_bottom_m ? props.h_margin_bottom_m + 'px !important' : '0px !important')};
${media.greaterThan('md')`
    font-size: ${props => (props.headingSize ? props.headingSize + 'em !important' : 'auto')};
    margin-bottom: ${props => (props.h_margin_bottom ? props.h_margin_bottom + 'px !important' : '0px !important')};
`}

`
const StyledSubHeading = styled.h5`
font-size: ${props => (props.subheadingSizeM ? props.subheadingSizeM + 'em !important' : 'auto')};
color: ${props =>(props.subhead_color?props.subhead_color:'#000')};
${media.greaterThan('md')`
    font-size: ${props => (props.subheadingSize ? props.subheadingSize + 'em !important' : 'auto')};
`}

`

const HeadingWSubhead = ({ blok }) => {
    const hasAlt = blok.altfont;
    return (
        <SbEditable content={blok}>
            <StyledDiv
            margin_bottom = {blok.margin_bottom}
            margin_bottom_m = {blok.margin_bottom_m}
            heading_align ={blok.heading_align}
            margin_top = {blok.margin_top}>
                <StyledHeading 
                as={blok.heading_h} 
                headingSize={blok.heading_size}
                headingSizeM={blok.heading_size_m}
                h_margin_bottom = {blok.h_margin_bottom}
                h_margin_bottom_m = {blok.h_margin_bottom_m}
                heading_color = {blok.heading_color}
                >
                {blok.heading} {{hasAlt} && <span>{blok.altfont}</span>}
                </StyledHeading>
                {blok.subheading &&
                <StyledSubHeading 
                    as={blok.subhead_h}
                    subheadingSize={blok.subheading_size}
                    subheadingSizeM={blok.subheading_size_m}
                    subhead_color={blok.subhead_color}
                    >
                    {blok.subheading} 
                </StyledSubHeading>
                }
            </StyledDiv>
        </SbEditable>
    )        
}

export default HeadingWSubhead