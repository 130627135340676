import React, { useMemo } from 'react'
import SbEditable from 'storyblok-react'

import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-plugin-storyblok-image'

import { SpeakerWrapper, ImageDiv } from './styles'

function SpeakerImage({ blok }) {
  const fluidProps =
    blok.image &&
    useMemo(
      () =>
        getFluidGatsbyImage(blok.image, {
          maxWidth: 900,
          downloadLocal: true,
        }),
      blok.image,
    )

  return (
    <SbEditable content={blok}>
      <SpeakerWrapper>
        <ImageDiv>
          <Img fluid={fluidProps} alt={blok.name + " profile image"} className="speaker-image"/>
        </ImageDiv>
        <div>
          <h5>{blok.name}</h5>
          <h5>{blok.title}</h5>
          <p>{blok.description}</p>
        </div>
      </SpeakerWrapper>
    </SbEditable>
  )
}

export default SpeakerImage
