import React from 'react'
import { components } from 'react-select'
import { Checkbox } from './styles'

export default function Option(props) {
  return (
    <components.Option {...props}>
      <Checkbox isFocused={props.isFocused}>
        {props.children}
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => {}}
        />
        <span className="checkmark" />
      </Checkbox>
    </components.Option>
  )
}
