import styled from 'styled-components'

export const ContentWrapper = styled.div`
  padding: 40px 0px;
  background: rgb(247, 207, 128);
  min-height: 450px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  ::before {
    content: '';
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://a.storyblok.com/f/112543/233x150/20c05d70bb/map-light.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 70%;
    opacity: 0.3;
  }

  .cta {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;

    p {
      font-family: ${({ textSize }) =>
        textSize === 'large' ? 'Halyard-Light' : 'Halyard-Book'};
      font-size: ${({ textSize }) =>
        textSize === 'large' ? '100px' : 'min(max(1rem, 7vw), 40px)'};
      line-height: ${({ textSize }) =>
        textSize === 'large' ? '108px' : '1.2'};
      text-align: ${({ textSize }) =>
        textSize === 'large' ? 'center' : 'left'};
      margin: 0;
    }
  }

  @media (max-width: 834px) {
    .cta {
      p {
        font-size: ${({ textSize }) =>
          textSize === 'large' ? '58px' : 'min(max(1rem, 7vw), 40px)'};
        line-height: ${({ textSize }) =>
          textSize === 'large' ? '1.2' : '1.2'};
      }
    }
  }

  @media (max-width: 500px) {
    min-height: 350px;

    .cta {
      width: 100%;
      padding: 0 32px;
      align-items: ${({ textSize }) =>
          textSize === 'large' ? 'center' : 'flex-start'};
    }
  }
`
