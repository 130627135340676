import React, { useLayoutEffect, useState, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import ImageComponent from '../../image'
import Video from 'components/NewVideo/InlineVideo'
import Headline from '../../Headline'
import IconButton from '../../ButtonBase'

import { SlideContainer } from './styles'

gsap.registerPlugin(ScrollTrigger)

export default function VideoImageSlide({ blok, scrollTimeline, videoUrls }) {
  const [videoPlayer, setVideoPlayer] = useState(null)

  const handleVideoPlayerChange = newPlayer => {
    setVideoPlayer(newPlayer)
  }

  const pauseVideo = videoUrl => {
    if (videoPlayer && blok.video_link.vimeo_raw === videoUrl) {
      videoPlayer.pause()
    }
  }

  useLayoutEffect(() => {
    let mm = gsap.matchMedia(),
      breakPoint = 500

    mm.add(
      {
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
      },
      context => {
        let { isDesktop, isMobile } = context.conditions
        const slides = gsap.utils.toArray('.slide-wrapper')

        slides.forEach((slide, index) => {
          const videoUrl = videoUrls[index]

          const tl = gsap.timeline({
            scrollTrigger: {
              containerAnimation: scrollTimeline,
              trigger: slide,
              start: isDesktop ? '40% left' : '50% left',
              scrub: true,
              // markers: true,
              onEnter: () => pauseVideo(videoUrl),
            },
          })

          const tlTwo = gsap.timeline({
            scrollTrigger: {
              containerAnimation: scrollTimeline,
              trigger: slide,
              start: isDesktop ? '10% 90%' : '50% 90%',
              end: isDesktop ? '10% 90%' : '50% 90%',
              scrub: true,
              // markers: true,
              onEnterBack: () => pauseVideo(videoUrl),
            },
          })
        })

        return () => {}
      },
    )
  }, [videoPlayer])

  return (
    <>
      <SlideContainer>
        <div className="slide-wrapper">
          <div className="slide-content">
            {blok.headline && (
              <Headline
                type="h4"
                blok={blok}
                className="headline-medium-large"
                textAlign="left"
                margin={blok.headline_line_two ? '0' : '0 0 32px 0'}
              />
            )}
            {blok.headline_line_two && (
              <Headline
                type="h4"
                blok={{ headline: blok.headline_line_two }}
                className="headline-medium-large"
                textAlign="left"
                margin={blok.headline_line_three ? '0' : '0 0 32px 0'}
              />
            )}
            {blok.headline_line_three && (
              <Headline
                type="h4"
                blok={{ headline: blok.headline_line_three }}
                className="headline-medium-large"
                textAlign="left"
              />
            )}
            <p className="body-large">{blok.introduction}</p>
            {blok.button_text && (
              <IconButton
                blok={blok}
                marginTop="16"
                blueText={true}
                noFillIcon={true}
              />
            )}
          </div>
          <div className="slide-media">
            {blok.video_link.vimeo_oembed && (
              <div class="video-wrapper">
                <Video
                  blok={{
                    vimeo_link: blok.video_link,
                    thumbnail: blok.video_thumbnail,
                    polyv_link: blok.polyv_link,
                  }}
                  onVideoPlayerChange={handleVideoPlayerChange}
                />
              </div>
            )}
            {blok.image && (
              <div class="image-wrapper">
                <ImageComponent blok={{ media: blok.image }} />
              </div>
            )}
          </div>
        </div>
      </SlideContainer>
    </>
  )
}
