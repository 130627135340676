import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components'

import Headline from '../Headline'
import Content from '../Content'

function TwoColumnText({ blok }) {
  return (
    <SbEditable content={blok}>
      <TwoColumnTextWrapper className="margin-standard">
        <div className="two-column-container">
          <div className="column">
            <div className="icon-wrapper">
              <img src={blok.icon_one} alt="icon representing Avenues" />
            </div>
            <Headline
              type="h3"
              className="headline-medium-large"
              blok={{ headline: blok.headline_one }}
              textAlign="center"
            />
            <Content
              className="body-large"
              blok={{ content: blok.content_one }}
            />
          </div>
          <div className="column">
            <div className="icon-wrapper">
              <img src={blok.icon_two} alt="icon representing Avenues" />
            </div>
            <Headline
              type="h3"
              className="headline-medium-large"
              blok={{ headline: blok.headline_two }}
              textAlign="center"
            />
            <Content
              className="body-large"
              blok={{ content: blok.content_two }}
            />
          </div>
        </div>
      </TwoColumnTextWrapper>
    </SbEditable>
  )
}

export default TwoColumnText

const TwoColumnTextWrapper = styled.div`
  display: flex;
  padding: 0 64px;
  justify-content: center;

  .two-column-container {
    display: flex;
    column-gap: 80px;
    max-width: 1140px;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon-wrapper {
        margin-bottom: 16px;
      }
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 834px) {
    padding: 0 32px;

    .two-column-container {
      flex-direction: column;
    }
  }
`
