import styled from 'styled-components'
import media from 'utils/media'
import { Link } from 'components/Common'
import Img from 'gatsby-image'

export const CoverImage = styled(Img)`
  width: 100%;
`

export const WrapperDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5.4px ${media.greaterThan('sm')`
    flex-direction: row;
    align-items: baseline
    margin-top: 7px;
  `};
`
export const FeaturedTitle = styled.h4`
  margin-bottom: 0px;
`

export const FeaturedText = styled.p`
  display: flex;
  align-items: center;
  height: 72px;
  min-width: 165px;
  letter-spacing: 0.69px;
  text-transform: uppercase;

  ${media.greaterThan('sm')`
    justify-content: center;
    margin-right: 30px
  `}
`

export const ReadButton = styled(Link)`
  width: 185px;
  margin-top: 33px;

  ${media.greaterThan('sm')`
    margin-left: auto;
    margin-top: 36px;
  `}
`
