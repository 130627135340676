import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import { theme, media } from 'utils'
import ArrowImg from 'static/img/right_arrow_dark.svg'
import ArrowWhiteImg from 'static/img/right_arrow_white.svg'

import { removeGlobalSlug, withSlash } from 'utils/helpers'

const MyLink = ({ blok, renderAs, type, ...props }) => {
  const attrs = {
    target: blok.new_tab
      ? '_blank'
      : blok.target
      ? blok.target.join(' ')
      : '_self',
    rel: blok.new_tab || (blok.url.target === "_blank" && blok.url.url.includes("https")) ? 'noopener' : blok.rel ? blok.rel.join(' ') : '',
  }

  const extHref = _get(blok, 'url.url')
  const cachedUrl = _get(blok, 'url.cached_url', '')

  const intHref = cachedUrl && withSlash(removeGlobalSlug(cachedUrl))

  return !_isEmpty(extHref) ? (
    <StyledLink
      href={extHref}
      type={blok.type || type}
      {...attrs}
      {...props}
      {...(renderAs && { as: renderAs })}
    >
      {props.children || blok.text}
      {props.type === 'textLink' && (
        <img
          src={props.arrowStyle === 'white' ? ArrowWhiteImg : ArrowImg}
          alt="arrow"
        />
      )}
      {props.withArrow && (
        <img
          src={props.arrowStyle === 'dark' ? ArrowImg : ArrowWhiteImg}
          alt="arrow"
        />
      )}
    </StyledLink>
  ) : (
    <StyledLink
      as={attrs.target ? 'a' : renderAs || Link}
      to={intHref}
      href={intHref}
      type={blok.type || type}
      {...props}
      {...attrs}
    >
      {props.children || blok.text}
      {props.type === 'textLink' && (
        <img
          src={props.arrowStyle === 'white' ? ArrowWhiteImg : ArrowImg}
          alt="arrow"
        />
      )}
      {props.withArrow && (
        <img
          src={props.arrowStyle === 'dark' ? ArrowImg : ArrowWhiteImg}
          alt="arrow"
        />
      )}
    </StyledLink>
  )
}

export default React.memo(MyLink)

const StyledLink = styled.a`
  ${({ theme, type }) =>
    type && type.includes('ButtonStyle')
      ? theme.buttons[theme[type]]
      : theme.buttons[type]}
  ${({ theme, size }) => size && theme.buttonsSize[size]}
  ${({ theme, buttonOption }) =>
    buttonOption && theme.buttonOptions[buttonOption]}
  ${({ theme, withArrow }) => withArrow && theme.buttonOptions.withArrow}
  ${({ theme, withUnderline }) =>
    withUnderline && theme.buttonOptions.withUnderline}
  ${({ theme, withSmallUnderline }) =>
    withSmallUnderline && theme.buttonOptions.withSmallUnderline}
  border-color: ${({ borderColor, theme }) =>
    borderColor
      ? theme.colors[borderColor] || borderColor
      : theme.colors[theme.accentColor]};

  ${({ position }) => {
    if (!position) return null
    const positionS = parsePosition(position.xs)
    const positionL = parsePosition(position.lg)

    return css`
      margin-left: ${positionS.left} !important;
      margin-right: ${positionS.right} !important;
      ${media.greaterThan('md')`
        margin-left: ${positionL.left} !important;
        margin-right: ${positionL.right} !important;
      `}
    `
  }}
`

function parsePosition(val) {
  let left, right
  switch (val) {
    case 'center':
      left = right = 'auto'
      break
    case 'right':
      left = 'auto'
      right = 0
      break
    default:
      // left
      left = 0
      right = 'auto'
  }

  return { left, right }
}

MyLink.propTypes = {
  type: PropTypes.oneOf([
    ...Object.keys(theme.buttons),
    'primaryButtonStyle',
    'secondaryButtonStyle',
  ]),
  size: PropTypes.oneOf(Object.keys(theme.buttonsSize)),
  option: PropTypes.oneOf(Object.keys(theme.buttonOptions)),
  css: PropTypes.string,
  borderColor: PropTypes.oneOf(Object.keys(theme.colors)),
  position: PropTypes.shape({ xs: PropTypes.string, lg: PropTypes.string }),
}
