import React from 'react'

export default function Headline({ blok, type, className, margin, textAlign, letterSpacing, textTransform }) {
  const HeadlineTag = type ? type : 'h3'

  const headlineStyle = {
    margin: margin || '0 0 32px 0',
    textAlign: textAlign || 'center',
    letterSpacing: letterSpacing || '0',
    textTransform: textTransform || 'initial'
  }

  return (
    <HeadlineTag className={className} style={headlineStyle}>
      {blok.headline}
    </HeadlineTag>
  )
}
