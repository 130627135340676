import React, { useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'

import _get from 'lodash/get'

import { Container } from 'components/Grid'
import { Background, StyledSection, StyledHeadline } from './styles'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

export default function HeroRotating({ blok }) {
  const wordList = []
  const [words, setWords] = useState(blok?.word_selection[0]?.text)

  useEffect(() => {
    blok.word_selection.map(word => {
      wordList.push(word.text)
    })

    wordList.forEach((word, i) =>
      setTimeout(() => setWords(word), (i + 1) * 950),
    )
  }, [])

  const backgroundVideoSrc = !IsChinaDeploy
    ? blok.background_video
    : blok.background_video_alt

  const imageSrc = blok.background_image?.filename

  return (
    <SbEditable content={blok}>
      <StyledSection>
        <Container>
          <StyledHeadline>
            {blok.headline}
            <br></br>
            {words}
          </StyledHeadline>
        </Container>
        {imageSrc && (
          <Background opacity={_get(blok, 'background_opacity', 1)}>
            <img src={imageSrc} alt={blok.background_image.alt ? blok.background_image.alt : ""}/>
          </Background>
        )}
        <Background opacity={_get(blok, 'background_opacity', 1)}>
          <video autoPlay loop playsInline muted src={backgroundVideoSrc} />
        </Background>
      </StyledSection>
    </SbEditable>
  )
}
