import React from 'react'
import SbEditable from 'storyblok-react'

import { Section, StyledMarkdown } from 'components/Common'

import { Container, Row, Col } from 'components/Grid'

function MarkdownSection({ blok }) {
  return (
    <SbEditable content={blok}>
      <Section
        paddingTop={blok.padding_top || 0}
        paddingTopMb={blok.padding_top_mb || 0}
        paddingBottom={blok.padding_bottom || 0}
        paddingBottomMb={blok.padding_bottom_mb || 0}
      >
        <Container>
          <Row justifyContent="center">
            <Col width={[1, 1, 1, 8 / 12]}>
              <StyledMarkdown source={blok.body} />
            </Col>
          </Row>
        </Container>
      </Section>
    </SbEditable>
  )
}

export default MarkdownSection
