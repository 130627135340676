import React from 'react'
import { RemoveScroll } from 'react-remove-scroll'

import Content from 'components/Content'
import XIcon from 'static/img/x_icon_dark.svg'
import ImageComponent from '../image'
import Headline from '../Headline'

import { StyledModal, StyledModalContent, CloseButton } from './styles'

export default function TeamMemberModal({ profile, closeModal, lang }) {
  console.log(lang)
  return (
    <div>
      <RemoveScroll>
        <StyledModal>
          <StyledModalContent>
            <CloseButton onClick={closeModal}>
              <img src={XIcon} width={14} />
            </CloseButton>
            <div className="modal-wrapper">
              <div className="image-wrapper">
                <div className="image-inner-wrapper">
                  <ImageComponent blok={{ media: profile.content.image }} />
                  <Headline
                    type="h5"
                    blok={{ headline: profile.content.name }}
                    margin="0"
                    className="bio-semibold-small"
                    letterSpacing=".8px"
                  />
                  <p className="title body-small">
                    {profile.content.title}
                    {profile.content.campus &&
                      ((lang === 'zh' && ' ') || ', ') +
                        (lang === 'zh' && profile.content.campus === 'New York'
                          ? '爱文纽约'
                          : lang === 'zh' &&
                            profile.content.campus === 'São Paulo'
                          ? '爱文圣保罗'
                          : lang === 'zh' &&
                            profile.content.campus === 'Shenzhen'
                          ? '爱文深圳'
                          : lang === 'zh' && profile.content.campus === 'Online'
                          ? '爱文在线'
                          : lang === 'zh' &&
                            profile.content.campus === 'Silicon Valley'
                          ? '爱文硅谷'
                          : profile.content.campus)}
                  </p>
                  {profile.content.email && (
                    <a
                      className="email"
                      href={`mailto:${profile.content.email}`}
                    >
                      {profile.content.email}
                    </a>
                  )}
                  {profile.content.phone_number && (
                    <a
                      className="phone"
                      href={`tel:${profile.content.phone_number}`}
                    >
                      {profile.content.phone_number}
                    </a>
                  )}
                  {/* profile.content.open_ed_link && (
                    <IconButton
                      blok={{
                        button_link: profile.content.open_ed_link,
                        button_text: 'Watch the episode',
                      }}
                      marginTop="16"
                      solidBackground={true}
                    />
                  )*/}
                </div>
              </div>
              <div className="content-wrapper">
                <Content blok={{ content: profile.content.content }} />
              </div>
            </div>
          </StyledModalContent>
        </StyledModal>
      </RemoveScroll>
    </div>
  )
}
