import React, { useEffect, useState, useContext } from 'react'
import HubspotForm from 'react-hubspot-form'
import { atcb_action } from 'add-to-calendar-button'

import SbEditable from 'storyblok-react'
import ContentContext from 'lib/content.context'
import CButton from 'components/Common/CButton'
import calendarIcon from 'static/img/calendar_white.svg'

import { HubspotFormWrapper, LoaderStyled, CalendarDiv } from './styles'

import 'add-to-calendar-button/assets/css/atcb.css'

const HUBSPOT_PORTAL_ID = process.env.GATSBY_HUBSPOT_PORTAL_ID

const HubSpotForm = ({ blok, eventCardData, lang }) => {
  /* States */
  const [data, setData] = useState({})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  //Utilizes the eventData passed in the storyblok-entry.js template
  const { eventData: eventData } = useContext(ContentContext)
  const timezoneAbrvOverride =
    eventData?.timezone_override &&
    eventData.timezone_override.split(',')[0].trim()
  const timezoneOverride =
    eventData?.timezone_override &&
    eventData.timezone_override.split(',')[1].trim()

  //Sets timezone value used in Time Input
  const timeZoneValue =
    lang === 'pt'
      ? ' '
      : timezoneAbrvOverride
      ? timezoneAbrvOverride
      : eventData?.location === 'New York' || eventData?.location === 'Online'
      ? 'EST'
      : eventData?.location === 'Shenzhen'
      ? 'CST'
      : eventData?.location === 'São Paulo'
      ? 'BST'
      : eventData?.location === 'Silicon Valley'
      ? 'PST'
      : ''

  // check if function come form lock_half_grid
  const customFunction = blok.snippet_after_form_submission_function
    ? blok.snippet_after_form_submission_function
    : (blok.snippet_after_form_submission &&
        new Function(blok.snippet_after_form_submission)) ||
      null

  /* Effects */
  useEffect(() => {
    window.addEventListener('message', handleHubSpotFormEventListener)
    return () => {
      window.removeEventListener('message', handleHubSpotFormEventListener)
    }
  }, [])

  useEffect(() => {
    if (data.formId && isFormSubmitted) {
      runCustomSnippet()
    }
  }, [data, isFormSubmitted])

  function handleHiddenFieldsTwo() {
    const altDate = eventData?.date
    const date = blok.event_last_registered_date

    const nameValue = eventData?.location + ' ' + altDate
    const dateValue =
      blok.event_last_registered_date === undefined || altDate === undefined
        ? ''
        : blok.event_last_registered_date === ''
        ? altDate.slice(0, -6)
        : date.slice(0, -6)
    const timeValue =
      blok.event_last_registered_time === '' ||
      blok.event_last_registered_time === undefined
        ? eventData?.time
        : blok.event_last_registered_time
    const urlValue =
      blok.event_last_registered_url === '' ||
      blok.event_last_registered_url === undefined
        ? eventData?.link
        : blok.event_last_registered_url
    const titleValue =
      blok.event_last_registered_external_title === '' ||
      blok.event_last_registered_external_title === undefined
        ? eventData?.external_title
        : blok.event_last_registered_external_title
    const categoryValue =
      blok.event_last_registered_category === '' ||
      blok.event_last_registered_category === undefined
        ? eventData?.category
        : blok.event_last_registered_category
    const streetValue =
      blok.event_street === '' || blok.event_street === undefined
        ? eventData?.street
        : blok.event_street
    const cityValue =
      blok.event_city === '' || blok.event_city === undefined
        ? eventData?.city
        : blok.event_city
    const stateValue =
      blok.event_state === '' || blok.event_state === undefined
        ? eventData?.state
        : blok.event_state
    const zipValue =
      blok.event_zip === '' || blok.event_zip === undefined
        ? eventData?.zip
        : blok.event_zip
    const urlIdValue = eventData?.page_slug
    const maxGuestValue =
      eventData?.max_guests === '' ? 0 : eventData?.max_guests
    const spDateValue = eventData?.sp_date
    const zoomPasscodeValue = eventData?.zoom_passcode

    const nameInput = document.querySelector(
      'input[name="event_last_registered___name"]',
    )
    const dateInput = document.querySelector(
      'input[name="event_last_registered___date"]',
    )
    const timeInput = document.querySelector(
      'input[name="event_last_registered___time"]',
    )
    const urlInput = document.querySelector(
      'input[name="event_last_registered___url"]',
    )
    const titleInput = document.querySelector(
      'input[name="event_last_registered___external_title"]',
    )
    const categoryInput = document.querySelector(
      'input[name="event_last_registered___category"]',
    )
    const streetInput = document.querySelector(
      'input[name="event___street_address"]',
    )
    const cityInput = document.querySelector('input[name="event___city"]')
    const stateInput = document.querySelector(
      'input[name="event___state_region"]',
    )
    const zipInput = document.querySelector('input[name="event___postal_code"]')
    const urlIdInput = document.querySelector(
      'input[name="event_last_registered___url_id"]',
    )
    const maxGuestInput = document.querySelector(
      'input[name="maximum___of_guests"]',
    )
    const spDateInput = document.querySelector(
      'input[name="sp_event_last_registered___date"]',
    )
    const zoomPasscodeInput = document.querySelector(
      'input[name="event_last_registered___zoom_passcode"]',
    )

    if (nameInput) {
      nameInput.value = nameValue
      nameInput.dispatchEvent(new Event('nameInput', { bubbles: true }))
    }

    if (dateInput) {
      dateInput.value = dateValue
      dateInput.dispatchEvent(new Event('dateInput', { bubbles: true }))
    }

    if (timeInput) {
      timeInput.value = timeValue
      timeInput.dispatchEvent(new Event('timeInput', { bubbles: true }))
    }

    if (urlInput) {
      urlInput.value = urlValue
      urlInput.dispatchEvent(new Event('urlInput', { bubbles: true }))
    }

    if (titleInput) {
      titleInput.value = titleValue
      titleInput.dispatchEvent(new Event('titleInput', { bubbles: true }))
    }

    if (categoryInput) {
      categoryInput.value = categoryValue
      categoryInput.dispatchEvent(new Event('categoryInput', { bubbles: true }))
    }

    if (streetInput) {
      streetInput.value = streetValue
      streetInput.dispatchEvent(new Event('streetInput', { bubbles: true }))
    }

    if (cityInput) {
      cityInput.value = cityValue
      cityInput.dispatchEvent(new Event('cityInput', { bubbles: true }))
    }

    if (stateInput) {
      stateInput.value = stateValue
      stateInput.dispatchEvent(new Event('stateInput', { bubbles: true }))
    }

    if (zipInput) {
      zipInput.value = zipValue
      zipInput.dispatchEvent(new Event('zipInput', { bubbles: true }))
    }

    if (urlIdInput) {
      urlIdInput.value = urlIdValue
      urlIdInput.dispatchEvent(new Event('urlIdInput', { bubbles: true }))
    }

    if (maxGuestInput) {
      maxGuestInput.value = maxGuestValue
      maxGuestInput.dispatchEvent(new Event('maxGuestInput', { bubbles: true }))
    }

    if (spDateInput) {
      spDateInput.value = spDateValue
      spDateInput.dispatchEvent(new Event('spDateInput', { bubbles: true }))
    }

    if (zoomPasscodeInput) {
      zoomPasscodeInput.value = zoomPasscodeValue
      zoomPasscodeInput.dispatchEvent(
        new Event('zoomPasscodeInput', { bubbles: true }),
      )
    }
  }

  /* Methods */
  /* 
  Function selects all Hidden Fields from HubSpot 
  Checks to see if field exists, if doesn't exist moves to next field in Hubspot
  If exists, inputs the value from Storyblok as the field value and submits to Hubspot
  */
  function handleHiddenFields() {
    const nameInput = document.querySelector(
      'input[name="event_last_registered___name"]',
    )
    const dateInput = document.querySelector(
      'input[name="event_last_registered___date"]',
    )
    const timeInput = document.querySelector(
      'input[name="event_last_registered___time"]',
    )
    const urlInput = document.querySelector(
      'input[name="event_last_registered___url"]',
    )
    const titleInput = document.querySelector(
      'input[name="event_last_registered___external_title"]',
    )
    const categoryInput = document.querySelector(
      'input[name="event_last_registered___category"]',
    )
    const streetInput = document.querySelector(
      'input[name="event___street_address"]',
    )
    const cityInput = document.querySelector('input[name="event___city"]')
    const stateInput = document.querySelector(
      'input[name="event___state_region"]',
    )
    const zipInput = document.querySelector('input[name="event___postal_code"]')
    const urlIdInput = document.querySelector(
      'input[name="event_last_registered___url_id"]',
    )
    const maxGuestInput = document.querySelector(
      'input[name="maximum___of_guests"]',
    )
    const spDateInput = document.querySelector(
      'input[name="sp_event_last_registered___date"]',
    )
    const zoomPasscodeInput = document.querySelector(
      'input[name="event_last_registered___zoom_passcode"]',
    )

    const timezoneOverrideInput = document.querySelector(
      'input[name="event_last_registered___timezone_override"]',
    )

    const descriptionInput = document.querySelector(
      'input[name="event_last_registered___description"]',
    )

    if (nameInput) {
      nameInput.value = eventData?.name
      nameInput.dispatchEvent(new Event('nameInput', { bubbles: true }))
    }

    if (dateInput) {
      dateInput.value = eventData?.date
      dateInput.dispatchEvent(new Event('dateInput', { bubbles: true }))
    }

    if (timeInput) {
      timeInput.value = eventData?.time + ' ' + timeZoneValue
      timeInput.dispatchEvent(new Event('timeInput', { bubbles: true }))
    }

    if (descriptionInput) {
      descriptionInput.value = eventData?.description
      descriptionInput.dispatchEvent(new Event('descriptionInput', { bubbles: true }))
    }

    if (urlInput) {
      urlInput.value = eventData?.link
      urlInput.dispatchEvent(new Event('urlInput', { bubbles: true }))
    }

    if (titleInput) {
      titleInput.value = eventData?.external_title
      titleInput.dispatchEvent(new Event('titleInput', { bubbles: true }))
    }

    if (categoryInput) {
      categoryInput.value = eventData?.category
      categoryInput.dispatchEvent(new Event('categoryInput', { bubbles: true }))
    }

    if (streetInput) {
      streetInput.value = eventData?.street
      streetInput.dispatchEvent(new Event('streetInput', { bubbles: true }))
    }

    if (cityInput) {
      cityInput.value = eventData?.city
      cityInput.dispatchEvent(new Event('cityInput', { bubbles: true }))
    }

    if (stateInput) {
      stateInput.value = eventData?.state
      stateInput.dispatchEvent(new Event('stateInput', { bubbles: true }))
    }

    if (zipInput) {
      zipInput.value = eventData?.zip
      zipInput.dispatchEvent(new Event('zipInput', { bubbles: true }))
    }

    if (urlIdInput) {
      urlIdInput.value = eventData?.page_slug
      urlIdInput.dispatchEvent(new Event('urlIdInput', { bubbles: true }))
    }

    if (maxGuestInput) {
      maxGuestInput.value =
        eventData?.max_guests === '' ? 0 : eventData?.max_guests
      maxGuestInput.dispatchEvent(new Event('maxGuestInput', { bubbles: true }))
    }

    if (spDateInput) {
      spDateInput.value = eventData?.display_date
      spDateInput.dispatchEvent(new Event('spDateInput', { bubbles: true }))
    }

    if (zoomPasscodeInput) {
      zoomPasscodeInput.value = eventData?.zoom_passcode
      zoomPasscodeInput.dispatchEvent(
        new Event('zoomPasscodeInput', { bubbles: true }),
      )
    }

    if (timezoneOverrideInput && eventData?.timezone_override) {
      timezoneOverrideInput.value = eventData?.timezone_override
        ?.split(',')[1]
        ?.trim()
      timezoneOverrideInput.dispatchEvent(
        new Event(' timezoneOverrideInput', { bubbles: true }),
      )
    }
  }

  const handleHubSpotFormEventListener = event => {
    if (eventData?.component === 'event_new') {
      handleHiddenFields()
    }

    if (eventData?.component === 'event') {
      handleHiddenFieldsTwo()
    }

    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmit'
    ) {
      const serializeData = event.data.data.reduce(
        (newObject, input) => ({
          ...newObject,
          [input.name]: input.value,
        }),
        {},
      )
      setData({
        formId: blok.form_id,
        ...serializeData,
      })
    }
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      setIsFormSubmitted(true)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const runCustomSnippet = () => {
    window.HSdata = window.HSdata || {}
    window.HSdata[blok.form_id] = {
      formdata: data,
      ...(eventCardData && { eventName: eventCardData.name }),
    }
    customFunction && customFunction()
  }

  const addToCalendar = () => {
    const config = {
      name: eventData.external_title,
      description: eventData.description,
      startDate: eventData.date,
      endDate: eventData.date_end.slice(0, 10),
      startTime: eventData.calendar_time.slice(11, 16),
      endTime: eventData.date_end.slice(11, 16),
      options: [
        'Apple',
        'Google',
        'iCal',
        'Microsoft365',
        'Outlook.com',
        'Yahoo',
      ],
      timeZone: timezoneOverride
        ? timezoneOverride
        : eventData?.location === 'New York' || eventData?.location === 'Online'
        ? 'America/New_York'
        : eventData?.location === 'Shenzhen'
        ? 'Asia/Shanghai'
        : eventData?.location === 'São Paulo'
        ? 'America/Sao_Paulo'
        : eventData?.location === 'test'
        ? 'America/Sao_Paulo'
        : eventData?.location === 'Silicon Valley'
        ? 'America/Los_Angeles'
        : 'America/New_York',
      trigger: 'click',
      iCalFileName: 'Reminder-Event',
    }

    const button = document.getElementById('calendar-button')
    atcb_action(config, button)
  }

  const convertTime = timeStr => {
    const [time, modifier] = timeStr.split(' ')
    let [hours, minutes] = time.split(':')
    if (hours === '12') {
      hours = '00'
    }
    if (hours.length === 1) {
      hours = '0' + hours
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }
    return `${hours}:${minutes}`
  }

  const addToCalendarTwo = () => {
    const splitTime = eventData.time.split(' ')
    const newEndTime = splitTime[2] + ' ' + splitTime[3]

    const config = {
      name: eventData.external_title,
      description: eventData.description,
      startDate: eventData.date.slice(0, 10),
      endDate: eventData.date.slice(0, 10),
      startTime: eventData.date.slice(11, 16),
      endTime: convertTime(newEndTime),
      options: [
        'Apple',
        'Google',
        'iCal',
        'Microsoft365',
        'Outlook.com',
        'Yahoo',
      ],
      timeZone:
        eventData?.location === 'New York' || eventData?.location === 'Online'
          ? 'America/New_York'
          : eventData?.location === 'Shenzhen'
          ? 'Asia/Shanghai'
          : eventData?.location === 'São Paulo'
          ? 'America/Sao_Paulo'
          : // : eventData?.location === 'São Paulo'
          // ? 'America/Sao_Paulo'
          eventData?.location === 'Silicon Valley'
          ? 'America/Los_Angeles'
          : 'America/New_York',
      trigger: 'click',
      iCalFileName: 'Reminder-Event',
    }

    const button = document.getElementById('calendar-button')
    atcb_action(config, button)
  }

  return (
    <SbEditable content={blok}>
      <HubspotFormWrapper>
        <HubspotForm
          portalId={HUBSPOT_PORTAL_ID}
          formId={blok.form_id}
          onSubmit={form => {}}
          onReady={() => {
            const script = document.createElement('script')
            script.src =
              'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js'
            let jqueryScript = document.getElementsByTagName('script')
            let src = Array.from(jqueryScript).filter(
              item => item.src === script.src,
            )
            if (src.length === 0) {
              document.body.appendChild(script)
            }
          }}
          loading={
            <LoaderStyled>
              <div />
              <div />
              <div />
              <div />
            </LoaderStyled>
          }
          cssClass="avenueHubSpotForm"
        />
        {isFormSubmitted && eventData?.component === 'event_new' && (
          <CalendarDiv>
            <CButton
              onClick={addToCalendar}
              type="green"
              size="big"
              css={'font-size: 16px;'}
            >
              <img src={calendarIcon} />
              <span>Add to Calendar</span>
            </CButton>
          </CalendarDiv>
        )}
        {isFormSubmitted && eventData?.component === 'event' && (
          <CalendarDiv>
            <CButton
              onClick={addToCalendarTwo}
              type="green"
              size="big"
              css={'font-size: 16px;'}
            >
              <img src={calendarIcon} />
              <span>Add to Calendar</span>
            </CButton>
          </CalendarDiv>
        )}
      </HubspotFormWrapper>
    </SbEditable>
  )
}

export default HubSpotForm
