import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { theme, media } from 'utils'
import ArrowWhiteImg from 'static/img/right_arrow_white.svg'
import ArrowImg from 'static/img/right_arrow_dark.svg'

function CButton({
  type,
  htmlType,
  css,
  position_mobile,
  position_desktop,
  ...props
}) {
  return (
    <StyledButton
      type={htmlType}
      variant={type}
      {...props}
      css={css}
      buttonOption={props.buttonOption || props.button_accent}
      position={{ xs: position_mobile, lg: position_desktop }}
    >
      {props.children}
      {type === 'textLink' && <img src={ArrowImg} alt="arrow" />}
      {props.withArrow && <img src={ArrowWhiteImg} alt="arrow white" />}
    </StyledButton>
  )
}

export default React.memo(CButton)

const StyledButton = styled.button`
  ${({ theme, variant }) =>
    variant && variant.includes('ButtonStyle')
      ? theme.buttons[theme[variant]]
      : theme.buttons[variant]}
  ${({ theme, size }) => size && theme.buttonsSize[size]}
  ${({ theme, withArrow }) => withArrow && theme.buttonOptions.withArrow}
  ${({ theme, buttonOption }) =>
    buttonOption && theme.buttonOptions[buttonOption]}
  ${({ theme, withUnderline }) =>
    withUnderline && theme.buttonOptions.withUnderline}
  border-color: ${({ borderColor, theme }) =>
    borderColor
      ? theme.colors[borderColor] || borderColor
      : theme.colors[theme.accentColor]};
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';
  cursor: pointer;
  ${({ position }) => {
    if (!position) return null
    const positionS = parsePosition(position.xs)
    const positionL = parsePosition(position.lg)

    return css`
      margin-left: ${positionS.left} !important;
      margin-right: ${positionS.right} !important;
      ${media.greaterThan('md')`
        margin-left: ${positionL.left} !important;
        margin-right: ${positionL.right} !important;
      `}
    `
  }};
`

const parsePosition = val => {
  let left, right
  switch (val) {
    case 'center':
      left = right = 'auto'
      break
    case 'right':
      left = 'auto'
      right = 0
      break
    default:
      // left
      left = 0
      right = 'auto'
  }
  return { left, right }
}

CButton.propTypes = {
  type: PropTypes.oneOf(Object.keys(theme.buttons)),
  size: PropTypes.oneOf(Object.keys(theme.buttonsSize)),
  buttonOption: PropTypes.string,
  button_accent: PropTypes.string,
  withArrow: PropTypes.bool,
  css: PropTypes.string,
}

CButton.defaultProps = {
  type: 'primary',
  size: 'standard',
  withArrow: false,
}
