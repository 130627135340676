import styled from 'styled-components'

import { IconButton } from 'components/Common'

export const Wrapper = styled.div`
  display: none;

  @media (min-width: 834px) {
    position: relative;
    display: block;
  }
`

export const LangList = styled.ul`
  display: none;

  @media (min-width: 834px) {
    display: block;

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 24px;
    height: 24px;
  }

  li {
    display: block;
    float: left;
    padding: 8px;
    margin-bottom: 6px;
    width: 100%;
    position: relative;
    cursor: pointer;
    color: ${props => props.theme.colors.dark};
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  
    :last-child {
      margin-bottom: 0;
    }
  }
  
   li ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    display: none;
    width: auto;
    height: auto;
    margin 0;
    padding: 10px;
    top: 40px;
    left: unset;
    right: 0;
    bottom: unset;
    transform: unset;
    background: ${({ theme }) => theme.colors.white};
  }
  
  li:hover > ul,
  li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  
  li ul li {
    clear: both;
    width: 100%;
  }
  }
`

export const ListMobile = styled.ul`
  position: fixed;
  width: 100%;
  height: 100%;
  margin 0;
  padding: 86px 16px;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  transition: transform 0.3s ease, opacity 0.3s ease;
  -webkit-overflow-scrolling: touch;

  li {
    margin-bottom: 6px;
    width: 100%;
    position: relative;
    cursor: pointer;
    color: ${props => props.theme.colors.dark};
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;

    :last-child {
      margin-bottom: 0;
    }

    &:after {
      position: absolute;
      width: 100%;
      content: '';
      height: 2px;
      background-color: ${({ theme, accentColor }) =>
        theme.colors[accentColor]};
      opacity: 0;
      left: 0;
      top: 100%;
      transition: opacity 0.25s;
    }
    &:hover {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        opacity: 0;
        background-color: ${({ theme }) => theme.colors.neutral};
        transition: opacity 0.3s ease;
      }
      &:hover:before {
        opacity: 1;
      }
    }
    &:hover:after {
      opacity: 1;
    }
  }

  @media (min-width: 834px) {
    display: none;
  }
`

export const SelectDivMobile = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid transparent;

  img {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 834px) {
    display: none;
  }
`

export const XIcon = styled(IconButton)`
  position: absolute;
  bottom: 50px;
  left: 48%;
  transform: ${({ isVisible }) =>
    isVisible ? 'translateX(0)' : 'translateX(-40px)'};
  transition: transform 0.3s ease;

  img {
    width: 16px;
    height: 16px;
    margin: 0;
  }

  @media (min-width: 834px) {
    display: none;
  }
`
