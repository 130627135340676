import styled, { css } from 'styled-components'
import { media } from 'utils'

import { Content } from '../styles'

const modalPresets = {
  standard: css`
    ${media.greaterThan('sm')`
      padding: 20px 43px;
    `}

    ${media.greaterThan('xl')`
       width: 80%;
    `}
  `,
  splash_form: css`
    width: 100%;

    button {
      right: 32px;
    }

    ${media.greaterThan('md')`
      width: 600px;
    `}
  `,
}

export const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  padding:  0;
  overflow: scroll;
  max-height: calc(100% - 40px);
  position: fixed;
  left: 0;
  right: 0;
  top: 20px;

  p:last-child {
    margin-bottom: 0;
  }

  ${({ preset }) => modalPresets[preset || 'standard']}

  ${media.greaterThan('md')`
    top: 40px;
  `}
`
